import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Popover } from 'antd';
import { InfoIcon, Text } from 'rx-shared-lib';
import { formatPhone } from '../../API/util';
import { useTranslation } from 'react-i18next';
import { AlertIcon } from '../icons/AlertIcon';

const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0px !important;
  }
`;
const StyledInfoIcon = styled(InfoIcon)`
  ${({ theme }) => css`
    margin-left: ${theme.spacingInPx(1.5)};
    cursor: pointer;
  `}
`;
const StyledPopoverContent = styled('div')`
  ${({ theme }) => css`
    height: 40px;
    padding: 0px ${theme.spacingInPx(1.5)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;
const StyledContentRow = styled('div')`
  display: flex;
`;

const StyledArrow = styled('div')`
  width: 0;
  height: 0;
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 24px solid white;
  right: 3px;
  top: -9px;
  position: absolute;
`;

interface PrescriberDetailPopOverProps {
  npi: any;
  phone: any;
}

const TextCenter = styled(Text)`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

const NoData = () => (
  <div
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      color: '#9F5710',
      marginLeft: '0.25rem',
    }}
  >
    <AlertIcon style={{ color: '#9F5710' }} size="14" />
    <span style={{ margin: '0.15rem 0 0 0.25rem', fontSize: '0.75rem' }}>No data</span>
  </div>
);

const PrescriberDetailPopOver = ({ npi, phone }: PrescriberDetailPopOverProps) => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  return (
    <StyledPopover
      placement="bottomRight"
      trigger="click"
      arrowPointAtCenter
      overlayInnerStyle={{
        right: -7,
        position: 'absolute',
        borderRadius: 8,
        height: 64,
        width: 205,
      }}
      overlayStyle={{ borderRadius: 8 }}
      content={
        <StyledPopoverContent>
          <StyledArrow></StyledArrow>
          <StyledContentRow>
            <TextCenter shade="regular" variant="P3">
              {t('patientCard:office-label')}: {formatPhone(phone) || <NoData />}
            </TextCenter>
          </StyledContentRow>
          <StyledContentRow>
            <TextCenter shade="regular" variant="P3">
              {t('patientCard:npi-label')}: {npi}
            </TextCenter>
          </StyledContentRow>
        </StyledPopoverContent>
      }
    >
      <StyledInfoIcon color={theme.stellusPalette.aurora[80]} height={16} width={16} />
    </StyledPopover>
  );
};

export default PrescriberDetailPopOver;
