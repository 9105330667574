import React from 'react';
import { Header } from 'antd/lib/layout/layout';
import AppSelector from './AppSelector';
import moment from 'moment';
import UserProfileAvatar from './UserProfileAvatar';
import { RootState } from '../../app/store';
import { Text } from 'rx-shared-lib';
import PortalTaskBar from '../../assets/PortalTaskBar.svg';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useAppSelector } from '../../app/hooks';

const StyledRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
  `}
`;

const StyledGreetings = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 0.5;
    color: ${theme.palette.ancillary.black} !important;
  `}
`;

const StyledGreetingsText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme?.stellusPalette?.indigo?.[100]} !important;
  `}
`;

const StyledDashboardRoot = styled.div`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
  `}
`;

const StyledDate = styled.div`
  ${({ theme }) => css`
    text-align: center;
    padding-bottom: ${theme.spacingInPx(4)};
  `}
`;

const StyledDateText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme?.stellusPalette?.grey?.[90]} !important;
  `}
`;

const StyledFooterText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme?.stellusPalette?.grey?.[90]};
    text-align: center;
    padding-bottom: 20px;
  `}
`;

const StyledAvatarContainer = styled.div`
  margin-left: auto;
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NAVBAR = 'NAVBAR';
export const GREETINGS = 'GREETINGS';
export const APP_SELECTOR = 'APP_SELECTOR';
export const FOOTER = 'FOOTER';

const GenericDashboard = () => {
  const { t } = useTranslation();

  const userDetails = useAppSelector((state: RootState) => {
    return state.auth?.userDetails;
  });
  const date = moment().format('dddd, MMMM D, YYYY');

  return (
    <StyledDashboardRoot>
      <Header
        data-testid={NAVBAR}
        style={{
          backgroundImage: `url(${PortalTaskBar})`,
          color: 'white',
          display: 'flex',
          height: '80px',
          width: '100%',
          paddingRight: 24,
        }}
      >
        <StyledAvatarContainer>
          <UserProfileAvatar
            fullName={userDetails?.name || 'Abe Arthur'}
            avatarColor={userDetails?.avatarColor || '#531AF5'}
          />
        </StyledAvatarContainer>
      </Header>
      <StyledRow>
        <StyledGreetings data-testid={GREETINGS}>
          <StyledDate>
            <StyledDateText variant="SUBHEADING" shade="semibold">
              {date}
            </StyledDateText>
          </StyledDate>
          <StyledGreetingsText variant="D2" shade="regular">
            {t('common:welcome')} {userDetails?.name.split(' ')[0] || 'User'}!
          </StyledGreetingsText>
        </StyledGreetings>
        <AppSelector data-testid={APP_SELECTOR} />
      </StyledRow>
      <StyledFooterText variant="P2" shade="regular">
        Copyright © 2023 Stellus Rx. All rights reserved.
      </StyledFooterText>
    </StyledDashboardRoot>
  );
};

export default GenericDashboard;
