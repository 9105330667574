import { Card } from 'antd';
import { Link } from 'react-router-dom';
import AdhereLogo from '../../assets/AdhereLogo.svg';
import EngageLogo from '../../assets/EngageLogo.svg';
import RxInsights from '../../assets/RxInsights.svg';
import styles from './GenericDashboard.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { RX_ADHERE, RX_INSIGHTS, RX_ENGAGE } from '../../constants';
import { Text } from 'rx-shared-lib';
import styled, { css, useTheme } from 'styled-components';

const CardBoxDiv = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: center;
`;

const CardsDiv = styled.div`
  width: 60vw;
  max-width: 70vw;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
`;
const StyledAppText = styled(Text)`
  ${({ theme }) => css`
    text-align: center;
    padding-top: 136px;
  `}
`;

export const APP_CARD = 'APP_CARD';
export const APP_NAME = 'APP_NAME';

const onIconClick = (selectedApp: string) => {
  if (selectedApp === RX_ENGAGE) {
    const url = window.location.origin;
    window.open(`${url}/engage`, '_self');
  }
};

const navigate = (selectedApp: string) => {
  switch (selectedApp) {
    case RX_ADHERE:
    case RX_INSIGHTS:
      return '/medi';
    default:
      return '/';
  }
};

const getIcon = (selectedApp: string) => {
  switch (selectedApp) {
    case RX_ADHERE:
      return AdhereLogo;
    case RX_INSIGHTS:
      return RxInsights;
    default:
      return EngageLogo;
  }
};

const getAppName = (selectedApp: string) => {
  switch (selectedApp) {
    case RX_ADHERE:
      return 'Adhere';
    case RX_INSIGHTS:
      return 'Insights';
    default:
      return 'Engage';
  }
};

const getAppColor = (theme: any, selectedApp: string) => {
  switch (selectedApp) {
    case RX_ADHERE:
      return theme?.stellusPalette?.ocean?.[80];
    case RX_INSIGHTS:
      return '';
    default:
      return theme?.stellusPalette?.indigo?.[90];
  }
};

const AppSelector = () => {
  const theme: any = useTheme();
  const userDetails = useSelector((state: RootState) => {
    return state.auth?.userDetails;
  });

  return (
    <CardBoxDiv>
      <CardsDiv data-testid={APP_CARD}>
        {userDetails?.applications.map((app: any) => {
          return (
            <div key={app.guid} style={{ marginLeft: 60, marginRight: 60 }}>
              <Link
                onClick={() => onIconClick(app.icon)}
                to={navigate(app.icon)}
                data-testid={APP_NAME}
              >
                <Card
                  className={styles.card}
                  bodyStyle={{ borderRadius: 16.6154 }}
                  hoverable={true}
                  cover={<img className={styles.img} alt={app.name} src={getIcon(app.icon)} />}
                >
                  <StyledAppText variant="H2" shade="semibold" color={getAppColor(theme, app.icon)}>
                    {getAppName(app.icon)}
                  </StyledAppText>
                </Card>
              </Link>
            </div>
          );
        })}
      </CardsDiv>
    </CardBoxDiv>
  );
};

export default AppSelector;

export const exportedForTesting = {
  onIconClick,
  navigate,
  getIcon,
  getAppName,
  getAppColor,
};
