import { List, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { EditNoteIcon, FileTextIcon, Text, variantMap } from 'rx-shared-lib';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import styled, { css } from 'styled-components';
import { RootState } from '../../../../app/store';
import { NoteLogList } from '../../Types';
import NewNote from './NewNote';
export const NOTE_ITEM = 'NOTE_ITEM';
interface NoteProps {
  note: NoteLogList;
  index: number;
}
const StyledTitle = styled('div')`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
  `}
`;

const StyledEditLink = styled(Typography.Link)`
  ${({ theme }) => css`
    ${variantMap(TypographySubType.regular, 'P3')['P3'].styles}
    color: ${theme.palette.ancillary.blue} !important;
  `}
`;

const StyledListMeta = styled(List.Item.Meta)`
  ${({ theme }) => css`
    align-items: center;
    padding: ${theme.spacingInPx(4)} ${theme.spacingInPx(2)} !important;
    border-radius: 4px;
    &:hover {
      background: ${theme.palette.graphite['20']};
    }
  `}
`;

const StyledListItem = styled(List.Item)`
  ${({ theme }) => css`
    border-bottom: 0px !important;
  `}
`;
const StyledText = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: 0px !important;
    padding-right: ${theme.spacingInPx(7)} !important;
  `}
`;

const Note = ({ note, index }: NoteProps) => {
  const [hovered, setHovered] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const userDetails = useSelector((state: RootState) => {
    return state.auth?.userDetails;
  });

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const showEdit =
    hovered &&
    index === 0 &&
    (note.userId === userDetails.guid || note.metadata.user?.email === userDetails.email) &&
    moment(note?.date).isAfter(moment().add(-1, 'day'));
  return (
    <StyledListItem
      data-testid={NOTE_ITEM}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {editMode ? (
        <NewNote editMode handleCancelAddNote={toggleEditMode} note={note} />
      ) : (
        <StyledListMeta
          avatar={<div>{showEdit ? <EditNoteIcon /> : <FileTextIcon />}</div>}
          title={
            <StyledTitle>
              <div id="name" style={{ textTransform: 'capitalize' }}>
                <StyledText variant="P3" shade="bold">
                  {note?.user.name}
                </StyledText>
              </div>
              {showEdit ? (
                <StyledEditLink onClick={toggleEditMode}>Edit</StyledEditLink>
              ) : (
                <div>
                  <StyledText variant="P3" shade="regular">
                    {moment(note?.date).calendar()}
                  </StyledText>
                </div>
              )}
            </StyledTitle>
          }
          description={
            <StyledText variant="P3" shade="regular">
              {note.isEdited && 'Edited: '}
              {note?.message}
            </StyledText>
          }
        />
      )}
    </StyledListItem>
  );
};

export default Note;
