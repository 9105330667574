import React, { FC } from 'react';
import UserOptions from '../../component/UserProfile/UserOptions';
import { Button, Text } from 'rx-shared-lib';
import styled, { useTheme } from 'styled-components';

interface UserProfileAvatarProps {
  fullName: string;
  avatarColor: string;
  userBtn?: React.ReactNode;
}
export const USER_OPTIONS = 'USER_OPTIONS';
export const FIRSTNAME_CONTAINER = 'FIRSTNAME_CONTAINER';
const UserProfileAvatar: FC<UserProfileAvatarProps> = ({ fullName, avatarColor, userBtn }) => {
  const theme = useTheme();
  const getAvatarInitials = () => {
    const splittedName = fullName?.trim().split(' ');
    if (splittedName.length <= 1) {
      return splittedName[0]?.trim()?.charAt(0)?.toUpperCase() || '';
    }
    return (
      (splittedName[0]?.trim()?.charAt(0)?.toUpperCase() || '') +
      (splittedName[splittedName.length - 1]?.trim()?.charAt(0)?.toUpperCase() || '')
    );
  };

  const getFirstName = () => {
    const splittedName = fullName?.trim().split(' ');
    return `${splittedName[0]?.trim() || ''}`;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {userBtn}
      <span style={{ paddingRight: theme.spacingInPx(4) }} data-testid={FIRSTNAME_CONTAINER}>
        <Text shade="bold" color={theme.stellusPalette.ancillary.white} variant="P2">
          {getFirstName()}
        </Text>
      </span>

      <UserOptions
        data-testid={USER_OPTIONS}
        avatarColor={avatarColor}
        userInitials={getAvatarInitials()}
      />
    </div>
  );
};

export default UserProfileAvatar;
