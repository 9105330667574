import { Space } from 'antd';
import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rx-shared-lib';
import styled, { css } from 'styled-components';
import { clearRoleNames } from '../../API/util';
import { Role } from '../../features/authentication/authAPI';
import { UserDetailSection } from './DetailedUserInfo';
import PasswordSection from './PasswordSection';
export const EDIT_PROFILE_SECTION = 'EDIT_PROFILE_SECTION';
export interface EditProfileProps {
  handleSaveClick: MouseEventHandler;
  email: string;
  phone: string;
  role: Role;
}
const StyledEditContainer = styled('div')`
  ${({ theme }) => css`
    margin-top: ${theme.spacingInPx(7)};
  `}
`;
const StyledDetailRow = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const RoleName = styled(Text)`
  text-transform: capitalize;
`;
const EditProfile = ({ handleSaveClick, email, phone, role }: EditProfileProps) => {
  const { t } = useTranslation();
  return (
    <StyledEditContainer data-testid={EDIT_PROFILE_SECTION}>
      <Space style={{ width: '100%' }} direction="vertical">
        <StyledDetailRow>
          <UserDetailSection title={t('common:email')} content={email} />
          <UserDetailSection title={t('common:phone')} content={phone || 'No-Data'} />
          <PasswordSection />
          <UserDetailSection
            title={t('common:role')}
            content={
              role.name && (
                <RoleName variant="P2" shade="regular">
                  {clearRoleNames(role.name)}
                </RoleName>
              )
            }
          />
          <UserDetailSection title={t('common:groups')} />
        </StyledDetailRow>
      </Space>
    </StyledEditContainer>
  );
};
export default EditProfile;
