import { Checkbox, Text, TextVariants } from 'rx-shared-lib';
import styled, { css } from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { onAssigneeCheckboxClick, setOldAssignee } from '../dashboardSlice';

const StyledAssigneeCellHolder = styled.div`
  ${({ theme }) => css`
    display: flex;
    alignitems: center;
    minwidth: 0;
    padding-left: ${theme.spacingInPx(4)} !important;
    padding-right: ${theme.spacingInPx(4)} !important;
  `}
`;

const StyledAssignedUserNameHolder = styled.div`
  ${({ theme }) => css`
    padding-left: ${theme.spacingInPx(2)} !important;
    padding-top: ${theme.spacingInPx(2)} !important;
  `}
`;

export const AssigneeCell = (props: any) => {
  const dispatch = useAppDispatch();

  const { selectedAssignees } = useAppSelector((state: RootState) => {
    return state.dashboard;
  });

  const onCheckboxClick = () => {
    dispatch(onAssigneeCheckboxClick(props?.patientId));
    dispatch(setOldAssignee(props?.assignedUserId));
  };

  return (
    <StyledAssigneeCellHolder>
      <Checkbox
        onClick={onCheckboxClick}
        checked={Object.keys(selectedAssignees).includes(`${props.patientId}`)}
        disabled={props.disabled}
      />
      <StyledAssignedUserNameHolder>
        <Text variant={TextVariants.P2} shade="regular">
          {props?.assignedUserName?.toLowerCase()}
        </Text>
      </StyledAssignedUserNameHolder>
    </StyledAssigneeCellHolder>
  );
};
