import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../app/store';
import MeasureCard from './MeasureCard';
import Notes from './Notes/Notes';
import PatientDetails from './PatientDetails';

export const PATIENT_CARD_CONTENT = 'PATIENT_CARD_CONTENT';
export const NOTES_CONTAINER = 'NOTES_CONTAINER';
const NotesContainer = styled('div')`
  height: 90%;
`;
const StyledPatientCardContent = styled('div')`
  height: calc(100% - 72px);
`;
const PatientCard = () => {
  const { selectedPatientMeasures, selectedPatientCardTab, selectedPatientDetails } = useSelector(
    (state: RootState) => state.patient,
  );

  const notesAndPatientDetails = () => {
    if (selectedPatientCardTab === '2') {
      return (
        <NotesContainer data-testid={NOTES_CONTAINER}>
          <Notes />
        </NotesContainer>
      );
    }
    return <PatientDetails details={selectedPatientDetails} />;
  };

  return (
    <StyledPatientCardContent data-testid={PATIENT_CARD_CONTENT} id="patient-data-details">
      {selectedPatientCardTab === '1' ? (
        <>
          {selectedPatientMeasures.map((e: any) => {
            return (
              <MeasureCard
                key={e?.measureId}
                measureName={e?.measureDesc}
                measureId={e?.measureId}
                taskId={e?.taskId || ''}
                PDC={e?.pdc}
              />
            );
          })}
        </>
      ) : (
        notesAndPatientDetails()
      )}
    </StyledPatientCardContent>
  );
};

export default PatientCard;
