import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { AddNoteIcon, Text } from 'rx-shared-lib';
import styled, { css, useTheme } from 'styled-components';

const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0px !important;
  }
`;

const StyledPopoverContent = styled('div')`
  ${({ theme }) => css`
    padding: 0px ${theme.spacingInPx(1.5)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;
const StyledIcon = styled('div')`
  ${({ theme }) => css`
    border-radius: 4px;
    display: flex;
    align-items: center;
    &:hover {
      background: ${theme.stellusPalette.grey[10]};
    }
  `}
`;
const StyledArrow = styled('div')`
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 13px solid white;
  right: 10px;
  top: -6px;
  position: absolute;
`;

const AddNotePopover = ({ onClick }: any) => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  return (
    <StyledPopover
      placement="bottomRight"
      arrowPointAtCenter
      trigger="hover"
      overlayInnerStyle={{
        right: -8,
        top: -8,
        position: 'relative',
        borderRadius: 8,
        height: 42,
        width: 'max-content',
      }}
      overlayStyle={{ borderRadius: 8 }}
      content={
        <StyledPopoverContent>
          <StyledArrow></StyledArrow>
          <Text shade="regular" variant="CAPTION">
            {t('patientCard:add_note')}
          </Text>
        </StyledPopoverContent>
      }
    >
      <StyledIcon onClick={onClick}>
        <AddNoteIcon color={theme.stellusPalette.ancillary.blue} />
      </StyledIcon>
    </StyledPopover>
  );
};

export default AddNotePopover;
