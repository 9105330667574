import { Dropdown, Menu } from 'antd';
import React, { ReactChild, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rx-shared-lib';
import styled, { css } from 'styled-components';
import { useAppDispatch } from '../../app/hooks';
import { logout } from '../../features/authentication/authAPI';
import { deselectAllAssignees } from '../../features/dashboard/dashboardSlice';
import UserProfileModal from './UserProfileModal';

export const USER_OPTIONS_DROPDOWN = 'USER_OPTIONS_DROPDOWN';
export const OVERLAY_MENU = 'OVERLAY_MENU';

const StyledMenuItem = styled(Menu.Item)`
  ${({ theme }) => css`
    &:hover {
      background-color: ${theme.palette.graphite['20']};
    }
  `}
`;

export default function UserOptionsDropdown({
  children,
  avatarColor,
  userInitials,
}: {
  children: ReactChild;
  avatarColor: string;
  userInitials: string;
}) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useAppDispatch();

  const showUserProfileModal = () => {
    setIsModalVisible(true);
    dispatch(deselectAllAssignees());
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleLogout = async () => {
    await dispatch(logout);
  };

  return (
    <div data-testid={USER_OPTIONS_DROPDOWN}>
      <Dropdown
        overlay={
          <Menu data-testid={OVERLAY_MENU}>
            <StyledMenuItem key="profile" onClick={showUserProfileModal}>
              <Text shade="regular" variant="P2">
                {t('common:profile')}
              </Text>
            </StyledMenuItem>
            <StyledMenuItem key="logout" onClick={handleLogout}>
              <Text shade="regular" variant="P2">
                {t('common:logout')}
              </Text>
            </StyledMenuItem>
          </Menu>
        }
        placement="bottomRight"
        arrow
        trigger={['click']}
      >
        {children}
      </Dropdown>
      <UserProfileModal
        isModalVisible={isModalVisible}
        avatarColor={avatarColor}
        userInitials={userInitials}
        handleCancel={handleCancel}
      />
    </div>
  );
}
