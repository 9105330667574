import { List } from 'antd';
import { t } from 'i18next';
import moment from 'moment';
import { Text } from 'rx-shared-lib';
import styled, { css } from 'styled-components';
import { NoteLogList } from '../../Types';
export const LOG_SECTION = 'LOG_SECTION';
interface LogProps {
  log: Omit<NoteLogList, 'isEdited'>;
}

const StyledTitle = styled('div')`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    margin-right: ${theme.spacingInPx(2)};
    margin-left: ${theme.spacingInPx(10)};
  `}
`;
const StyledDescription = styled('div')`
  ${({ theme }) => css`
    margin-left: ${theme.spacingInPx(10)};
    margi-right: ${theme.spacingInPx(2)};
  `}
`;
const StyledListMeta = styled(List.Item.Meta)`
  ${({ theme }) => css`
    align-items: center;
    padding: 0px ${theme.spacingInPx(2)};
  `}
`;
const StyledListItem = styled(List.Item)`
  ${({ theme }) => css`
    border-bottom: 0px !important;
    padding-top: ${theme.spacingInPx(4)} !important;
    padding-bottom: ${theme.spacingInPx(4)} !important;
  `}
`;
const StyledText = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: 0px !important;
    padding: 0px ${theme.spacingInPx(10)} !important;
  `}
`;
const ContentText = styled(StyledText)`
  ${({ theme }) => css`
    text-transform: capitalize;
  `}
`;
const Log = ({ log }: LogProps) => {
  return (
    <StyledListItem data-testid={LOG_SECTION}>
      <StyledListMeta
        style={{ alignItems: 'center' }}
        title={
          <StyledTitle>
            <StyledText variant="P3" shade="semibold">
              {log?.user.name}
            </StyledText>
            <StyledText variant="P3" shade="regular">
              {moment(log?.date).calendar()}
            </StyledText>
          </StyledTitle>
        }
        description={
          <StyledDescription>
            <ContentText variant="P3" shade="regular">
              {log.message?.toLowerCase()}
              {log.reason && (
                <div>
                  <span>
                    {t('patientCard:reason')}: {log.reason?.toLowerCase()}
                  </span>
                </div>
              )}
            </ContentText>
          </StyledDescription>
        }
      />
    </StyledListItem>
  );
};

export default Log;
