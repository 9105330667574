import { ButtonProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from 'rx-shared-lib';
import styled, { css, useTheme } from 'styled-components';
import { UserMgmntIcon } from '../icons/UserMgmntIcon';

const USERS = 'USERS';

interface UserMgmntButtonProps extends ButtonProps {
  screen?: 'PATIENTS' | 'USERS';
}

const UserMgmntStyled = styled(Button)<UserMgmntButtonProps>`
  ${({ theme, screen }) => css`
    width: 108px !important;
    background: ${screen === USERS
      ? theme.stellusPalette.ocean[10]
      : theme.stellusPalette.ocean[90]} !important;
    color: ${screen === USERS
      ? theme.stellusPalette.ocean[80]
      : theme.stellusPalette.ancillary.white} !important;
    &:hover {
      background: ${screen === USERS
        ? theme.stellusPalette.ocean[20]
        : theme.stellusPalette.ocean[100]} !important;
      color: ${screen === USERS
        ? theme.stellusPalette.ocean[80]
        : theme.stellusPalette.ancillary.white} !important;
    }
    &:focus,
    &:active {
      background: ${screen === USERS
        ? theme.stellusPalette.ocean[5]
        : theme.stellusPalette.ocean[80]} !important;
      color: ${screen === USERS
        ? theme.stellusPalette.ocean[80]
        : theme.stellusPalette.ancillary.white} !important;
    }
    margin-right: 48px;
  `}
`;

const UserMgmntBtn = (props: UserMgmntButtonProps) => {
  const { t } = useTranslation();
  const theme: any = useTheme();

  return (
    <UserMgmntStyled
      {...props}
      block
      type="primary"
      size="small"
      icon={
        <UserMgmntIcon
          color={
            props.screen === USERS
              ? theme.stellusPalette.ocean[100]
              : theme.stellusPalette.ancillary.white
          }
        />
      }
    >
      {t('dashboard:users')}
    </UserMgmntStyled>
  );
};

export default UserMgmntBtn;
