import React from 'react';

export const LeftArrayIcon: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.3333 14.3335L18.6666 25.0002L29.3333 35.6668"
        stroke="#2B2B2B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
