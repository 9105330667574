import { Col, Divider, Row, notification } from 'antd';
import moment from 'moment';
import { Button, PopoverModal, Text, TextVariants } from 'rx-shared-lib';
import styled, { useTheme } from 'styled-components';
import {
  capitalizeFirstLetter,
  getReassignButtonText,
  truncateToDecimals,
} from '../../../API/util';
import MedicationPopover from '../../../component/MedicationPopover/MedicationPopover';
import PrescriberDetailPopOver from '../../../component/PrescriberDetailPopover/PrescriberDetailPopover';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { postPatientMeasureGap } from '../../../API/Monolith/patientAPI';
import { AxiosError } from 'axios';
import { WarningOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../../app/hooks';
import { setAllMeasureData, setAssigneeButtonText } from '../../../slice/PatientSlice';
import { fetchMeasures, fetchPatientDetails } from '../../../actions';
import { setOldAssignee } from '../dashboardSlice';
import { AlertIcon } from '../../../component/icons/AlertIcon';

export const MEASURE_CARD_HEADER = 'MEASURE_CARD_HEADER';

const StyledRow = styled(Row)`
  width: 100%;
`;
const StyledRightCol = styled(Col)`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  flex: 1 0 0;
`;
const StyledLeftCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-end;
`;
const StyledSubDiv = styled.div`
  display: flex;
`;
const StyledPrescriberDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const MedicationNameDiv = styled(Text)`
  display: flex;
  text-align: right;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`;
const StyledPrescriberName = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface MeasureCardHeaderProps {
  key?: any;
  measureName?: string;
  measureInfoId?: string | number;
  measureId?: string | number;
  PDC?: string;
  lastImpactDate?: string | Date;
  medications?: any[];
  medicationNamesWithDosage?: any;
  prescriberDetail?: any;
  isSingleFill: boolean;
  hasOpenGap: boolean;
  stepData?: Array<any>;
  lastImpactDateUpd?: string;
  nextExpectedFillDateUpd?: string;
  claimFound?: boolean | null;
}

const getPDCColor = (PDC: number | undefined, isSingleFill: boolean, theme: any) => {
  if (PDC === undefined || PDC === null) return '#000000';
  if (isSingleFill) {
    return '#000000';
  } else if (PDC >= 80) {
    return '#1B8233';
  }
  return theme.stellusPalette.error?.[100];
};

const MeasureCardHeader = ({
  measureName,
  measureId,
  measureInfoId,
  PDC,
  lastImpactDate,
  medications = [],
  prescriberDetail,
  isSingleFill,
  hasOpenGap,
  stepData,
  lastImpactDateUpd,
  nextExpectedFillDateUpd,
  claimFound,
}: MeasureCardHeaderProps) => {
  const [isLoadingAddTask, setLoadingAddTask] = useState(false);
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  const { t } = useTranslation();

  const { currentUser, selectedPatient } = useSelector((state: RootState) => state.dashboard);

  const role: string = (currentUser?.role || '').toLowerCase();

  const pdcValue = truncateToDecimals(PDC);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const openConfirmationModal = () => setShowConfirmationModal(true);
  const closeConfirmationModal = () => setShowConfirmationModal(false);

  const showAddTask = useMemo(
    () => !['admin'].includes(role) && !hasOpenGap && isArray(stepData) && stepData?.length === 0,
    [hasOpenGap, stepData, role],
  );

  const refetchInformation = async () => {
    dispatch(setAllMeasureData({}));
    await dispatch(fetchPatientDetails());
    dispatch(fetchMeasures());
    dispatch(setAssigneeButtonText(getReassignButtonText(selectedPatient)));
    dispatch(setOldAssignee(selectedPatient?.assignedUserId));
  };

  const createNewPatientMeasureGap = async () => {
    try {
      setLoadingAddTask(true);
      if (!selectedPatient?.patientId || !measureInfoId) return;

      await postPatientMeasureGap(Number(selectedPatient?.patientId), Number(measureInfoId));

      await refetchInformation();

      closeConfirmationModal();
    } catch (error) {
      const { response } = error as AxiosError;

      notification.error({
        message: <strong>Add Task Failed</strong>,
        description: response?.data?.message || 'Something went wrong',
        placement: 'topRight',
        icon: <WarningOutlined style={{ color: 'red' }} />,
      });
    } finally {
      setLoadingAddTask(false);
    }
  };

  const transformName = (name: string) => name.split(' ').map(capitalizeFirstLetter).join(' ');

  const claimData = medications?.[0]?.medicationData?.CLAIM ?? {};
  const lastFillDate =
    claimData?.LAST_FILL_DATE ??
    claimData?.MOST_RECENT_FILL_DATE ??
    medications?.[0]?.lastFillDate ??
    '';
  const ClaimFoundAlert = () => (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: '#FFF5EB',
        border: '1px solid #FFD6AD',
        borderRadius: '8px',
        display: 'flex',
        gap: '12px',
        marginBottom: '20px',
        padding: '8px 12px',
        width: '100%',
      }}
    >
      <AlertIcon style={{ color: '#9F5710' }} size="14" />
      <div>
        <p style={{ fontWeight: '700', margin: 0 }}>
          Claim found{' '}
          {lastFillDate && `(last fill date: ${moment(lastFillDate).format('MM/DD/YYYY')})`}
        </p>
        <span style={{ marginRight: '40px' }}>
          <span>&bull;</span> Updated LID: {moment(lastImpactDateUpd).format('MM/DD/YYYY')}
        </span>
        <span>
          <span>&bull;</span> Updated next expected fill:
          {moment(nextExpectedFillDateUpd).format('MM/DD/YYYY')}
        </span>
      </div>
    </div>
  );
  const showClaimFoundAlert = claimFound === false && lastImpactDateUpd && nextExpectedFillDateUpd;

  return (
    <StyledRow data-testid={MEASURE_CARD_HEADER}>
      {showClaimFoundAlert && <ClaimFoundAlert />}
      <StyledRightCol span={12}>
        <Text shade="bold" variant="SUBHEADING">
          {measureName}
        </Text>
        <StyledSubDiv>
          <Text shade="regular" variant="P3" color={getPDCColor(pdcValue, isSingleFill, theme)}>
            PDC: {pdcValue}
            {'%,'}&nbsp;
          </Text>
        </StyledSubDiv>
        <StyledSubDiv>
          <Text shade="regular" variant="P3">
            Last Impact: {lastImpactDate ? moment(lastImpactDate).format('MM/DD/YYYY') : ''}
          </Text>
        </StyledSubDiv>
      </StyledRightCol>
      <StyledLeftCol span={12}>
        <MedicationNameDiv>
          {medications?.map((medication: any) => (
            <MedicationPopover
              key={medication?.id}
              measureId={measureId}
              medicationInfo={medication}
            />
          ))}
        </MedicationNameDiv>
        <StyledPrescriberDiv>
          <StyledPrescriberName shade="regular" variant="P3">
            {prescriberDetail?.prescriberName}
          </StyledPrescriberName>
          <PrescriberDetailPopOver npi={prescriberDetail?.npi} phone={prescriberDetail?.phone} />
        </StyledPrescriberDiv>
      </StyledLeftCol>
      {showAddTask && (
        <StyledRow justify="center">
          <Button
            size="middle"
            onClick={openConfirmationModal}
            style={{ padding: '8px 35px', marginTop: '15px' }}
          >
            Add task
          </Button>
          <PopoverModal
            visible={showConfirmationModal}
            popoverHeader="Add task?"
            width="fit-content"
            centered={true}
            height="fit-content"
            closable={true}
            onClose={closeConfirmationModal}
            content={
              <div style={{ paddingTop: 15 }}>
                <Text variant={TextVariants.P2}>
                  Are you sure you want to add a task to{' '}
                  <strong>
                    {transformName(`${selectedPatient?.firstName} ${selectedPatient?.lastName}`)}
                  </strong>
                  ?
                </Text>
                <Button
                  size="large"
                  type="primary"
                  style={{ margin: '24px auto 0 auto', width: '100%' }}
                  onClick={createNewPatientMeasureGap}
                  isDisabled={isLoadingAddTask}
                >
                  {t('common:confirm')}
                </Button>
              </div>
            }
          />
        </StyledRow>
      )}
      <Divider style={{ margin: `${theme.spacingInPx(4)} 0 0 0` }} />
    </StyledRow>
  );
};
export default MeasureCardHeader;
