import React from 'react';

export const RightArrayIcon: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6667 35.1665L29.3334 24.4998L18.6667 13.8332"
        stroke="#2B2B2B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
