import { useState } from 'react';
import { Drawer } from 'rx-shared-lib';
import { RootState } from '../../app/store';
import LegalModal from '../../features/dashboard/legal/LegalModal';
import DefaultProfile from './DefaultProfile';
import EditProfile from './EditProfile';
import Footer from './ProfileFooter';
import ProfileHeader from './ProfileHeader';
import { useTheme } from 'styled-components';
import { useAppSelector } from '../../app/hooks';

export const USER_PROFILE_MODAL = 'USER_PROFILE_MODAL';

export default function UserProfileModal({
  isModalVisible,
  avatarColor,
  userInitials,
  handleCancel,
}: {
  isModalVisible: boolean;
  avatarColor: string;
  userInitials: string;
  handleCancel: () => void;
}) {
  const userDetails = useAppSelector((state: RootState) => state.auth.userDetails);
  const theme: any = useTheme();

  const [isEditMode, setIsEditMode] = useState(false);
  const [legalVisible, setLegalVisible] = useState(false);

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleSaveClick = () => {
    setIsEditMode(false);
  };
  const afterClose = () => {
    setIsEditMode(false);
  };
  const closeLegal = () => {
    setLegalVisible(false);
  };

  const formatMobile = () => {
    const mobile = userDetails?.mobile;
    return mobile && mobile !== 'undefined'
      ? `(${mobile.substring(0, 3)}) ${mobile.substring(3, 6)}-${mobile.substring(6, 10)}`
      : '';
  };

  const onCloseDrawer = () => {
    handleCancel();
    afterClose();
  };
  return (
    <div data-testid={USER_PROFILE_MODAL}>
      <Drawer
        width={600}
        bodyStyle={{ padding: `0px ${theme.spacingInPx(12)}` }}
        title={
          <ProfileHeader
            avatarColor={avatarColor}
            userInitials={userInitials}
            name={userDetails?.name}
            title="Title"
          />
        }
        headerStyle={{
          padding: `${theme.spacingInPx(13)} ${theme.spacingInPx(12)} 0px ${theme.spacingInPx(12)}`,
        }}
        footerStyle={{ padding: `${theme.spacingInPx(6)} ${theme.spacingInPx(12)}` }}
        placement={'right'}
        onClose={onCloseDrawer}
        visible={isModalVisible}
        footer={
          <Footer
            handleEditClick={handleEditClick}
            handleSaveClick={handleSaveClick}
            isEditMode={isEditMode}
            setLegalVisible={setLegalVisible}
          />
        }
      >
        <div>
          {isEditMode ? (
            <EditProfile
              handleSaveClick={handleSaveClick}
              email={userDetails?.email}
              phone={formatMobile()}
              role={userDetails?.roles}
            />
          ) : (
            <DefaultProfile
              handleEditClick={handleEditClick}
              setLegalVisible={setLegalVisible}
              email={userDetails?.email}
              phone={formatMobile()}
              role={userDetails?.roles}
              groups={['Group 1', 'Group 2 long name', 'Group 3']}
            />
          )}
        </div>
      </Drawer>
      <LegalModal
        handleCancel={closeLegal}
        handleOk={closeLegal}
        isModalVisible={legalVisible}
        centered
      />
    </div>
  );
}
