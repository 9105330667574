import axios from 'axios';
import { getAccessToken } from './util';
import config from '../config';
import { fetchRefreshToken } from '../features/authentication/authAPI';

export const instance = axios.create({
  timeout: Number(config.REACT_API_TIMEOUT),
});

export const requestInterceptor = async config => {
  const tokenData = await getAccessToken();

  if (tokenData && !instance.skipToken) {
    config.headers['Authorization'] = `Bearer ${tokenData}`;
  }
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
};

export const requestInterceptorError = error => {
  if (error.status === 401) {
    window.location.replace('/login');
    localStorage.clear();
  }
  return error;
};

// Add a request interceptor
instance.interceptors.request.use(requestInterceptor, requestInterceptorError);

export const responseInterceptorError = async error => {
  if (error.code === 'ECONNABORTED') return Promise.reject(error);

  const { config, status } = error?.response;

  const originalRequest = config;

  if (status !== 401) return Promise.reject(error);

  if (!originalRequest?._retry) {
    originalRequest._retry = true;

    try {
      const newAccessToken = await fetchRefreshToken();

      originalRequest.headers.Authorization = `Bearer ${newAccessToken?.access_token}`;

      return instance(originalRequest);
    } catch (error) {
      console.log(error);
      window.location.replace('/login');
      localStorage.clear();

      return Promise.reject(error);
    }
  }

  window.location.replace('/login');
  localStorage.clear();
  return Promise.reject(error);
};

export const responseInterceptor = response => {
  return response;
};

// Add a response interceptor
instance.interceptors.response.use(responseInterceptor, responseInterceptorError);

export default instance;
