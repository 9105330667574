import { toNumber, isEmpty } from 'lodash';
import moment from 'moment';
import { post } from '../API/commonAPI';
import { FetchPatientMeasures, PutStepResponse } from '../API/EventDriven/activityApi';
import {
  editPatientDetailsEds,
  FetchPatientDetailsById,
  fetchPatientLogs,
  fetchTaskData,
  searchPatientLogs,
} from '../API/EventDriven/patientAPI';
import {
  FetchMeasuresMonolith,
  FetchMeasuresDataMonolith,
  FetchPharmacyPrescriberMonolith,
  editPatientDetailsMono,
  FetchStepAnswer,
  FetchStepResponse,
  FetchPatientMedication,
  SaveEditedNote,
  SaveNote,
  FetchMonolithNotes,
  FetchMonolithLogs,
  PostGapData,
} from '../API/Monolith/patientAPI';
import {
  capitalizeString,
  isEventDrivenSystem,
  removeCommaParenthesisTrailingSpace,
} from '../API/util';
import { RootState } from '../app/store';
import config from '../config';
import {
  setAssigneeRole,
  checkAssginee,
  getMeasureDisplay,
  getStatusDisplay,
  setAnswerLoader,
  fetchPaginatedPatients,
  onPatientClosed,
} from '../features/dashboard/dashboardSlice';
import {
  NoteLogData,
  NoteLogList,
  SelectedPatientMedDetails,
  selectedPatientDetails,
} from '../features/dashboard/Types';
import {
  onFetchNoteLogs,
  refreshNoteLogData,
  setPatientMeasures,
  setPatientMedication,
  setSelectedPatientDetails,
  setSelectedPatientMedDetails,
  setStepData,
  setAllMeasureData,
} from '../slice/PatientSlice';
import { SCHEDULE_FOLLOWUP_STEP_ID } from '../utility/rules.constants';

export const fetchMeasures = () => (dispatch: any, getState: () => RootState) => {
  const selectedPatient: any = getState().dashboard.selectedPatient;

  if (!selectedPatient?.patientId) {
    return;
  }
  dispatch(setStepData([]));
  if (isEventDrivenSystem()) {
    dispatch(fetchMeasuresEDS(selectedPatient));
  } else {
    dispatch(fetchMeasuresMono(selectedPatient));
  }
};

const fetchMeasuresEDS =
  (selectedPatient: any) => async (dispatch: any, getState: () => RootState) => {
    try {
      let measureResponse = await FetchPatientMeasures(selectedPatient?.patientId);
      measureResponse = measureResponse.map((item: any) => {
        return {
          measureDesc: item.task.name,
          taskStatus: item.taskStatus,
          gapDays: item.gapDays,
          measureId: item.task._id,
          statusId: item.status,
          patient: item.patientId,
          taskId: item._id,
          pdc: toNumber(item?.cutPoint || 0),
        };
      });
      dispatch(setPatientMeasures(measureResponse));
      const patientDetails = await FetchPatientDetailsById(selectedPatient?.patientId);
      dispatch(setMedicationDetailsEDS(patientDetails, selectedPatient));
    } catch (error) {
      console.error(error);
    }
  };

const fetchMeasuresMono =
  (selectedPatient: any) => async (dispatch: any, getState: () => RootState) => {
    try {
      const isSearchPatientClick: any = getState().dashboard.searchPatientClick;
      let measureResponse = await FetchMeasuresMonolith(
        selectedPatient?.patientId,
        isSearchPatientClick,
      );
      if (measureResponse.measures.length < 1) {
        dispatch(onPatientClosed());
      } else {
        measureResponse = measureResponse.measures.map((item: any) => {
          return {
            measureDesc: getMeasureDisplay(item.measure),
            taskStatus: getStatusDisplay(item.status),
            gapDays: item.gapDaysRemaning,
            measureId: item.measure,
            statusId: item.status,
            patient: item.patient,
            taskId: item.measure,
            pdc: toNumber(item?.pdc || 0),
          };
        });
        dispatch(setPatientMeasures(measureResponse));
        measureResponse?.map((measure: any) => {
          dispatch(fetchStepDataMono(measure?.measureId));
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

const setMedicationDetailsEDS =
  (patientDetails: any, selectedPatient: any) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      const selectedPatientMeasures = getState().patient.selectedPatientMeasures;
      selectedPatientMeasures?.map(async measure => {
        const currentMeasure = {
          ...patientDetails?.measures?.find(
            (item: any) => item?.measureName?.toLowerCase() == measure?.measureDesc.toLowerCase(),
          ),
        };
        if (!isEmpty(currentMeasure)) {
          let medicationsData: any = {
            medicationName: currentMeasure?.medications?.map((x: any) => capitalizeString(x.name)),
            medicationNamesWithDosage: currentMeasure?.medications?.map((x: any) =>
              capitalizeString(x.name),
            ),
            assigneeName: selectedPatient?.assignedUserName,
            assigneeId: selectedPatient?.assignedUserId,
            pharmacyName: currentMeasure?.pharmacy?.name,
            prescriberName: currentMeasure?.prescriber?.name,
            patientName: `${patientDetails?.firstName} ${patientDetails?.lastName}`,
            patient: patientDetails,
            pharmacy: currentMeasure.pharmacy,
            prescriber: currentMeasure.prescriber,
          };
          medicationsData = {
            ...medicationsData,
            pharmacy: {
              phone: currentMeasure?.pharmacy?.phone?.office,
              fax: '',
              city: currentMeasure?.pharmacy?.address?.city,
              state: currentMeasure?.pharmacy?.address?.state,
              address: `${currentMeasure?.pharmacy?.address?.addressLine1} ${currentMeasure?.pharmacy?.address?.city} ${currentMeasure?.pharmacy?.address?.state}`,
            },
            prescriber: {
              phone: currentMeasure?.prescriber?.phone?.office,
              fax: '',
              city: currentMeasure?.prescriber?.address?.city,
              state: currentMeasure?.prescriber?.address?.state,
              address: `${currentMeasure?.prescriber?.address?.addressLine1} ${currentMeasure?.prescriber?.address?.city} ${currentMeasure?.prescriber?.address?.state}`,
            },
            patient: {
              mobilePhone: '',
              homePhone: '',
              city: patientDetails?.address?.city,
              state: patientDetails?.address?.state,
              address: `${patientDetails?.address?.addressLine1} ${patientDetails?.address?.city} ${patientDetails?.address?.state}`,
            },
          };
          let updatedAllMeasureData = { ...getState().patient.allMeasureData };
          updatedAllMeasureData[measure?.measureId] = medicationsData;
          dispatch(setAllMeasureData(updatedAllMeasureData));
          let tempTaskData;
          try {
            const res = await fetchTaskData(selectedPatient?.patientId, measure?.taskId);
            tempTaskData = res?.template?.tasks || [];
          } catch (err) {
            tempTaskData = [];
            console.log(err);
          }
          dispatchStepDataEDS(tempTaskData, measure?.measureId, dispatch, getState);
        }
      });
    } catch (error) {
      throw error;
    }
  };

export const fetchStepDataMono =
  (measureId: any) => async (dispatch: any, getState: () => RootState) => {
    const patientDetails = getState()?.patient?.selectedPatientDetails as selectedPatientDetails;

    const { patient } = patientDetails ?? {};

    if (!patient?.patientId) {
      return;
    }
    try {
      const res = await FetchMeasuresDataMonolith(patient?.patientId, measureId);
      const measureGapWithMeasureDetails = res?.measureGap?.find((gap: any) => {
        return gap.isComplete === false;
      });
      dispatch(setAssigneeRole(res?.assignee?.userData?.role));
      dispatch(checkAssginee(res?.assignee));

      // this is prescreber information
      const { prescriber, medications, measure, pharmacies } = res || {};
      const { providerData } = prescriber || {};

      const medicationsData: SelectedPatientMedDetails = {
        medicationName: res.medications.map((x: any) => capitalizeString(x.medicationName)),
        medicationNamesWithDosage: res.medications.map((x: any) =>
          [capitalizeString(x.medicationName), x.dosage].filter(Boolean).join(' - '),
        ),
        assigneeName: res.assignee.userFullName,
        assigneeId: res?.assignee?.id,
        pharmacyName: removeCommaParenthesisTrailingSpace(res.pharmacy?.pharmacyName),
        prescriberName: removeCommaParenthesisTrailingSpace(prescriber?.providerName),
        patientName: removeCommaParenthesisTrailingSpace(patient.patientName),
        patient: patient,
        pharmacy: {
          pharmacyId: res?.pharmacy?.id,
          pharmacyName: removeCommaParenthesisTrailingSpace(res?.pharmacy?.pharmacyName),
          npi: removeCommaParenthesisTrailingSpace(res?.pharmacy?.npi),
          address: res?.pharmacy?.pharmacyData?.address,
          city: res?.pharmacy?.pharmacyData?.city,
          state: res?.pharmacy?.pharmacyData?.state,
          zipcode: res?.pharmacy?.pharmacyData?.zip,
          email: res?.pharmacy?.pharmacyData?.email,
          fax: res?.pharmacy?.pharmacyData?.fax,
          phone: res?.pharmacy?.pharmacyData?.phone,
          weburl: res?.pharmacy?.pharmacyData?.address,
        },
        prescriber: {
          prescriberId: prescriber?.id,
          prescriberName: prescriber?.providerName,
          npi: removeCommaParenthesisTrailingSpace(prescriber?.npi),
          address: providerData?.address,
          city: removeCommaParenthesisTrailingSpace(providerData?.city),
          state: removeCommaParenthesisTrailingSpace(providerData?.state),
          zipcode: providerData?.zip,
          email: providerData?.email,
          fax: providerData?.fax,
          phone: providerData?.phone,
          weburl: '',
        },
        lastImpactDate: measure?.lastImpactDate,
        isSingle: measureGapWithMeasureDetails?.isSingle,
        isGapOpen: !!measureGapWithMeasureDetails,
        measureInfo: {
          hasOpenGap: measure.hasOpenGap,
          id: measure.id,
          nextExpectedFillDate: measure?.nextExpectedFillDate,
        },
        medications: medications.map((medication: any) => {
          medication.medicationNameWithDosage = [
            capitalizeString(medication.medicationName),
            medication.dosage,
          ]
            .filter(Boolean)
            .join(' - ');

          return medication;
        }),
        pharmacies: pharmacies,
        lastImpactDateUpd: measure?.lastImpactDateUpd,
        nextExpectedFillDateUpd: measure?.nextExpectedFillDateUpd,
        claimFound: measure?.claimFound,
      };
      dispatch(setSelectedPatientMedDetails(medicationsData));
      let updatedAllMeasureData = { ...getState().patient.allMeasureData };
      updatedAllMeasureData[measureId] = medicationsData;
      dispatch(setAllMeasureData(updatedAllMeasureData));
      dispatchStepDataMono(res, patient?.patientId, measureId, dispatch, getState);
    } catch (error) {
      dispatch(setSelectedPatientDetails([]));
      throw error;
    }
  };

export const fetchPatientDetails = () => async (dispatch: any, getState: () => RootState) => {
  const selectedPatient = getState().dashboard.selectedPatient;
  if (!selectedPatient?.patientId) {
    return;
  }
  try {
    if (isEventDrivenSystem()) {
      const res = await FetchPatientDetailsById(selectedPatient?.patientId);

      const patientDetails = {
        patient: {
          address: res.address.addressLine1,
          birthDate: res.dob,
          city: res.address.city,
          email: res.email,
          gender: res.gender,
          homePhone: res.phone.home,
          mobilePhone: res.phone.mobile,
          patientId: res.patientId,
          patientName: `${res.firstName} ${res.lastName}`,
          state: res.address.state,
          zipcode: res.address.zip,
        },
        prescriber: {
          // address: pres.address.addressLine1,
          // address2: pres.address.addressLine2,
          // city: pres.address.city,
          // email: pres.email,
          // fax: pres.fax,
          // npi: pres.npi,
          // phone: pres.phone.office,
          // prescriberId: pres.id,
          // prescriberName: pres.name,
          // state: pres.address.state,
          // weburl: '',
          // zipcode: pres.address.zip,
        },
        pharmacy: {
          // address: pharmRes.address.addressLine1,
          // address2: pharmRes.address.addressLine2,
          // city: pharmRes.address.city,
          // email: pharmRes.email,
          // fax: pharmRes.fax,
          // npi: pharmRes.npi,
          // pharmacyId: pharmRes.id,
          // pharmacyName: pharmRes.name,
          // phone: pharmRes.phone.office,
          // state: pharmRes.address.state,
          // weburl: '',
          // zipcode: pharmRes.address.zip,
        },
      };
      dispatch(setSelectedPatientDetails(patientDetails));
    } else {
      let res = await FetchPharmacyPrescriberMonolith(selectedPatient?.patientId);
      let { prescriber, pharmacy } = res;

      prescriber = {
        ...prescriber,
        prescriberName: removeCommaParenthesisTrailingSpace(prescriber?.prescriberName),
        npi: removeCommaParenthesisTrailingSpace(prescriber?.npi),
      };
      pharmacy = {
        ...pharmacy,
        pharmacyName: removeCommaParenthesisTrailingSpace(pharmacy?.pharmacyName),
        npi: removeCommaParenthesisTrailingSpace(pharmacy?.npi),
      };
      res = { ...res, prescriber, pharmacy };
      dispatch(setSelectedPatientDetails(res));
    }
  } catch (error) {
    dispatch(setSelectedPatientDetails([]));
    console.log(error);
  }
};

export const editPatientDetails =
  (payload: any, edsPayload: any) => async (dispatch: any, getState: () => RootState) => {
    const selectedPatient = getState().dashboard.selectedPatient;
    try {
      if (selectedPatient) {
        if (isEventDrivenSystem()) {
          const res = await editPatientDetailsEds(edsPayload, selectedPatient?.patientId);
          return res;
        } else {
          const res = await editPatientDetailsMono(selectedPatient?.patientId, payload);
          return res;
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

export const fetchStepAnswer =
  (
    measureId: any,
    taskId: any,
    stepId: string,
    questionId: string,
    answers: any,
    patientId: any,
    uId: string,
    reason?: string,
  ) =>
  async (dispatch: any, getState: () => RootState) => {
    dispatch(setAnswerLoader(measureId));
    try {
      if (isEventDrivenSystem()) {
        await dispatch(
          fetchStepAnswerEDS({
            measureId: measureId,
            taskId: taskId,
            stepId: stepId,
            questionId: questionId,
            answers: answers,
            patientId: patientId,
            uId: uId,
            reason: reason,
          }),
        );
      } else {
        await dispatch(
          fetchStepAnswerMono(measureId, stepId, questionId, answers, patientId, uId, reason),
        );
      }
    } catch (error) {
      dispatch(setAnswerLoader(false));
      console.error(error);
      throw error;
    }
  };

interface ScheduleFollowUpDateWithAnswerProps {
  measureId: any;
  taskId: any;
  stepId: string;
  questionId?: string;
  answers?: any;
  patientId: any;
  uId: string;
  scheduleFollowUp?: any;
}

export const sendScheduleFollowUpDateWithAnswer =
  ({
    measureId,
    taskId,
    stepId,
    questionId,
    answers,
    patientId,
    uId,
    scheduleFollowUp,
  }: ScheduleFollowUpDateWithAnswerProps) =>
  async (dispatch: any, getState: () => RootState) => {
    dispatch(setAnswerLoader(measureId));
    try {
      if (!isEventDrivenSystem()) {
        if (answers && questionId) {
          await dispatch(
            fetchStepAnswerMono(
              measureId,
              stepId,
              questionId,
              answers,
              patientId,
              uId,
              undefined,
              scheduleFollowUp,
            ),
          );
        }
        // await dispatch(
        //   fetchStepResponse(scheduleFollowUp, stepId, measureId, taskId, patientId, uId),
        // );
      }
    } catch (error) {
      dispatch(setAnswerLoader(false));
      console.error(error);
      throw error;
    }
  };

interface FetchStepAnswerEDSProps {
  measureId: any;
  taskId: any;
  stepId: string;
  questionId: string;
  answers: any[];
  patientId: any;
  uId: string;
  reason?: string;
}

const fetchStepAnswerEDS =
  ({
    measureId,
    taskId,
    stepId,
    questionId,
    answers,
    patientId,
    uId,
    reason,
  }: FetchStepAnswerEDSProps) =>
  async (dispatch: any, getState: () => RootState) => {
    const selectedPatient: any = getState().dashboard.selectedPatient;
    try {
      const stepPayload: any = {
        type: 'STEP_UPDATE',
        payload: {
          taskId: taskId,
          stepId,
          uId: uId.toString(),
          questions: [
            {
              id: questionId,
              answers: answers,
            },
          ],
        },
      };
      /* istanbul ignore else  */
      if (reason) {
        stepPayload.payload.reason = reason;
      }
      let res = await PutStepResponse(stepPayload, taskId);
      let tempTaskData;
      try {
        const response = await fetchTaskData(selectedPatient?.patientId, taskId);
        tempTaskData = response?.template?.tasks || [];
      } catch (err) {
        tempTaskData = [];
        console.log(err);
      }
      dispatchStepDataEDS(tempTaskData, measureId, dispatch, getState);
      dispatch(setAnswerLoader(false));
      return res;
    } catch (error) {
      throw error;
    }
  };

const fetchStepAnswerMono =
  (
    measureId: any,
    stepId: string,
    questionId: string,
    answers: any[],
    patientId: any,
    uId: string,
    reason?: string,
    date?: string,
  ) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      const dashboardState = getState()?.dashboard;
      const payload: any = {
        measureId,
        stepId,
        uId,
        questions: [
          {
            id: questionId,
            answers: answers,
          },
        ],
      };
      /* istanbul ignore else  */
      if (reason) {
        payload.reason = reason;
      }

      if (date) {
        payload.actionDate = moment(date).format('MM/DD/YYYY');
      }

      const res: any = await FetchStepAnswer(patientId, measureId, payload);
      const { tasks: updateTasks, newTask } = res;

      const answerData = getAnswerDataforSteps(updateTasks[updateTasks.length - 1]);
      const patientmedicalDetails = getState().patient.selectedPatientMedDetails;
      const allMeasure = getState().patient.allMeasureData;
      dispatch(
        setAllMeasureData({
          ...allMeasure,
          [measureId]: {
            ...allMeasure[measureId],
            stepData: updateTasks,
            answerData,
            stepAnswers: answerData,
          },
        }),
      );
      dispatch(setStepData(updateTasks));
      /* istanbul ignore else  */
      if (newTask) {
        await fetchPatientMedication(patientId, measureId)(dispatch, getState);
      }
      dispatch(setAnswerLoader(false));

      dispatch(
        setSelectedPatientMedDetails({
          ...patientmedicalDetails,
          assigneeId: res?.assignee?.id,
          assigneeName: res?.assignee?.userFullName,
        } as SelectedPatientMedDetails),
      );
      /* istanbul ignore else  */
      if (res?.tasks?.at(-1)?._id === SCHEDULE_FOLLOWUP_STEP_ID) {
        dispatch(
          fetchPaginatedPatients(
            dashboardState.offset ?? 0,
            dashboardState.pageSize ?? 0,
            dashboardState.selectedSort ?? {
              gapDays: 'ASC',
            },
          ),
        );
      }
      dispatch(checkAssginee(res.assignee));
    } catch (error) {
      dispatch(setAnswerLoader(false));
      throw error;
    }
  };

export const fetchStepResponse =
  (date: any, stepId: string, measureId: any, taskId: any, patientId: any, uId: string) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setAnswerLoader(measureId));
      const allMeasure = getState()?.patient?.allMeasureData;

      if (isEventDrivenSystem()) {
        const stepPayload: any = {
          type: 'STEP_UPDATE',
          payload: {
            taskId: taskId,
            stepId,
            uId: uId.toString(),
            actionDate: moment(date).format('MM/DD/YYYY'),
          },
        };
        let res = await PutStepResponse(stepPayload, taskId);
        dispatch(setAnswerLoader(false));
      } else {
        const payload: any = {
          measureId,
          stepId,
          actionDate: moment(date).format('MM/DD/YYYY'),
          uId,
        };

        const response: any = await FetchStepResponse(patientId, measureId, payload);
        const { tasks: updateTasks, newTask } = response;
        const answerData = getAnswerDataforSteps(updateTasks[updateTasks.length - 1]);
        dispatch(
          setAllMeasureData({
            ...allMeasure,
            [measureId]: {
              ...allMeasure[measureId],
              stepData: updateTasks,
              answerData,
              stepAnswers: answerData,
            },
          }),
        );
        /* istanbul ignore else  */
        if (newTask) {
          await fetchPatientMedication(patientId, measureId)(dispatch, getState);
        }
        dispatch(setAnswerLoader(false));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const fetchPatientMedication =
  (id: string, measureId: number) => async (dispatch: any, getState: () => RootState) => {
    try {
      const res: any = await FetchPatientMedication(id, measureId);

      dispatch(setPatientMedication(res));
      dispatchStepDataMono(res, id, measureId, dispatch, getState);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const saveEditedNote =
  (patientId: string, noteId: string, note: string) => async (dispatch: any, getState: any) => {
    if (isEventDrivenSystem()) {
      const payload: any = {
        message: note,
        patientTaskAssignmentId: '1',
      };
      try {
        const result = await fetchPatientLogs(noteId, payload);
        await dispatch(refreshNoteLogData());
        return result;
      } catch (error: any) {
        throw error;
      }
    } else {
      const payload: any = {
        note: note,
        editedNoteId: noteId,
      };
      try {
        const result = await SaveEditedNote(patientId, noteId, payload);
        await dispatch(refreshNoteLogData());
        return result;
      } catch (error: any) {
        throw error;
      }
    }
  };

export const saveNote =
  (patientId: string, note: string) => async (dispatch: any, getState: any) => {
    if (isEventDrivenSystem()) {
      const payload: any = {
        patientId,
        message: note,
        type: 'NOTES',
        patientTaskAssignmentId: '1',
      };

      const url = `${config.REACT_ACTIVITY_BASE_URL}/api/patient-logs`;
      try {
        const result = await post(url, payload);
        dispatch(refreshNoteLogData());
        return result;
      } catch (error: any) {
        throw error;
      }
    } else {
      const currentSelectedFilter = getState().dashboard.currentSelectedFilter;
      let currentUser = {
        UserId: currentSelectedFilter[0].value[0]?.id ?? currentSelectedFilter[0].value?.id,
        UserFullName:
          currentSelectedFilter[0].value[0]?.name ?? currentSelectedFilter[0].value?.name,
      };

      const payload: any = {
        note: note,
        userId: currentUser.UserId,
        userFullName: currentUser.UserFullName,
      };

      try {
        const result = await SaveNote(patientId, payload);
        await dispatch(refreshNoteLogData());
        return result;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    }
  };

export const fetchNotesAndLogs = (data: any) => async (dispatch: any, getState: any) => {
  const selectedPatient: any = getState().dashboard.selectedPatient;
  if (isEventDrivenSystem()) {
    try {
      const payload = {
        patientId: selectedPatient?.patientId,
        types: ['LOGS', 'NOTES'],
        skip: 0,
        limit: data.limit,
      };
      const res = await searchPatientLogs(payload);

      const noteAndlogs = res.result.map((record: any) => {
        return {
          id: record._id,
          patientId: record.patientId,
          date: record.createdAt,
          message: record.message,
          type: record.type,
          userId: record.createdById,
          isEdited: Boolean(record.history?.length),
          user: {
            id: record.createdById,
            name: record.createdByName,
          },
          metadata: record,
        } as NoteLogList;
      });

      const noteLogData: NoteLogData = {
        result: noteAndlogs,
        total: res.metadata?.total ?? 0,
      };

      dispatch(onFetchNoteLogs(noteLogData));
      return res;
    } catch (error) {}
  } else {
    try {
      const notes = ['1', '3'].includes(data?.activeTab)
        ? await dispatch(fetchMonolithNotes(selectedPatient?.patientId ?? ''))
        : [];
      const logs = ['1', '2'].includes(data?.activeTab)
        ? await dispatch(fetchMonolithLogs(selectedPatient?.patientId ?? ''))
        : [];
      const noteAndlogs: NoteLogList[] = [...notes, ...logs].sort(
        (a: NoteLogList, b: NoteLogList) => {
          return moment.utc(b.date).diff(moment.utc(a.date));
        },
      );

      const noteLogData: NoteLogData = {
        result: noteAndlogs,
        total: noteAndlogs.length,
      };

      dispatch(onFetchNoteLogs(noteLogData));
      return noteLogData;
    } catch (error) {}
  }
};

export const fetchMonolithNotes = (id: string) => async (dispatch: any, getState: any) => {
  try {
    const res = await FetchMonolithNotes(id);
    const notes = res.notesDetails.map((record: any) => {
      return {
        id: record.id,
        patientId: record.patientId,
        date: record.createdDate,
        message: record.note,
        type: 'NOTES',
        userId: record.userId,
        isEdited: Boolean(record.editedNoteId),
        user: {
          id: record.userId,
          name: record.user.userFullName,
        },
        metadata: record,
      } as NoteLogList;
    });
    return notes;
  } catch (error) {
    throw error;
  }
};

export const fetchMonolithLogs = (id: string) => async (dispatch: any, getState: any) => {
  try {
    const res = await FetchMonolithLogs(id);
    const logs = res.activities.map((record: any) => {
      return {
        id: record.id,
        patientId: record.patientId,
        date: record.activityDate,
        message: record.activityData?.message ?? record.activityType?.dataSourceName,
        type: 'LOGS',
        userId: record.userId,
        user: {
          id: record.userId,
          name: record.user?.userFullName,
        },
        metadata: record,
        reason: record?.activityData?.attributes?.reason,
      } as NoteLogList;
    });
    return logs;
  } catch (error) {}
};

export const dispatchStepDataMono = async (
  response: any,
  patientId: any,
  measureId: any,
  dispatch: any,
  getState: any,
) => {
  const foundIncompleteMg =
    response?.measureGap?.filter((mG: any) => mG.isComplete === false) || [];
  const allMeasureData = getState().patient.allMeasureData;
  if (foundIncompleteMg.length > 1) {
    console.error(
      `Found more than one incomplete step for patient: ${patientId} and measure: ${measureId}`,
    );
    dispatch(
      setAllMeasureData({
        ...allMeasureData,
        [measureId]: {
          ...allMeasureData[measureId],
          stepData: [],
          answerData: [],
          stepAnswers: [],
        },
      }),
    );
  } else {
    const foundMeasures: any = foundIncompleteMg[0] || {};
    const measureGapId = foundMeasures?.id;
    const tasks = foundMeasures?.gapData?.tasks || [];
    const currentStep = foundMeasures?.currentStep;
    let updatedTasks: any = [...tasks];
    if (measureGapId && currentStep === 1 && updatedTasks.length === 0) {
      try {
        const response1 = await PostGapData(measureGapId);
        updatedTasks = response1.tasks;
      } catch (errors) {
        console.log(errors);
      }
    }
    const answerData = getAnswerDataforSteps(updatedTasks[updatedTasks.length - 1]);
    dispatch(
      setAllMeasureData({
        ...allMeasureData,
        [measureId]: {
          ...allMeasureData[measureId],
          stepData: updatedTasks,
          answerData: answerData,
          stepAnswers: answerData,
        },
      }),
    );
  }
};
export const dispatchStepDataEDS = async (
  response: any,
  measureId: any,
  dispatch: any,
  getState: any,
) => {
  const allMeasureData = getState().patient.allMeasureData;

  const answerData = getAnswerDataforSteps(response[response.length - 1]);
  dispatch(
    setAllMeasureData({
      ...allMeasureData,
      [measureId]: {
        ...allMeasureData[measureId],
        stepData: response,
        answerData: answerData,
        stepAnswers: answerData,
      },
    }),
  );
};

const getAnswerDataforSteps = (lastStep: any) => {
  let questionObj = {};
  lastStep?.children.map((child: any) => {
    questionObj = {
      ...questionObj,
      [child?._id]: child?.answers ? child?.answers?.map((answer: any) => answer) : [],
    };
  });
  return {
    stepId: lastStep?._id,
    uId: lastStep?.uId,
    questionWithAnswers: questionObj || [],
  };
};
