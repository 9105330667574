import { t } from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import React, { useRef } from 'react';
import { Text, TextVariants } from 'rx-shared-lib';
import styled from 'styled-components';
import { truncateToDecimals } from '../../API/util';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import InfiniteScrollDataGrid from '../../component/DataGrid/InfiniteScrollDataGrid';
import { Tracker } from '../../tracker';
import styles from './Dashboard.module.css';
import patientStyles from './PatientData.module.css';
import { AssigneeCell } from './assignee/AssigneeCell';
import {
  deselectAllAssignees,
  fetchPaginatedPatients,
  onPageSizeChanged,
  onPaginationChanged,
  onPatientClicked,
  onPatientSelected,
  setSort,
} from './dashboardSlice';
import loadingGif from './loading.gif';

export const TITLE_DATA_GRID = 'TITLE_DATA_GRID';
export const FILTERED_DATA_GRID = 'FILTERED_DATA_GRID';
export const LOADING = 'LOADING';
export const FILTERED_DATA_GRID_ID = 'FILTERED_DATA_GRID_ID';

const StyledText = styled(Text)`
  width: 85%;
  text-align: start;
`;
const FixedWidthDiv = styled.div`
  width: 15%;
`;
const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
`;
const StyledPatientName = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const PatientData = (props: any) => {
  const { selectedSearchPatient, searchPatientClick } = useAppSelector((state: RootState) => {
    return state.dashboard;
  });
  const dispatch = useAppDispatch();
  const {
    patient,
    filteredPatient,
    allSearchPatient,
    searchText,
    currentSelectedFilter,
    selectedFilterId,
    offset,
    limit,
    pageSize,
    selectedPatient,
    selectedAssignees,
    showPastDueGlobalWarning,
    showFailRiskGlobalWarning,
    searchedPatientID,
    currentUser,
  } = useAppSelector((state: RootState) => {
    return state.dashboard;
  });

  const role: string = (currentUser?.role || '').toLowerCase();

  const click = () => {
    dispatch(onPatientClicked());
    dispatch(deselectAllAssignees());
  };
  const doNothingSort = (data1: any, data2: any) => {
    return 0; //means no comparing and no sorting
  };
  const getSelectedPatient = () => {
    return selectedSearchPatient?.result?.filter((patient: any) => patient.id == searchedPatientID);
  };

  const getPatientHeaderComponent = () => (
    <Text variant={TextVariants.P2} shade="bold">
      {t('header:patient-header')}
    </Text>
  );

  const getPatientCellRendererComponent = (props: any) => {
    if (props.data) {
      return (
        <span
          className={patientStyles.dataCell}
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
          onClick={click}
        >
          <StyledPatientName variant={TextVariants.P2} shade="bold">
            {props?.data?.firstName?.toLowerCase()} {props?.data?.lastName?.toLowerCase()}
          </StyledPatientName>
        </span>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getDOBHeaderComponent = () => (
    <Text variant={TextVariants.P2} shade="bold">
      {t('header:dob-header')}
    </Text>
  );

  const getDOBCellRendererComponent = (props: any) => {
    if (props?.data) {
      return (
        <Text variant={TextVariants.P2} shade="regular" onClick={click}>
          {moment.utc(props?.data?.dob, 'YYYY-MM-DD').format('MM/DD/YYYY')}
        </Text>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getPDCCellRendererComponent = (props: any) => {
    if (props?.data) {
      return (
        <div id="stats-col" onClick={click} className={patientStyles.dataCell}>
          {_.map(props.data.patientTaskData, (measure: any, index: number) => {
            const pdcValue = truncateToDecimals(measure?.PDC);
            const pdcColor = pdcValue >= 80 ? '#1B8233' : '#C52020';
            return (
              <CenteredDiv>
                <FixedWidthDiv> </FixedWidthDiv>
                <StyledText key={index} variant={TextVariants.P2} shade="regular" color={pdcColor}>
                  {pdcValue}%
                </StyledText>
              </CenteredDiv>
            );
          })}
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getMeasureHeaderComponent = () => (
    <Text variant={TextVariants.P2} shade="bold">
      {t('header:measure-header')}
    </Text>
  );

  const getMeasureCellRenderer = (props: any) => {
    if (props?.data) {
      return (
        <div onClick={click} className={patientStyles.dataCell}>
          {_.map(props.data.patientTaskData, (measure: any, index: number) => {
            return (
              <Text variant={TextVariants.P2} shade="regular" key={index}>
                {measure?.measureData?.title}
              </Text>
            );
          })}
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getLastImpactCellRenderer = (props: any) => {
    if (props?.data) {
      return (
        <div onClick={click} className={patientStyles.dataCell}>
          {_.map(props.data.patientTaskData, (measure: any, index: number) => {
            return (
              <CenteredDiv>
                <FixedWidthDiv>
                  {measure?.showFailRiskWarning && <div className={styles.urgentDot}>&nbsp;</div>}
                </FixedWidthDiv>
                <StyledText key={index} shade="regular" variant={TextVariants.P2}>
                  {measure?.lastImpactDate === null
                    ? '--'
                    : moment(measure?.lastImpactDate).format('MM/DD/YYYY')}
                </StyledText>
              </CenteredDiv>
            );
          })}
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getNextStepCellRenderer = (props: any) => {
    if (props?.data) {
      return (
        <div onClick={click} className={patientStyles.dataCell}>
          {_.map(props.data.patientTaskData, (measure: any, index: number) => {
            return (
              <Text key={index} variant={TextVariants.P2} shade="regular">
                {measure?.stepData.title}
              </Text>
            );
          })}
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getDuebyDateCellRenderer = (props: any) => {
    if (props?.data) {
      return (
        <div onClick={click} className={patientStyles.dataCell}>
          {_.map(props.data.patientTaskData, (measure: any, index: number) => {
            return (
              <CenteredDiv>
                <FixedWidthDiv>
                  {measure?.showPastDueWarning && <div className={styles.urgentDot}>&nbsp;</div>}
                </FixedWidthDiv>
                <Text variant={TextVariants.P2} shade="regular" key={index}>
                  {moment(measure?.actionDate).format('MM/DD/YYYY')}
                </Text>
              </CenteredDiv>
            );
          })}
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getPayerHeaderComponent = () => (
    <Text variant={TextVariants.P2} shade="bold">
      {t('header:payer-header')}
    </Text>
  );

  const getPayerCellRenderer = (props: any) => {
    if (props?.data) {
      return (
        <div className={patientStyles.dataCell} onClick={click}>
          {_.map(props.data.patientTaskData, (measure: any, index: number) => {
            return (
              <Text variant={TextVariants.P2} shade="regular" key={index}>
                {measure?.payer?.toLowerCase()}
              </Text>
            );
          })}
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getReassigneeHeaderComponent = () => (
    <Text variant={TextVariants.P2} shade="bold">
      {Object.keys(selectedAssignees).length > 0
        ? `${t('header:Reassigne-header')} (${Object.keys(selectedAssignees).length})`
        : t('header:assignee-header')}
    </Text>
  );

  const disableReassignment = (props: any): boolean => {
    return (
      ['admin'].includes(role) ||
      props?.data?.patientTaskData.every((measure: any) => measure.hasOpenGap === false)
    );
  };

  const getReassigneeCellRenderer = (props: any) => {
    if (props?.data?.assignedUserName) {
      return (
        <AssigneeCell
          patientId={props?.data?.patientId}
          assignedUserName={props?.data?.assignedUserName.replaceAll(',', '')}
          checkedByDefault={false}
          assignedUserId={props?.data?.assignedUserId}
          disabled={disableReassignment(props)}
        />
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const agGridConfigs: any = [
    {
      key: 'PATIENT',
      minWidth: 200,
      maxWidth: 500,
      lockPinned: true,
      resizable: false,
      headerComponent: getPatientHeaderComponent,
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eText" class="header-text"></span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
</div>`,
      },
      // it is important to have node.id here, so that when the id changes (which happens
      // when the row is loaded) then the cell is refreshed.
      valueGetter: 'node.data.id',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        'min-width': 0,
      },
      cellRenderer: getPatientCellRendererComponent,
    },
    {
      headerComponent: getDOBHeaderComponent,
      minWidth: 130,
      maxWidth: 130,
      lockPinned: true,
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eText" class="header-text"></span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
</div>`,
      },
      key: 'DOB',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        'min-width': 0,
      },
      cellRenderer: getDOBCellRendererComponent,
    },
    {
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        ${`<div class=${styles.noDot}>&nbsp;</div>`}
        <span ref="eText" class="sorted-col-header-text"></span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
</div>`,
      },
      headerName: t('header:pdc-header'),
      key: 'PDC',
      colId: 'PDC',
      minWidth: 140,
      maxWidth: 140,
      lockPinned: true,
      resizable: false,
      wrapText: true,
      unSortIcon: true,
      sortable: true,
      sortingOrder: ['desc', 'asc'],
      comparator: doNothingSort,
      cellRenderer: getPDCCellRendererComponent,
    },
    {
      headerComponent: getMeasureHeaderComponent,
      minWidth: 170,
      maxWidth: 300,
      lockPinned: true,
      resizable: false,
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eText" class="header-text"></span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
</div>`,
      },
      key: 'MEASURE',
      wrapText: true,

      cellRenderer: getMeasureCellRenderer,
    },
    {
      headerName: t('header:lastImpact-header'),
      minWidth: 185,
      maxWidth: 185,
      lockPinned: true,
      resizable: false,
      sortingOrder: ['desc', 'asc'],
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          ${
            showFailRiskGlobalWarning
              ? `<div class=${styles.urgentDot}>&nbsp;</div>`
              : `<div class=${styles.noDot}>&nbsp;</div>`
          }
        <span ref="eText" class="sorted-col-header-text"></span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
</div>`,
      },
      key: 'GAPDAYS',
      colId: 'gapDays',
      initialSort: 'asc',
      unSortIcon: true,
      sortable: true,
      comparator: doNothingSort,
      cellRenderer: getLastImpactCellRenderer,
    },
    {
      headerName: t('header:nextSteps-header'),
      minWidth: 200,
      maxWidth: 300,
      lockPinned: true,
      resizable: false,
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
    <span ref="eText" class="col-header-text"></span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
</div>`,
      },
      key: 'WORK ITEMS',
      wrapText: true,

      cellRenderer: getNextStepCellRenderer,
    },
    {
      headerName: t('header:dueByDate-header'),
      minWidth: 185,
      maxWidth: 185,
      lockPinned: true,
      resizable: false,
      unSortIcon: true,
      sortingOrder: ['desc', 'asc'],
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        ${
          showPastDueGlobalWarning
            ? `<div class=${styles.urgentDot}>&nbsp;</div>`
            : `<div class=${styles.noDot}>&nbsp;</div>`
        }
        <span ref="eText" class="sorted-col-header-text"></span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
</div>`,
      },
      key: 'FACILITY',
      colId: 'actionDate',
      sortable: true,
      cellRenderer: getDuebyDateCellRenderer,
    },
    {
      headerComponent: getPayerHeaderComponent,
      minWidth: 200,
      maxWidth: 300,
      lockPinned: true,
      resizable: false,
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eText" class="header-text"></span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
</div>`,
      },
      key: 'PAYER',
      cellRenderer: getPayerCellRenderer,
    },
    {
      headerComponent: getReassigneeHeaderComponent,
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eText" class="header-text"></span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
</div>`,
      },
      key: 'ASSIGNEE',
      lockPinned: true,
      resizable: false,
      minWidth: 220,
      maxWidth: 420,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 600,
        'min-width': 0,
      },
      cellRenderer: getReassigneeCellRenderer,
    },
  ];

  const handleRowSelectionChange = (selectedRows: any, dispatch: any) => {
    if (selectedRows.length > 0) {
      dispatch(onPatientSelected(selectedRows[0]));
      const patientInfo = _.cloneDeep(selectedRows[0]);
      patientInfo['patientTaskData'] = JSON.stringify(patientInfo['patientTaskData']);
      Tracker.track(Tracker.events.selectPatient, patientInfo);
    }
  };
  const gridRef: any = useRef();
  return (
    <>
      {/*<WithoutBodyStyledTable columns={columns} showHeader={true}/>*/}
      <div data-testid={TITLE_DATA_GRID} className={styles.gridRoot}>
        <InfiniteScrollDataGrid
          gridRef={gridRef}
          onPaginationChanged={onPaginationChanged}
          onPageSizeChanged={onPageSizeChanged}
          fetchPaginatedRecords={fetchPaginatedPatients}
          handleRowSelectionChange={handleRowSelectionChange}
          setSortChanged={setSort}
          columns={agGridConfigs}
          offset={searchPatientClick ? 0 : offset}
          limit={limit}
          selectedPatient={selectedPatient}
          pageSize={pageSize}
          selectedFilterId={selectedFilterId}
          patients={searchPatientClick ? getSelectedPatient() : patient?.result}
          filterdPatients={filteredPatient?.result}
          searchText={searchText}
          totalFilteredSearchRecords={filteredPatient?.metadata?.total}
          totalPaginatedRecords={
            searchPatientClick
              ? selectedSearchPatient?.metadata?.total || 0
              : patient?.metadata?.total
          }
          totalAllSearchRecords={allSearchPatient?.metadata?.total}
          currentSelectedFilter={currentSelectedFilter}
          searchPatientClick={searchPatientClick}
        />
      </div>
    </>
  );
};
export default React.memo(PatientData);
