import React, { useState } from 'react';
import { Divider, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { PopoverModal, Button, TabsComponent, Text, stellusEngageTheme } from 'rx-shared-lib';
import styled, { useTheme, css, ThemeProvider } from 'styled-components';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import { minScreenSize } from '../../../ScreenSizes';

export const LEGAL_MODAL = 'LEGAL_MODAL';
export const PRIVACY_POLICY = 'PRIVACY_POLICY';
export const TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS';
export const OK_BUTTON = 'OK_BUTTON';
export const MODAL_HEADER = 'MODAL_HEADER';

const StyledModal = styled(PopoverModal)`
  ${({ theme, centered }) => css`
    .ant-modal-body {
      padding: 48px !important;
      padding-bottom: 0 !important;
    }
    .ant-modal-footer {
      padding: 0 !important;
    }
    top: 14%;
    @media screen and ${minScreenSize.laptopL} {
      margin-right: ${centered ? '' : '15%'};
    }
  `}
`;

const StyledTabs = styled(TabsComponent)`
  & .ant-tabs-tab-btn {
    width: 154px;
    text-align: center;
  }
  & .ant-tabs-nav-list {
    gap: 6px;
  }
  .ant-tabs-tab {
    height: 36px !important;
  }
  .ant-tabs-nav {
    margin: 24px 0 24px 0;
  }
`;

const StyledDevider = styled(Divider)`
  margin: 0px;
`;

const StyledTabContainer = styled.div`
  ${({ theme }) => css`
    max-height: 448px;
    overflow-y: auto;
  `}
`;

const ButtonHolder = styled('div')`
  ${({ theme }) => css`
    padding: ${theme.spacingInPx(6)} ${theme.spacingInPx(12)} ${theme.spacingInPx(6)}
      ${theme.spacingInPx(12)};
  `}
`;

export default function LegalModal({
  handleOk,
  handleCancel,
  isModalVisible,
  centered,
}: {
  handleOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  isModalVisible: boolean | undefined;
  centered: boolean;
}) {
  const { t } = useTranslation();
  const [isTermsTab, setIsTermsTab] = useState(true);
  const [activeKey, setActiveKey] = useState('1');
  const theme: any = useTheme();

  const handlePrivacyPolicyClick = () => {
    setActiveKey('2');
    setIsTermsTab(!isTermsTab);
  };

  const onKeyChange = (key: string) => {
    setActiveKey(key);
    setIsTermsTab(!isTermsTab);
  };

  return (
    <ThemeProvider theme={stellusEngageTheme}>
      <StyledModal
        centered={centered}
        data-testid={LEGAL_MODAL}
        visible={isModalVisible}
        height={'621px'}
        onOk={handleOk}
        mask={false}
        shadow={false}
        onClose={handleCancel}
        popoverHeader={isTermsTab ? t('common:terms-amp-conditions') : t('common:privacy-policy')}
        content={
          <>
            <StyledTabs
              size="small"
              type="card"
              theme={stellusEngageTheme}
              defaultActiveKey="1"
              activeKey={activeKey}
              onChange={onKeyChange}
            >
              <Tabs.TabPane
                tab={
                  <Text variant="P1" shade="semibold" color="inherit">
                    {t('common:terms-of-use')}
                  </Text>
                }
                key="1"
              >
                <StyledTabContainer>
                  <TermsAndConditions openPrivacyPolicy={handlePrivacyPolicyClick} />
                </StyledTabContainer>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <Text variant="P1" shade="semibold" color="inherit">
                    {t('common:privacy-policy')}
                  </Text>
                }
                key="2"
              >
                <StyledTabContainer>
                  <PrivacyPolicy />
                </StyledTabContainer>
              </Tabs.TabPane>
            </StyledTabs>
          </>
        }
        footer={[
          <StyledDevider key="1" />,
          <ButtonHolder key="2">
            <Button type="primary" size="large" onClick={handleOk} data-testid={OK_BUTTON} block>
              <Text variant="P1" shade="semibold" color={theme.stellusPalette.grey?.[0]}>
                {t('common:ok')}
              </Text>
            </Button>
          </ButtonHolder>,
        ]}
      ></StyledModal>
    </ThemeProvider>
  );
}
