import { post, put, get } from '../commonAPI';
import config from '../../config';

export const FetchDataSources = (role: string) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/datasource/role/${role}`;
  return get(url);
};

export const FetchGenericEmail = () => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/newgenericUserEmail`;
  return get(url);
};

export const CreateUser = (payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/createuser`;
  return post(url, payload);
};

export const GetUsers = (payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/users`;
  return post(url, payload);
};

export const UpdateUserStatus = (userId: number, payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/users/${userId}/toggleuseractivation`;
  return put(url, payload);
};

export const UpdateUser = (userId: number, payload: any) => {
  const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/users/${userId}`;
  return put(url, payload);
};
