import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TabsComponent, Text } from 'rx-shared-lib';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import AddNotePopover from '../../../../component/AddNotePopover/AddNotePopover';
import { setShowAddNoteIcon } from '../../../../slice/PatientSlice';
import NoteList from './NoteList';
export const NOTE_HEADER = 'NOTE_HEADER';
export const ALL_TAB = 'ALL_TAB';
export const TABS = 'TABS';
export const LOG_TAB = 'LOG_TAB';
export const NOTE_TAB = 'NOTE_TAB';
export const TOOLTIP = 'TOOLTIP';
export const NEW_NOTE = 'NEW_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
const { TabPane } = Tabs;

const StyledContainer = styled('div')`
  height: 100%;
  position: relative;
`;
const StyledTooltip = styled('div')`
  position: absolute;
  top: 5px;
  right: 17px;
  z-index: 12;
`;
export const Notes = (props: any) => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const dispatch = useAppDispatch();
  const { selectedPatientCardTab, showAddNoteIcon } = useSelector(
    (state: RootState) => state.patient,
  );

  const { currentUser } = useSelector((state: RootState) => state.dashboard);
  const role: string = (currentUser?.role || '').toLowerCase();

  const { t } = useTranslation();
  const handleChangeTab = (activeKey: any) => {
    setActiveTab(activeKey);
    dispatch(setShowAddNoteIcon(false));
  };

  const handleAddNote = () => {
    dispatch(setShowAddNoteIcon(true));
  };

  useEffect(() => {
    setActiveTab('1');
  }, [selectedPatientCardTab]);

  return (
    <StyledContainer id="tab-header" data-testid={NOTE_HEADER}>
      {activeTab === '3' && !['admin'].includes(role) && !showAddNoteIcon && (
        <StyledTooltip>
          <AddNotePopover onClick={handleAddNote} />
        </StyledTooltip>
      )}
      <TabsComponent
        data-testid={TABS}
        defaultActiveKey="1"
        centered
        type="line"
        size="small"
        onChange={handleChangeTab}
        activeKey={activeTab}
        style={{ position: 'relative' }}
      >
        <TabPane
          data-testid={TABS}
          tab={
            <Text variant="P2" shade="semibold" color="inherit">
              {t('patientCard:TAB_NAME_STRING_ALL')}
            </Text>
          }
          key="1"
        ></TabPane>
        <TabPane
          data-testid={LOG_TAB}
          tab={
            <Text variant="P2" shade="semibold" color="inherit">
              {t('patientCard:TAB_NAME_STRING_LOG')}
            </Text>
          }
          key="2"
        ></TabPane>
        <TabPane
          data-testid={NOTE_TAB}
          tab={
            <Text variant="P2" shade="semibold" color="inherit">
              {t('patientCard:TAB_NAME_STRING_NOTES')}
            </Text>
          }
          key="3"
        ></TabPane>
      </TabsComponent>
      <NoteList activeTab={activeTab} />
    </StyledContainer>
  );
};

export default Notes;
