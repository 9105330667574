import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Button, Select } from 'antd';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DropDown, Text, TextVariants, variantMap } from 'rx-shared-lib';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import styled, { css } from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import cheveronDownArrow from '../../assets/downArrow.svg';
import cheveronUpArrow from '../../assets/upArrow.svg';
import styles from './DataGrid.module.css';

export const TABLE_DATA = 'TABLE_DATA';
export const PAGIATION = 'PAGINATION';
export const OPTION10 = 'OPTION10';
export const OPTION20 = 'OPTION20';
export const OPTION100 = 'OPTION100';
export const OPTION50 = 'OPTION50';
export const NEXTPAGE = 'NEXTPAGE';
export const PREVPAGE = 'PREVPAGE';

const StyledDropDown = styled(DropDown)`
  ${({ theme }) => css`
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${theme.stellusPalette.tiger?.['5']} !important;
    }
    .ant-select-selector {
      border-color: ${theme.stellusPalette.grey['30']} !important;
    }
    .ant-select-selector: focus-within {
      border-color: ${theme.stellusPalette.grey['30']} !important;
      box-shadow: none !important;
    }
    .ant-select-arrow {
      right: ${theme.spacingInPx(2)};
    }
  `}
`;

const StyledAgGridReact = styled(AgGridReact)`
  ${({ theme }) => css`
    .ag-header-cell-label {
      font-family: nunito-bold, cormorant-garamond-bold !important;
      font-weight: 600;
      font-size: 16px !important;
    }
    .ag-header {
      background: ${theme.palette.ancillary.white} !important;
    }
    .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
    .ag-ltr .ag-cell-range-single-cell,
    .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
    .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
    .ag-rtl .ag-cell-range-single-cell,
    .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
      border: none !important;
    }
    .ag-cell-wrapper {
      min-height: 100%;
    }
    .ag-row-odd {
      background-color: #ffff !important;
    }
    .ag-row-hover {
      background-color: ${theme.stellusPalette.grey?.['10']} !important;
    }

    .ag-row-selected {
      background-color: ${theme.stellusPalette.tiger?.['5']} !important;
    }
    .ag-root-wrapper {
      border: solid 1px;
      border-color: var(--ag-border-color, #2a7bff) !important;
    }
    .ag-root-wrapper {
      border: none;
    }
    .ag-cell,
    .ag-full-width-row .ag-cell-wrapper.ag-row-group {
      padding: 0px;
    }
    .ag-icon-asc {
      background: transparent url(${cheveronUpArrow}) center/contain no-repeat;
      color: transparent;
      height: 12px !important;
      width: 24px;
    }
    .ag-icon-desc {
      background: transparent url(${cheveronDownArrow}) center/contain no-repeat;
      color: transparent;
      height: 12px !important;
      width: 24px !important;
    }
    .ag-header-cell-label:hover .ag-icon-desc {
      color: transparent;
    }
    .ag-header-cell-label:hover .ag-icon-asc {
      height: 12px !important;
      width: 24px !important;
      color: transparent;
    }
    .ag-header-cell-sortable:hover {
      background: ${theme.stellusPalette.grey?.['10']} !important;
    }
    .ag-sort-none-icon {
      display: none;
      color: transparent;
      height: 12px !important;
      width: 24px;
    }
    .ag-header-cell-label:hover .ag-sort-none-icon {
      display: block;
    }
  `}
`;

const StyledText = styled(Text)`
  ${({ theme }) => css`
    margin-top: ${theme.spacingInPx(1.5)} !important;
    margin-right: ${theme.spacingInPx(1)} !important;
  `}
`;

const PaginatedDataGrid = styled.div`
  ${({ theme }) => css`
    .sorted-col-header-text {
      ${variantMap(TypographySubType.bold, 'P2')['P2'].styles}
      color: ${theme.stellusPalette.aurora[80]};
    }
    .col-header-text {
      ${variantMap(TypographySubType.bold, 'P2')['P2'].styles}
      color: ${theme.stellusPalette.grey?.[90]};
    }
  `}
`;

const configureRowSelection =
  (column: string) => (api: any, selectedPatient: any, source?: any) => {
    if (api) {
      api?.forEachNode((rowNode: any) => {
        if (selectedPatient?.[column] === rowNode?.data?.[column]) {
          rowNode.setSelected(true, false);
        } else if (rowNode.selected) {
          rowNode.setSelected(false, false);
        }
      });
    }
  };

const calculateRowHeight = (params: any) => {
  if (params?.data?.patientTaskData?.length >= 2) {
    return params?.data?.patientTaskData?.length * 60;
  } else {
    return 76;
  }
};

const InfiniteScrollDataGrid = (props: any) => {
  const gridRef: any = props.gridRef;
  const dispatch = useAppDispatch();
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const { currentUser } = useAppSelector((state: RootState) => {
    return state.dashboard;
  });

  const { selectedSort: sort, highlightRow } = useAppSelector((state: RootState) => {
    return state.dashboard;
  });

  const { searchUserSelected } = useAppSelector((state: RootState) => {
    return state.users;
  });

  const [columns, setColumns] = useState(props.columns);

  const {
    selectedPatient,
    searchText,
    offset,
    limit,
    pageSize,
    totalPaginatedRecords,
    searchPatientClick,
    onPaginationChanged,
    onPageSizeChanged,
    fetchPaginatedRecords,
    handleRowSelectionChange,
    setSortChanged,
    source,
  } = props;
  const columnId = 'patientId';
  useEffect(() => {
    const { api } = gridRef?.current;
    if (api) {
      api?.forEachNode((rowNode: any) => {
        if (selectedPatient?.[columnId] === rowNode?.data?.[columnId]) {
          rowNode.setSelected(true, false);
          api.ensureNodeVisible(rowNode);
        } else if (rowNode.selected) {
          rowNode.setSelected(false, false);
        }
      });
    }
    configureRowSelection(columnId)(api, selectedPatient, source);
  }, [selectedPatient, props.patients]);

  useEffect(() => {
    if (currentUser.role === 'admin') {
      setColumns(props.columns);
    }
  }, [props.columns, props.patients]);

  const getRowHeight = useCallback((params: any) => {
    return calculateRowHeight(params);
  }, []);

  useEffect(() => {
    dispatch(fetchPaginatedRecords(offset, pageSize + offset, sort, highlightRow));
  }, [offset, pageSize, dispatch, sort, searchText, highlightRow]);

  const onPaginationGridReady = useCallback(async (params: any) => {}, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: false,
      suppressMovable: true,
    };
  }, []);

  const onPageSizeChange = (pageSize: string) => {
    dispatch(onPageSizeChanged(parseInt(pageSize)));
  };

  const onNextPage = () => {
    dispatch(
      onPaginationChanged({
        offset: offset + pageSize,
        limit: pageSize,
      }),
    );
  };

  const onPreviousPage = () => {
    dispatch(
      onPaginationChanged({
        offset: offset - pageSize,
        limit: pageSize,
      }),
    );
  };

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    handleRowSelectionChange(selectedRows, dispatch);
  }, []);

  const onSortChanged = useCallback(e => {
    const colState = e.columnApi.getColumnState();
    const sortState = colState.find(function (s: any) {
      return s.sort != null;
    });

    const sortObj = {
      [sortState.colId]: sortState.sort.toUpperCase(),
    };

    const newColumns = columns.map((column: any) => {
      if (column.colId === sortState.colId) {
        return {
          ...column,
          sort: sortState.sort,
        };
      }
      return {
        ...column,
        sort: undefined,
      };
    });
    dispatch(setSortChanged(sortObj));
    setColumns(newColumns);
  }, []);

  let endRows = offset + limit;
  if (endRows > totalPaginatedRecords) {
    endRows = totalPaginatedRecords;
  }
  const startIndex = searchUserSelected ? 1 : offset + 1;

  const paginationText = (
    <div>
      <span>{!totalPaginatedRecords ? 0 : startIndex}</span> - <span>{endRows}</span> of{' '}
      <span>
        {totalPaginatedRecords} {source === 'USERS' ? 'Users' : 'Patients'}
      </span>
    </div>
  );

  const getRowId = useMemo(() => {
    return (params: any) => {
      return params.data.patientId;
    };
  }, []);

  return (
    <PaginatedDataGrid style={containerStyle} data-testid={TABLE_DATA}>
      <div style={gridStyle} className="ag-theme-alpine">
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <StyledAgGridReact
            columnDefs={columns}
            defaultColDef={defaultColDef}
            cacheBlockSize={10}
            rowData={props.patients}
            onGridReady={onPaginationGridReady}
            pagination={false}
            suppressRowTransform={true}
            ref={gridRef}
            getRowHeight={getRowHeight}
            rowSelection={'single'}
            onRowClicked={onSelectionChanged}
            onSortChanged={onSortChanged}
            getRowId={getRowId}
          ></StyledAgGridReact>
          <div
            className={styles.paginationBar}
            style={{ display: 'flex', height: 60, alignItems: 'center' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Text variant={TextVariants.P2} shade="semibold" color="#363936">
                {paginationText}
              </Text>
              <div style={{ marginLeft: 32, display: 'flex', alignItems: 'center' }}>
                <Button
                  data-testid={PREVPAGE}
                  icon={<LeftOutlined />}
                  disabled={searchPatientClick || offset === 0}
                  onClick={onPreviousPage}
                >
                  &#8203;
                </Button>
                <Button
                  data-testid={NEXTPAGE}
                  icon={<RightOutlined />}
                  onClick={onNextPage}
                  disabled={searchPatientClick || endRows >= totalPaginatedRecords}
                >
                  &#8203;
                </Button>
              </div>
            </div>
            <StyledDropDown
              data-testid={PAGIATION}
              defaultValue={'10'}
              onChange={onPageSizeChange}
              value={'' + pageSize}
              style={{ marginRight: 3 }}
              getPopupContainer={triggerNode => triggerNode}
            >
              <Select.Option value={'10'}>
                <StyledText data-testid={OPTION10} variant={TextVariants.P1} shade="regular">
                  10 / page
                </StyledText>
              </Select.Option>
              <Select.Option data-testid={OPTION20} value={'20'}>
                <StyledText variant={TextVariants.P1} shade="regular">
                  20 / page
                </StyledText>
              </Select.Option>
              <Select.Option data-testid={OPTION50} value={'50'}>
                <StyledText variant={TextVariants.P1} shade="regular">
                  50 / page
                </StyledText>
              </Select.Option>
              <Select.Option data-testid={OPTION100} value={'100'}>
                <StyledText variant={TextVariants.P1} shade="regular">
                  100 / page
                </StyledText>
              </Select.Option>
            </StyledDropDown>
          </div>
        </div>
      </div>
    </PaginatedDataGrid>
  );
};

export const exportedForTesting = {
  configureRowSelection: configureRowSelection('patientId'),
  calculateRowHeight,
};

export default React.memo(InfiniteScrollDataGrid);
