import InfiniteScroll from 'react-infinite-scroll-component';
import PatientSearchList from './PatientSearchList';

interface InfiniteSearchScreenInterface {
  searchedResultTotal: number | string;
  lastWorkedPatients: any;
  searchText: string;
  searchedData: any;
  loadNextData: any;
  onPatientClick: any;
}

const InfiniteSearchScreen = ({
  searchedData,
  loadNextData,
  searchedResultTotal,
  lastWorkedPatients,
  onPatientClick,
  searchText,
}: InfiniteSearchScreenInterface) => {
  return (
    <>
      <InfiniteScroll
        dataLength={searchedData.length}
        next={loadNextData}
        hasMore={searchedData.length !== searchedResultTotal}
        loader={<b></b>}
        style={{ zIndex: 100 }}
        scrollableTarget="scrollableDiv"
      >
        <PatientSearchList
          lastWorkedPatients={lastWorkedPatients}
          onClick={onPatientClick}
          data={searchedData}
          searchText={searchText}
        />
      </InfiniteScroll>
    </>
  );
};

export default InfiniteSearchScreen;
