import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  NoteLogData,
  selectedPatientDetails,
  SelectedPatientMedDetails,
} from '../features/dashboard/Types';

export interface PatientCard {
  selectedPatientMeasures: any[];
  selectedPatientDetails?: selectedPatientDetails | null;
  noteLogData: NoteLogData;
  selectedPatientMeasureID: string;
  selectedPatientMedDetails: SelectedPatientMedDetails | null;
  selectedPatientMedication?: any;
  stepMeasureData: any;
  allMeasureData: any;
  fetchStepAnswerResponse: any;
  selectedPatientCardTab?: any;
  assigneeSelector?: any;
  assigneeButtonText?: any;
  showAddNoteIcon?: boolean;
}

const initialState: PatientCard = {
  selectedPatientMeasures: [],
  selectedPatientDetails: null,
  noteLogData: {
    result: [],
    total: 0,
    refreshDataToggle: undefined,
  },
  selectedPatientMeasureID: '',
  selectedPatientMedDetails: null,
  selectedPatientMedication: [],
  stepMeasureData: [],
  allMeasureData: {},
  fetchStepAnswerResponse: {},
  selectedPatientCardTab: '1',
  assigneeSelector: null,
  assigneeButtonText: '',
  showAddNoteIcon: false,
};

export const PatientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPatientMeasures: (state, action: PayloadAction<any>) => {
      state.selectedPatientMeasures = action.payload;
    },
    setSelectedPatientDetails: (state, action: PayloadAction<any>) => {
      state.selectedPatientDetails = action.payload;
    },
    onFetchNoteLogs: (state, action: PayloadAction<NoteLogData>) => {
      state.noteLogData = action.payload;
    },
    refreshNoteLogData: state => {
      state.noteLogData.refreshDataToggle = !state.noteLogData.refreshDataToggle;
    },
    setSelectedPatientMeasureID: (state, action: PayloadAction<any>) => {
      state.selectedPatientMeasureID = action.payload;
    },
    setSelectedPatientMedDetails: (state, action: PayloadAction<SelectedPatientMedDetails>) => {
      state.selectedPatientMedDetails = action.payload;
    },
    setPatientMedication: (state, action: PayloadAction<any>) => {
      state.selectedPatientMedication = action.payload;
    },
    setStepData: (state, action: PayloadAction<any>) => {
      state.stepMeasureData = action.payload;
    },
    setAllMeasureData: (state, action: PayloadAction<any>) => {
      state.allMeasureData = action.payload;
    },
    setFetchStepAnswerResponse: (state, action: PayloadAction<any>) => {
      state.fetchStepAnswerResponse = action.payload;
    },
    setSelectedPatientCardTab: (state, action: PayloadAction<any>) => {
      state.selectedPatientCardTab = action.payload;
    },
    setAssigneeSelector: (state, action: PayloadAction<any>) => {
      state.assigneeSelector = action.payload;
    },
    setAssigneeButtonText: (state, action: PayloadAction<any>) => {
      state.assigneeButtonText = action.payload;
    },
    setShowAddNoteIcon: (state, action: PayloadAction<any>) => {
      state.showAddNoteIcon = action.payload;
    },
  },
});

export const {
  setPatientMeasures,
  setSelectedPatientDetails,
  onFetchNoteLogs,
  refreshNoteLogData,
  setSelectedPatientMeasureID,
  setSelectedPatientMedDetails,
  setPatientMedication,
  setStepData,
  setAllMeasureData,
  setFetchStepAnswerResponse,
  setSelectedPatientCardTab,
  setAssigneeSelector,
  setAssigneeButtonText,
  setShowAddNoteIcon,
} = PatientSlice.actions;

export default PatientSlice.reducer;
