import { Text } from 'rx-shared-lib';

export const PRIVACY_POLICY_CONTENT = 'PRIVACY_POLICY_CONTENT';

export const TERMS_AND_CONDITIONS_CONTENT = 'TERMS_AND_CONDITIONS_CONTENT';

export default function TermsAndConditions(props: any) {
  return (
    <div
      data-testid={TERMS_AND_CONDITIONS_CONTENT}
      style={{ height: '100%', letterSpacing: '-0.02em' }}
    >
      <Text variant="CAPTION" shade="semibold">
        TERMS OF USE
      </Text>
      <br />

      <Text variant="CAPTION" shade="regular">
        These Terms of Use (“Terms”) govern your use of the telemedicine service, powered by
        MediBookr, Inc.’s (“MediBookr,” “we,” “us,” or “our”) platform and any other website or
        online service that we operate and that links to these Terms (collectively, the “Services”).
        Please review these Terms carefully before using the Services. We may change these Terms or
        modify any features of the Services at any time. The most current version of the Terms can
        be viewed through the Services. You accept the Terms by using the Services, and you accept
        any changes to the Terms by continuing to use the Services after we post the changes. As
        used in these Terms, “user” refers to physicians’ or other healthcare providers’
        (collectively, “Providers”), other employees and staff of the Providers and patients and
        other consumers of healthcare services.
      </Text>
      <br />
      <br />
      <Text variant="CAPTION" shade="semibold">
        PRIVACY
      </Text>
      <br />

      <Text variant="CAPTION" shade="regular">
        By using the Services, you consent to our processing your information consistent with our
        Privacy Policy.
      </Text>

      <br />
      <br />
      <Text variant="CAPTION" shade="semibold">
        EMERGENCIES
      </Text>

      <br />
      <Text variant="CAPTION" shade="regular">
        DO NOT USE THE SERVICES FOR EMERGENCY MEDICAL NEEDS. IN THE EVENT OF A MEDICAL EMERGENCY,
        CALL 9-1-1 IMMEDIATELY. Please review these Terms carefully before using the Services. We
        may change these Terms or modify any features of the Services at any time. The most current
        version of the Terms can be viewed through the Services. You accept the Terms by using the
        Services, and you accept any changes to the Terms by continuing to use the Services after we
        post the changes. As used in these Terms, “user” refers to physicians’ or other healthcare
        providers’ (collectively, “Providers”), other employees and staff of the Providers and
        patients and other consumers of healthcare services.
      </Text>
    </div>
  );
}
