import React from 'react';
import styled, { css } from 'styled-components';

const NoStyleContainer = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacingInPx(2)} 0px;
    height: 200px;
    justify-content: center;
  `}
`;

interface NoResultsProps {
  children: React.ReactNode;
}

export const NoResults = ({ children }: NoResultsProps) => (
  <NoStyleContainer>{children}</NoStyleContainer>
);
