import { List } from 'antd';
import Highlighter from 'react-highlight-words';
import styled, { css, useTheme } from 'styled-components';
import { RootState } from '../../app/store';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import { variantMap } from 'rx-shared-lib';
import { useAppSelector } from '../../app/hooks';
export const USER_SEARCH_LIST = 'USER_SEARCH_LIST';

const StyledListItem = styled(List.Item)`
  ${({ theme }) => css`
    border-bottom: none !important;
    cursor: pointer;
    &:hover {
      background: ${theme.palette.graphite[20]} !important;
    }
  `}
`;
const StyledSearchOptionContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
`;
const StyledName = styled('div')`
  ${({ theme }) => css`
    padding: ${theme.spacingInPx(4)};
    width: 50%;
    word-wrap: break-word;
    ${variantMap(TypographySubType.regular, 'P2')['P2'].styles};
  `}
`;

const StyledHighlighter = styled(Highlighter)`
  ${({}) => css`
    mark {
      font-weight: 700;
      background-color: rgba(0, 0, 0, 0);
      margin: 0px;
      padding: 0px;
    }
  `}
`;

export const UserSearchList = ({ data, searchText, onClick }: any) => {
  const theme: any = useTheme();
  const onUserClick = async (user: any) => {
    onClick(user);
  };
  const { editUser } = useAppSelector((state: RootState) => {
    return state.users;
  });
  return (
    <List
      dataSource={data}
      data-testid={USER_SEARCH_LIST}
      renderItem={(user: any, index: number) => (
        <StyledListItem
          style={{
            background: editUser?.id === user.id ? theme.palette.goldenrod[10] : '',
          }}
          key={index}
          onClick={() => {
            onUserClick(user);
          }}
        >
          <StyledSearchOptionContainer>
            <StyledName>
              <StyledHighlighter
                searchWords={[...searchText.split(/[, ]+/)]}
                autoEscape={true}
                textToHighlight={user.firstName + ' ' + user.lastName}
              />
            </StyledName>
            <StyledName>
              <StyledHighlighter
                searchWords={[...searchText.split(/[, ]+/)]}
                autoEscape={true}
                textToHighlight={user.email}
              />
            </StyledName>
          </StyledSearchOptionContainer>
        </StyledListItem>
      )}
    />
  );
};

export default UserSearchList;
