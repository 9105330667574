export default {
  REACT_APP_TYPE: process.env.REACT_APP_TYPE,
  REACT_SECURITY_BASE_URL:
    process.env.REACT_APP_SECURITY_BASE_URL ||
    'https://api.development.catalysthealthcloud.com/security',
  REACT_COHORT_BASE_URL:
    process.env.REACT_APP_COHORT_BASE_URL ||
    'https://api.development.catalysthealthcloud.com/cohort',
  REACT_LOOKUP_BASE_URL:
    process.env.REACT_APP_LOOKUP_BASE_URL ||
    'https://api.development.catalysthealthcloud.com/lookup',
  REACT_APP_MONOLITH_BASE_URL:
    process.env.REACT_APP_MONOLITH_BASE_URL ||
    'https://rxm.api.development.catalysthealthcloud.com/rxadheremono',
  REACT_ACTIVITY_BASE_URL:
    process.env.REACT_APP_ACTIVITY_BASE_URL ||
    'https://api.development.catalysthealthcloud.com/activity',
  REACT_PATIENT_BASE_URL:
    process.env.REACT_APP_PATIENT_BASE_URL ||
    'https://api.development.catalysthealthcloud.com/patient',
  REACT_APP_WEB_SOCKET_URL:
    process.env.REACT_APP_WEB_SOCKET_URL ||
    'wss://79yggrmkj8.execute-api.us-east-1.amazonaws.com/development',
  REACT_APP_HEAP_IO_ID: process.env.REACT_APP_HEAP_IO_ID || '',
  REACT_APP_EDIT_PATIENT_DETAILS: process.env.REACT_APP_EDIT_PATIENT_DETAILS || 'false',
  REACT_API_TIMEOUT: process.env.REACT_API_TIMEOUT || '60000',
  REACT_APP_FIVE9_BASE_URL:
    process.env.REACT_APP_FIVE9_BASE_URL ||
    'https://app.five9.com/clients/integrations/adt.li.main.html',
  REACT_APP_FIVE9_REFRESH_TOKEN_TIME: process.env.REACT_APP_FIVE9_REFRESH_TOKEN_TIME || '120000',
};
