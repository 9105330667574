import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { NavBar, Text, theme } from 'rx-shared-lib';
import styled, { css } from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import FilterDrawer from '../../component/PatientFilters/FilterDrawer';
import UserProfileAvatar from '../GenericDashBoard/UserProfileAvatar';
import { fetchFilters } from './dashboardSlice';
import PatientData from './PatientData';
import ToolBar from './ToolBar';
import StellusLogo from '../../assets/stellus-logo.svg';
import LeftIcon from '../../assets/cheveron-left.svg';
import Taskbar from '../../assets/adhere-taskbar.svg';
import PatientCardDrawer from './PatientCard/PatientCardDrawer';
import PatientSearchModal from '../../component/PatientSearch/PatientSearchModal';
import UserMgmntBtn from '../../component/UserManagmentBtn';
import { isUserMgmntEnabled } from '../../API/util';
import { resetUsers } from '../../slice/UsersSlice';
import { FiveNinePopover } from '../../component/FiveNine/FiveNinePopover';

const StyledRxAdhereText = styled(Text)`
  ${({ theme }) => css`
    display: flex;
    white-space: nowrap;
    align-items: center;
    text-align: center;
  `}
`;

const StyledLogoContainer = styled(Link)`
  ${({ theme }) => css`
    width: 157px;
    display: flex;
    align-items: center;
    padding: 0px ${theme.spacingInPx(2)};
    border-radius: ${theme.spacingInPx(1)};
    &:hover {
      cursor: pointer;
      background: ${theme.stellusPalette.ocean['90']};
    }
  `}
`;

const { Content } = Layout;

export const DASHBOARD = 'DASHBOARD';

export const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logoImage, setLogoImage] = useState(StellusLogo);

  const userDetails = useAppSelector((state: RootState) => state.auth.userDetails);

  useEffect(() => {
    dispatch(fetchFilters());
  }, []);

  const addUserMgmntBtn = () => {
    const enableUserMgmnt: boolean = isUserMgmntEnabled(userDetails);
    return enableUserMgmnt ? (
      <UserMgmntBtn
        onClick={() => {
          dispatch(resetUsers());
          navigate('/users');
        }}
      />
    ) : undefined;
  };

  return (
    <>
      <Layout data-testid={DASHBOARD} style={{ padding: 0 }}>
        <NavBar
          containerStyle={{ backgroundImage: `url(${Taskbar})`, height: 80 }}
          leftSlot={
            <>
              <StyledLogoContainer
                onMouseOver={() => setLogoImage(LeftIcon)}
                onMouseOut={() => setLogoImage(StellusLogo)}
                to={'/'}
              >
                <img
                  src={logoImage}
                  alt="Stellus Logo"
                  style={{
                    width: 24,
                    height: 24,
                    marginRight: 16,
                  }}
                />
                <StyledRxAdhereText
                  variant="SUBHEADING"
                  shade="semibold"
                  color={theme.stellusPalette.ancillary.white}
                >
                  {'Adhere'}
                </StyledRxAdhereText>
              </StyledLogoContainer>
              <FiveNinePopover />
            </>
          }
          centerSlot={<PatientSearchModal />}
          rightSlot={
            <UserProfileAvatar
              userBtn={addUserMgmntBtn()}
              fullName={userDetails?.name || 'Abe Arthur'}
              avatarColor={userDetails?.avatarColor || '#531AF5'}
            />
          }
        />
        <Content style={{ backgroundColor: '#FFFFFF' }}>
          <ToolBar />
          <PatientData />
        </Content>
      </Layout>

      <PatientCardDrawer />
      <FilterDrawer />
    </>
  );
};

export default Dashboard;
