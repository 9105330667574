export const TASKS_LABEL = 'TASKS_LABEL';
export const HISTORY_LABEL = 'HISTORY_LABEL';

export const APP_VERSION = 'V2.6.2';

export const MODAL_PLACEHOLDER_TEXT = 'Reason for edit';

export const CURRENT = 'CURRENT';
export const DEFAULT = 'DEFAULT';
export const DATE_FORMAT = 'MM/DD/YYYY';

export const RX_ADHERE = 'Rx Adhere';
export const RX_INSIGHTS = 'Rx Insights';
export const RX_ENGAGE = 'Rx Engage';
