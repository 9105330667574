import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { calendarConfig } from './API/util';
import './App.less';
import PrivateRoute from './app/PrivateRoute';
import { useAppDispatch } from './app/hooks';
import { RootState } from './app/store';
import { RX_ADHERE } from './constants';
import ComingSoon from './features/GenericDashBoard/AppSelector';
import GenericDashBoard from './features/GenericDashBoard/GenericDashboard';
import ForceChangePassword from './features/authentication/ForceChangePassword';
import ForgotPassword from './features/authentication/ForgotPassword';
import Login from './features/authentication/Login';
import { logout } from './features/authentication/authAPI';
import { onFetchUserDetails } from './features/authentication/authSlice';
import Dashboard from './features/dashboard/Dashboard';
import UserManagment from './features/userManagement';
import i18n from './i18n';
import { Tracker } from './tracker';

const App: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [dataLoaded, setDataLoaded] = useState(false);

  const { userDetails } = useSelector((state: RootState) => {
    return state.auth;
  });

  // VALIDATE AUTH USER IN SLICE
  const validateUser = async () => {
    try {
      setDataLoaded(false);

      const loggedinData = localStorage.getItem('login_data');

      if (loggedinData) {
        const loggedinDataObject = JSON.parse(loggedinData);

        const userData: any = await dispatch(onFetchUserDetails(loggedinDataObject?.globalId));

        const isAdhereApp = userData?.applications.find((obj: any) => obj?.name === RX_ADHERE);

        if (!isAdhereApp) await dispatch(logout);
      } else if (location.pathname !== '/changePassword') {
        navigate('/login');
        setDataLoaded(true);
      } else {
        setDataLoaded(true);
      }
    } catch (error) {
      localStorage.clear();
      navigate('/login');
    } finally {
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    i18n.changeLanguage('en');
    localStorage.setItem('lng', 'en');

    // Configure date-times to display correctly across the app
    calendarConfig();
    Tracker.initialize();
    validateUser();
  }, []);

  useEffect(() => {
    Tracker.login(userDetails);
  }, [userDetails.email]);

  if (!dataLoaded) return null;

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/changePassword" element={<ForceChangePassword />} />

      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<GenericDashBoard />} />
        <Route path="/history" element={<Dashboard />} />
        <Route path="/medi" element={<Dashboard />} />
        <Route path="/users" element={<UserManagment />} />
        <Route path="/rx" element={<ComingSoon />} />
      </Route>
    </Routes>
  );
};

export default App;
