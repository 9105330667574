import React from 'react';

export const PhoneIcon: React.FC<{ size?: number }> = ({ size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8778 13.4279V15.5029C16.8785 15.6955 16.8391 15.8862 16.7619 16.0627C16.6847 16.2392 16.5716 16.3977 16.4296 16.5278C16.2876 16.6581 16.1201 16.7573 15.9376 16.819C15.7551 16.8807 15.5618 16.9035 15.3699 16.8863C13.2415 16.655 11.1971 15.9277 9.40086 14.7628C7.72967 13.7009 6.3128 12.284 5.25086 10.6128C4.08192 8.80842 3.35448 6.754 3.12744 4.61606C3.11016 4.42479 3.13289 4.23202 3.19418 4.05001C3.25548 3.868 3.35401 3.70076 3.48348 3.55893C3.61296 3.41709 3.77055 3.30376 3.94622 3.22617C4.12189 3.14857 4.3118 3.1084 4.50385 3.10822H6.57885C6.91452 3.10492 7.23994 3.22379 7.49446 3.44267C7.74897 3.66155 7.91521 3.96551 7.96218 4.29789C8.04976 4.96194 8.21218 5.61395 8.44635 6.24147C8.53941 6.48904 8.55956 6.75809 8.50439 7.01675C8.44923 7.27541 8.32107 7.51284 8.1351 7.7009L7.25668 8.5793C8.24131 10.3109 9.67503 11.7447 11.4067 12.7293L12.2851 11.8509C12.4732 11.6649 12.7105 11.5367 12.9692 11.4816C13.2279 11.4264 13.497 11.4466 13.7445 11.5397C14.3721 11.7738 15.024 11.9363 15.6881 12.0238C16.0241 12.0712 16.3309 12.2405 16.5503 12.4993C16.7697 12.7582 16.8862 13.0887 16.8778 13.4279Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
