import { t } from 'i18next';
import React from 'react';
import { FilterIcon, Text } from 'rx-shared-lib';
import styled, { css, useTheme } from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { AssigneeHeader } from './assignee/AssigneeHeader';
import {
  clearAllFiltersToDefault,
  deselectAllAssignees,
  setShowFilterDrawer,
  updateFilters,
} from './dashboardSlice';

export const CLEAR_FILTER_BUTTON = 'CLEAR_FILTER_BUTTON';
export const FILTERBTN = 'FILTERBTN';

const HeaderFilterContainer = styled('div')`
  ${({ theme }) => css`
    height: 40px;
    padding: ${theme.spacingInPx(2)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:hover {
      background: ${theme.stellusPalette.grey['20']};
      mix-blend-mode: multiply;
      border-radius: 20px;
      mix-blend-mode: multiply;
    }
    cursor: default;
  `}
`;
const StyledClearFilter = styled('div')`
  ${({ theme }) => css`
    height: 40px;
    padding: ${theme.spacingInPx(2)};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:hover {
      background: ${theme.stellusPalette.grey['20']};
      mix-blend-mode: multiply;
      border-radius: 20px;
    }
    cursor: default;
  `}
`;
const StyledToolBar = styled('div')`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacingInPx(2)} ${theme.spacingInPx(4)};
    align-items: center;
    background-color: ${theme.stellusPalette.ocean[10]};
    justify-content: space-between;
    width: 100%;
    height: 52px;
  `}
`;
const StyledFilterIcon = styled(FilterIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacingInPx(4)};
  `}
`;

export const ToolBar = () => {
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  const defaultSelectedFilters = useAppSelector(
    (state: RootState) => state.dashboard.defaultSelectedFilters,
  );

  const onFilterClick = () => {
    dispatch(setShowFilterDrawer(true));
    dispatch(deselectAllAssignees());
  };

  const clearFilters = async () => {
    let defaultFilters = await dispatch(clearAllFiltersToDefault(defaultSelectedFilters));
    /* istanbul ignore else  */
    if (defaultFilters) {
      dispatch(updateFilters(defaultFilters));
    }
  };
  return (
    <StyledToolBar>
      <HeaderFilterContainer data-testid={FILTERBTN} onClick={onFilterClick}>
        <StyledFilterIcon />
        <Text variant="P1" shade="regular" color={theme.stellusPalette.grey['90']}>
          {`${t('dashboard:filter')}`}
        </Text>
      </HeaderFilterContainer>
      <StyledClearFilter data-testid={CLEAR_FILTER_BUTTON} onClick={clearFilters}>
        <Text variant="P2" shade="regular" color={theme.stellusPalette.grey['90']}>
          {`${t('dashboard:clearFilters')}`}
        </Text>
      </StyledClearFilter>
      <div id="assigneeHeaderId" style={{ position: 'absolute', right: 0, top: 207, height: 10 }}>
        <AssigneeHeader showPopover />
      </div>
    </StyledToolBar>
  );
};

export default React.memo(ToolBar);
