import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'rx-shared-lib';
import styled from 'styled-components';
import { cloneDeep, isEmpty, uniqBy } from 'lodash';
import { RootState } from '../../../app/store';
import MeasureCardHeader from './MeasureCardHeader';
import MeasureHistory from './MeasureHistory';
import NextStepComponent from './StepDetails/NextStepComponent';
import { Spinner } from '../../../component/PatientSearch/Loader';

export const MEASURE_CARD = 'MEASURE_CARD';
const StyledCard = styled(Card)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px 16px;
  // height: 242px;
  margin: auto;
  margin-bottom: 24px;
  .ant-card-body {
    width: -webkit-fill-available;
  }
  .ant-collapse-item-active .historyHeader {
    display: none;
  }
`;

interface MeasureCardProps {
  key?: any;
  measureName?: string;
  measureId?: number | string;
  taskId: number | string;
  PDC?: string;
}

const MeasureCard = ({ key, measureName, measureId, PDC, taskId }: MeasureCardProps) => {
  const [allMeasureData, showAnswerLoader] = useSelector((state: RootState) => [
    state.patient.allMeasureData,
    state.dashboard.showAnswerLoader,
  ]);

  const [measureData, setMeasureData] = useState(measureId ? allMeasureData[measureId] : {});

  useEffect(() => {
    let currentMeasureData = measureId ? allMeasureData[measureId] : {};
    setMeasureData(currentMeasureData);
  }, [allMeasureData]);

  let withLoaderStyle: any = {
    opacity: 0.5,
    PointerEvent: 'none',
  };

  let stepData;
  if (measureData?.stepData?.length) {
    stepData = cloneDeep(measureData?.stepData[measureData?.stepData.length - 1]);
    if (stepData?.children?.length > 1) {
      stepData.children = uniqBy(stepData.children, '_id');
    }
  }

  return (
    <StyledCard
      headStyle={measureId === showAnswerLoader ? withLoaderStyle : {}}
      bodyStyle={measureId === showAnswerLoader ? withLoaderStyle : {}}
      data-testid={MEASURE_CARD}
      key={key}
    >
      <MeasureCardHeader
        measureName={measureName}
        PDC={PDC}
        lastImpactDate={measureData?.lastImpactDate}
        medications={measureData?.medications}
        medicationNamesWithDosage={measureData?.medicationNamesWithDosage}
        prescriberDetail={measureData?.prescriber}
        isSingleFill={measureData?.isSingle}
        hasOpenGap={measureData?.measureInfo?.hasOpenGap}
        measureInfoId={measureData?.measureInfo?.id}
        measureId={measureId}
        stepData={measureData?.stepData}
        lastImpactDateUpd={measureData?.lastImpactDateUpd}
        nextExpectedFillDateUpd={measureData?.nextExpectedFillDateUpd}
        claimFound={measureData?.claimFound}
      />

      {!isEmpty(measureData?.stepData) && (
        <>
          {showAnswerLoader == measureId && <Spinner />}
          <MeasureHistory stepData={measureData?.stepData} measureId={measureId} />
          <NextStepComponent
            stepData={stepData}
            measureId={measureId}
            taskId={taskId}
            answerData={measureData?.answerData}
          />
        </>
      )}
    </StyledCard>
  );
};
export default MeasureCard;
