export const screenSize = {
  desktopL: `(max-width: 1920px)`,
  desktop: `(max-width: 1440px)`,
  laptopL: `(max-width: 1024px)`,
  laptop: `(max-width: 900px)`,
};

export const minScreenSize = {
  desktopL: `(min-width: 1920px)`,
  desktop: `(min-width: 1440px)`,
  laptopL: `(min-width: 1025px)`,
  laptop: `(min-width: 900px)`,
};
