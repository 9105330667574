import { List } from 'antd';
import Highlighter from 'react-highlight-words';
import styled, { css, useTheme } from 'styled-components';
import { getFormattedDate } from '../../API/util';
import { RootState } from '../../app/store';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import { variantMap } from 'rx-shared-lib';
import { useAppSelector } from '../../app/hooks';
export const PATIENT_SEARCH_LIST = 'PATIENT_SEARCH_LIST';

const StyledListItem = styled(List.Item)`
  ${({ theme }) => css`
    border-bottom: none !important;
    cursor: pointer;
    &:hover {
      background: ${theme.palette.graphite[20]} !important;
    }
  `}
`;
const StyledSearchOptionContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
`;
const StyledName = styled('div')`
  ${({ theme }) => css`
    padding: ${theme.spacingInPx(4)};
    width: 50%;
    ${variantMap(TypographySubType.regular, 'P2')['P2'].styles};
  `}
`;
const StyledDob = styled('div')`
  ${({ theme }) => css`
    padding: ${theme.spacingInPx(4)};
    width: 50%;
    ${variantMap(TypographySubType.regular, 'P2')['P2'].styles};
    display: flex;
    justify-content: flex-end;
  `}
`;
const StyledHighlighter = styled(Highlighter)`
  ${({ theme }) => css`
    mark {
      font-weight: 700;
      background-color: rgba(0, 0, 0, 0);
      margin: 0px;
      padding: 0px;
    }
  `}
`;

export const PatientSearchList = ({ data, searchText, onClick }: any) => {
  const theme: any = useTheme();
  const onPatientClick = async (patients: any) => {
    onClick(patients);
  };
  const { searchedPatientID } = useAppSelector((state: RootState) => {
    return state.dashboard;
  });
  return (
    <List
      dataSource={data}
      data-testid={PATIENT_SEARCH_LIST}
      renderItem={(patient: any, index: number) => (
        <StyledListItem
          style={{
            background: searchedPatientID === patient.id ? theme.palette.goldenrod[10] : '',
          }}
          key={index}
          onClick={() => {
            onPatientClick(patient);
          }}
        >
          <StyledSearchOptionContainer>
            <StyledName>
              <StyledHighlighter
                searchWords={[...searchText.split(/[, ]+/)]}
                autoEscape={true}
                textToHighlight={patient.firstName + ' ' + patient.lastName}
              />
            </StyledName>
            <StyledDob>
              <StyledHighlighter
                searchWords={[...searchText.split(/[, ]+/)]}
                autoEscape={true}
                textToHighlight={getFormattedDate(
                  patient.birthDate ? patient.birthDate : patient.dob,
                )}
              />
            </StyledDob>
          </StyledSearchOptionContainer>
        </StyledListItem>
      )}
    />
  );
};

export default PatientSearchList;
