import config from '../../config';

const filterConfig = {
  client: {
    type: 'DROPDOWN',
    key: 'client',
    multiselect: false,
    closable: true,
    optionAPIURL: `${config.REACT_COHORT_BASE_URL}/api/cohort?cohortType=["CLIENT"]`,
    label: 'Client',
    cohortType: 'CLIENT',
  },
  careCoordinator: {
    type: 'DROPDOWN',
    multiselect: false,
    key: 'careCoordinator',
    closable: true,
    optionAPIURL: `${config.REACT_COHORT_BASE_URL}/api/cohort?cohortType=["CARE_COORDINATOR"]`,
    label: 'Care Coordinator',
    cohortType: 'CARE_COORDINATOR',
  },

  programStatus: {
    type: 'DROPDOWN',
    key: 'programStatus',
    multiselect: true,
    closable: true,
    optionAPIURL: `${config.REACT_COHORT_BASE_URL}/api/cohort?cohortType=["PROGRAM_STATUS"]`,
    label: 'Program Status',
    cohortType: 'PROGRAM_STATUS',
  },
  careProgram: {
    type: 'DROPDOWN',
    multiselect: false,
    key: 'careProgram',
    closable: true,
    optionAPIURL: `${config.REACT_COHORT_BASE_URL}/api/cohort?cohortType=["CARE_PROGRAM"]`,
    label: 'Care Program',
    cohortType: 'CARE_PROGRAM',
  },
  scheduleEndDate: {
    type: 'DATE',
    key: 'scheduleEndDate',
    multiselect: false,
    closable: true,
    label: 'Scheduled End Date',
  },
  scheduleStartDate: {
    type: 'DATE',
    key: 'scheduleStartDate',
    multiselect: false,
    closable: true,
    label: 'Scheduled Start Date',
  },
  facility: {
    type: 'DROPDOWN',
    key: 'facility',
    multiselect: false,
    closable: true,
    optionAPIURL: `${config.REACT_COHORT_BASE_URL}/api/cohort?cohortType=["FACILITY"]`,
    label: 'Facility',
    cohortType: 'FACILITY',
  },
  provider: {
    type: 'DROPDOWN',
    key: 'provider',
    multiselect: false,
    closable: true,
    optionAPIURL: `${config.REACT_COHORT_BASE_URL}/api/cohort?cohortType=["PROVIDER"]`,
    label: 'Provider',
    cohortType: 'PROVIDER',
  },
  patientStatus: {
    type: 'DROPDOWN',
    key: 'patientStatus',
    multiselect: false,
    closable: true,
    optionAPIURL: `${config.REACT_COHORT_BASE_URL}/api/cohort?cohortType=["PATIENT_STATUS"]`,
    label: 'Patient Status',
    cohortType: 'PATIENT_STATUS',
  },
  nextStepStatus: {
    type: 'DROPDOWN',
    key: 'nextStepStatus',
    multiselect: false,
    closable: true,
    optionAPIURL: `${config.REACT_COHORT_BASE_URL}/api/cohort?cohortType=["NEXT_STEP_STATUS"]`,
    label: 'Next Step Status',
    cohortType: 'NEXT_STEP_STATUS',
  },
  // patient: {
  //     type:'DROPDOWN',
  //     key:'patient',
  //     multiselect:false,
  //     closable:true,
  //     optionAPIURL:'',
  //     label:'Patient'
  // },
} as any;

export default filterConfig;
