import { debounce, isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SearchInput, Text } from 'rx-shared-lib';
import styled, { css } from 'styled-components';
import { RootState } from '../../app/store';

import {
  loadLastWorkedPatients,
  loadSearchData,
  onInputTxt,
  onPatientClicked,
  onPatientSelected,
  onSearchModalOpen,
  onSearchPatientClick,
  onSearchPationClicked,
  onSelectedSearchPatient,
  reloadGrid,
  searchPatient,
  setLastWorkedPatientFlag,
  setSearchedData,
  setSearchedPage,
  setSearchedResultTotal,
  setSearchPatientId,
} from '../../../src/features/dashboard/dashboardSlice';
import { useAppSelector } from '../../app/hooks';
import { ReactComponent as NoSearchResults } from '../../assets/NoSearchResults.svg';
import { CURRENT } from '../../constants';
import { Tracker } from '../../tracker';
import InfiniteSearchScreen from './InfiniteSearchScreen';
import { Loader } from './Loader';
import { NoResults } from './NoSearchResult';
import ResultLabelComp from './ResultLabelComp';

export const PATIENT_SEARCH_OVERLAY = 'PATIENT_SEARCH_OVERLAY';
export const PATIENT_SEARCH_INPUT = 'PATIENT_SEARCH_INPUT';
export const SEARCH_FORM_INPUT = 'SEARCH_FORM_INPUT';
export const SEARCH_INPUT_TEST_ID = 'SEARCH_INPUT_TEST_ID';
export const PATIENT_SEARCH_GRID = 'PATIENT_SEARCH_GRID';
export const PATIENT_SEARCH_LIST = 'PATIENT_SEARCH_LIST';

const StyledCollapse = styled('div')`
  ${({ theme }) => css`
    position: absolute;
    z-index: 102;
    background: white;
    width: 480px;
    margin-top: ${theme.spacingInPx(1)};
    border-radius: 4px;
  `}
`;
const StyledSearchInput = styled('div')`
  ${({ theme }) => css`
    width: 480px;
    z-index: 102;
    & .ant-form-item {
      margin-bottom: 0px;
    }
    & .ant-input:placeholder-shown {
      margin-top: ${theme.spacingInPx(1)};
    }
  `}
`;

const SearchContainer = styled('div')`
  ${({ theme }) => css`
    margin-right: ${theme.spacingInPx(1)};
    max-height: 543px;
    overflow: auto;
    ::-webkit-scrollbar {
      background-color: transparent;
    }
    ::-webkit-scrollbar-track {
      background-color: ${theme.palette.ancillary.white} !important;
    }
  `}
`;
const StyledOverlay = styled('div')`
  ${({ theme }) => css`
    width: 100%;
    height: 200%;
    background-color: black;
    position: absolute;
    left: 0;
    opacity: 0.5;
    z-index: 96;
  `}
`;
const PatientSearchModal = () => {
  const [searchDebounceText, setSearchDebounceText] = useState('');
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onPatientClick = async (patient: any) => {
    if (isEmpty(searchDebounceText) && lastWorkedPatientFlag) {
      dispatch(onInputTxt(patient.lastName && patient.lastName.toLowerCase()));
    }
    Tracker.track(Tracker.events.selectPatient, patient);
    dispatch(onSearchPatientClick(true));
    dispatch(setSearchPatientId(patient.id));
    dispatch(onPatientClicked());
    handleDebounceFn(inputTxt);
    dispatch(onPatientSelected(patient));
    dispatch(onSearchModalOpen(false));
    await dispatch(searchPatient(patient));
    dispatch(onSelectedSearchPatient(0));
  };
  const {
    searchModalOpen,
    searchedPage,
    inputTxt,
    searchedData,
    searchLoader,
    searchedResultTotal,
    lastWorkedPatientFlag,
    lastWorkedPatients,
  } = useAppSelector((state: RootState) => {
    return state.dashboard;
  });
  const { searchPatientClick } = useAppSelector((state: RootState) => {
    return state.dashboard;
  });
  const loadNextData = () => {
    dispatch(setSearchedPage(searchedPage + 1));
  };

  useEffect(() => {
    dispatch(loadLastWorkedPatients());
  }, [searchModalOpen]);

  useEffect(() => {
    if (isEmpty(searchDebounceText) && isEmpty(lastWorkedPatients)) {
      dispatch(setLastWorkedPatientFlag(false));
    } else {
      dispatch(setLastWorkedPatientFlag(true));
    }
    if (isEmpty(searchDebounceText)) {
      dispatch(setSearchPatientId(''));
      dispatch(setSearchedData([]));
      dispatch(setSearchedResultTotal(0));
    } else {
      dispatch(setLastWorkedPatientFlag(true));
    }
  }, [searchDebounceText]);

  useEffect(() => {
    if (searchDebounceText?.length > 0) {
      dispatch(loadSearchData(searchDebounceText));
    }
  }, [searchDebounceText, searchedPage]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isEmpty(e.target.value) && searchPatientClick) {
      onClearText();
    }
    dispatch(onSearchModalOpen(true));
    dispatch(onInputTxt(e.target.value));
    handleDebounceFn(e.target.value);
  };

  const handleDebounceFn = useCallback(
    debounce(value => {
      setSearchDebounceText(value);
    }, 1000),
    [],
  );

  const onClearText = () => {
    dispatch(setSearchedPage(1));
    dispatch(onSearchPatientClick(true));
    dispatch(onSearchPatientClick(false));
    dispatch(reloadGrid(CURRENT));
    dispatch(onInputTxt(''));
    dispatch(setLastWorkedPatientFlag(false));
    dispatch(onSearchModalOpen(false));
    dispatch(setSearchedData([]));
    dispatch(setSearchPatientId(''));
    dispatch(onSearchPationClicked(false));
    setSearchDebounceText('');
    dispatch(onPatientSelected(null));
  };
  const onClickInput = () => {
    dispatch(onSearchModalOpen(true));
    if (!isEmpty(lastWorkedPatients)) {
      dispatch(setLastWorkedPatientFlag(true));
    }
    dispatch(onPatientSelected(null));
  };

  const infiniteScreenRenderer = () => {
    if (searchLoader) {
      if (searchedData?.length) {
        return (
          <InfiniteSearchScreen
            data-testid={PATIENT_SEARCH_LIST}
            loadNextData={loadNextData}
            searchedData={searchedData}
            searchedResultTotal={searchedResultTotal}
            onPatientClick={onPatientClick}
            searchText={inputTxt}
            lastWorkedPatients={lastWorkedPatients}
          />
        );
      } else {
        return <Loader />;
      }
    } else if (searchedData?.length && !isEmpty(searchDebounceText)) {
      return (
        <InfiniteSearchScreen
          loadNextData={loadNextData}
          searchedData={searchedData}
          searchedResultTotal={searchedResultTotal}
          onPatientClick={onPatientClick}
          searchText={inputTxt}
          lastWorkedPatients={lastWorkedPatients}
        />
      );
    } else if (isEmpty(searchDebounceText) && lastWorkedPatientFlag) {
      return (
        <InfiniteSearchScreen
          data-testid={PATIENT_SEARCH_GRID}
          loadNextData={loadNextData}
          searchedData={lastWorkedPatients}
          searchedResultTotal={searchedResultTotal}
          onPatientClick={onPatientClick}
          searchText={inputTxt}
          lastWorkedPatients={lastWorkedPatients}
        />
      );
    }
    return (
      <NoResults>
        <>
          <NoSearchResults />
          <Text variant="P2" shade="regular">
            {t('common:no-results-found')}
          </Text>
        </>
      </NoResults>
    );
  };

  return (
    <>
      {searchModalOpen && (
        <StyledOverlay data-testid={PATIENT_SEARCH_OVERLAY} onClick={onClearText}></StyledOverlay>
      )}
      <StyledSearchInput data-testid={PATIENT_SEARCH_INPUT}>
        <SearchInput
          value={inputTxt}
          onInputChange={onInputChange}
          onClick={onClickInput}
          allowClear
          placeholder={`${t('dashboard:searchPlaceholderLabel')}`}
          FormItemTestID={SEARCH_FORM_INPUT}
          InputTestID={SEARCH_INPUT_TEST_ID}
          centerPlaceholder={true}
        ></SearchInput>
        {searchModalOpen && lastWorkedPatientFlag && (
          <StyledCollapse>
            <ResultLabelComp>
              {!isEmpty(searchDebounceText)
                ? ` ${t('dashboard:patients')} (${searchedResultTotal})`
                : `${t('dashboard:last_label')} ${lastWorkedPatients?.length || 0} ${t(
                    'dashboard:worked_label',
                  )}`}
            </ResultLabelComp>
            <SearchContainer
              id="scrollableDiv"
              style={searchedData.length < searchedResultTotal ? { maxHeight: 490 } : {}}
            >
              {infiniteScreenRenderer()}
            </SearchContainer>
          </StyledCollapse>
        )}
      </StyledSearchInput>
    </>
  );
};
export default PatientSearchModal;
