import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Text } from 'rx-shared-lib';
import { CheckboxGroup } from 'rx-shared-lib/dist/components/Checkbox/Checkbox';
import { useAppDispatch } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { setAllMeasureData } from '../../../../slice/PatientSlice';
import { Tracker } from '../../../../tracker';
import { getStringReplaced } from '../../dashboardSlice';
export const STEP_RADIO = 'STEP_RADIO';
export const STEP_DETAIL = 'STEP_DETAIL';
export const COLLAPSE = 'COLLAPSE';
export const PANEL = 'PANEL';
export const RADIO_BTN = 'RADIO_BTN';
export const SCHEDULETASK = 'SCHEDULETASK';
export const SCHEDULETASK_RADIO = 'SCHEDULETASK_RADIO';
export const SCHEDULETASK_DATE = 'SCHEDULETASK_DATE';
export const CHECKBOX = 'CHECKBOX';

interface CheckBoxGroupComponentProps {
  options: any[];
  measureId: any;
  questionId: string;
  radioStyle: any;
  userDisabled?: boolean;
  patientUserInfo: any;
  answerData: any;
  stepData: any;
}

export const CheckBoxGroupComponent: React.FC<CheckBoxGroupComponentProps> = ({
  options,
  answerData,
  questionId,
  radioStyle,
  userDisabled,
  patientUserInfo,
  measureId,
  stepData,
}) => {
  const { allMeasureData } = useSelector((state: RootState) => state.patient);

  const dispatch = useAppDispatch();
  const [allAnswers, setAllAnswers] = useState();

  const onCheckBoxChanged = (e: any, option: any) => {
    dispatch(
      setAllMeasureData({
        ...allMeasureData,
        [measureId]: {
          ...allMeasureData[measureId],
          answerData: {
            ...allMeasureData[measureId]?.answerData,
            questionWithAnswers: {
              ...allMeasureData[measureId]?.answerData?.questionWithAnswers,
              [questionId]: !allMeasureData[measureId]?.answerData?.questionWithAnswers[
                questionId
              ].some((val: any) => val.id == e.target.value)
                ? [
                    ...allMeasureData[measureId]?.answerData?.questionWithAnswers[questionId],
                    { id: e.target.value, name: option.name },
                  ]
                : allMeasureData[measureId]?.answerData?.questionWithAnswers[questionId].filter(
                    (val: any) => val?.id !== e.target.value,
                  ),
            },
          },
        },
      }),
    );
  };
  useEffect(() => {
    setAllAnswers(
      allMeasureData[measureId]?.answerData?.questionWithAnswers[questionId]?.map(
        (answers: any) => answers?.id,
      ),
    );
  }, [allMeasureData]);

  return (
    <CheckboxGroup value={allAnswers}>
      {options?.map((option: any) => (
        <>
          {option?.title && (
            <>
              <Text variant="P2" fontSize={14} fontWeight={'bold'}>
                {option?.title}
              </Text>
              <hr color="#EBEBEB" />
            </>
          )}
          <Checkbox
            data-testid={CHECKBOX}
            disabled={userDisabled}
            key={option._id}
            style={radioStyle}
            onChange={e => onCheckBoxChanged(e, option)}
            value={option._id.toString()}
            data-tracker-event={Tracker.events.selectOption}
            data-tracker-step={stepData?.name}
            data-tracker-option={option.name.replace(/\[|\]/g, '')}
          >
            <Text variant="P2" shade="regular">
              {getStringReplaced(option.name, patientUserInfo)}
            </Text>
          </Checkbox>
        </>
      ))}
    </CheckboxGroup>
  );
};
