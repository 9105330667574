import { DatePickerProps } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DatePicker, Text, variantMap } from 'rx-shared-lib';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import styled, { css } from 'styled-components';
import { fetchStepResponse } from '../../../../actions';
import { Tracker } from '../../../../tracker';
import { setAllMeasureData } from '../../../../slice/PatientSlice';
import { SCHEDULE_FOLLOWUP_STEP_ID } from '../../../../utility/rules.constants';
export const NEXT_STEP_TAG = 'NEXT_STEP_TAG';
export const DATE_PICKER = 'DATE_PICKER';
export const DATE_PICKER_WRAPPER = 'DATE_PICKER_WRAPPER';
const YES_FOLLOW_SCHEDULE_UP_ANSWER_ID = '625daca1b91534991007e711';
const SHEDULE_FOLLOW_UP_QUESTION_ID = '625daca1b91534991007e710';
const SHEDULE_FOLLOW_UP_STEP_ID = '625daca1b91534991007e700';

const StyledHeader = styled('div')`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacingInPx(10)} 0px;
    width: 96%;
    height: 64px;
    justify-content: space-between;
    align-items: center;
  `}
`;
const StyledDatePicker = styled(DatePicker)`
  ${({ theme, value }) => css`
    width: 160px;
    margin-left: ${theme.spacingInPx(2.5)};
    input {
      ${moment(value).format('MM/DD/YYYY') === moment().format('MM/DD/YYYY') &&
      variantMap(TypographySubType.bold, 'P2')['P2'].styles};
      color: ${theme.stellusPalette.grey['90']};
    }
    height: 35px;
    .ant-picker-input > input {
      color: unset !important;
    }
  `}
`;
const StyledStepNameWithData = styled('div')`
  ${({ theme }) => css`
    display: flex;

    align-items: center;
    max-width: 80%;
  `}
`;
const StepHeader = ({
  stepData,
  measureId,
  latestQuestionId,
  taskId,
  selectedPatient,
  allMeasureData,
  userDisabledFunc,
  stepEvent,
  suggestedFollowUpDate,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onActionDateChange = (date: any) => {
    if (stepData?._id === SCHEDULE_FOLLOWUP_STEP_ID) {
      dispatch(
        setAllMeasureData({
          ...allMeasureData,
          [measureId]: {
            ...allMeasureData[measureId],
            answerData: {
              ...allMeasureData[measureId]?.answerData,
              scheduleFollowUp: date,
            },
          },
        }),
      );
    } else {
      dispatch(
        fetchStepResponse(
          date,
          stepData._id,
          measureId,
          taskId,
          selectedPatient?.patientId,
          stepData.uId,
        ),
      );
    }
  };

  const customFormat: DatePickerProps['format'] = value =>
    `${
      value.format('MM/DD/YYYY') === moment().format('MM/DD/YYYY')
        ? `${t('patientCard:today')}`
        : value.format('MM/DD/YYYY')
    }`;

  const getActionDate = (measureId: string) => {
    let followUpDt = allMeasureData[measureId]?.answerData?.scheduleFollowUp;
    if (!followUpDt && suggestedFollowUpDate) {
      followUpDt = suggestedFollowUpDate;
      onActionDateChange(suggestedFollowUpDate);
    }
    return followUpDt ? { value: moment(followUpDt) } : {};
  };

  const displayFollowupQuestions = () => {
    return (allMeasureData[measureId]?.answerData?.questionWithAnswers[
      SHEDULE_FOLLOW_UP_QUESTION_ID
    ] &&
      allMeasureData[measureId]?.answerData?.questionWithAnswers[SHEDULE_FOLLOW_UP_QUESTION_ID][0]
        ?.id) === YES_FOLLOW_SCHEDULE_UP_ANSWER_ID ? (
      <StyledStepNameWithData>
        <span>
          <Text variant="P3" shade="regular">
            {stepData?.name}:
          </Text>
        </span>
        <div
          onClick={(e: any) => {
            e.stopPropagation();
          }}
          data-testid={DATE_PICKER_WRAPPER}
        >
          <StyledDatePicker
            data-testid={DATE_PICKER}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
            disabledDate={(current: any) => {
              let customDate = moment();
              return current && current < moment(customDate, 'YYYY-MM-DD');
            }}
            onChange={onActionDateChange}
            dropdownClassName={'ant-picker-header-view'}
            format={customFormat}
            showToday={false}
            disabled={userDisabledFunc(latestQuestionId, 'schedule-data-picker')}
            {...getActionDate(measureId)}
          />
        </div>
      </StyledStepNameWithData>
    ) : (
      ''
    );
  };

  return (
    <StyledHeader data-tracker-event={stepEvent}>
      <span data-testid={NEXT_STEP_TAG}>
        <Text variant="P2" shade="bold">
          {t('patientCard:next_step')}
        </Text>
      </span>
      {stepData?._id !== SHEDULE_FOLLOW_UP_STEP_ID ? (
        <StyledStepNameWithData>
          <span>
            <Text variant="P3" shade="regular">
              {stepData?.name}:
            </Text>
          </span>
          <div
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            <StyledDatePicker
              allowClear={false}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              disabled={userDisabledFunc(latestQuestionId)}
              onChange={onActionDateChange}
              disabledDate={(current: any) => {
                let customDate = moment().add(-1, 'days');
                return current && current < moment(customDate, 'YYYY-MM-DD');
              }}
              placeholder={t('patientCard:today')}
              format={customFormat}
              showToday={false}
              value={moment(stepData?.actionDate)}
            />
          </div>
        </StyledStepNameWithData>
      ) : (
        displayFollowupQuestions()
      )}
    </StyledHeader>
  );
};

export default StepHeader;
