import { MouseEventHandler } from 'react';
import { Role } from '../../features/authentication/authAPI';
import { DetailedUserInfo } from './DetailedUserInfo';
export interface DefaultProfileProps {
  handleEditClick: MouseEventHandler;
  setLegalVisible: Function;
  email: string;
  phone: string;
  role: Role;
  groups: string[];
}

const DefaultProfile = ({
  handleEditClick,
  setLegalVisible,
  email,
  phone,
  role,
  groups,
}: DefaultProfileProps) => {
  return (
    <div>
      <DetailedUserInfo email={email} phone={phone} role={role} groups={groups} />
    </div>
  );
};
export default DefaultProfile;
