import { Form } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DatePicker, FormInputItem, SmallerThanIcon, Text, variantMap } from 'rx-shared-lib';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import styled, { css, useTheme } from 'styled-components';
import {
  cutpointValueIncorrect,
  getCurrentSelectedMeasureCount,
  isEventDrivenSystem,
  valueDoesNotExist,
} from '../../API/util';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import { DATE_FORMAT } from '../../constants';
import {
  createFilters,
  reloadFilterDropdown,
  setCurrentSelectedFilters,
  setFiltersValues,
  updateFilterCounts,
} from '../../features/dashboard/dashboardSlice';
import PharmacyFilter from '../PharmacySearch/PharmacyFilter/PharmacyFilter';
import FilterDropDown from './FilterDropDown';

const StyledFormItem = styled(Form.Item)`
  ${({ theme }) => css`
    .ant-form-item-control {
      height: 36px !important;
    }
    .ant-form-item-label {
      padding-bottom: ${theme.spacingInPx(1)};
    }
    .ant-form-item-label .ant-typography {
      color: ${theme.stellusPalette.grey[70]} !important;
    }
    .ant-picker-suffix {
      margin-bottom: ${theme.spacingInPx(1)};
    }
  `}
`;

const StyledRangeText = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacingInPx(3)} !important;
  `}
`;
const StyledFormInputItem = styled(FormInputItem)`
  .ant-form-item-control-input {
    height: 36px !important;
  }
`;

const StyledPharmacyFilterItem = styled(Form.Item)`
  ${({ theme }) => css`
    .ant-form-item-label {
      padding-bottom: ${theme.spacingInPx(1)};
    }
    .ant-form-item-label .ant-typography {
      color: ${theme.stellusPalette.grey[70]} !important;
    }
  `}
`;

const StyledDatePicker = styled(DatePicker)`
  ${({ theme }) => css`
    .ant-picker-input > input {
      ${variantMap(TypographySubType.regular, 'P2')['P2'].styles}
    }
    .ant-picker-header-view {
      left: 236px !important;
    }
    .ant-picker-suffix {
      margin-bottom: ${theme.spacingInPx(2)} !important;
      margin-top: ${theme.spacingInPx(1.5)} !important;
    }
  `}
`;

const StyledCutPoint = styled('span')`
  ${({ theme }) => css`
    color: ${theme.stellusPalette.grey['70']};
    ${variantMap(TypographySubType.regular, 'P3')['P3'].styles}
    position: absolute;
    top: 32px;
    z-index: 1000;
    width: 100px;
    &:hover {
      z-index: 5;
    }
  `}
`;

const StyledDueByDate = styled('span')`
  ${({ theme }) => css`
    color: ${theme.stellusPalette.grey['70']};
    ${variantMap(TypographySubType.regular, 'P3')['P3'].styles}
    position: absolute;
    top: 34px;
    left: 108px;
    z-index: 1000;
    width: 100px;
    &:hover {
      z-index: 5;
    }
  `}
`;

const StyledDiv = styled('div')`
  ${({ theme }) => css`
    position: relative;
  `}
`;

const StyledText = styled(Text)`
  ${({ theme }) => css`
    margin-right: ${theme.spacingInPx(1)} !important;
  `}
`;

const StyledPDCCutPointLabel = styled(Text)`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacingInPx(1)} !important;
  `}
`;
const StyledOptionsContainer = styled('div')`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    align-items: center;
  `}
`;

export const PatientFilters = () => {
  const theme: any = useTheme();
  const dispatch = useAppDispatch();

  const {
    filteredResult,
    selectedDropdownFilters,
    currentSelectedFilter,
    filterValues,
    showFilterDrawer,
  } = useSelector((state: RootState) => {
    return state.dashboard;
  });
  const [cutPointClick, setCutPointClick] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [pdcLength, setPdcLength] = useState(filterValues?.cutPoint?.toString().length * 11 + 26);
  const onCareMemberChange = async (value: any) => {
    const foundItem = filteredResult?.team.find((member: any) => member.uId === value.key);
    dispatch(setFiltersValues({ ...filterValues, user: foundItem }));
    await dispatch(updateFilterCounts({ value: foundItem, type: 'userIds' }));
  };

  const onMeasureChanged = async (value: any) => {
    const foundItem = filteredResult?.measures.find((member: any) => member.id === value.key);
    dispatch(setFiltersValues({ ...filterValues, measures: foundItem }));
    await dispatch(updateFilterCounts({ value: foundItem, type: 'measureid' }));
  };
  const onStepsChange = async (value: any) => {
    const foundItem = filteredResult?.steps.find((member: any) => member.id === value.key);
    dispatch(setFiltersValues({ ...filterValues, steps: foundItem }));
    await dispatch(updateFilterCounts({ value: foundItem, type: 'stepIds' }));
  };

  const onCutPointChanged = async (e: any) => {
    const currentValue = e.target.value;
    dispatch(setFiltersValues({ ...filterValues, cutPoint: currentValue }));
    /* istanbul ignore else  */
    if (!cutpointValueIncorrect(currentValue)) {
      await dispatch(updateFilterCounts({ value: parseInt(e.target.value), type: 'cutPoint' }));
    }
  };

  const onSelectDate = async (value: any) => {
    await dispatch(updateFilterCounts({ value: value.format('YYYY-MM-DD'), type: 'dueBy' }));
    dispatch(setFiltersValues({ ...filterValues, currentDate: value.format('YYYY-MM-DD') }));
  };

  const disabledDate = (current: moment.Moment) => {
    return current && current.endOf('day').isSameOrBefore(moment().add(-1, 'day').endOf('day'));
  };

  let measureIdsFound: any;
  /* istanbul ignore else  */
  if (currentSelectedFilter) {
    measureIdsFound = currentSelectedFilter.find((filter: any) => filter.key === 'measureid');
  }
  const { value: measureIdValues } = measureIdsFound || { value: [] };
  const currentMeasureIdValue = measureIdValues[0] ?? measureIdValues;

  useEffect(() => {
    if (filterValues?.cutPoint - Math.floor(filterValues?.cutPoint) !== 0)
      setPdcLength(filterValues?.cutPoint.toString().length * 11 + 22);
    else setPdcLength(filterValues?.cutPoint.toString().length * 11 + 26);
  }, [filterValues?.cutPoint]);

  useEffect(() => {
    dispatch(setCurrentSelectedFilters(selectedDropdownFilters));
    dispatch(createFilters(currentSelectedFilter));
    dispatch(reloadFilterDropdown(currentSelectedFilter));
  }, [showFilterDrawer]);

  const actualMeasureCount = getCurrentSelectedMeasureCount(
    currentMeasureIdValue?.id,
    filteredResult,
  );

  const measureCount = valueDoesNotExist(actualMeasureCount) ? '' : actualMeasureCount;
  const onBlur = () => {
    /* istanbul ignore else  */
    if (filterValues.cutPoint !== '') {
      setCutPointClick(false);
    }
  };

  const onCutPointClick = () => {
    document.getElementById('cutpnt-input')?.focus();
    setCutPointClick(true);
  };

  const onPharmacyChange = async (pharmacyValue: any) => {
    dispatch(setFiltersValues({ ...filterValues, pharmacy: pharmacyValue }));
    await dispatch(updateFilterCounts({ value: pharmacyValue, type: 'pharmacy' }));
  };

  const onDatePickerHandler = () => {
    document.getElementById('filter-date-picker')?.focus();
    setDatePickerOpen(true);
  };

  const onPayerChange = async (value: any) => {
    const foundItem = filteredResult?.payers?.find((member: any) => member.id === value.key);
    dispatch(setFiltersValues({ ...filterValues, payers: foundItem }));
    await dispatch(updateFilterCounts({ value: foundItem, type: 'payers' }));
  };

  const dateRenderComp = (current: any) => {
    const style: any = {};
    if (current.isBefore(filterValues?.currentDate)) {
      style.background = theme.palette.goldenrod['20'];
    }
    return (
      <div className="ant-picker-cell-inner" style={style}>
        {current.date()}
      </div>
    );
  };

  const [cutpointValidationStatus, setCutpointValidationStatus] = useState<ValidateStatus>('');
  useEffect(() => {
    console.log('filterValues?.cutPoint:', filterValues?.cutPoint);
    if (cutpointValueIncorrect(filterValues?.cutPoint)) {
      setCutpointValidationStatus('error');
    } else {
      setCutpointValidationStatus('success');
    }
  }, [filterValues?.cutPoint]);

  return (
    <div>
      <Form layout="vertical">
        <StyledFormItem
          label={
            <Text shade="semibold" variant="P3">
              {`${t('patientFilters:careMember')}`}
            </Text>
          }
        >
          <FilterDropDown
            onChangeFunc={onCareMemberChange}
            value={filterValues.user?.uId}
            defaultValue={filterValues.user?.uId}
            filteredResult={filteredResult?.team}
            type="team"
          />
        </StyledFormItem>
        <StyledFormItem
          label={
            <Text shade="semibold" variant="P3">
              {`${t('patientFilters:measures')}`}
            </Text>
          }
        >
          <FilterDropDown
            onChangeFunc={onMeasureChanged}
            value={filterValues.measures?.id}
            defaultValue={'all'}
            filteredResult={filteredResult?.measures}
            type="measure"
          />
        </StyledFormItem>
        <StyledFormItem
          label={
            <Text shade="semibold" variant="P3">
              {`${t('patientFilters:nextSteps')}`}
            </Text>
          }
        >
          <FilterDropDown
            onChangeFunc={onStepsChange}
            value={filterValues.steps?.id}
            defaultValue={'all'}
            filteredResult={filteredResult?.steps}
            type="step"
          />
        </StyledFormItem>

        <StyledFormItem
          label={
            <Text shade="semibold" variant="P3">
              {`${t('patientFilters:payer')}`}
            </Text>
          }
        >
          <FilterDropDown
            onChangeFunc={onPayerChange}
            value={filterValues.payers?.id}
            options={filteredResult?.payers?.map(x => {
              return {
                value: x.id,
                label: (
                  <StyledOptionsContainer className="payersCount">
                    <StyledText
                      variant="P2"
                      shade="regular"
                      color={theme.stellusPalette.grey['90']}
                    >
                      {x.name}
                    </StyledText>{' '}
                    <Text variant="P3" shade="regular" color={theme.stellusPalette.grey['70']}>
                      <div className="measureCountInPayer">({measureCount})</div>
                    </Text>
                  </StyledOptionsContainer>
                ),
              };
            })}
            defaultValue={'all'}
            filteredResult={filteredResult?.payers}
            type="payer"
          />
        </StyledFormItem>

        <StyledDiv>
          {!cutPointClick && (
            <StyledCutPoint
              style={{
                left: pdcLength,
              }}
              onClick={onCutPointClick}
              onFocus={onCutPointClick}
            >{` (${measureCount})`}</StyledCutPoint>
          )}
          <StyledFormInputItem
            id={'cutpnt-input'}
            type="number"
            onInputChange={onCutPointChanged}
            allowClear={false}
            onClick={onCutPointClick}
            onFocus={onCutPointClick}
            onBlur={onBlur}
            prefix={<SmallerThanIcon />}
            value={filterValues?.cutPoint}
            label={
              <StyledPDCCutPointLabel shade="semibold" variant="P3">
                {`${t('patientFilters:cutPoint')}`}
              </StyledPDCCutPointLabel>
            }
            placeholder={`${t('patientFilters:cut-Point')}`}
            helpText={
              cutpointValidationStatus === 'error' ? (
                <StyledRangeText
                  shade="regular"
                  color={theme.palette.error['100']}
                  variant="CAPTION"
                >
                  {`${t('patientFilters:rangeError')}`}
                </StyledRangeText>
              ) : undefined
            }
            validateStatus={cutpointValidationStatus}
          ></StyledFormInputItem>
        </StyledDiv>
        <StyledDiv>
          <StyledDueByDate
            onClick={onDatePickerHandler}
            onBlur={() => setDatePickerOpen(false)}
          >{` (${measureCount})`}</StyledDueByDate>
          <StyledFormItem
            label={
              <Text shade="semibold" variant="P3">
                {`${t('patientFilters:dueBy')}`}
              </Text>
            }
          >
            <StyledDatePicker
              getPopupContainer={(triggerNode: any) => triggerNode}
              id={'filter-date-picker'}
              onClick={onDatePickerHandler}
              onBlur={() => setDatePickerOpen(false)}
              open={datePickerOpen}
              allowClear={false}
              dateRender={dateRenderComp}
              disabledDate={disabledDate}
              value={moment(filterValues?.currentDate)}
              onSelect={onSelectDate}
              dropdownClassName={'ant-picker-header-view'}
              format={DATE_FORMAT}
              showToday={false}
              style={{
                width: '100%',
                height: '100%',
                padding: `0px ${theme.spacingInPx(4)}`,
              }}
              popupStyle={{
                left: 400,
              }}
            />
          </StyledFormItem>
        </StyledDiv>
        {!isEventDrivenSystem() && (
          <StyledPharmacyFilterItem
            label={
              <Text shade="semibold" variant="P3">
                {`${t('patientFilters:pharmacy')}`}
              </Text>
            }
          >
            <StyledDiv>
              <PharmacyFilter
                value={filterValues?.pharmacy?.name}
                onChangeFunc={onPharmacyChange}
              />
            </StyledDiv>
          </StyledPharmacyFilterItem>
        )}
      </Form>
    </div>
  );
};

export default PatientFilters;
