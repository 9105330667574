import { Button } from 'antd';
import { Text } from 'rx-shared-lib';
import React, { useState } from 'react';
import { APP_VERSION } from '../../../constants';
import LegalModal from './LegalModal';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

export const LOGIN_LEGAL_TEXT = 'LOGIN_LEGAL_VERSION';
export const OPEN_MODAL_BUTTON = 'OPEN_MODAL_BUTTON';
export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 140%;
  font-weight: 400;
  text-decoration: underline;
  color: #000000;
`;

const StyledText = styled(Text)`
  ${({ theme }) => css`
    text-decoration: underline;
    text-underline-offset: 2px;
  `}
`;
const StyledLegalText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: ${theme.spacingInPx(4)};
  `}
`;

const TextHolder = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
  `}
`;

export default function LoginLegalText() {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <StyledLegalText data-testid={LOGIN_LEGAL_TEXT}>
      <TextHolder onClick={showModal} data-testid={OPEN_MODAL_BUTTON}>
        <StyledText variant="P3" shade="regular">
          {t('common:terms-amp-conditions')}
        </StyledText>
        <Text variant="P3" shade="regular">
          {APP_VERSION}
        </Text>
      </TextHolder>

      <LegalModal
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        centered={false}
      />
    </StyledLegalText>
  );
}
