import moment from 'moment';
import { useSelector } from 'react-redux';
import { Text } from 'rx-shared-lib';
import { RootState } from '../../../app/store';
import { formatPhone } from '../../../utility/formatPhone';
import { CONTACT_PRESCRIBER_STEP_NAME } from '../../../utility/rules.constants';
import styles from './PatientCard.module.css';
import { CapitalizeText } from './PatientDetails';
import { ContactPhone } from '../../../component/FiveNine/ContactPhone';
export const STEP_TABLE = 'STEP_TABLE';
interface StepTableProps {
  type: string;
  measureId: number;
}

const StepTable = ({ type, measureId }: StepTableProps) => {
  const { selectedPatientMedDetails, allMeasureData }: any = useSelector((state: RootState) => {
    return state.patient;
  });

  const measureData = allMeasureData?.[measureId];

  const displayNames = () => {
    return (
      <td>
        <CapitalizeText variant="P3" shade="regular">
          {type === 'Research' || type === CONTACT_PRESCRIBER_STEP_NAME
            ? measureData?.prescriberName || 'No-Data'
            : type === 'Call Pharmacy'
            ? selectedPatientMedDetails?.pharmacyName || 'No-Data'
            : type === 'Contact Patient'
            ? selectedPatientMedDetails?.patientName?.toLowerCase() || 'No-Data'
            : ''}
        </CapitalizeText>
      </td>
    );
  };

  const displayContacts = () => {
    return (
      <td style={{ textAlign: 'right' }}>
        <Text variant="P3" shade="regular">
          {' '}
          {type === 'Research' || type === CONTACT_PRESCRIBER_STEP_NAME
            ? 'Office :'
            : type === 'Contact Patient'
            ? 'Home :'
            : 'Phone :'}{' '}
          {type === 'Research' || type === CONTACT_PRESCRIBER_STEP_NAME ? (
            <ContactPhone
              contactNumber={selectedPatientMedDetails?.prescriber?.phone}
              noData="No data"
            />
          ) : type === 'Call Pharmacy' ? (
            <ContactPhone
              contactNumber={selectedPatientMedDetails?.pharmacy?.phone}
              noData="No data"
            />
          ) : type === 'Contact Patient' ? (
            <ContactPhone
              contactNumber={selectedPatientMedDetails?.patient?.homePhone}
              noData="No data"
            />
          ) : (
            'NOT GIVEN'
          )}
        </Text>
      </td>
    );
  };

  const displayAddress = () => {
    return (
      <td>
        <CapitalizeText variant="P3" shade="regular">
          {' '}
          {type === 'Research' || type === CONTACT_PRESCRIBER_STEP_NAME
            ? selectedPatientMedDetails?.prescriber?.address || 'No-Data'
            : type === 'Call Pharmacy'
            ? selectedPatientMedDetails?.pharmacy?.address || 'No-Data'
            : type === 'Contact Patient'
            ? selectedPatientMedDetails?.patient?.address || 'No-Data'
            : ''}
        </CapitalizeText>
      </td>
    );
  };

  const displayOtherAddressDetails = () => {
    return (
      <td>
        <CapitalizeText variant="P3" shade="regular">
          {' '}
          {type === 'Research' || type === CONTACT_PRESCRIBER_STEP_NAME
            ? selectedPatientMedDetails?.prescriber?.address2 || 'Address 2'
            : type === 'Call Pharmacy'
            ? selectedPatientMedDetails?.pharmacy?.address2 || 'Address 2'
            : type === 'Contact Patient'
            ? [selectedPatientMedDetails?.patient?.city, selectedPatientMedDetails?.patient?.state]
                .filter(Boolean)
                .join(' ') || 'No-Data'
            : ''}
        </CapitalizeText>
      </td>
    );
  };

  const displayEmail = () => {
    return (
      <td style={{ textAlign: 'right' }}>
        <Text variant="P3" shade="regular" color="#2F54EB">
          {type === 'Research' || type === CONTACT_PRESCRIBER_STEP_NAME
            ? selectedPatientMedDetails?.prescriber?.email || 'No-Data'
            : type === 'Call Pharmacy'
            ? selectedPatientMedDetails?.pharmacy?.email || 'No-Data'
            : type === 'Contact Patient'
            ? selectedPatientMedDetails?.patient?.email || 'No-Data'
            : ''}
        </Text>
      </td>
    );
  };

  const displayFax = () => {
    return (
      <td style={{ textAlign: 'right' }}>
        <Text variant="P3" shade="regular">
          {type === 'Contact Patient' ? 'Mobile : ' : 'Fax : '}
          {type === 'Research' || type === CONTACT_PRESCRIBER_STEP_NAME ? (
            formatPhone(selectedPatientMedDetails?.prescriber?.fax) || 'No-Data'
          ) : type === 'Call Pharmacy' ? (
            formatPhone(selectedPatientMedDetails?.pharmacy?.fax) || 'No-Data'
          ) : type === 'Contact Patient' ? (
            <ContactPhone
              contactNumber={selectedPatientMedDetails?.patient?.mobilePhone}
              noData="No data"
            />
          ) : (
            ''
          )}
        </Text>
      </td>
    );
  };

  const displayCityState = () => {
    return (
      <td>
        <CapitalizeText variant="P3" shade="regular">
          {type === 'Contact Patient' && 'Gender : '}
          {type === 'Research' || type === CONTACT_PRESCRIBER_STEP_NAME
            ? [
                selectedPatientMedDetails?.prescriber?.city,
                selectedPatientMedDetails?.prescriber?.state,
              ]
                .filter(Boolean)
                .join(' ') || 'No-Data'
            : type === 'Call Pharmacy'
            ? [
                selectedPatientMedDetails?.pharmacy?.city,
                selectedPatientMedDetails?.pharmacy?.state,
              ]
                .filter(Boolean)
                .join(' ') || 'No-Data'
            : type === 'Contact Patient'
            ? selectedPatientMedDetails?.patient?.gender || 'No-Data'
            : ''}
        </CapitalizeText>
      </td>
    );
  };

  const displayNpi = () => {
    return (
      <td style={{ textAlign: 'right' }}>
        <Text variant="P3" shade="regular">
          {type === 'Contact Patient' ? 'DOB' : 'NPI'}
          {' : '}
          {type === 'Research' || type === CONTACT_PRESCRIBER_STEP_NAME
            ? selectedPatientMedDetails?.prescriber?.npi || 'No-Data'
            : type === 'Call Pharmacy'
            ? selectedPatientMedDetails?.pharmacy?.npi || 'No-Data'
            : type === 'Contact Patient'
            ? moment(selectedPatientMedDetails?.patient?.birthDate).format('MM/DD/YYYY') ||
              'No-Data'
            : ''}
        </Text>
      </td>
    );
  };

  return (
    <div className={styles.stepTable} data-testid={STEP_TABLE}>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            {displayNames()}
            {displayContacts()}
          </tr>
          <tr>
            {displayAddress()}
            {displayFax()}
          </tr>
          <tr>
            {displayOtherAddressDetails()}
            {displayEmail()}
          </tr>
          <tr>
            {displayCityState()}
            {displayNpi()}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default StepTable;
