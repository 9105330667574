import React from 'react';
import { Text } from 'rx-shared-lib';
import styled, { css } from 'styled-components';

const StyledTotalResultContainer = styled('div')`
  ${({ theme }) => css`
    padding: ${theme.spacingInPx(2)} ${theme.spacingInPx(4)} ${theme.spacingInPx(1)}
      ${theme.spacingInPx(4)};
    height: 30px;
    border-bottom: 1.5px solid ${theme.palette.graphite['30']};
    position: sticky;
    top: 0;
  `}
`;
const StyledText = styled(Text)`
  ${({ theme }) => css`
    display: flex;
    color: ${theme.palette.graphite['80']} !important;
  `}
`;
const ResultLabelComp = ({ children }: any) => {
  return (
    <StyledTotalResultContainer>
      <StyledText variant="CAPTION" shade="regular">
        {children}
      </StyledText>
    </StyledTotalResultContainer>
  );
};

export default ResultLabelComp;
