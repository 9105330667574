import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { reassignPatientsAPI, searchAssigneesAPI } from '../../API/EventDriven/assigneeSearchAPI';
import { getFilterCount } from '../../API/EventDriven/countApi';
import { fetchHierarchy } from '../../API/EventDriven/hierarchyApi';
import {
  FetchPaginatedPatients,
  FetchPatientRow,
  searchLastWorkedPatients,
} from '../../API/EventDriven/patientAPI';
import { reassignPatients, searchAssignees } from '../../API/Monolith/assigneeSearchAPI';
import {
  fetchLastWorkedPatientsMonolith,
  fetchPatientPharmacy,
  FetchPatientRowWithoutFilterMonolith,
} from '../../API/Monolith/patientAPI';
import { searchPatients } from '../../API/patientSearchAPI';
import {
  capitalizeFirstLetter,
  getDaysBetweenStartAndEndDates,
  isEventDrivenSystem,
} from '../../API/util';
import { RootState } from '../../app/store';
import { CURRENT, DEFAULT } from '../../constants';
import { CONTACT_PRESCRIBER_STEP_NAME } from '../../utility/rules.constants';
import filterConfig from './filterConfig';
import { MonoMeasure, SelectedPatient, User } from './Types';

export interface DashboardState {
  cutPointCurrentValue?: any;
  patient: any;
  pharmacyData: any;
  pharmacyLoader: any;
  pharmacyInputTxt: any;
  searchedPharmacyResultTotal: any;
  currentUser: any;
  showAnswerLoader: any;
  searchText: any;
  isLoading: boolean;
  filteredPatient: any;
  allSearchPatient?: any;
  isFailed: any;
  inputTxt: any;
  pageNumber: any;
  searchPatientClick: any;
  searchedPatientID: any;
  searchedPharmacyName: any;
  selectedSearchPatient: any;
  searchPatientClicked: any;
  searchModalOpen: any;
  savedFilterList: any;
  currentSelectedFilter?: FilterType[];
  savedFilterLoading?: boolean;
  selectedFilterId?: string;
  filterOptions?: any;
  assigneeDetail?: any;
  assigneeRole?: any;
  showFilteredData?: boolean;
  showSearchFilteredData?: boolean;
  offset?: number;
  limit?: number;
  highlightRow?: number;
  pageSize?: number;
  filteredOffset?: number;
  filteredLimit?: number;
  defaultSelectedFilters: FilterType[];
  selectedPatient?: SelectedPatient | null;
  isClicked?: boolean;
  oldAssigneeId?: any;
  globalid?: string;
  userIds?: any;
  measureIds?: any;
  stepIds?: any;
  cutPoint?: number;
  startDate?: string;
  endDate?: string;
  filteredResult?: FilterResponse;
  filtered?: boolean;
  cancelAssignment: number;
  selectedAssignees: any;
  selectAllAssignees: string;
  users: User[];
  showPastDueGlobalWarning?: boolean;
  showFailRiskGlobalWarning?: boolean;
  showFilterDrawer?: boolean;
  showFiveNinePopover?: boolean;
  filterValues?: any;
  selectedDropdownFilters?: any;
  searchLoader?: boolean;
  searchedData?: any;
  searchedPage?: any;
  searchedResultTotal?: any;
  lastWorkedPatientTotal?: any;
  lastWorkedPatients?: any;
  lastWorkedPatientFlag?: any;
  selectedSort: any;
}

export interface FilterType {
  key: string;
  value: Array<any> | string | number | Object;
}

export interface FilterResponse {
  team: Team[];
  measures: Measure[];
  steps: Step[];
  pharmacy?: Pharmacy[];
  payers?: Payer[];
  // total: Total;
}

export interface Team {
  id: number;
  uId: any;
  name: string;
  type: 'TEAM' | 'USER';
  count: string | number;
}

export interface Measure {
  id: any;
  type: 'MEASURE';
  name: string;
  count: any;
}

export interface UserData {
  //contains comma seperated names
  medicationNames: Array<string>;
  pharmacyName: string;
  prescriberName: string;
  providerName: string;
  userId: number;
  userFullName: string;
  patientName: string;
  measureName: string;
}

export interface Payer {
  id: any;
  type?: 'PAYER';
  name: string;
  count?: any;
}

export interface Pharmacy {
  id: any;
  name: string;
}

export interface Step {
  id: any;
  type: 'STEP';
  name: string;
  count: any;
}

export interface Total {
  measures: number;
  steps: number;
  cutPoint: number;
  dueBy: number;
}

export interface DisplayOption {
  key: string;
  selected: boolean;
  order: number;
  pharmacy: boolean;
  disabled: boolean;
}

export const selectedPatientSelector = (state: RootState) => state.dashboard.selectedPatient;

export const selectedPatientMeasureIDSelector = (state: RootState) =>
  state.patient.selectedPatientMeasureID;

export const selectedPatientMeasuresSelector = (state: RootState) =>
  state.patient.selectedPatientMeasures;

export const defaultFilters = (): FilterType[] => {
  return [
    { key: 'userIds', value: [] },
    { key: 'measureid', value: { id: 'all', type: 'MEASURE', count: 1 } },
    { key: 'stepIds', value: { id: 'all', type: 'STEP', count: 1 } },
    { key: 'cutPoint', value: 80 },
    { key: 'pharmacy', value: {} },
    { key: 'payers', value: { id: 'all', type: 'PAYER', count: 1 } },
    {
      key: 'dueBy',
      value: {
        from: moment().startOf('year').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      },
    },
  ];
};

export const getSelectedCohortIds = (selectedFilterFields: any) => {
  const filter: any = {};
  let cohortIds: string[] = [];
  _.each(filterConfig, (config: any) => {
    const key = config.key;
    const filteredField = _.filter(selectedFilterFields, filterField => {
      return filterField.key === key;
    });
    if (config.cohortType) {
      /* istanbul ignore else  */
      if (filteredField.length > 0) {
        const selectedCohortIds = filteredField[0].value;
        /* istanbul ignore else  */
        if (selectedCohortIds) {
          cohortIds = cohortIds.concat(selectedCohortIds);
        }
      }
    } else {
      switch (key) {
        case 'scheduleStartDate': {
          /* istanbul ignore else  */
          if (filteredField.length > 0 && filteredField[0].value) {
            filter.startDate = moment(filteredField[0].value, 'MM/DD/YYYY').format('YYYY-MM-DD');
          }
          break;
        }
        case 'scheduleEndDate': {
          /* istanbul ignore else  */
          if (filteredField.length > 0 && filteredField[0].value) {
            filter.endDate = moment(filteredField[0].value, 'MM/DD/YYYY').format('YYYY-MM-DD');
          }
          break;
        }
      }
    }
  });
  filter.cohortIds = cohortIds;
  return filter;
};

export let fetchPaginatedPatientsAvailable: boolean = false;

export const fetchPaginatedPatients =
  (startRow: number, endRow: number, sort?: any, highlightRow?: any) =>
  (dispatch: any, getState: any) =>
    new Promise(async (resolve: any, reject: any) => {
      if (fetchPaginatedPatientsAvailable) {
        if (isEventDrivenSystem()) {
          const currentSelectedFilters = getState().dashboard.currentSelectedFilter;
          const offset = startRow;
          const limit = endRow - startRow;
          dispatch(onPaginationChanged({ offset, limit }));
          const filter: any = getSelectedCohortIds(currentSelectedFilters);
          const filterPayload = getSearchPatientPayload(currentSelectedFilters);

          try {
            let payload: any = {};
            let updatedSort: any = { ...sort };
            /* istanbul ignore else  */
            if (sort?.gapDays) {
              delete updatedSort['gapDays'];
              updatedSort['LastImpactDate'] = sort?.gapDays;
            }
            payload = {
              offset: offset,
              limit: limit,
              sort: updatedSort,
              filter: filterPayload.filter,
            };

            /* istanbul ignore else  */
            if (filter.cohortIds.length > 0) {
              payload.filter = filter;
            }

            const res = await FetchPaginatedPatients(payload);

            dispatch(onPatientReceived(getGapDayAndActionDateFlag(res)));
            dispatch(loadingSuccess());
            resolve(res);
          } catch (error) {
            console.error(error);
            dispatch(loadingFailed());
            reject(error);
          }
        } else {
          try {
            const currentSelectedFilters = getState().dashboard.currentSelectedFilter;
            const offset = startRow;
            const limit = endRow - startRow;
            dispatch(onPaginationChanged({ offset, limit }));

            await dispatch(
              fetchMonoPatients(currentSelectedFilters, startRow, limit, sort, highlightRow),
            );
            resolve();
          } catch (error) {
            console.error(error);
            reject(error);
          }
        }
      }
    });

const getGapDayAndActionDateFlag = (patients: any) => {
  const data = {
    ...patients,
    result: patients.result.map((patient: any) => {
      return {
        ...patient,
        patientTaskData: patient?.patientTaskData?.map((task: any) => {
          const { showPastDueWarning } = getWarningFlags(task?.actionDate);
          return {
            ...task,
            showPastDueWarning,
            PDC: task?.cutPoint,
          };
        }),
      };
    }),
  };
  return data;
};

const getSortColumn = (sortByColumn: string) => {
  if (sortByColumn === 'gapDays') {
    return 'LastImpactDate';
  } else {
    if (sortByColumn === 'PDC') {
      return 'PDC';
    } else {
      return 'NextActionDate';
    }
  }
};

const getSortColumnDetails = (currentSelectedSort: any) => {
  const currentSelectedSortList = currentSelectedSort || {};
  const sortByColumn = getSortColumn(Object.keys(currentSelectedSortList)[0] || 'LastImpactDate');
  const sortByDirection = Object.values(currentSelectedSortList)[0] || 'ASC';
  return { sortByColumn, sortByDirection };
};

export const fetchMonoPatients =
  (currentSelectedFilters: any, startRow: any, limit: any, sort?: any, highlightRow?: any) =>
  (dispatch: any, getState: any) =>
    new Promise(async (resolve: any, reject: any) => {
      try {
        let payload: any = {};
        const { sortByColumn, sortByDirection } = getSortColumnDetails(sort);
        payload = {
          page: Math.floor(startRow / limit) + 1,
          pageSize: limit,
          sortColumn: sortByColumn,
          sortDirection: sortByDirection,
        };

        const filters = getFilterPayloadForMonoPatient(currentSelectedFilters);

        /* istanbul ignore else  */
        if (filters) {
          payload.filters = filters;
        }

        const res = await FetchPatientRowWithoutFilterMonolith(payload);
        const { PatientMeasureList, TotalPatientCount, MinNextActionDate, MinLastImpactDate } = res;

        const { showPastDueWarning, showFailRiskWarning } = getWarningFlags(
          MinNextActionDate,
          MinLastImpactDate,
        );
        dispatch(setShowPastDueGlobalWarning(showPastDueWarning));
        dispatch(setShowFailRiskGlobalWarning(showFailRiskWarning ? true : false));

        const globalFlags = getGlobalFlags(MinNextActionDate, MinLastImpactDate);
        const patientData = getPatientDataFromMeasures(
          PatientMeasureList,
          TotalPatientCount,
          globalFlags,
          dispatch,
        );

        dispatchCurrentUser(dispatch, res);
        dispatch(onPatientReceived(patientData));
        if (highlightRow === 0) {
          dispatch(onNextPatientselection(0));
        } else if (highlightRow === -1) {
          dispatch(selectLastPatient());
        }
        dispatch(loadingSuccess());
        resolve(patientData);
      } catch (error) {
        console.error(error);
        dispatch(loadingFailed());
        reject(error);
      }
    });

export const getHierarchy =
  (shouldKeepSelectedFilterIntact?: boolean) => (dispatch: any, getState: () => RootState) =>
    new Promise(async (resolve: any, reject: any) => {
      const { offset, pageSize } = getState().dashboard;
      const login_data = JSON.parse(localStorage.getItem('login_data') || '{}');
      const login_decoded: any = jwt_decode(login_data.refresh_token);
      /* istanbul ignore else  */
      if (!isEmpty(login_decoded.assignedCohorts)) {
        const selfCohortGlobalId = login_decoded.selfCohortGuid;
        const teamGlobalId = login_decoded?.assignedCohorts?.find(
          (x: any) => x.type === 'TEAM',
        )?.globalId;
        try {
          const res = await fetchHierarchy(teamGlobalId);
          const ids = res.children.map((e: any) => e.id).join(',');
          const foundCurrentUser = res.children.find(
            (child: any) => child.globalId === selfCohortGlobalId,
          );
          const currentDefaultFilters = JSON.parse(
            JSON.stringify(
              shouldKeepSelectedFilterIntact
                ? getState().dashboard.currentSelectedFilter
                : defaultFilters(),
            ),
          );
          const userIdsFilter = currentDefaultFilters.find(
            (filter: any) => filter.key === 'userIds',
          );
          const { id, name } = foundCurrentUser || {};
          let uIds;
          if (shouldKeepSelectedFilterIntact) {
            const currentSelectedUserId = userIdsFilter.value[0]?.uId?.split('-')[1];
            uIds = currentSelectedUserId ? `${ids}-${currentSelectedUserId}` : `${ids}`;
          }
          userIdsFilter.value = shouldKeepSelectedFilterIntact
            ? [{ ...userIdsFilter.value[0], uId: uIds }]
            : [{ id, uId: `${ids}-${id}`, name, type: 'USER' }];
          dispatch(setDefaultFilters(currentDefaultFilters));
          dispatch(
            fetchPaginatedPatients(offset ?? 0, (pageSize ?? 0) + (offset ?? 0), {
              gapDays: 'ASC',
            }),
          );
          resolve(res);
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }
    });

export const fetchFilters = () => (dispatch: any, getState: () => RootState) =>
  new Promise(async (resolve: any, reject: any) => {
    const userDetails = getState().auth.userDetails;
    if (isEventDrivenSystem()) {
      try {
        let currentUser = {
          userId: userDetails?.guid,
          name: userDetails?.name,
          role: userDetails?.roles.name,
        };
        dispatch(setCurrentUser(currentUser));

        await dispatch(getHierarchy());
        await dispatch(getCount());
        await dispatch(getFilter());

        resolve({});
      } catch (error) {
        console.log(error);
        reject(error);
      }
    } else {
      try {
        const currentUser = {
          userId: userDetails?.guid,
          name: userDetails?.name,
          role: userDetails?.roles.name,
        };
        dispatch(setCurrentUser(currentUser));
        await dispatch(getMonoFilters());
        resolve({});
      } catch (error) {
        console.log(error);
        reject(error);
      }
    }
  });

const assignMeasureAndNextsteps = (currentSelectedFilters: any[], filters: any) => {
  const measureValue = currentSelectedFilters.find(x => x.key === 'measureid');
  const nextStepValue = currentSelectedFilters.find(x => x.key === 'stepIds');
  const measures = measureValue?.value[0]?.id ?? measureValue?.value?.id;
  /* istanbul ignore else  */
  if (!isNaN(measures)) {
    filters.measures = measures;
  }

  const nextSteps = nextStepValue?.value[0]?.id ?? nextStepValue?.value?.id;

  /* istanbul ignore else  */
  if (!isNaN(nextSteps)) {
    filters.nextSteps = nextSteps;
  }
};

const assignActionDtAndCutpoint = (currentSelectedFilters: any[], filters: any) => {
  const cutPointValue = currentSelectedFilters.find(x => x.key === 'cutPoint');
  const actionDateValue = currentSelectedFilters.find(x => x.key === 'dueBy');
  const cutPoint = cutPointValue?.value ?? 80;

  /* istanbul ignore else  */
  if (cutPoint) {
    filters.cutPoint = cutPoint ?? 80;
  }
  const actionDate = actionDateValue?.value?.to;
  /* istanbul ignore else  */
  if (actionDate) {
    filters.actionDate = actionDate;
  }
};

const assignPayerAndPharmacy = (currentSelectedFilters: any[], filters: any) => {
  const pharmacy = currentSelectedFilters.find(x => x.key === 'pharmacy');
  const payerValue = currentSelectedFilters.find(x => x.key === 'payers');
  const payerId = (payerValue?.value && payerValue?.value[0]?.id) ?? payerValue?.value?.id;

  if (payerId && payerId !== 'all') {
    filters.payerId = payerId;
  }

  /* istanbul ignore else  */
  if (pharmacy) {
    filters.pharmacyId = pharmacy?.value?.id;
  }
};

const getFilterPayloadForMonoPatient = (currentSelectedFilters: any[]) => {
  const filters: any = {};
  const assignee = currentSelectedFilters.find(x => x.key === 'userIds')?.value[0];
  const isTeam = assignee?.type === 'TEAM';
  let userIds = isNaN(assignee?.id)
    ? assignee?.id?.split(',').map((item: any) => Number(item))
    : assignee?.id;

  /* istanbul ignore else  */
  if (assignee) {
    filters.assignee = {
      assigneeId: userIds,
      isTeam: !!isTeam,
    };
  }

  assignMeasureAndNextsteps(currentSelectedFilters, filters);
  assignPayerAndPharmacy(currentSelectedFilters, filters);
  assignActionDtAndCutpoint(currentSelectedFilters, filters);
  // if filters exist in the filters object, return it
  if (Object.keys(filters).length > 0) return filters;
  // if no filters, return null
  else return null;
};

export const getMonoFilters = () => (dispatch: any, getState: () => RootState) =>
  new Promise(async (resolve: any, reject: any) => {
    try {
      const currentSelectedFilters = getState().dashboard.defaultSelectedFilters;
      await dispatch(getMonoCount(currentSelectedFilters));
      resolve({});
    } catch (error) {
      console.log('error is ', error);
      reject(error);
    }
  });

// Updates filters on clicking apply filters button

export const updateFilters = (filters: any) => (dispatch: any, getState: () => RootState) =>
  new Promise(async (resolve: any, reject: any) => {
    if (isEventDrivenSystem()) {
      try {
        dispatch(setShowFailRiskGlobalWarning(false));
        dispatch(
          onPaginationChanged({
            offset: 0,
            limit: getState().dashboard.limit,
          }),
        );
        await dispatch(onSelectionValueChanged(filters));
        await dispatch(getCount());
        dispatch(getFilter());
        resolve({});
      } catch (error) {
        console.log(error);
        reject(error);
      }
    } else {
      try {
        dispatch(setShowFailRiskGlobalWarning(false));
        dispatch(onPaginationChanged({ offset: 0, limit: getState().dashboard.limit }));
        await dispatch(onSelectionValueChanged(filters));
        const currentSelectedFilters = getState().dashboard.currentSelectedFilter;
        await dispatch(getMonoCount(currentSelectedFilters));
        resolve({});
      } catch (error) {
        console.log(error);
        reject(error);
      }
    }
  });

// Updates filter counts monolith side only on changing selection of filters
const updateFilterCountsForMonoAndEds = async (currentSelectedFilterCloned: any, dispatch: any) => {
  if (isEventDrivenSystem()) {
    await dispatch(onFilterValueChanged(currentSelectedFilterCloned));
    await dispatch(getFilterCountEDS(currentSelectedFilterCloned));
  } else {
    await dispatch(onFilterValueChanged(currentSelectedFilterCloned));
    await dispatch(getMonoFilterResponse(currentSelectedFilterCloned));
  }
};

export const updateFilterCounts = (filters: any) => (dispatch: any, getState: () => RootState) =>
  new Promise(async (resolve: any, reject: any) => {
    const { type, value } = filters;
    const currentSelectedFilterCloned = JSON.parse(
      JSON.stringify(getState().dashboard.currentSelectedFilter),
    );
    _.each(currentSelectedFilterCloned, filter => {
      /* istanbul ignore else  */
      if (filter.key === type) {
        switch (type) {
          case 'userIds':
          case 'measureid':
          case 'stepIds': {
            filter.value = [value];
            break;
          }
          case 'dueBy': {
            filter.value.to = value;
            break;
          }
          default: {
            filter.value = value;
            break;
          }
        }
      }
    });
    try {
      await updateFilterCountsForMonoAndEds(currentSelectedFilterCloned, dispatch);
      resolve({});
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

export const reloadFilterDropdown = (filters: any) => (dispatch: any, getState: () => RootState) =>
  new Promise(async (resolve: any, reject: any) => {
    if (isEventDrivenSystem()) {
      try {
        await dispatch(getFilterCountEDS(filters));
        resolve({});
      } catch (error) {
        console.log(error);
        reject(error);
      }
    } else {
      try {
        await dispatch(getMonoFilterResponse(filters));
        resolve({});
      } catch (error) {
        console.log(error);
        reject(error);
      }
    }
  });

export const getFilterCountEDS =
  (currentSelectedFilters?: any) => (dispatch: any, getState: () => RootState) =>
    new Promise(async (resolve: any, reject: any) => {
      let filters;
      /* istanbul ignore else  */
      if (isEventDrivenSystem()) {
        if (currentSelectedFilters?.length > 0) {
          filters = currentSelectedFilters;
        } else {
          filters = getState().dashboard.currentSelectedFilter;
        }
        const payload: any = getSearchPatientPayload(filters);
        try {
          const res = await getFilterCount(payload.filter);
          const filterResponse: any = convertToFilterResponse(res);
          dispatch(setFilterResponse(filterResponse));
          resolve(res);
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }
    });

// setting filter values in dropdown to default on clicking clear all  button
export const clearAllFiltersToDefault =
  (defaultSelectedFilters: any) => (dispatch: any, getState: () => any) =>
    new Promise(async (resolve: any, reject: any) => {
      dispatch(setCurrentSelectedFilters(defaultSelectedFilters));
      let defaultFilters = await dispatch(createFilters(defaultSelectedFilters));
      if (isEventDrivenSystem()) {
        try {
          await dispatch(getFilterCountEDS());
          resolve(defaultFilters);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      } else {
        try {
          dispatch(onPharmacyInputTxt(''));
          resolve(defaultFilters);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      }
    });

export const getMonoFilterResponse =
  (currentSelectedFilters: any) => (dispatch: any, getState: () => any) =>
    new Promise(async (resolve: any, reject: any) => {
      let filters;
      if (currentSelectedFilters?.length === 0) {
        const currentDefaultFilters = JSON.parse(JSON.stringify(defaultFilters()));
        filters = getFilterPayloadForMonoPatient(currentDefaultFilters);
      } else {
        filters = getFilterPayloadForMonoPatient(currentSelectedFilters);
      }

      try {
        const payload: any = {
          page: getState().dashboard.offset,
          pageSize: getState().dashboard.limit,
          sortColumn: 'LastImpactDate',
          sortDirection: 'ASC',
        };

        /* istanbul ignore else  */
        if (filters) {
          payload.filters = filters;
        }
        const res = await FetchPatientRowWithoutFilterMonolith(payload);
        const filterResponse = convertToMonoFilterResponse(res);
        dispatch(setFilterResponse(filterResponse));
        resolve(res);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });

const processMonoCount = async (payload: any, dispatch: any, getState: () => any) => {
  const res = await FetchPatientRowWithoutFilterMonolith(payload);
  const filterResponse = convertToMonoFilterResponse(res);
  dispatch(setFilterResponse(filterResponse));
  let defaultSelectedFilters = [...getState().dashboard.defaultSelectedFilters];
  let users: any = defaultSelectedFilters.find((filter: any) => filter.key === 'userIds');
  if (users.value.length === 0) {
    const teamid = res?.CurrentUser ? res?.CurrentUser[0]?.TeamId : '';
    const userid = res?.CurrentUser ? res?.CurrentUser[0]?.UserId : '';
    const team = filterResponse.team.find(x => x.id === userid);

    const userData = {
      key: 'userIds',
      value: [{ ...team, uId: `${teamid}-${userid}` }],
    };
    defaultSelectedFilters[0] = userData;
    dispatch(setDefaultFilters(defaultSelectedFilters));
  }
  const { PatientMeasureList, TotalPatientCount, MinNextActionDate, MinLastImpactDate } = res;

  const { showPastDueWarning, showFailRiskWarning } = getWarningFlags(
    MinNextActionDate,
    MinLastImpactDate,
  );
  dispatch(setShowPastDueGlobalWarning(showPastDueWarning));
  dispatch(setShowFailRiskGlobalWarning(showFailRiskWarning ? true : false));
  const globalFlags = getGlobalFlags(MinNextActionDate);
  const patientData = getPatientDataFromMeasures(
    PatientMeasureList,
    TotalPatientCount,
    globalFlags,
    dispatch,
  );

  dispatchCurrentUser(dispatch, res);
  dispatch(onPatientReceived(patientData));
  fetchPaginatedPatientsAvailable = true;
  return res;
};

export const getMonoCount = (currentSelectedFilters: any) => (dispatch: any, getState: () => any) =>
  new Promise(async (resolve: any, reject: any) => {
    let filters;
    if (currentSelectedFilters?.length === 0) {
      const currentDefaultFilters = JSON.parse(JSON.stringify(defaultFilters()));
      filters = getFilterPayloadForMonoPatient(currentDefaultFilters);
    } else {
      filters = getFilterPayloadForMonoPatient(currentSelectedFilters);
    }

    const { sortByColumn, sortByDirection } = getSortColumnDetails(
      getState().dashboard.selectedSort,
    );

    try {
      const { offset, limit } = getState().dashboard;
      const payload: any = {
        page: Math.floor(offset / limit) + 1,
        pageSize: limit,
        sortColumn: sortByColumn,
        sortDirection: sortByDirection,
      };

      /* istanbul ignore else  */
      if (filters) {
        payload.filters = filters;
      }

      const res = processMonoCount(payload, dispatch, getState);

      resolve(res);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

export const dispatchCurrentUser = (dispatch: any, res: any) => {
  try {
    let currentUser = {
      userId: res?.CurrentUser[0].UserId,
      name: res?.CurrentUser[0].UserFullName,
      role: res?.CurrentUser[0].UserData?.role,
    };
    dispatch(setCurrentUser(currentUser));
  } catch (error) {
    console.error(error);
  }
};

const getGlobalFlags = (actionDate: any, lastImpactDate: any = null) => {
  const today = new Date();

  let showPastDueWarning = false;
  if (actionDate) {
    const minNextActionDate = new Date(actionDate);
    minNextActionDate.setHours(0);
    minNextActionDate.setMinutes(0);
    minNextActionDate.setSeconds(0, 0);
    minNextActionDate.setMilliseconds(0);
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0, 0);
    today.setMilliseconds(0);
    showPastDueWarning = minNextActionDate < today;
  }

  let showFailRiskWarning =
    lastImpactDate === null
      ? false
      : getDaysBetweenStartAndEndDates({ startDate: today, endDate: lastImpactDate }) <= 10;

  return { showPastDueWarning, showFailRiskWarning };
};

const getPatientDataFromMeasures = (
  measures: MonoMeasure[],
  totalPatients: number,
  globalFlags: any,
  dispatch: any,
) => {
  const patientMap: any = new Map();
  // if measureList is null
  if (!measures) measures = [];

  measures.forEach((measure: MonoMeasure) => {
    // Only add shared patient data if patient doesn't already exist
    if (!patientMap.has(measure.PatientId)) {
      patientMap.set(measure.PatientId, {
        dob: measure.BirthDate,
        firstName: measure.FirstName,
        lastName: measure.LastName,
        assignedUserName: measure.UserFullName,
        assignedUserId: measure.AssignedUserId,
        patientId: measure.PatientId,
        patientTaskData: [],
        id: measure.PatientId,
      });
    }
    // add unique measure data
    const patientClone = patientMap.get(measure.PatientId);
    const { showPastDueWarning, showFailRiskWarning } = getWarningFlags(
      measure?.NextActionDate,
      measure?.LastImpactDate,
    );

    const { showPastDueWarning: isActionDatePassedGlobalFlag } = globalFlags;
    patientClone.patientTaskData.push({
      showPastDueWarning: showPastDueWarning,
      isActionDatePassedGlobalFlag: isActionDatePassedGlobalFlag,
      showFailRiskWarning,
      gapDays: measure.GapDaysRemaining,
      payer: `${measure.PayerName} ${measure?.DataSourceOrganizationName ?? ''}`,
      actionDate: measure.NextActionDate,
      taskStatus: getStatusDisplay(measure.Status),
      PDC: measure.PDC,
      measureDesc: getMeasureDisplay(measure.Measure),
      measureData: {
        title: getMeasureDisplay(measure.Measure),
        id: measure.Measure,
      },
      stepData: {
        title: getNextStepDisplay(measure.OpenGapCurrentStep),
      },
      id: measure.Measure,
      taskId: measure.Measure,
      lastImpactDate: measure.LastImpactDate,
      hasOpenGap: measure.HasOpenGap,
    });

    patientMap.set(measure.PatientId, patientClone);
  });
  return {
    result: Array.from(patientMap.values()),
    metadata: {
      total: totalPatients,
    },
  };
};

export const getStatusDisplay = (statusCode: number) => {
  switch (statusCode) {
    case 1:
      return 'PASSING';

    case 2:
      return 'FAILING';

    case 3:
      return 'SINGLE FILL';

    case 4:
      return 'PASSED';

    case 5:
      return 'FAILED';

    default:
      return '';
  }
};

const getWarningFlags = (actionDate: any, lastImpactDate: any = null) => {
  const today = new Date();

  let showPastDueWarning = actionDate
    ? moment(actionDate).startOf('day').isBefore(moment().startOf('day'))
    : false;

  let showFailRiskWarning =
    lastImpactDate === null
      ? false
      : getDaysBetweenStartAndEndDates({ startDate: today, endDate: lastImpactDate }) <= 9;

  return { showPastDueWarning, showFailRiskWarning };
};

export const getCount = () => (dispatch: any, getState: () => RootState) =>
  new Promise(async (resolve: any, reject: any) => {
    /* istanbul ignore else  */
    if (isEventDrivenSystem()) {
      const currentSelectedFilters: any = getState().dashboard.currentSelectedFilter;
      const payload: any = getSearchPatientPayload(currentSelectedFilters);
      try {
        const res = await getFilterCount(payload.filter);

        const { showPastDueWarning, showFailRiskWarning } = getWarningFlags(
          res?.metaData?.minNextActionDate,
          res?.metaData?.minLastImpactDate,
        );
        dispatch(setShowPastDueGlobalWarning(showPastDueWarning));
        dispatch(setShowFailRiskGlobalWarning(showFailRiskWarning ? true : false));

        const filterResponse: any = convertToFilterResponse(res);
        dispatch(setFilterResponse(filterResponse));
        resolve(res);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    }
  });

export const createFilters = (selectedFilters: any) => (dispatch: any, getState: () => RootState) =>
  new Promise(async (resolve: any, reject: any) => {
    try {
      let userIdsFound: any,
        measureIdsFound: any,
        stepIdsFound: any,
        endDateFound: any,
        cutPointFound: any,
        pharmacyFound: any,
        payersFound: any;

      /* istanbul ignore else  */
      if (selectedFilters) {
        userIdsFound = selectedFilters.find((filter: any) => filter.key === 'userIds');
        measureIdsFound = selectedFilters.find((filter: any) => filter.key === 'measureid');
        stepIdsFound = selectedFilters.find((filter: any) => filter.key === 'stepIds');
        cutPointFound = selectedFilters.find((filter: any) => filter.key === 'cutPoint');
        endDateFound = selectedFilters.find((filter: any) => filter.key === 'dueBy');
        pharmacyFound = selectedFilters.find((filter: any) => filter.key === 'pharmacy');
        payersFound = selectedFilters.find((filter: any) => filter.key === 'payers');
      }
      const { value: userIdValues } = userIdsFound || { value: [] };
      const { value: measureIdValues } = measureIdsFound || { value: [] };
      const { value: stepIdValues } = stepIdsFound || { value: [] };
      const { value: cutPointValue } = cutPointFound || { value: [] };
      const { value: pharmacyValue } = pharmacyFound || { value: {} };
      const { value: payersValue } = payersFound || { value: [] };

      const currentUserIdValue = userIdValues[0];
      const currentMeasureIdValue = measureIdValues;
      const currentStepIdValue = stepIdValues;

      const filters = {
        user: currentUserIdValue,
        measures: currentMeasureIdValue,
        steps: currentStepIdValue,
        cutPoint: cutPointValue,
        currentDate: endDateFound?.value?.to,
        pharmacy: pharmacyValue,
        payers: payersValue,
      };
      dispatch(setFiltersValues(filters));
      resolve(filters);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

const addToMeasureList = (measures: Measure[]) => {
  if (measures.findIndex(x => x.name === 'Diabetes') === -1) {
    measures.push({
      id: 'Diabetes',
      type: 'MEASURE',
      count: 0,
      name: 'Diabetes',
    } as Measure);
  }

  if (measures.findIndex(x => x.name === 'Blood Pressure') === -1) {
    measures.push({
      id: 'Blood_Pressure',
      type: 'MEASURE',
      count: 0,
      name: 'Blood Pressure',
    } as Measure);
  }

  if (measures.findIndex(x => x.name === 'Cholesterol') === -1) {
    measures.push({
      id: 'Cholesterol',
      type: 'MEASURE',
      count: 0,
      name: 'Cholesterol',
    } as Measure);
  }

  if (measures.findIndex(x => x.name === 'Statin Use - DM') === -1) {
    measures.push({
      id: 'Statin_Use_DM',
      type: 'MEASURE',
      count: 0,
      name: 'Statin Use - DM',
    } as Measure);
  }

  if (measures.findIndex(x => x.name === 'Statin Use - CVD') === -1) {
    measures.push({
      id: 'Statin_Use_CVD',
      type: 'MEASURE',
      count: 0,
      name: 'Statin Use - CVD',
    } as Measure);
  }
};

const addToStepList = (steps: Step[]) => {
  if (steps.findIndex(x => x.name === 'Research') === -1) {
    steps.push({
      id: 'Research',
      type: 'STEP',
      count: 0,
      name: 'Research',
    } as Step);
  }

  if (steps.findIndex(x => x.name === 'Call Pharmacy') === -1) {
    steps.push({
      id: 'Call_Pharmacy',
      type: 'STEP',
      count: 0,
      name: 'Call Pharmacy',
    } as Step);
  }

  if (steps.findIndex(x => x.name === 'Contact Patient') === -1) {
    steps.push({
      id: 'Contact_Patient',
      type: 'STEP',
      count: 0,
      name: 'Contact Patient',
    } as Step);
  }

  if (steps.findIndex(x => x.name === CONTACT_PRESCRIBER_STEP_NAME) === -1) {
    steps.push({
      id: 'Contact_Prescriber',
      type: 'STEP',
      count: 0,
      name: CONTACT_PRESCRIBER_STEP_NAME,
    } as Step);
  }

  if (steps.findIndex(x => x.name === 'Triage to Pharmacist') === -1) {
    steps.push({
      id: 'Triage_to_Pharmacist',
      type: 'STEP',
      count: 0,
      name: 'Triage to Pharmacist',
    } as Step);
  }

  if (steps.findIndex(x => x.name === 'Intervention Outcome') === -1) {
    steps.push({
      id: 'Intervention_Outcome',
      type: 'STEP',
      count: 0,
      name: 'Intervention Outcome',
    } as Step);
  }
};

const convertToMonoFilterResponse = (res: any): FilterResponse => {
  let { MeasureFilter, CurrentUser, StepFilter, UserFilter, PharmacyFilter, PayerFilter } = res;

  // If any data is empty, assign it to array
  if (!MeasureFilter) MeasureFilter = [];
  if (!CurrentUser) CurrentUser = []; // We ALWAYS need a current user
  if (!StepFilter) StepFilter = [];
  if (!UserFilter) UserFilter = [];
  if (!PharmacyFilter) PharmacyFilter = [];
  if (!PayerFilter) PayerFilter = [];

  const teamid = CurrentUser[0]?.TeamId;

  const teams: Team[] = [
    ...UserFilter.filter((x: any) => x.UserId != null).map((x: any) => {
      return {
        id: x.UserId,
        uId: `${teamid}-${x.UserId}`,
        type: 'USER',
        count: x.PatientCount,
        name: x.UserFullName,
      } as Team;
    }),
  ];

  const allPayers: Payer = {
    id: 'all',
    type: 'PAYER',
    name: 'All Payers',
  };

  let payers: Payer[] = [
    allPayers,
    ...PayerFilter?.map((x: any) => {
      return {
        name: x.PayerName,
        type: 'PAYER',
        id: x.id ?? x.PayerName,
      } as Payer;
    }),
  ];

  const totalMeasures = MeasureFilter?.reduce((previousValue: number, x: any) => {
    let currentCount = !isNaN(x.MeasureCount) ? parseInt(x.MeasureCount, 10) : 0;
    return previousValue + currentCount;
  }, 0);

  const allMeasures: Measure = {
    id: 'all',
    type: 'MEASURE',
    count: totalMeasures,
    name: 'All Measures',
  };

  let measures: Measure[] = [
    allMeasures,
    ...MeasureFilter?.map((x: any) => {
      return {
        id: x.Measure,
        type: 'MEASURE',
        count: x.MeasureCount,
        name: getMeasureDisplay(x.Measure),
      } as Measure;
    }),
  ];

  addToMeasureList(measures);

  const totalSteps = StepFilter?.reduce((previousValue: number, x: any) => {
    let currentCount = !isNaN(x.StepCount) ? parseInt(x.StepCount, 10) : 0;
    return previousValue + currentCount;
  }, 0);

  const allSteps: Step = {
    id: 'all',
    type: 'STEP',
    count: totalSteps,
    name: 'All Steps',
  };

  const steps: Step[] = [
    allSteps,
    ...StepFilter?.map((x: any) => {
      return {
        id: x.OpenGapCurrentStep,
        type: 'STEP',
        count: x.StepCount,
        name: getNextStepDisplay(x.OpenGapCurrentStep),
      } as Step;
    }),
  ];

  addToStepList(steps);

  let pharmacyData: Pharmacy[] = PharmacyFilter.map((x: any) => {
    return { id: x.PatientId, name: x.PharmacyName };
  });

  pharmacyData = _.sortBy(pharmacyData, 'name');
  const filterResponse: FilterResponse = {
    team: teams,
    measures: measures,
    steps: steps,
    pharmacy: pharmacyData,
    payers: payers,
  };
  return filterResponse;
};

export const getMeasureDisplay = (measureCode: number) => {
  switch (measureCode) {
    case 1:
      return 'Diabetes';
    case 2:
      return 'Blood Pressure';
    case 3:
      return 'Cholesterol';
    case 4:
      return 'Statin Use - DM';
    case 5:
      return 'Statin Use - CVD';
    default:
      return '';
  }
};

const getNextStepDisplay = (nextStepCode: number) => {
  switch (nextStepCode) {
    case 1:
      return 'Research';
    case 2:
      return 'Call Pharmacy';
    case 3:
      return 'Contact Patient';
    case 4:
      return CONTACT_PRESCRIBER_STEP_NAME;
    case 5:
      return 'Triage to Pharmacist';
    case 6:
      return 'Intervention Outcome';
    default:
      return '';
  }
};

const convertToFilterResponse = (res: any): FilterResponse => {
  const teamId = res.team.children.map((x: any) => x.id).join(',');

  const teams: Team[] = [
    ...res.team.children.map((x: any) => {
      return {
        id: x.id,
        uId: `${teamId}-${x.id}`,
        type: 'USER',
        count: x.count,
        name: x.name,
      } as Team;
    }),
  ];

  const allMeasures: Measure = {
    id: 'all',
    type: 'MEASURE',
    count: res.total.measures,
    name: 'All Measures',
  };

  const measures: Measure[] = [
    allMeasures,
    ...res.measures.map((x: any) => {
      return {
        id: x.id,
        type: 'MEASURE',
        count: x.count,
        name: x.name,
      } as Measure;
    }),
  ];

  const allSteps: Step = {
    id: 'all',
    type: 'STEP',
    count: res.total.steps,
    name: 'All Steps',
  };

  const steps: Step[] = [
    allSteps,
    ...res.steps.map((x: any) => {
      return {
        id: x.id,
        type: 'STEP',
        count: x.count,
        name: x.name,
      } as Step;
    }),
  ];

  const filterResponse: FilterResponse = {
    team: teams,
    measures: measures,
    steps: steps,
  };
  return filterResponse;
};

const getSearchPatientPayload = (currentSelectedFilters: any) => {
  const userIdsFound: any = currentSelectedFilters?.find((filter: any) => filter.key === 'userIds');
  const dueByFound: any = currentSelectedFilters?.find((filter: any) => filter.key === 'dueBy');
  const measuresFound: any = currentSelectedFilters?.find(
    (filter: any) => filter.key === 'measureid',
  );
  const stepsFound: any = currentSelectedFilters?.find((filter: any) => filter.key === 'stepIds');
  const cutpoint: any = currentSelectedFilters?.find((filter: any) => filter.key === 'cutPoint');
  const { from, to } = dueByFound?.value || {};
  let userIds = isNaN(userIdsFound?.value[0]?.id)
    ? userIdsFound?.value[0]?.id.split(',').map((item: any) => Number(item))
    : [userIdsFound?.value[0]?.id];
  let measureIds = isNaN(measuresFound?.value?.id) ? [] : [measuresFound?.value?.id];
  let stepIds = isNaN(stepsFound?.value?.id) ? [] : [stepsFound?.value?.id];
  let cutPoint = cutpoint?.value;

  const payload: any = {
    offset: 0,
    limit: 10,
    filter: {
      userIds,
      measureIds,
      stepIds,
      cutPoint,
      startDate: from,
      endDate: to,
    },
  };
  return payload;
};

export const getFilter = () => (dispatch: any, getState: () => RootState) =>
  new Promise(async (resolve: any, reject: any) => {
    /* istanbul ignore else  */
    if (isEventDrivenSystem()) {
      try {
        const currentSelectedFilters = getState().dashboard.currentSelectedFilter;
        await dispatch(getEventDrivenFilter(currentSelectedFilters));
        resolve();
      } catch (error) {
        reject(error);
      }
    }
  });

const getEventDrivenFilter = (currentSelectedFilters: any) => (dispatch: any) =>
  new Promise(async (resolve: any, reject: any) => {
    const payload: any = getSearchPatientPayload(currentSelectedFilters);
    try {
      const res = await searchPatients(payload);
      dispatch(onPatientReceived(res));
      dispatch(loadingSuccess());
      resolve(res);
    } catch (error) {
      console.log(error);
      dispatch(loadingFailed());
      reject(error);
    }
  });

export const searchPatient = (patient: any) => (dispatch: any, getState: () => RootState) =>
  new Promise(async (resolve: any, reject: any) => {
    if (isEventDrivenSystem()) {
      const offset = 0;
      const limit = 10;
      try {
        let payload: any = {
          offset: offset,
          limit: limit,
        };
        payload.search = patient.lastName;
        const res = await FetchPatientRow(payload);
        const results = [];
        dispatch(onSelectedPatientReceived(getGapDayAndActionDateFlag(res)));
        dispatch(loadingSuccess());
        resolve(res);
      } catch (error) {
        console.error(error);
        dispatch(loadingFailed());
      }
    } else {
      try {
        let payload: any = {};
        payload = {
          page: 1,
          pageSize: 10,
          filters: {
            patientId: patient.id,
          },
        };

        const res = await FetchPatientRowWithoutFilterMonolith(payload);
        const { PatientMeasureList, TotalPatientCount, MinNextActionDate, MinLastImpactDate } = res;

        const { showPastDueWarning, showFailRiskWarning } = getWarningFlags(
          MinNextActionDate,
          MinLastImpactDate,
        );
        dispatch(setShowPastDueGlobalWarning(showPastDueWarning));
        dispatch(setShowFailRiskGlobalWarning(showFailRiskWarning ? true : false));

        const globalFlags = getGlobalFlags(MinNextActionDate);
        const patientData = getPatientDataFromMeasures(
          PatientMeasureList,
          TotalPatientCount,
          globalFlags,
          dispatch,
        );

        dispatchCurrentUser(dispatch, res);
        dispatch(onSelectedPatientReceived(patientData));
        dispatch(loadingSuccess());
        resolve(res);
      } catch (error) {
        console.error(error);
        dispatch(loadingFailed());
      }
    }
  });

export const reloadGrid = (filter: any) => (dispatch: any) => {
  dispatch(getReloadCount(filter));
  dispatch(getReloadFilter(filter));
};

export const getReloadCount = (filter: any) => (dispatch: any, getState: () => RootState) =>
  new Promise(async (resolve: any, reject: any) => {
    let currentSelectedFilters: any;
    if (filter === DEFAULT) {
      currentSelectedFilters = getState().dashboard.defaultSelectedFilters;
      dispatch(setDefaultFilters(currentSelectedFilters));
      dispatch(
        onPaginationChanged({
          offset: 0,
          limit: 10,
        }),
      );
    } else {
      currentSelectedFilters = getState().dashboard.currentSelectedFilter;
    }

    if (isEventDrivenSystem()) {
      const payload: any = getSearchPatientPayload(currentSelectedFilters);
      try {
        const res = await getFilterCount(payload.filter);
        const { showPastDueWarning, showFailRiskWarning } = getWarningFlags(
          res?.metaData?.minNextActionDate,
        );
        dispatch(setShowPastDueGlobalWarning(showPastDueWarning));
        dispatch(setShowFailRiskGlobalWarning(showFailRiskWarning ? true : false));

        const filterResponse = convertToFilterResponse(res);
        dispatch(setFilterResponse(filterResponse));
        resolve(res);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    } else {
      try {
        dispatch(getMonoCount(currentSelectedFilters));
        resolve({});
      } catch (error) {
        console.error(error);
      }
    }
  });

export const getReloadFilter = (filter?: any) => (dispatch: any, getState: () => RootState) =>
  new Promise(async (resolve: any, reject: any) => {
    const currentSelectedFilters: any =
      filter == DEFAULT
        ? getState().dashboard.defaultSelectedFilters
        : getState().dashboard.currentSelectedFilter;
    if (isEventDrivenSystem()) {
      getEventDrivenFilter(currentSelectedFilters);
    }
  });

export const fetchUsers = (data: any) => (dispatch: any, getState: any) =>
  new Promise(async (resolve: any, reject: any) => {
    if (isEventDrivenSystem()) {
      const selectedAssignees: any = getState().dashboard.selectedAssignees;
      const teamId = getState().auth?.userDetails?.assignedCohorts?.guid;
      try {
        let payload: any = {
          patientIds: Object.keys(selectedAssignees),
          teamId,
        };

        let response: any = await searchAssigneesAPI(payload);
        let users: User[] = response.map((user: any) => {
          return {
            ...user,
            userFullName: user.name,
            userData: {
              role: user.role,
            },
          } as User;
        });
        if (data?.userSearchText) {
          let searchText = data?.userSearchText?.toLowerCase();
          if (searchText.includes(',')) {
            let newUserList: User[] = [];

            const strings = searchText.split(',');
            const searchLastName = strings.shift() ?? '';
            const searchFirstName = strings.join(' ');
            const regex = new RegExp('^' + searchFirstName.trim(), 'gim');

            newUserList = [
              ...newUserList,
              ...users.filter(user => {
                const strings = user.userFullName.split(' ');
                const firstName = strings.shift() ?? '';
                const lastName = strings.join(' ');

                return (
                  lastName.toLowerCase() === searchLastName.toLowerCase() && firstName.match(regex)
                );
              }),
            ];

            newUserList = Array.from(new Map(newUserList.map(item => [item.id, item])).values());
            users = newUserList;
          } else {
            const regex = new RegExp('^' + searchText, 'gim');
            users = [
              ...users.filter(user => {
                const strings = user.userFullName.split(' ');
                const firstName = strings.shift() ?? '';
                const lastName = strings.join(' ');

                return firstName.match(regex) || lastName.match(regex);
              }),
            ];
          }
        }
        dispatch(setUsers(users));
        resolve(users);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    } else {
      const selectedAssignees: any = getState().dashboard.selectedAssignees;
      try {
        let payload: any = {
          patientIds: Object.keys(selectedAssignees).map(assigneeId => parseInt(assigneeId)),
          skip: 0,
          take: 9999,
          searchText: data?.userSearchText,
        };

        const response = await searchAssignees(payload);
        let users: User[] = response[0]?.map((user: any) => {
          return {
            ...user,
            isUserAllowForReassignment: true,
          } as User;
        });
        dispatch(setUsers(users));
        resolve(users);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    }
  });

export const reassignPatient = (data: any) => (dispatch: any, getState: () => RootState) => {
  return new Promise<any>(async (resolve, reject) => {
    const { searchPatientClick, selectedPatient } = getState().dashboard;

    if (isEventDrivenSystem()) {
      try {
        const user = {
          id: data?.user?.id,
          name: data?.user?.userFullName,
          selfCohortGuid: data?.user?.selfCohortGuid,
          teamCohortGuid: data?.user?.teamCohortGuid,
          teamGuid: data?.user?.teamGuid,
        };
        const payload = {
          patientIds: data.patientIds,
          assignee: user,
        };
        const response = await reassignPatientsAPI(payload);
        dispatch(fetchFilters());
        resolve(response);
      } catch (e) {
        console.log(e);
        reject(e);
      }
    } else {
      try {
        const response = await reassignPatients(data);
        if (searchPatientClick === true) {
          dispatch(searchPatient(selectedPatient));
        } else {
          dispatch(reloadGrid(CURRENT));
        }
        resolve(response);
      } catch (e) {
        console.log(e);
        reject(e);
      }
    }
  });
};

export const loadSearchData = (searchedText: any) => (dispatch: any, getState: () => RootState) => {
  return new Promise<any>(async (resolve, reject) => {
    const { searchedPage } = getState().dashboard;
    const pageSize = 10;
    try {
      let payload: any = {
        offset: 0,
        limit: pageSize * searchedPage,
      };
      if (searchedText !== null) {
        payload.search = searchedText;
      }

      dispatch(setSearchedLoader(true));
      const response = await searchPatients(payload);
      dispatch(setSearchedData([...response.result]));
      dispatch(setSearchedResultTotal(response?.metadata?.total));
      dispatch(setSearchedLoader(false));
      resolve(response);
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

const searchPharmacy = async (payload: { search: any; filteredResult: any }) => {
  const { search } = payload;
  if (isEventDrivenSystem()) {
    return { result: [], total: 0 };
  } else {
    const { searchedPharmacies } = await fetchPatientPharmacy({ searchText: search });
    const pharamcyNames =
      searchedPharmacies?.map((pharmacy: any) => {
        return {
          id: pharmacy.id,
          name: pharmacy.pharmacyName,
        };
      }) ?? [];
    return {
      result: pharamcyNames,
      total: pharamcyNames?.length ?? 0,
    };
  }
};

export const loadPharmacyData =
  (searchedText: any) => (dispatch: any, getState: () => RootState) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let payload: any = {};
        if (searchedText !== null) {
          payload.search = searchedText;
        }
        const { filteredResult } = getState().dashboard;
        payload.filteredResult = filteredResult;

        dispatch(setPharmacyLoader(true));
        const response = await searchPharmacy(payload);
        dispatch(setPharmacyData([...response?.result]));
        dispatch(setSearchedPharmacyResultTotal(response?.total));
        dispatch(setPharmacyLoader(false));
        resolve(response);
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

export const loadLastWorkedPatients = () => (dispatch: any, getState: () => RootState) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      dispatch(setLastWorkedPatientFlag(false));
      let response;
      if (isEventDrivenSystem()) {
        response = await searchLastWorkedPatients();
      } else {
        response = await fetchLastWorkedPatientsMonolith();
      }
      if (isEmpty(response.result)) {
        dispatch(setLastWorkedPatientFlag(false));
      } else {
        dispatch(setLastWorkedPatientFlag(true));
      }
      dispatch(setLastWorkedPatients([...response.result]));
      dispatch(setLastWorkedPatientTotal(response?.metadata?.count));
      resolve(response);
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

export const getPatientMeasureInfo = (
  dashboardState: any,
  measureId?: any,
  selectedPatientMedDetails?: any,
): UserData => {
  const { selectedPatient, currentUser } = dashboardState;
  const UserId = currentUser[0]?.UserId;
  const UserFullName = currentUser[0]?.UserFullName;
  const patientFirstName = selectedPatient?.firstName;
  const { medicationName, pharmacyName, prescriberName } = selectedPatientMedDetails || {};
  let measureName;
  if (isEventDrivenSystem()) {
    measureName = getMeasureNameForEDS(measureId);
  } else {
    measureName = getMeasureName(measureId);
  }
  return {
    userId: UserId,
    userFullName: UserFullName,
    patientName: patientFirstName,
    prescriberName,
    providerName: prescriberName,
    medicationNames: medicationName,
    pharmacyName,
    measureName: measureName,
  };
};

const measureIdMap: any = {
  '1': 'Diabetes',
  '2': 'Blood Pressure',
  '3': 'Cholesterol',
  '4': 'Statin USE-DM',
  '5': 'Statin USE-CVD',
};

const measureIdMapEDS: any = {
  '625daca1b91534991007e001': 'Diabetes',
  '625daca1b91534991007e002': 'Blood Pressure',
  '625daca1b91534991007e003': 'Cholesterol',
  '625daca1b91534991007e004': 'Statin USE-DM',
  '625daca1b91534991007e005': 'Statin USE-CVD',
};

const getMeasureName = (measureId: number) => {
  return measureIdMap[`${measureId}`] || '';
};

const getMeasureNameForEDS = (measureId: number) => {
  return measureIdMapEDS[`${measureId}`] || '';
};

export const getStringReplaced = (value: string, userData: UserData): string => {
  const { prescriberName, medicationNames, pharmacyName, measureName, patientName } = userData;
  return (value || '')
    .replaceAll(
      '[$MedicationName]',
      medicationNames?.length > 1 ? 'Prescription' : medicationNames ? medicationNames[0] : '',
    )
    .replaceAll('[$pharmacy’s]', `${pharmacyName}'s`)
    .replaceAll('[$PatientFirstName]', capitalizeFirstLetter(patientName || ''))
    .replaceAll('[$PrescriberName]', prescriberName)
    .replaceAll('[$Measure]', measureName)
    .replaceAll('[Measure]', measureName)
    .replaceAll('[$Pharmacy]', pharmacyName);
};
export const filterValuesInitial = {
  user: [],
  measures: [],
  steps: [],
  cutPoint: 80,
  currentDate: '',
  pharmacy: '',
  payers: [],
};

export const initialState: DashboardState = {
  showAnswerLoader: false,
  isLoading: false,
  showFilteredData: false,
  patient: [],
  currentUser: [],
  inputTxt: '',
  pharmacyInputTxt: '',
  searchPatientClick: false,
  searchedPatientID: 0,
  searchedPharmacyName: '',
  selectedSearchPatient: [],
  pageNumber: 1,
  cancelAssignment: 1,
  searchPatientClicked: false,
  selectedAssignees: {},
  searchModalOpen: false,
  searchText: null,
  filteredPatient: [],
  allSearchPatient: [],
  isFailed: false,
  savedFilterList: [],
  currentSelectedFilter: [],
  savedFilterLoading: false,
  filterOptions: {},
  assigneeDetail: [],
  searchedPharmacyResultTotal: 0,
  assigneeRole: '',
  offset: 0,
  limit: 10,
  pageSize: 10,
  filteredOffset: 0,
  filteredLimit: 10,
  defaultSelectedFilters: defaultFilters(),
  selectedPatient: null,
  isClicked: false,
  globalid: '',
  userIds: [],
  measureIds: [],
  stepIds: [],
  cutPoint: 80,
  startDate: moment().startOf('year').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  filtered: false,
  selectAllAssignees: '',
  users: [],
  showFailRiskGlobalWarning: false,
  showFilterDrawer: false,
  showFiveNinePopover: false,
  filterValues: filterValuesInitial,
  selectedDropdownFilters: [],
  searchLoader: false,
  searchedData: [],
  pharmacyData: [],
  pharmacyLoader: false,
  searchedPage: 1,
  searchedResultTotal: 0,
  lastWorkedPatients: [],
  lastWorkedPatientTotal: 0,
  lastWorkedPatientFlag: false,
  selectedSort: {
    gapDays: 'ASC',
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    onPatientReceived: (state: { patient: any }, action: PayloadAction<any>) => {
      state.patient = action.payload;
    },
    setCurrentUser: (state: { currentUser: any }, action: PayloadAction<any>) => {
      state.currentUser = action.payload;
    },

    setSort: (state: { selectedSort: any }, action: PayloadAction<any>) => {
      state.selectedSort = action.payload;
    },

    setAssigneeRole: (state, action: PayloadAction<any>) => {
      state.assigneeRole = action.payload;
    },

    onSearchPationClicked: (state: { searchPatientClicked: any }, action: PayloadAction<any>) => {
      state.searchPatientClicked = action.payload;
    },

    onSearchModalOpen: (state: { searchModalOpen: any }, action: PayloadAction<any>) => {
      state.searchModalOpen = action.payload;
    },

    onNextPatientselection: (state, action: PayloadAction<any>) => {
      state.selectedPatient = state.patient.result[action.payload];
    },

    selectLastPatient: state => {
      state.selectedPatient = state.patient.result[state.patient.result.length - 1];
    },

    onPatientClicked: state => {
      state.isClicked = true;
    },
    onPatientClosed: state => {
      state.isClicked = false;
    },
    setOldAssignee: (state, action: PayloadAction<any>) => {
      state.oldAssigneeId = action.payload;
    },
    onAssigneeCheckboxClick: (state: { selectedAssignees: any }, action: PayloadAction<any>) => {
      const clickedPatientId = action.payload;
      const assigneesCloned = JSON.parse(JSON.stringify(state.selectedAssignees));

      // If assignees contains the patient id it is being unchecked, otherwise it is being checked and should be added.
      if (assigneesCloned[clickedPatientId]) delete assigneesCloned[clickedPatientId];
      else assigneesCloned[clickedPatientId] = clickedPatientId;

      state.selectedAssignees = assigneesCloned;
    },

    deselectAllAssignees: (state: { selectedAssignees: {}; cancelAssignment: number }) => {
      // Change internal state engine assignees to empty
      state.selectedAssignees = {};

      // When this value is changed, all assigee cells detect they should be deselected
      if (state.cancelAssignment === 1) {
        state.cancelAssignment = 2;
      } else {
        state.cancelAssignment = 1;
      }
    },

    setUsers: (state: { users: any }, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },

    onAllSearchPatientReceived: (state, action: PayloadAction<any>) => {
      state.allSearchPatient = action.payload;
    },

    onFilteredPatientReceived: (state: { filteredPatient: any }, action: PayloadAction<any>) => {
      state.filteredPatient = action.payload;
    },
    onClearFilterClicked: (state: { defaultSelectedFilters: FilterType[] }) => {
      state.defaultSelectedFilters = defaultFilters();
    },

    setFilterResponse: (state, action: PayloadAction<any>) => {
      state.filteredResult = action.payload as FilterResponse;
    },
    setAnswerLoader: (state: { showAnswerLoader: any }, action: PayloadAction<any>) => {
      state.showAnswerLoader = action.payload;
    },
    setDefaultFilters: (state, action: PayloadAction<any>) => {
      state.defaultSelectedFilters = action.payload;
      state.currentSelectedFilter = action.payload;
      state.selectedDropdownFilters = action.payload;
    },

    loadingFailed: (state: { isFailed: boolean }) => {
      state.isFailed = true;
    },
    onPaginationChanged: (state, action: PayloadAction<any>) => {
      state.offset = action.payload.offset;
      state.limit = action.payload.limit;
      state.highlightRow = action.payload.highlightRow;
    },
    onPageSizeChanged: (state, action: PayloadAction<any>) => {
      state.pageSize = action.payload;
      state.offset = 0;
      state.limit = action.payload;
    },
    loadingSuccess: (state: { isLoading: boolean }) => {
      state.isLoading = false;
    },

    checkAssginee: (state, action: PayloadAction<any>) => {
      state.assigneeDetail = action.payload;
    },

    setSavedFilterLoading: (state, action: PayloadAction<any>) => {
      state.savedFilterLoading = action.payload;
    },
    onSearchTextChanged: (state: { searchText: any }, action: PayloadAction<any>) => {
      state.searchText = action.payload;
    },

    onPatientSelected: (state, action: PayloadAction<any>) => {
      state.selectedPatient = action.payload;
    },

    onInputTxt: (state: { inputTxt: any }, action: PayloadAction<any>) => {
      state.inputTxt = action.payload;
    },

    onPharmacyInputTxt: (state: { pharmacyInputTxt: any }, action: PayloadAction<any>) => {
      state.pharmacyInputTxt = action.payload;
    },

    onSelectedPatientReceived: (
      state: { selectedSearchPatient: any },
      action: PayloadAction<any>,
    ) => {
      state.selectedSearchPatient = action.payload;
    },

    onSelectedSearchPatient: (state, action: PayloadAction<any>) => {
      state.selectedPatient = state.selectedSearchPatient.result[action.payload];
    },

    setSearchPatientId: (state: { searchedPatientID: any }, action: PayloadAction<any>) => {
      state.searchedPatientID = action.payload;
    },

    setSearchPharmacyName: (state: { searchedPharmacyName: any }, action: PayloadAction<any>) => {
      state.searchedPharmacyName = action.payload;
    },
    onSearchPatientClick: (state: { searchPatientClick: any }, action: PayloadAction<any>) => {
      state.searchPatientClick = action.payload;
    },

    setShowPastDueGlobalWarning: (state, action: PayloadAction<any>) => {
      state.showPastDueGlobalWarning = action.payload;
    },
    setShowFailRiskGlobalWarning: (state, action: PayloadAction<any>) => {
      state.showFailRiskGlobalWarning = action.payload;
    },
    setShowFilterDrawer: (state, action: PayloadAction<any>) => {
      state.showFilterDrawer = action.payload;
    },
    setShowFiveNinePopover: (state, action: PayloadAction<any>) => {
      state.showFiveNinePopover = action.payload;
    },
    setFiltersValues: (state, action: PayloadAction<any>) => {
      state.filterValues = action.payload;
    },
    onSelectionValueChanged: (state, action: PayloadAction<any>) => {
      const filters = action.payload;
      const currentSelectedFilterCloned = [
        { key: 'userIds', value: [filters?.user] },
        { key: 'measureid', value: filters?.measures },
        { key: 'stepIds', value: filters?.steps },
        { key: 'cutPoint', value: parseInt(filters?.cutPoint) },
        { key: 'pharmacy', value: filters?.pharmacy },
        { key: 'payers', value: filters?.payers },
        {
          key: 'dueBy',
          value: {
            from: moment().startOf('year').startOf('month').format('YYYY-MM-DD'),
            to: filters?.currentDate,
          },
        },
      ];
      state.selectedDropdownFilters = currentSelectedFilterCloned;
      state.currentSelectedFilter = currentSelectedFilterCloned;
    },
    onFilterValueChanged: (state, action: PayloadAction<any>) => {
      const filters = action.payload;
      const currentSelectedFilterCloned = JSON.parse(JSON.stringify(filters));
      state.currentSelectedFilter = currentSelectedFilterCloned;
    },
    // setSelectedDropdownFilters: (state, action: PayloadAction<any>) => {
    //   state.selectedDropdownFilters = action.payload;
    // },
    setCurrentSelectedFilters: (state, action: PayloadAction<any>) => {
      state.currentSelectedFilter = action.payload;
    },
    setSearchedData: (state, action: PayloadAction<any>) => {
      state.searchedData = action.payload;
    },

    setSearchedLoader: (state, action: PayloadAction<any>) => {
      state.searchLoader = action.payload;
    },

    setPharmacyData: (state: { pharmacyData: any }, action: PayloadAction<any>) => {
      state.pharmacyData = action.payload;
    },
    setPharmacyLoader: (state: { pharmacyLoader: any }, action: PayloadAction<any>) => {
      state.pharmacyLoader = action.payload;
    },
    setSearchedPage: (state, action: PayloadAction<any>) => {
      state.searchedPage = action.payload;
    },
    setSearchedResultTotal: (state, action: PayloadAction<any>) => {
      state.searchedResultTotal = action.payload;
    },
    setSearchedPharmacyResultTotal: (
      state: { searchedPharmacyResultTotal: any },
      action: PayloadAction<any>,
    ) => {
      state.searchedPharmacyResultTotal = action.payload;
    },

    setLastWorkedPatientFlag: (state, action: PayloadAction<any>) => {
      state.lastWorkedPatientFlag = action.payload;
    },
    setLastWorkedPatients: (state, action: PayloadAction<any>) => {
      state.lastWorkedPatients = action.payload;
    },
    setLastWorkedPatientTotal: (state, action: PayloadAction<any>) => {
      state.lastWorkedPatientTotal = action.payload;
    },
    resetDashboard: () => initialState,
  },
});

export const {
  onInputTxt,
  onPharmacyInputTxt,
  onSearchPatientClick,
  setSearchPatientId,
  setSearchPharmacyName,
  onSearchPationClicked,
  onSelectedSearchPatient,
  onSearchModalOpen,
  onPatientClicked,
  setFilterResponse,
  onPatientClosed,
  onAssigneeCheckboxClick,
  deselectAllAssignees,
  setUsers,
  onFilteredPatientReceived,
  loadingFailed,
  loadingSuccess,
  onPatientReceived,
  setCurrentUser,
  onSearchTextChanged,
  setDefaultFilters,
  setSavedFilterLoading,
  onPaginationChanged,
  onPageSizeChanged,
  onSelectedPatientReceived,
  onAllSearchPatientReceived,
  onPatientSelected,
  selectLastPatient,
  onNextPatientselection,
  onClearFilterClicked,
  setAnswerLoader,
  checkAssginee,
  setAssigneeRole,
  setShowPastDueGlobalWarning,
  setShowFailRiskGlobalWarning,
  setShowFilterDrawer,
  setFiltersValues,
  onSelectionValueChanged,
  onFilterValueChanged,
  setCurrentSelectedFilters,
  setSearchedData,
  setPharmacyData,
  setSearchedPharmacyResultTotal,
  setSearchedLoader,
  setPharmacyLoader,
  setSearchedPage,
  setSearchedResultTotal,
  setLastWorkedPatients,
  setLastWorkedPatientFlag,
  setLastWorkedPatientTotal,
  setSort,
  setShowFiveNinePopover,
  setOldAssignee,
  resetDashboard,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
