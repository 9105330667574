import config from './config';
import { UserDetails } from './features/authentication/authAPI';

const initialize = () => {
  (window as any).heap?.load(config.REACT_APP_HEAP_IO_ID);
};

const login = (userDetails: UserDetails) => {
  const heap = (window as any).heap;
  if (heap && userDetails.email) {
    const { id, name, email } = userDetails;
    heap.identify(id);
    heap.addUserProperties({ Name: name, email });
  }
};

const track = (event: string, properties: any = undefined) => {
  (window as any).heap?.track(event, properties);
};

const events = {
  selectPatient: 'selectPatient',
  closeCardRow: 'closeCardRow',
  openCardRow: 'openCardRow',
  selectOption: 'selectOption',
  saveStep: 'saveStep',
};

export const Tracker = {
  initialize,
  login,
  track,
  events,
};
