import moment from 'moment';
import { SCHEDULE_FOLLOWUP_STEP_ID } from '../../../../utility/rules.constants';

export function getSuggestedFollowUpDate(
  claimFound: boolean | null = null,
  stepId: string,
  lastImpactDateUpd: string,
  nextExpectedFillDateUpd: string,
  daysSupply: number,
): string {
  // verify if all required data has valid values
  if (
    claimFound === null ||
    !stepId ||
    !lastImpactDateUpd ||
    !nextExpectedFillDateUpd ||
    !daysSupply
  ) {
    return '';
  }

  // rule 1: if claimFound is true, no need to suggest follow up date
  if (claimFound) {
    return '';
  }

  // rule 2: if step is not schedule follow-up, no need to suggest follow up date
  if (stepId !== SCHEDULE_FOLLOWUP_STEP_ID) {
    return '';
  }

  // calculating gap days
  const lastImpactDate = moment(lastImpactDateUpd);
  const nextExpectedFillDate = moment(nextExpectedFillDateUpd);
  const gapDays = lastImpactDate.diff(moment(nextExpectedFillDateUpd).add(1, 'days'), 'days');

  // calculating suggested follow-up date
  const isShortSupply = daysSupply >= 28 && daysSupply <= 30;
  const isLongSupply = daysSupply >= 84 && daysSupply <= 100;

  let suggestedFollowUpDate: moment.Moment | null = null;
  if (isShortSupply) {
    suggestedFollowUpDate = nextExpectedFillDate.subtract(7, 'days');
  } else if (isLongSupply) {
    suggestedFollowUpDate = nextExpectedFillDate.subtract(21, 'days');
  }

  // rule 3: if days supply is not in range, no need to suggest follow up date
  if (!suggestedFollowUpDate) {
    return '';
  }

  // setting up conditions for each suggested date month
  const month = suggestedFollowUpDate.month();
  const monthConditions = [
    { months: [0, 1, 2], shortGap: 50, longGap: 40 },
    { months: [3, 4, 5], shortGap: 40, longGap: 30 },
    { months: [6, 7, 8], shortGap: 30, longGap: 20 },
    { months: [9, 10, 11], shortGap: 15, longGap: 15 },
  ];

  // calculating whether or not a follow-up is necessary based on follow-up date
  for (const condition of monthConditions) {
    if (condition.months.includes(month)) {
      if (
        (isShortSupply && gapDays <= condition.shortGap) ||
        (isLongSupply && gapDays <= condition.longGap)
      ) {
        return suggestedFollowUpDate.format('MM/DD/YYYY');
      }
    }
  }

  return '';
}
