export const formatPhone = (phone?: string): string | null => {
  if (phone) {
    const normalizedPhone = phone?.replace(/\D/g, '');
    return normalizedPhone?.length === 10
      ? `(${normalizedPhone.substring(0, 3)}) ${normalizedPhone.substring(
          3,
          6,
        )}-${normalizedPhone.substring(6, 10)}`
      : null;
  } else return null;
};
