import { instance } from './axios';

export const get = url => {
  return new Promise((resolve, reject) => {
    instance
      .get(url)
      .then(result => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const post = (url, data) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data)
      .then(result => {
        if (result.status >= 200 && result.status < 300) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const postWithHeader = (url, data, header) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data, header)
      .then(result => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const put = (url, data) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, data)
      .then(result => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const putWithHeader = (url, data, header) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, data, header)
      .then(result => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const remove = (url, data) => {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, {
        data,
      })
      .then(result => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const patch = (url, data) => {
  return new Promise((resolve, reject) => {
    instance
      .patch(url, data)
      .then(result => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
