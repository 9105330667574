import { Text } from 'rx-shared-lib';

export const PRIVACY_POLICY_CONTENT = 'PRIVACY_POLICY_CONTENT';

export default function PrivacyPolicy() {
  return (
    <div data-testid={PRIVACY_POLICY_CONTENT} style={{ height: '100%', letterSpacing: '-0.02em' }}>
      <Text variant="CAPTION" shade="semibold">
        PRIVACY POLICY
      </Text>
      <br />

      <Text variant="CAPTION" shade="regular">
        Last updated: October 5, 2020
      </Text>
      <br />
      <br />

      <Text variant="CAPTION" shade="regular">
        MediBookr, Inc.'s ("MediBookr," "we," "us," or "our") provides this Privacy Policy (this
        "policy") to inform users of our policies and procedures regarding the collection, use and
        disclosure of personally identifiable information received or collected from users of the
        telemedicine service, powered by our platform (the "Platform") and any other website or
        online service that we operate (collectively, the "Services").
      </Text>

      <br />
      <br />

      <Text variant="CAPTION" shade="regular">
        By using the Services you expressly agree to this policy. This policy was last updated on
        the date set forth above and applies from that date. We may occasionally amend this policy
        to reflect regulatory requirements, advances in technology, Services updates and changes in
        our information collection and disclosure practices. Any new policy will automatically be
        effective when it is published through the Services. You should therefore return here
        regularly to view our most up to date policy. You should also print a copy for your records.
        If you have any questions or comments about this policy or our use of your personally
        identifiable information, please contact us at support@medibookr.com.
      </Text>
    </div>
  );
}
