import { Col, Form, Row, Select } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DropDown,
  FormInputItem,
  PopoverModal,
  Text,
  TextVariants,
  variantMap,
} from 'rx-shared-lib';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import styled, { css } from 'styled-components';
import validator from 'validator';
import { createUser, fetchDataSources, fetchRoles } from '../../../actions';
import { FetchGenericEmail } from '../../../API/Monolith/usersAPI';
import { clearRoleNames } from '../../../API/util';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import UserPlusIcon from '../../../assets/user-plus.svg';
import DataSourceFilter from '../../../component/PharmacySearch/PharmacyFilter/DataSourceFilter';
import { setCloseUserModal, setErrorOnCreate } from '../../../slice/UsersSlice';

const UserPlusButton = styled(Button)`
  ${({ theme }) => css`
    width: 101px !important;
    color: ${theme.stellusPalette.ancillary.white} !important;
    background: ${theme.stellusPalette.ocean[90]} !important;
    &:hover {
      color: ${theme.stellusPalette.ancillary.white} !important;
      background: ${theme.stellusPalette.ocean[100]} !important;
    }
    &:focus,
    &:active {
      color: ${theme.stellusPalette.ancillary.white} !important;
      background: ${theme.stellusPalette.ocean[80]} !important;
    }
    margin-right: 24px;
  `}
`;

const AddNewMemberPopover = styled(PopoverModal)``;

const ActionBtnContainer = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    padding-top: 96px;
    align-items: flex-end;
  `}
`;

const ActionBtn = styled(Button)`
  ${() => css`
    width: 234px;
  `}
`;

const StyledDropDown = styled(DropDown)`
  ${({ theme }) => css`
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
    .ant-select-item-option {
      height: 40px;
      text-transform: capitalize;
    }
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }
    .ant-select-arrow {
      right: 7px;
    }
  `}
`;

const StyledText = styled(Text)`
  ${({ theme }) => css`
    margin-right: ${theme.spacingInPx(1)} !important;
  `}
`;

export const StyledFormDropdownItem = styled(Form.Item)`
  ${({ theme }) => css`
    flex-direction: column;
    .ant-form-item-label {
      text-align: left;
    }
    .ant-form-item-label > label::after {
      content: '';
    }
    .ant-form-item-explain-error {
      padding-top: 4px;
      ${variantMap(TypographySubType.regular, 'CAPTION')['CAPTION'].styles}
      color: ${theme.stellusPalette.error[100]} !important;
    }
    .ant-form-item-label {
      ${variantMap(TypographySubType.semibold, TextVariants.CAPTION)[TextVariants.CAPTION].styles}
      padding: 0px;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      margin-right: ${theme.spacingInPx(0.5)};
      font-family: nunito-regular !important;
      color: ${theme.stellusPalette.clay[70]};
    }
  `}
`;

export const getRoleDropdown = (roles: any, onChange: (data: any) => void, disabled: boolean) => {
  const { Option } = Select;
  return (
    <StyledDropDown
      {...(disabled ? { disabled: true } : {})}
      listHeight={200}
      getPopupContainer={triggerNode => triggerNode}
      onChange={(data: any) => {
        onChange(data);
      }}
    >
      {roles.map((role: any) => {
        const { id, name } = role;
        return (
          <Option key={id} value={id}>
            <StyledText variant={TextVariants.P2} shade="regular">
              {clearRoleNames(name)}
            </StyledText>
          </Option>
        );
      })}
    </StyledDropDown>
  );
};

interface DatasourceDropdownProps {
  dataSources: any[];
  onChange: any;
  selectedDataSources: any[];
  onRemove: any;
  disabled?: boolean;
}

export const DatasourceDropdown: React.FC<DatasourceDropdownProps> = ({
  dataSources,
  onChange,
  selectedDataSources,
  onRemove,
  disabled,
}) => {
  return (
    <>
      <DataSourceFilter
        onChangeFunc={onChange}
        dataSources={dataSources}
        selectedDataSources={selectedDataSources}
        onRemove={onRemove}
        disabled={disabled}
      />
    </>
  );
};

interface MemberFieldsProps {
  emailError: any;
  setEmailError: any;
  dataSources: any[];
  onChange: any;
  selectedDataSources: any[];
  errorOnCreate: any;
  roles: any[];
  onRemove: any;
  editView?: boolean;
  onRoleChange: (data: any) => void;
  roleName: string;
}

export const MemberFields: React.FC<MemberFieldsProps> = ({
  emailError,
  setEmailError,
  dataSources,
  onChange,
  selectedDataSources,
  errorOnCreate,
  roles,
  onRemove,
  editView,
  onRoleChange,
  roleName,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const disableDatasource = ['admin'].includes(roleName);
  let filteredRoles = roles;
  if (editView && !['unassigned'].includes(roleName)) {
    filteredRoles = roles.filter(r => {
      return !r.name.toLowerCase().includes('unassigned');
    });
  }
  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <FormInputItem
            label={
              <Text variant="P2" shade="semibold">
                {t('common:firstName')}
              </Text>
            }
            hasFeedback
            name="firstName"
            placeholder="First Name"
            rules={[
              {
                required: true,
                message: t('common:firstName-field-empty-string'),
              },
              () => ({
                validator(_, value) {
                  if (value === undefined || value === null || value === '') {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          />
        </Col>
        <Col span={12}>
          <FormInputItem
            label={
              <Text variant="P2" shade="semibold">
                {t('common:lastName')}
              </Text>
            }
            hasFeedback
            name="lastName"
            placeholder="Last Name"
            rules={[
              {
                required: true,
                message: t('common:lastName-field-empty-string'),
              },
              () => ({
                validator(_, value) {
                  if (value === undefined || value === null || value === '') {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          />
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <FormInputItem
            disabled={editView || ['unassigned'].includes(roleName)}
            label={
              <Text variant="P2" shade="semibold">
                {t('common:email')}
              </Text>
            }
            hasFeedback
            onInputChange={(e: any) => {
              if (errorOnCreate?.code === 'email') {
                dispatch(setErrorOnCreate(undefined));
              }
            }}
            name="email"
            placeholder="Email"
            validateStatus={emailError}
            rules={[
              {
                required: true,
                message: t('common:email-field-empty-string'),
              },
              () => ({
                validator(_, value) {
                  if (value === undefined || value === null || value === '') {
                    setEmailError('error');
                    return Promise.reject();
                  } else if (!validator.isEmail(value)) {
                    setEmailError('error');
                    return Promise.reject(t('common:provide-email-string'));
                  } else if (errorOnCreate?.code === 'email') {
                    setEmailError('error');
                    return Promise.reject(t('common:email-already-exist'));
                  }
                  setEmailError('');
                  return Promise.resolve();
                },
              }),
            ]}
          />
        </Col>
        <Col span={12}>
          <StyledFormDropdownItem
            label={
              <Text variant="P2" shade="semibold">
                {t('common:role')}
              </Text>
            }
            name="role"
            rules={[
              {
                required: true,
                message: t('common:role-field-empty-string'),
              },
              () => ({
                validator(_, value) {
                  if (value === undefined || value === null || value === '') {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            {getRoleDropdown(
              filteredRoles,
              onRoleChange,
              editView && ['unassigned'].includes(roleName) ? true : false,
            )}
          </StyledFormDropdownItem>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <StyledFormDropdownItem
            className="add-user-datasource"
            label={
              <Text variant="P2" shade="semibold">
                {t('common:datasources')}
              </Text>
            }
            name="dataSources"
            rules={[
              () => ({
                validator(_, value) {
                  if (!value || value.length === 0) {
                    if (!disableDatasource) {
                      return Promise.reject(t('common:datasource-field-empty-string'));
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            {
              <DatasourceDropdown
                selectedDataSources={selectedDataSources}
                onChange={onChange}
                dataSources={dataSources}
                onRemove={onRemove}
                disabled={disableDatasource}
              />
            }
          </StyledFormDropdownItem>
        </Col>
      </Row>
    </>
  );
};

const rowGutter = 8;
const AddNewMember: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedDataSources, setSelectedDataSources] = useState<any>([]);
  const [emailError, setEmailError] = useState<ValidateStatus>('');
  const [form] = Form.useForm();
  const [roleName, setRoleName] = useState<string>('');

  const onChange = (newValue: any) => {
    const updatedValues = [...selectedDataSources];
    const index = updatedValues.indexOf(`${newValue}`);
    let newValues;
    if (index > -1) {
      updatedValues.splice(index, 1);
      newValues = updatedValues;
    } else {
      newValues = [...selectedDataSources, newValue];
    }
    setSelectedDataSources(newValues);
    form.setFieldsValue({ dataSources: newValues });
  };

  const onRemove = (id: any) => {
    const updatedValues = [...selectedDataSources];
    const index = updatedValues.indexOf(`${id}`);
    updatedValues.splice(index, 1);
    setSelectedDataSources(updatedValues);
    form.setFieldsValue({ dataSources: updatedValues });
    form.validateFields(['dataSources']);
  };

  const { roles, dataSources, errorOnCreate, closeUserModal } = useAppSelector(
    (state: RootState) => {
      return state.users;
    },
  );
  const {
    userDetails: { applications },
  } = useAppSelector((state: RootState) => {
    return state.auth;
  });

  useEffect(() => {
    if (errorOnCreate) {
      if (errorOnCreate.code === 'email') {
        form.validateFields(['email']);
      }
    }
  }, [errorOnCreate, form]);

  useEffect(() => {
    if (closeUserModal) {
      onCancel();
      dispatch(setCloseUserModal(undefined));
    }
  }, [closeUserModal]);

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchDataSources('intern'));
  }, []);

  const onSubmit = (values: any) => {
    if (emailError === 'error') {
      return;
    }
    console.log('onSubmit=>values', values);
    const { firstName, lastName, role, dataSources, email } = values;
    const foundRole = roles
      .filter((r: any) => {
        return r.id === role;
      })
      .map((r: any) => {
        return {
          id: r.guid,
          name: r.name,
        };
      });

    const dataSourcesNormalized = dataSources.map((dsId: string) => parseInt(dsId));
    const foundApp = applications.find((a: any) => {
      return a.name === 'Rx Adhere';
    });
    dispatch(
      createUser({
        firstName,
        lastName,
        email,
        role: foundRole[0],
        datasource: dataSourcesNormalized,
        applications: [foundApp?.guid],
      }),
    );
  };

  const onCancel = () => {
    setModalVisible(false);
  };

  const onOpenAddUserModal = () => {
    setEmailError('');
    setSelectedDataSources([]);
    setModalVisible(true);
    dispatch(setErrorOnCreate(undefined));
    setRoleName('');
    form.resetFields();
  };

  useEffect(() => {
    if (dataSources.length === 0) {
      form.setFieldsValue({ dataSources: [] });
      setSelectedDataSources([]);
    }
  }, [dataSources]);

  const onRoleChange = async (roleId: any) => {
    const foundRole = roles.find((r: any) => {
      return r.id === roleId;
    });
    if (roleName === 'unassigned') {
      form.setFieldsValue({ email: '' });
    }
    setRoleName(foundRole.name.toLowerCase());
    if (foundRole.name.toLowerCase() === 'unassigned') {
      const response = await FetchGenericEmail();
      console.log('unassigned', response);
      form.setFieldsValue({ email: response });
    }
    if (foundRole.name.toLowerCase() === 'admin') {
      form.setFieldsValue({ dataSources: [] });
      setSelectedDataSources([]);
    }
    await dispatch(fetchDataSources(foundRole?.name?.toLowerCase()));
    form.validateFields();
  };

  return (
    <>
      <UserPlusButton
        onClick={onOpenAddUserModal}
        block
        type="primary"
        size="small"
        icon={<img src={UserPlusIcon} alt="User" />}
      >
        {t('dashboard:add-user')}
      </UserPlusButton>
      <AddNewMemberPopover
        visible={isModalVisible}
        popoverHeader={t('dashboard:add-new-member')}
        width={572}
        height={'fit-content'}
        maskClosable={false}
        closable={true}
        onClose={onCancel}
        content={
          <Form
            layout="horizontal"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
          >
            <div style={{ paddingTop: 24 }}>
              <MemberFields
                onRoleChange={onRoleChange}
                dataSources={dataSources}
                emailError={emailError}
                onChange={onChange}
                setEmailError={setEmailError}
                selectedDataSources={selectedDataSources}
                errorOnCreate={errorOnCreate}
                roles={roles}
                onRemove={onRemove}
                roleName={roleName}
              />
            </div>
            <ActionBtnContainer>
              <ActionBtn type="default" size="large" onClick={onCancel}>
                <Text variant="P1" shade="semibold" color="inherit">
                  {t('common:cancel')}
                </Text>
              </ActionBtn>
              <ActionBtn type="primary" size="large" htmlType="submit">
                <Text variant="P1" shade="semibold" color="inherit">
                  {t('common:save')}
                </Text>
              </ActionBtn>
            </ActionBtnContainer>
          </Form>
        }
      />
    </>
  );
};
export default AddNewMember;
