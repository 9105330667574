import { Navigate, Outlet, RouteProps } from 'react-router-dom';
import { isUserAuthenticated } from '../features/authentication/authSlice';
import { useAppSelector } from './hooks';

export type PrivateRouteProps = RouteProps;

export const PrivateRoute = ({ ...props }: PrivateRouteProps) => {
  const isLoggedin = useAppSelector(isUserAuthenticated);

  if (!isLoggedin) {
    return <Navigate to={'/login'} />;
  }
  return <Outlet />;
};
export default PrivateRoute;
