import config from '../config';
import { fetchRefreshToken } from '../features/authentication/authAPI';

const refreshIntervalTime = Number(config.REACT_APP_FIVE9_REFRESH_TOKEN_TIME);

export const callToDialNumber = (phoneNumber: string) => {
  try {
    const crmApi = window.Five9.CrmSdk.crmApi();

    const normalizedPhone = phoneNumber?.replace(/\D/g, '');

    crmApi.click2dial({
      click2DialData: {
        clickToDialNumber: normalizedPhone,
      },
    });
  } catch (error) {
    console.error('Error calling to dial number', error);
  }
};

export const createRefreshFiveNine = () => {
  try {
    // when the user is in a five9 call
    // the token will be refreshed
    const interval = setInterval(fetchRefreshToken, refreshIntervalTime);

    return interval;
  } catch (error) {
    console.error('Error refreshing Five9', error);
  }
};

export const cancelRefreshFiveNine = (interval: NodeJS.Timeout | undefined) => {
  try {
    if (interval) clearInterval(interval);

    interval = undefined;
  } catch (error) {
    console.error('Error canceling refresh Five9', error);
  }
};

export const sendFiveNineNotification = (payload: any) => {
  const { callData = {} } = payload;

  if (!('Notification' in window)) {
    return alert('This browser does not support desktop notification.');
  }

  if (Notification.permission === 'granted') {
    return createNotification(callData?.number);
  }

  if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        return createNotification(callData?.number);
      }
    });
  }
};

const createNotification = (callNumber: string) =>
  new Notification(`Inbound call: ${callNumber}`, {
    body: '',
    silent: false,
    icon: 'stellusLogo.png',
  });
