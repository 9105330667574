import { debounce, isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SearchInput, Text } from 'rx-shared-lib';
import styled, { css } from 'styled-components';
import { RootState } from '../../app/store';

import { searchPaginatedUserList, fetchPaginatedUserList } from '../../actions';

import InfiniteSearchScreen from './InfiniteSearchScreen';
import { Loader } from '../PatientSearch/Loader';
import { NoResults } from '../PatientSearch/NoSearchResult';
import ResultLabelComp from '../PatientSearch/ResultLabelComp';
import { useAppSelector } from '../../app/hooks';
import { ReactComponent as NoSearchResults } from '../../assets/NoSearchResults.svg';
import {
  onUserSelection,
  setSearchUserList,
  onSearchedPage,
  setUserList,
  onSearchUserSelected,
} from '../../slice/UsersSlice';

export const USER_SEARCH_OVERLAY = 'USER_SEARCH_OVERLAY';
export const USER_SEARCH_INPUT = 'USER_SEARCH_INPUT';
export const SEARCH_FORM_INPUT = 'SEARCH_FORM_INPUT';
export const SEARCH_INPUT_TEST_ID = 'SEARCH_INPUT_TEST_ID';
export const USER_SEARCH_GRID = 'USER_SEARCH_GRID';
export const USER_SEARCH_LIST = 'USER_SEARCH_LIST';

const StyledCollapse = styled('div')`
  ${({ theme }) => css`
    position: absolute;
    z-index: 102;
    background: white;
    width: 480px;
    margin-top: ${theme.spacingInPx(1)};
    border-radius: 4px;
  `}
`;
const StyledSearchInput = styled('div')`
  ${({ theme }) => css`
    width: 480px;
    z-index: 102;
    & .ant-form-item {
      margin-bottom: 0px;
    }
    & .ant-input:placeholder-shown {
      margin-top: ${theme.spacingInPx(1)};
    }
  `}
`;

const SearchContainer = styled('div')`
  ${({ theme }) => css`
    margin-right: ${theme.spacingInPx(1)};
    max-height: 543px;
    overflow: auto;
    ::-webkit-scrollbar {
      background-color: transparent;
    }
    ::-webkit-scrollbar-track {
      background-color: ${theme.palette.ancillary.white} !important;
    }
  `}
`;
const StyledOverlay = styled('div')`
  ${({ theme }) => css`
    width: 100%;
    height: 200%;
    background-color: black;
    position: absolute;
    left: 0;
    opacity: 0.5;
    z-index: 96;
  `}
`;
const UserSearchModal = () => {
  const [searchDebounceText, setSearchDebounceText] = useState('');
  const [searchModalOpen, onSearchModalOpen] = useState(false);
  const [inputTxt, onInputTxt] = useState('');

  const { searchUsers, searchedResultTotal, searchLoader, searchPageNumber, limit } =
    useAppSelector((state: RootState) => {
      return state.users;
    });

  const { searchPatientClick } = useAppSelector((state: RootState) => {
    return state.dashboard;
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(searchDebounceText)) {
      dispatch(onUserSelection(null));
      dispatch(setSearchUserList({searchPageNumber : 1, data:[], total: 0 }));
    }
  }, [searchDebounceText]);

  useEffect(() => {
    if (searchDebounceText?.length > 0) {
      dispatch(onSearchedPage(1));
      dispatch(searchPaginatedUserList(searchDebounceText, 1));
    }
  }, [searchDebounceText]);

  const onUserClick = async (user: any) => {
    handleDebounceFn(inputTxt);
    dispatch(onSearchUserSelected(true));
    dispatch(onUserSelection(user));
    dispatch(setUserList({ data: [user], total: 1 }));
    onSearchModalOpen(false);
  };

  const loadNextData = () => {
    dispatch(searchPaginatedUserList(searchDebounceText, (searchPageNumber ?? 1) + 1));
    dispatch(onSearchedPage((searchPageNumber ?? 1) + 1));
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isEmpty(e.target.value) && searchPatientClick) {
      onClearText();
    }
    onSearchModalOpen(true);
    onInputTxt(e.target.value);
    handleDebounceFn(e.target.value);
  };

  const handleDebounceFn = useCallback(
    debounce(value => {
      setSearchDebounceText(value);
    }, 1000),
    [],
  );

  const onClearText = () => {
    onSearchedPage(1);
    onInputTxt('');
    onSearchModalOpen(false);
    dispatch(onSearchUserSelected(false));
    dispatch(setSearchUserList({searchPageNumber : 1, data:[], total: 0 }));
    dispatch(onUserSelection(null));
    dispatch(onUserSelection(null));
    dispatch(fetchPaginatedUserList(0, limit ?? 10));
    setSearchDebounceText('');
  };

  const onClickInput = () => {
    onSearchModalOpen(true);
    dispatch(onUserSelection(null));
  };

  const infiniteScreenRenderer = () => {
    if (searchUsers?.length) {
      return (
        <InfiniteSearchScreen
          loadNextData={loadNextData}
          searchedData={searchUsers}
          searchedResultTotal={searchedResultTotal ?? 0}
          onUserClick={onUserClick}
          searchText={inputTxt}
        />
      );
    }
    return searchLoader ? (
      <Loader />
    ) : (
      <NoResults>
        <>
          <NoSearchResults />
          <Text variant="P2" shade="regular">
            {t('common:no-results-found')}
          </Text>
        </>
      </NoResults>
    );
  };

  return (
    <>
      {searchModalOpen && (
        <StyledOverlay data-testid={USER_SEARCH_OVERLAY} onClick={onClearText}></StyledOverlay>
      )}
      <StyledSearchInput data-testid={USER_SEARCH_INPUT}>
        <SearchInput
          value={inputTxt}
          onInputChange={onInputChange}
          onClick={onClickInput}
          allowClear
          placeholder={`${t('dashboard:searchPlaceholderLabel')}`}
          FormItemTestID={SEARCH_FORM_INPUT}
          InputTestID={SEARCH_INPUT_TEST_ID}
          centerPlaceholder={true}
        ></SearchInput>
        {searchModalOpen && !isEmpty(searchDebounceText) && (
          <StyledCollapse>
            <ResultLabelComp>
              {!isEmpty(searchDebounceText)
                ? ` ${t('dashboard:Users')} (${searchedResultTotal ?? 0})`
                : ``}
            </ResultLabelComp>
            <SearchContainer id="scrollableDiv">{infiniteScreenRenderer()}</SearchContainer>
          </StyledCollapse>
        )}
      </StyledSearchInput>
    </>
  );
};
export default UserSearchModal;
