import { Col, Popover, Row } from 'antd';
import { useSelector } from 'react-redux';
import { Text } from 'rx-shared-lib';
import styled, { css, useTheme } from 'styled-components';
import { RootState } from '../../app/store';
import { formatPhone } from '../../API/util';
import { useState } from 'react';
import moment from 'moment';

const StyledPopoverContent = styled('div')`
  ${({ theme }) => css`
    padding: 0px ${theme.spacingInPx(1.5)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  `}
`;
const StyledMedicationName = styled(Text)<{ popOverClicked: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: ${({ popOverClicked }) => (popOverClicked ? '#F5F5F5' : 'unset')};
`;

const StyledTextInfo = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.palette.graphite['100']};
    line-height: 22px;
  `}
`;

const StyledTextError = styled(Text)`
  color: #9f5710 !important;
  line-height: 22px !important;
`;

const StyledNameContainer = styled('div')<{ showPopover: boolean }>`
  ${({ theme, showPopover }) => css`
    padding-left: ${theme.spacingInPx(4)};
    max-width: 100%;
    border-radius: 4px;
    cursor: ${showPopover ? 'pointer' : 'unset'};
    &:hover {
      background: ${showPopover ? theme.palette.graphite['20'] : 'unset'};
    }
  `}
`;

const StyledColumn = styled(Col)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding-right: ${theme.spacingInPx(1.5)};
  `}
`;

interface MedicationPopoverProps {
  measureId?: string | number;
  medicationInfo?: any;
}

const MedicationPopover = ({ measureId, medicationInfo }: MedicationPopoverProps) => {
  const theme: any = useTheme();

  const [showPopover, setShowPopover] = useState<boolean>(true);

  const [popOverClicked, setPopOverClicked] = useState<boolean>(false);

  const allMeasureData = useSelector((state: RootState) => state.patient.allMeasureData);

  const measureData = measureId ? allMeasureData[measureId] : {};

  const { pharmacies } = measureData ?? {};

  const pharmacy =
    pharmacies?.find((pharmacy: any) => pharmacy?.id === medicationInfo?.pharmacyId) ?? {};

  const claimData = medicationInfo?.medicationData?.CLAIM ?? {};

  const lastFillDate = claimData?.LAST_FILL_DATE ?? claimData?.MOST_RECENT_FILL_DATE;

  const AddressText = () => {
    const pharmacyData = pharmacy?.pharmacyData ?? {};

    const displayText = [
      pharmacyData?.address,
      pharmacyData?.city,
      pharmacyData?.state,
      pharmacyData?.zip,
    ]
      ?.filter(value => !!value && value?.length > 0)
      .join(', ');

    return (
      <>
        {pharmacyData?.address ? (
          <StyledTextInfo variant="P3">{displayText}</StyledTextInfo>
        ) : (
          <StyledTextError variant="P3">Address no added</StyledTextError>
        )}
      </>
    );
  };

  const HoveredContent = () => (
    <StyledPopoverContent>
      <Text shade="regular" variant="CAPTION" color={theme.palette.graphite['100']}>
        {medicationInfo?.medicationNameWithDosage}
      </Text>
    </StyledPopoverContent>
  );

  const ClickedContent = () => (
    <StyledPopoverContent>
      <Row>
        <StyledColumn span={9}>
          <div>
            <StyledTextInfo variant="P3" color="#757575">
              Day supply
            </StyledTextInfo>
            <span style={{ display: 'block' }}>{claimData?.DAYS_SUPPLY ?? '000'}</span>
          </div>
          <div>
            <StyledTextInfo variant="P3" color="#757575">
              Last filled
            </StyledTextInfo>
            <span style={{ display: 'block' }}>
              {lastFillDate
                ? moment.utc(lastFillDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
                : '00/00/0000'}
            </span>
          </div>
          <div>
            <StyledTextInfo variant="P3" color="#757575">
              Next expected fill
            </StyledTextInfo>
            <span style={{ display: 'block' }}>
              {claimData?.NEXT_EXPECTED_FILL_DATE
                ? moment.utc(claimData?.NEXT_EXPECTED_FILL_DATE, 'YYYY-MM-DD').format('MM/DD/YYYY')
                : '00/00/0000'}
            </span>
          </div>
        </StyledColumn>
        <StyledColumn
          span={15}
          style={{ background: '#F5F5F5', padding: '0.5rem', borderRadius: '0.5rem' }}
        >
          {pharmacy?.pharmacyName ? (
            <StyledTextInfo variant="P3">{pharmacy?.pharmacyName}</StyledTextInfo>
          ) : (
            <StyledTextError variant="P3">Pharmacy name no added</StyledTextError>
          )}{' '}
          {pharmacy?.pharmacyData?.phone ? (
            <StyledTextInfo variant="P3">
              {formatPhone(pharmacy?.pharmacyData?.phone)}
            </StyledTextInfo>
          ) : (
            <StyledTextError variant="P3">Phone no added</StyledTextError>
          )}
          <AddressText />
        </StyledColumn>
      </Row>
    </StyledPopoverContent>
  );

  return (
    <Popover
      getPopupContainer={triggerNode => triggerNode}
      placement="bottomRight"
      trigger="hover"
      overlayInnerStyle={{
        right: -23,
        top: -10,
        position: 'relative',
        borderRadius: 8,
        height: 30,
        display: showPopover ? 'flex' : 'none',
        alignItems: 'center',
        width: 'max-content',
      }}
      overlayStyle={{ borderRadius: 8 }}
      content={HoveredContent}
    >
      <Popover
        content={ClickedContent}
        trigger="click"
        placement="bottomRight"
        overlayInnerStyle={{
          maxWidth: 451,
        }}
        onVisibleChange={setPopOverClicked}
      >
        <StyledNameContainer
          showPopover={showPopover}
          onMouseEnter={(e: any) => {
            const scrollWidth = e.currentTarget.firstChild.scrollWidth;
            const offsetWidth = e.currentTarget.firstChild.offsetWidth;
            setShowPopover(offsetWidth < scrollWidth);
          }}
          style={{ cursor: 'pointer' }}
        >
          <StyledMedicationName
            shade="bold"
            variant="P1"
            color={theme.stellusPalette.indigo[80]}
            popOverClicked={popOverClicked}
          >
            {medicationInfo?.medicationName}
          </StyledMedicationName>
        </StyledNameContainer>
      </Popover>
    </Popover>
  );
};

export default MedicationPopover;
