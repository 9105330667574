import { Layout, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NavBar, Text, theme, variantMap } from 'rx-shared-lib';
import styled, { css } from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import UserProfileAvatar from '../GenericDashBoard/UserProfileAvatar';
import StellusLogo from '../../assets/stellus-logo.svg';
import LeftIcon from '../../assets/cheveron-left.svg';
import Taskbar from '../../assets/adhere-taskbar.svg';
import UserMgmntBtn from '../../component/UserManagmentBtn';
import { useTranslation } from 'react-i18next';
import { isAdminUser, isUserMgmntEnabled } from '../../API/util';
import AddNewMember from './addNewMember';
import { UserList } from './userList';
import { resetDashboard } from '../dashboard/dashboardSlice';
import notificationBanner from '../../utility/notification';
import { setErrorOnCreate } from '../../slice/UsersSlice';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import MemberDrawer from './viewMember';
import UserSearchModal from '../../component/UserSearch/UserSearchModal';

const StyledRxAdhereText = styled(Text)`
  ${({ theme }) => css`
    display: flex;
    white-space: nowrap;
    align-items: center;
    text-align: center;
  `}
`;

const StyledLogoContainer = styled(Link)`
  ${({ theme }) => css`
    width: 157px;
    display: flex;
    align-items: center;
    padding: 0px ${theme.spacingInPx(2)};
    border-radius: ${theme.spacingInPx(1)};
    &:hover {
      cursor: pointer;
      background: ${theme.stellusPalette.ocean['90']};
    }
  `}
`;

const UserBtnContainer = styled.span`
  display: flex;
`;

const { Content } = Layout;

export const DASHBOARD = 'DASHBOARD';

export const Dashboard = () => {
  const navigate = useNavigate();
  const [logoImage, setLogoImage] = useState(StellusLogo);
  const { t } = useTranslation();
  const userDetails = useAppSelector((state: RootState) => state.auth.userDetails);
  const { errorOnCreate } = useAppSelector((state: RootState) => {
    return state.users;
  });
  const dispatch = useAppDispatch();
  useEffect(() => {
    const userMgmntEnabled: boolean = isUserMgmntEnabled(userDetails);
    if (!userMgmntEnabled) {
      navigate('/');
    }
  }, []);

  // useEffect(() => {
  //   dispatch(fetchUserList({ limit: 10, offset: 1 }));
  // }, []);

  const Heading = styled(Typography.Text)`
    ${({ theme }) => css`
      ${variantMap(TypographySubType.bold, 'P2')['P2'].styles}
    `}
  `;

  const Description = styled(Typography.Text)`
    ${({ theme }) => css`
      ${variantMap(TypographySubType.regular, 'P3')['P3'].styles}
    `}
  `;

  useEffect(() => {
    if (errorOnCreate) {
      let header;
      let message;
      let error = 'error';
      if (errorOnCreate.code === 'useractivationfailed') {
        header = 'User Deactivation Failed!';
        message =
          'This team member has patients assigned to them. Please reassign the patients first, then return to update the status of this team member.';
      } else if (errorOnCreate.code === 'patientExistInDatasource') {
        header = 'User Update Failed!';
        message =
          'This team member is assigned patients from various datasources. Please reassign the patients first, then return to remove the data source from this team member’s list.';
      } else if (errorOnCreate.code === 'userUpdateFailed') {
        header = 'User Update Failed!';
        message = 'Please contact Administrator.';
      } else if (errorOnCreate.code === 'userCreateSuccess') {
        header = 'User Add Success!';
        message = 'User has been successfully added.';
        error = 'success';
      } else if (errorOnCreate.code === 'userUpdateSuccess') {
        header = 'User Update Success!';
        message = 'User has been successfully updated.';
        error = 'success';
      } else if (errorOnCreate.code === 'userCreateFailed') {
        header = 'User Add Failed!';
        message = 'Please contact Administrator.';
      }
      if (header && message) {
        notificationBanner({
          type: error === 'error' ? 'error' : 'success',
          message: <Heading>{header}</Heading>,
          description: <Description>{message}</Description>,
        });
        dispatch(setErrorOnCreate(undefined));
      }
    }
  }, [errorOnCreate]);

  return (
    <>
      <Layout data-testid={DASHBOARD} style={{ padding: 0 }}>
        <NavBar
          containerStyle={{ backgroundImage: `url(${Taskbar})`, height: 80 }}
          leftSlot={
            <StyledLogoContainer
              onMouseOver={() => setLogoImage(LeftIcon)}
              onMouseOut={() => setLogoImage(StellusLogo)}
              to={'/'}
            >
              <img
                src={logoImage}
                alt="Stellus Logo"
                style={{
                  width: 24,
                  height: 24,
                  marginRight: 16,
                }}
              />
              <StyledRxAdhereText
                variant="SUBHEADING"
                shade="semibold"
                color={theme.stellusPalette.ancillary.white}
              >
                {'Adhere'}
              </StyledRxAdhereText>
            </StyledLogoContainer>
          }
          centerSlot={<UserSearchModal />}
          rightSlot={
            <UserProfileAvatar
              userBtn={
                <UserBtnContainer>
                  <AddNewMember />
                  <UserMgmntBtn
                    screen="USERS"
                    onClick={() => {
                      dispatch(resetDashboard());
                      navigate('/medi');
                    }}
                  />
                </UserBtnContainer>
              }
              fullName={userDetails?.name || 'Abe Arthur'}
              avatarColor={userDetails?.avatarColor || '#531AF5'}
            />
          }
        />
        <Content style={{ backgroundColor: '#FFFFFF' }}>
          <UserList />
        </Content>
        <MemberDrawer />
      </Layout>
    </>
  );
};

export default Dashboard;
