import { notification } from 'antd';
import { ReactNode } from 'react';

import CheckedCircle from './../assets/check-circle.svg';
import AlertIcon from './../assets/alert-triangle.svg';
import NotificationCloseIcon from './../assets/notification-close.svg';

type notificationType = 'info' | 'success' | 'warning' | 'error';

export interface NotificationProps {
  type: notificationType;
  message: any;
  description: any;
  placement?: any;
  icon?: ReactNode;
  props?: any;
}

const notificationBanner = ({
  type,
  message,
  description,
  placement,
  icon,
  ...props
}: NotificationProps) => {
  switch (type) {
    case 'success': {
      return notification.success({
        ...props,
        className: 'notification-alert',
        message: message,
        description: description,
        icon: <img src={CheckedCircle} alt="Success" />,
        closeIcon: <img src={NotificationCloseIcon} alt="Error" />,
      });
    }
    case 'error': {
      return notification.error({
        ...props,
        className: 'notification-alert',
        message: message,
        description: description,
        icon: <img src={AlertIcon} alt="Error" />,
        closeIcon: <img src={NotificationCloseIcon} alt="Error" />,
      });
    }
  }
};

export default notificationBanner;
