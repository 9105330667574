import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { SearchInput, variantMap, Text } from 'rx-shared-lib';
import { t } from 'i18next';
import _, { debounce, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPharmacyData,
  setSearchedPharmacyResultTotal,
  loadPharmacyData,
  onPharmacyInputTxt,
  setSearchPharmacyName,
} from '../../../features/dashboard/dashboardSlice';
import { RootState } from '../../../app/store';
import ResultLabelComp from '../../PatientSearch/ResultLabelComp';
import { Loader } from '../../PatientSearch/Loader';
import { getSearchedPharmacyData, setSearchedPharmacyData } from '../../../API/util';
import { PharmacySearchList } from './PharmacySearchList';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import { NoResults } from '../../PatientSearch/NoSearchResult';
import { ReactComponent as NoSearchResults } from '../../../assets/noSearchResults-small.svg';

const StyledContainer = styled.div`
  ${({ theme }) => css`
    box-shadow: 0px ${theme.spacingInPx(1.5)} ${theme.spacingInPx(3)} rgba(0, 0, 0, 0.11);
    border-radius: ${theme.spacingInPx(1)};
  `}
`;

const StyledSearchInput = styled(SearchInput)`
  ${({ theme }) => css`
    .ant-form-item-control-input-content > input {
      ${variantMap(TypographySubType.regular, 'P2')['P2'].styles}
    }
  `}
`;

interface PharmacyFilterInterface {
  value: any;
  onChangeFunc: any;
}

export const PHARMACY_INPUT = 'PHARMACY_INPUT';

const PharmacyFilter = ({ value, onChangeFunc }: PharmacyFilterInterface) => {
  const [searchDebounceText, setSearchDebounceText] = useState('');
  const [lastSearchedPharmacy, setLastSearchedPharmacy] = useState(getSearchedPharmacyData() ?? []);
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  const [isFirstTimeClicked, setIsFirstTimeClicked] = useState(false);

  const lastSearchedPharmacyData = getSearchedPharmacyData();

  const dispatch = useDispatch();

  const { pharmacyInputTxt, pharmacyData, pharmacyLoader, searchedPharmacyResultTotal } =
    useSelector((state: RootState) => {
      return state.dashboard;
    });

  useEffect(() => {
    if (isEmpty(searchDebounceText) || isEmpty(pharmacyInputTxt)) {
      dispatch(setPharmacyData([]));
      dispatch(setSearchedPharmacyResultTotal(0));
    }
  }, [dispatch, pharmacyInputTxt, searchDebounceText]);

  useEffect(() => {
    if (!_.isEqual(lastSearchedPharmacyData, lastSearchedPharmacy)) {
      setLastSearchedPharmacy(lastSearchedPharmacyData);
    }
  }, [lastSearchedPharmacy, lastSearchedPharmacyData]);

  useEffect(() => {
    if (searchDebounceText.length > 0) {
      dispatch(loadPharmacyData(searchDebounceText));
    }
  }, [dispatch, searchDebounceText]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isEmpty(e.target.value)) {
      onClearText();
    }
    if (!showSearchContainer) {
      setShowSearchContainer(true);
    }
    dispatch(onPharmacyInputTxt(e.target.value));
    handleDebounceFn(e.target.value);
  };

  const handleDebounceFn = useCallback(
    debounce(value => {
      setSearchDebounceText(value);
    }, 1000),
    [],
  );

  const onClearText = () => {
    dispatch(onPharmacyInputTxt(''));
    setSearchDebounceText('');
    onChangeFunc({});
    setPharmacyData([]);
    dispatch(setSearchPharmacyName(''));
    setShowSearchContainer(false);
  };

  const onClickInput = (pharmacyName: any) => {
    dispatch(onPharmacyInputTxt(pharmacyName.name));
    onChangeFunc(pharmacyName);
    dispatch(setSearchPharmacyName(pharmacyName.name));
    setSearchedPharmacyData(pharmacyName);
    setShowSearchContainer(false);
  };

  const data = !isEmpty(searchDebounceText) ? pharmacyData : lastSearchedPharmacy;

  const getResultLabel = () => {
    if (!isEmpty(searchDebounceText)) {
      return `${searchedPharmacyResultTotal} ${t('patientFilters:result')}`;
    } else if (data?.length > 1) {
      return `${t('patientFilters:last')} ${data?.length} ${t('patientFilters:searches')}`;
    }
    return `${t('patientFilters:last')} ${t('patientFilters:search')}`;
  };

  return (
    <>
      <StyledSearchInput
        value={pharmacyInputTxt}
        data-testid={PHARMACY_INPUT}
        onInputChange={onInputChange}
        onBlur={() => {
          setTimeout(() => {
            setShowSearchContainer(false);
          }, 300);
        }}
        onClick={() => {
          if (!isFirstTimeClicked) {
            setIsFirstTimeClicked(true);
          } else {
            setShowSearchContainer(true);
          }
        }}
        allowClear
        placeholder={`${t('patientFilters:all-pharmacies')}`}
      />
      {pharmacyLoader ? (
        <Loader />
      ) : (
        <>
          {showSearchContainer && (
            <StyledContainer>
              {data?.length > 0 ? (
                <>
                  <ResultLabelComp>{getResultLabel()}</ResultLabelComp>
                  <PharmacySearchList
                    onClick={onClickInput}
                    data={data}
                    searchText={pharmacyInputTxt}
                  />
                </>
              ) : (
                <NoResults>
                  <>
                    <NoSearchResults />
                    <Text variant="FOOTER" shade="regular">
                      {t('common:no-results-found')}
                    </Text>
                  </>
                </NoResults>
              )}
            </StyledContainer>
          )}
        </>
      )}
    </>
  );
};

export default PharmacyFilter;
