import { Input, Tabs } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { saveEditedNote, saveNote } from '../../../../actions';
import { NoteLogList } from '../../Types';
import { TextAreaWithButtonComp, Text } from 'rx-shared-lib';
import { isEmpty } from 'lodash';
import moment from 'moment';
import styled, { css } from 'styled-components';
export const NEW_NOTE = 'NEW_NOTE';

interface NewNoteProp {
  handleCancelAddNote: Function;
  editMode: boolean;
  note?: Omit<NoteLogList, 'isEdited'>;
}

const { TabPane } = Tabs;
const { TextArea } = Input;

const StyledExtraHeader = styled('div')`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 22px;
    padding-left: ${theme.spacingInPx(8)};
    padding-right: ${theme.spacingInPx(2)};
  `}
`;
const StyledText = styled(Text)`
  margin-bottom: 0px !important;
`;
export const NewNote = ({ handleCancelAddNote, editMode, note }: NewNoteProp) => {
  const dispatch = useAppDispatch();
  const [noteText, setNoteText] = useState(note?.message ?? '');
  const { selectedPatient } = useSelector((state: RootState) => {
    return state.dashboard;
  });

  const onAddNote = async () => {
    if (!isEmpty(noteText)) {
      handleCancelAddNote();
      await dispatch(saveNote(selectedPatient?.patientId ?? '', noteText));
    }
  };

  const onSaveEditedNote = async () => {
    if (!isEmpty(noteText) && note) {
      handleCancelAddNote();
      await dispatch(
        saveEditedNote(
          selectedPatient?.patientId ?? '',
          note?.metadata?.editedNoteId ?? note?.id,
          noteText,
        ),
      );
    }
  };

  return (
    <form data-testid={NEW_NOTE} id="new-note-form" style={{ width: '100%' }}>
      <TextAreaWithButtonComp
        containerWidth={604}
        editMode={editMode}
        shade="regular"
        variant="P3"
        value={noteText}
        onChange={(e: any) => {
          setNoteText(e.target.value);
        }}
        onCancel={() => handleCancelAddNote()}
        onAdd={onAddNote}
        onSave={onSaveEditedNote}
        extraHeader={
          editMode && (
            <StyledExtraHeader>
              <StyledText shade="bold" variant="P3">
                {note?.user.name}
              </StyledText>
              <StyledText shade="regular" variant="P3">
                {moment(note?.date).format('h:mm A MM/DD/yyyy')}
              </StyledText>
            </StyledExtraHeader>
          )
        }
      />
    </form>
  );
};

export default NewNote;
