import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features/authentication/authSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import patientReducer from '../slice/PatientSlice';
import usersReducer from '../slice/UsersSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    patient: patientReducer,
    users: usersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
