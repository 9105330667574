import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CardRow, CardRowContainer, Text, theme } from 'rx-shared-lib';
import styled, { css } from 'styled-components';
import { RootState } from '../../../app/store';
import { getPatientMeasureInfo, getStringReplaced } from '../dashboardSlice';

export const MEASURE_HISTORY_CONTAINER = 'MEASURE_HISTORY_CONTAINER';
export const HISTORY_CONTENT = 'HISTORY_CONTENT';
interface MeasureHistoryProps {
  measureId: any;
  stepData: any;
}
const StyledCardRowContainer = styled(CardRowContainer)`
  ${({ theme }) => css`
    padding-top: 0px !important;
  `}
`;

const StyledStepName = styled(Text)`
  ${({ theme }) => css`
    display: flex;
    width: 50%;
  `}
`;
const StyledMeasureContent = styled('div')`
  ${({ theme }) => css`
    display: flex;
    // justify-content: space-between;
    margin-top: ${theme.spacingInPx(4)} !important;
    margin-bottom: ${theme.spacingInPx(2)} !important;
    ${StyledDate} {
      min-width: 76px;
      max-width: 76px;
      text-align: left;
      color: ${theme.stellusPalette.grey[70]} !important;
    }
    ${StyledStepName} {
      min-width: 135px;
      max-width: 135px;
      margin-left: 10px;
      text-align: left;
      color: ${theme.stellusPalette.grey[70]} !important;
    }
    ${StyledAnswerText} {
      width: unset;
      text-align: left;
      margin-left: 10px;
      margin-right: unset !important;
    }
  `}
`;

const StyledHeader = styled('div')`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacingInPx(10)} 0px;
    width: 96%;
    height: 64px;
    justify-content: space-between;
    align-items: center;
  `}
`;
const StyledCardRow = styled(CardRow)`
  ${({ theme }) => css`
    .ant-collapse-arrow {
      right: -${theme.spacingInPx(1)} !important;
    }
    .ant-collapse-header {
      padding: 0px !important;
    }

    .ant-collapse-content-box {
      padding: 0px !important;
    }
  `}
`;

const StyledHistoryText = styled(Text)`
  ${({ theme }) => css`
    width: 20%;
  `}
`;

const StyledHistory = styled('div')`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `}
`;
const StyledContent = styled('div')`
  ${({ theme }) => css``}
`;
const StyledDate = styled(Text)`
  text-align: right;
`;
const StyledAnswerText = styled(Text)`
  ${({ theme }) => css`
    margin-right: ${theme.spacingInPx(8)} !important;
    text-align: right;
    width: 100%;
  `}
`;

const MeasureHistory = ({ stepData, measureId }: MeasureHistoryProps) => {
  const { t } = useTranslation();

  const { selectedPatientMedDetails } = useSelector((state: RootState) => {
    return state.patient;
  });
  const dashboardState = useSelector((state: RootState) => {
    return state.dashboard;
  });
  const patientUserInfo = getPatientMeasureInfo(
    dashboardState,
    measureId,
    selectedPatientMedDetails,
  );
  const filteredStepData = stepData.filter(
    (item: any, index: number) => index !== stepData.length - 1,
  );
  const returnHistoryWithDate = (answer: any, answeredOn: any) => {
    return (
      <StyledHistory>
        <StyledAnswerText shade="regular" variant="P3" color={theme.palette.graphite[100]}>
          {answer &&
            answer[0].answers &&
            getStringReplaced(answer[0].answers[0].name, patientUserInfo)}
        </StyledAnswerText>
        <StyledDate shade="regular" variant="P3" color={theme.palette.graphite[100]}>
          {answeredOn && moment.unix(answeredOn).format('MM/DD/YYYY')}
        </StyledDate>
      </StyledHistory>
    );
  };

  return (
    <StyledCardRowContainer data-testid={MEASURE_HISTORY_CONTAINER}>
      <StyledCardRow
        key="2"
        header={
          <StyledHeader>
            <StyledHistoryText variant="P2" shade="bold">
              {t('patientCard:history')} ({filteredStepData?.length})
            </StyledHistoryText>
            <div className="historyHeader">
              {returnHistoryWithDate(
                filteredStepData[filteredStepData.length - 1]?.children,
                filteredStepData[filteredStepData.length - 1]?.answeredOn,
              )}
            </div>
          </StyledHeader>
        }
      >
        <StyledContent
          style={{ paddingBottom: isEmpty(filteredStepData) ? 0 : theme.spacingInPx(2) }}
        >
          {_.map(filteredStepData.reverse(), (item: any, index: number) => {
            const answeredOn = item?.answeredOn;
            const answer = item?.children;
            return (
              <StyledMeasureContent>
                <StyledDate shade="regular" variant="P3">
                  {answeredOn && moment.unix(answeredOn).format('MM/DD/YYYY')}
                </StyledDate>
                <StyledStepName data-testid={HISTORY_CONTENT} shade="bold" variant="P3">
                  {item?.name}
                </StyledStepName>
                <StyledAnswerText shade="regular" variant="P3" color={theme.palette.graphite[90]}>
                  {answer &&
                    answer[0].answers &&
                    getStringReplaced(answer[0].answers[0].name, patientUserInfo)}
                </StyledAnswerText>
              </StyledMeasureContent>
            );
          })}
        </StyledContent>
      </StyledCardRow>
    </StyledCardRowContainer>
  );
};

export default MeasureHistory;
