import { Popover } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { formatPhone } from '../../API/util';
import { callToDialNumber } from '../../actions';
import { useAppDispatch } from '../../app/hooks';
import { setShowFiveNinePopover } from '../../features/dashboard/dashboardSlice';
import { PhoneIcon } from '../icons/PhoneIcon';

const Container = styled.span`
  cursor: pointer;
  margin-left: 5px;
  rotate: 270deg;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
`;

interface ContactPhoneProps {
  contactNumber: string;
  noData: ReactNode;
}

export const ContactPhone = ({ contactNumber, noData }: ContactPhoneProps) => {
  const dispatch = useAppDispatch();

  const fiveNineCall = () => {
    dispatch(setShowFiveNinePopover(true));
    callToDialNumber(contactNumber);
  };

  return formatPhone(contactNumber) ? (
    <>
      {formatPhone(contactNumber)}
      <Popover
        placement="top"
        trigger="hover"
        overlayInnerStyle={{
          borderRadius: 8,
          height: 25,
          display: 'flex',
          alignItems: 'center',
        }}
        content="Call"
      >
        <Container onClick={fiveNineCall}>
          <PhoneIcon size={16} />
        </Container>
      </Popover>
    </>
  ) : (
    <>{noData}</>
  );
};
