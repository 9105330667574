import { List } from 'antd';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { fetchNotesAndLogs } from '../../../../actions';
import { NoteLogList } from '../../Types';
import Log from '../Logs/Log';
import Note from './Note';
import NewNote from './NewNote';
import { setShowAddNoteIcon } from '../../../../slice/PatientSlice';
import { useTranslation } from 'react-i18next';
import { NoResults } from '../../../../component/PatientSearch/NoSearchResult';
import { ReactComponent as NoSearchResults } from '../../../../assets/NoSearchResults.svg';
import { Text } from 'rx-shared-lib';
import styled from 'styled-components';

export const LOGS = 'LOGS';
export const NOTES = 'NOTES';
export const ALL_LOGS = 'ALL_LOGS';
export const NEW_NOTE = 'NEW_NOTE';
interface NoteListProps {
  activeTab: string;
}

const NoteList = ({ activeTab }: NoteListProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [list, setList] = useState<NoteLogList[]>([]);
  const [limit, setLimit] = useState<number>(20);
  const { selectedPatient } = useSelector((state: RootState) => {
    return state.dashboard;
  });

  const { noteLogData, showAddNoteIcon } = useSelector((state: RootState) => state.patient);

  const fetchData = () => {
    dispatch(fetchNotesAndLogs({ activeTab, limit }));
  };
  const handleCancelAddNote = () => {
    dispatch(setShowAddNoteIcon(false));
  };

  const loadNextData = () => {
    setLimit(limit + 10);
  };

  React.useEffect(() => {
    fetchData();
  }, [activeTab, selectedPatient, limit, noteLogData.refreshDataToggle]);

  React.useEffect(() => {
    let newList = [...noteLogData.result];
    if (activeTab === '2' || activeTab === '3') {
      newList = newList.filter(x => x.type === (activeTab === '2' ? 'LOGS' : 'NOTES'));
    }
    setList(newList);
  }, [activeTab, noteLogData]);

  const getTestId = () => {
    if (activeTab === '2') return LOGS;
    if (activeTab === '3') return NOTES;
    return ALL_LOGS;
  };

  const NoSearchResultsIcon = styled(NoSearchResults)`
    height: 55.99967956542969px;
    width: 62.000038146972656px;
  `;

  return (
    <div
      data-testid={getTestId()}
      id="notes-list-scroll"
      style={{
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <>
        {showAddNoteIcon && (
          <NewNote
            data-testid={NEW_NOTE}
            editMode={false}
            handleCancelAddNote={handleCancelAddNote}
          />
        )}
        {list.length > 0 ? (
          <InfiniteScroll
            dataLength={list.length}
            next={loadNextData}
            hasMore={list.length !== noteLogData.total}
            loader={<b></b>}
            endMessage={
              <i style={{ display: 'block', textAlign: 'center' }}>
                {t('patientCard:end_of_result')}
              </i>
            }
            scrollableTarget="notes-list-scroll"
          >
            <List
              id="listed-data"
              style={{ height: '100%' }}
              dataSource={list}
              renderItem={(item: NoteLogList, index: number) => {
                if (item.type === 'LOGS') {
                  return <Log log={item} key={'log_' + item.id} />;
                } else if (item.type === 'NOTES') {
                  return <Note note={item} key={'note_' + item?.id} index={index} />;
                }
              }}
            />
          </InfiniteScroll>
        ) : (
          <NoResults>
            <>
              <NoSearchResultsIcon />
              <Text variant="CAPTION" shade="semibold">
                No Data
              </Text>
            </>
          </NoResults>
        )}
      </>
    </div>
  );
};

export default NoteList;
