import { t } from 'i18next';
import { Button, Drawer, FilterIcon, Text } from 'rx-shared-lib';
import styled, { css, useTheme } from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import {
  clearAllFiltersToDefault,
  setShowFilterDrawer,
  updateFilters,
} from '../../features/dashboard/dashboardSlice';
import PatientFilters from './PatientFilters';

const HeaderFilterContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled('div')`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacingInPx(6)} ${theme.spacingInPx(12)} ${theme.spacingInPx(12)};
  `}
`;
const StyledButton = styled(Button)`
  width: 248px !important;
`;

const StyledFilterIcon = styled(FilterIcon)`
  ${({ theme }) => css`
    margin-left: ${theme.spacingInPx(6)};
    margin-right: ${theme.spacingInPx(2)};
  `}
`;

const FilterDrawer = () => {
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  const { showFilterDrawer, filterValues, defaultSelectedFilters } = useAppSelector(
    (state: RootState) => state.dashboard,
  );

  const onDrawerClose = () => {
    dispatch(setShowFilterDrawer(false));
  };
  const onApplyFilter = () => {
    /* istanbul ignore else  */
    if (filterValues.cutPoint >= 70 && filterValues.cutPoint <= 100) {
      dispatch(updateFilters(filterValues));
    }
  };
  const onClearAll = () => {
    dispatch(clearAllFiltersToDefault(defaultSelectedFilters));
  };
  return (
    <Drawer
      width={616}
      onClose={onDrawerClose}
      placement="left"
      visible={showFilterDrawer}
      bodyStyle={{ padding: `0px ${theme.spacingInPx(12)}` }}
      title={
        <HeaderFilterContainer>
          <StyledFilterIcon />
          <Text variant="SUBHEADING" color={theme.stellusPalette.ocean['100']}>
            {`${t('patientFilters:filter')}`}
          </Text>
        </HeaderFilterContainer>
      }
      footer={
        <ButtonContainer>
          <StyledButton onClick={onClearAll} block type="default" size="large">
            {`${t('patientFilters:clearAll')}`}
          </StyledButton>
          <StyledButton onClick={onApplyFilter} block type="primary" size="large">
            {`${t('patientFilters:apply')}`}
          </StyledButton>
        </ButtonContainer>
      }
      headerStyle={{
        height: '80px',
        background: `${theme.stellusPalette.ocean['10']}`,
        marginBottom: `${theme.spacingInPx(6)}`,
      }}
      footerStyle={{
        height: 120,
        boxShadow: 'inset 0px 1px 0px #E7E7E7',
      }}
    >
      <PatientFilters />
    </Drawer>
  );
};

export default FilterDrawer;
