import InfiniteScroll from 'react-infinite-scroll-component';
import UserSearchList from './UserSearchList';

interface InfiniteSearchScreenInterface {
  searchedResultTotal: number | string;
  searchText: string;
  searchedData: any;
  loadNextData: any;
  onUserClick: any;
}

const InfiniteSearchScreen = ({
  searchedData,
  loadNextData,
  searchedResultTotal,
  onUserClick,
  searchText,
}: InfiniteSearchScreenInterface) => {
  return (
    <>
      <InfiniteScroll
        dataLength={searchedData.length}
        next={loadNextData}
        hasMore={searchedData.length !== searchedResultTotal}
        loader={<b></b>}
        style={{ zIndex: 100 }}
        scrollableTarget="scrollableDiv"
      >
        <UserSearchList onClick={onUserClick} data={searchedData} searchText={searchText} />
      </InfiniteScroll>
    </>
  );
};

export default InfiniteSearchScreen;
