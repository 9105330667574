import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ChangePassword, FormInputItem, Text } from 'rx-shared-lib';
import styled, { css, useTheme } from 'styled-components';
import {
  updateCurrentPasscode,
  validateCurrentPasscode,
} from '../../features/authentication/authAPI';

export const PASSWORD_SECTION_CONTAINER = 'PASSWORD_SECTION_CONTAINER';
export const CHANGE_PASS = 'CHANGE_PASS';
export const CHANGE_PASSWORD_COMPONENT = 'CHANGE_PASSWORD_COMPONENT';
const StyledDetailRow = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: ${theme.spacingInPx(3)} 0px !important;
  `}
`;
const StyledText = styled(Text)`
  cursor: pointer;
`;

export const InvalidPatternMsg = () => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const CText = styled(Text)`
    ${() => css`
      margin-top: 4px;
    `}
  `;
  return (
    <>
      <CText variant="P3">
        {t('common:password-must-have')} <br />
      </CText>
      <Text variant="P3">
        <p style={{ marginLeft: theme.spacingInPx(2) }}>
          {t('common:password-char-length')} <br />
          {t('common:password-later-variation-message')} <br />
          {t('common:password-allowed-numbers')} <br />
          {t('common:password-special-character')}
        </p>
        <br />
      </Text>
    </>
  );
};

export const InvalidPatternError = () => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Text color={theme.stellusPalette.error[100]} variant="CAPTION">
        {t('common:invalid-password-format')} <br />
      </Text>
    </>
  );
};

const PasswordSection = () => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const [form] = Form.useForm();
  const [isButtonEnabled, setButtonEnabled] = useState(0);
  const [currentPassSuccess, setCurrentPassSuccess] = useState('');
  const [isPasswordChangeModalVisible, setPasswordChangeModalVisible] = useState(false);
  React.useEffect(() => {
    form.resetFields();
    setButtonEnabled(0);
    setCurrentPassSuccess('');
  }, [isPasswordChangeModalVisible]);

  const handleResetPassword = () => {
    setPasswordChangeModalVisible(true);
  };

  const handleClose = (e?: any) => {
    setPasswordChangeModalVisible(false);
  };
  const theme: any = useTheme();

  const onSubmit = async (oldPassword: string, newPassword: string) => {
    try {
      setProcessing(true);
      setTimeout(() => {
        notification.info({
          message: `${t('update-password-success')}`,
          description: `${t('common:update-password-message')}`,
          placement: 'topRight',
          icon: <CheckCircleOutlined style={{ color: 'rgb(135,219,137)' }} />,
        });
        setProcessing(false);
      }, 500);
      await updateCurrentPasscode(oldPassword, newPassword);
      handleClose();
    } catch (error) {}
  };
  const validateOldPassword = async (e: any) => {
    try {
      /* istanbul ignore else  */
      if (e !== '') {
        await validateCurrentPasscode(e);
        setCurrentPassSuccess('true');
        setButtonEnabled(1);
      } else {
        setCurrentPassSuccess('false');
        setButtonEnabled(0);
      }
    } catch (error) {
      setCurrentPassSuccess('false');
      setButtonEnabled(0);
    }
  };

  return (
    <>
      <StyledDetailRow data-testid={PASSWORD_SECTION_CONTAINER}>
        <FormInputItem
          defaultValue="••••••••"
          label={
            <Text shade="bold" variant="P3">
              {t('common:password')}
            </Text>
          }
          allowClear={false}
          suffix={
            <span onClick={handleResetPassword} data-testid={CHANGE_PASS}>
              <StyledText shade="regular" color={theme.palette.blueberry['80']} variant="P2">
                {t('common:change')}
              </StyledText>
            </span>
          }
        ></FormInputItem>
      </StyledDetailRow>

      <ChangePassword
        dataTestId={CHANGE_PASSWORD_COMPONENT}
        style={{ top: '20%' }}
        visible={isPasswordChangeModalVisible}
        onClose={handleClose}
        onSubmit={(oldPass: string, newPass: string) => onSubmit(oldPass, newPass)}
        popoverHeader={t('common:change-password-label')}
        changeButtonLabel={t('common:submit-button-label')}
        validateCurrentPassword={validateOldPassword}
        isLoading={processing}
        disableSubmitButton={isButtonEnabled === 0 ? true : false}
        currentPassSuccess={currentPassSuccess}
        currentPasswordMsg={{
          currentPasswordLabel: t('common:current-password-label'),
          currentPasswordPlaceholder: '',
        }}
        newPasswordMsg={{
          newPasswordLabel: t('common:new-password-label'),
          newPasswordPlaceholder: '',
        }}
        confirmPasswordMsg={{
          confirmPasswordLabel: t('common:confirm-password-label'),
          confirmPasswordPlaceholder: '',
        }}
        errorMsg={{
          currentPassword: t('common:current-password-message'),
          needPassword: t('common:please-enter-new-password'),
          notMatch: t('common:password-not-match'),
          samePassword: t('common:same-password-message'),
          invalidPatternMsg: <InvalidPatternMsg />,
          invalidPatternError: <InvalidPatternError />,
        }}
        currentPasswordSuccess={false}
      />
    </>
  );
};
export default PasswordSection;
