import jwt_decode from 'jwt-decode';
import { get, post, put } from '../../API/commonAPI';
import { gotoLogin } from '../../API/util';
import config from '../../config';
export interface UserData {
  userId: string;
  firstName: string;
  lastName: string;
  name: string;
}

interface Application {
  description: string;
  enabled: boolean;
  guid: string;
  icon: string;
  name: string;
}

export interface Role {
  enabled: boolean;
  guid: string;
  name: string;
}

export interface UserDetails {
  applications: Application[];
  email: string;
  enabled: boolean;
  guid: string;
  id: number;
  mobile: string;
  name: string;
  password: string;
  roles: Role;
  avatarColor: string;
}

const REACT_APP_Login_API = 'api/user/login';
const REACT_APP_User_Detail_API = 'api/user';
const REACT_APP_LOGOUT_API = 'api/user/logout';
const REACT_APP_REFRESH_TOKEN_APP = 'api/user/refreshToken';

const loginURL = `${REACT_APP_Login_API}`;
const userDetailURL = `${REACT_APP_User_Detail_API}`;
export const refreshTokenTimerKey = 'refreshTokenTimerID';
export const timeoutIdKey = 'logoutTimeoutID';

export const changePassword = async (key: string, emailId: string, password: string) => {
  try {
    const res = await post(`${config.REACT_SECURITY_BASE_URL}/api/user/resetPassword`, {
      email: emailId,
      key: key,
      newPassword: password,
    });
    return res;
  } catch (error) {
    throw Error('');
  }
};
export const login = async (username: string, password: string) => {
  const res = await post(`${config.REACT_SECURITY_BASE_URL}/${loginURL}`, {
    username,
    password,
  });

  if (res && Object.values(res).length > 0) {
    const decoded: any = jwt_decode(res.access_token);
    res['globalId'] = decoded.id || '9d2cc3ca-4f7f-4931-97b7-47ca581d02323495b';
    localStorage.setItem('login_data', JSON.stringify(res));
    return res;
  } else {
    throw Error('User not found in system');
  }
};

export const fetchUserDetails = async (userId: string) => {
  const url = `${config.REACT_SECURITY_BASE_URL}/${userDetailURL}/${userId}`;
  const response = await get(url);
  if (response) {
    return response;
  } else {
    throw Error('Error fetching user details');
  }
};

export const forgotPassword = async (emailId: string) => {
  const response = await get(
    `${config.REACT_SECURITY_BASE_URL}/api/user/passwordRecovery/${emailId}`,
  );
  if (response) {
    return response;
  } else {
    throw Error('Error fetching user details');
  }
};

export const validateCurrentPasscode = async (currentPass: string) => {
  return get(
    `${
      config.REACT_SECURITY_BASE_URL
    }/api/user/password/validate?currentPassword=${encodeURIComponent(currentPass)}`,
  );
};

export const updateCurrentPasscode = async (oldPassword: string, newPassword: string) => {
  const response = await put(`${config.REACT_SECURITY_BASE_URL}/api/user/password/change`, {
    oldPassword,
    newPassword,
  });
  if (response) {
    return response;
  } else {
    throw Error('Error updating password');
  }
};

export const logout = async () => {
  let response;
  try {
    response = await post(`${config.REACT_SECURITY_BASE_URL}/${REACT_APP_LOGOUT_API}`);
  } catch (error) {
    console.error(error);
  }
  localStorage.clear();
  gotoLogin();
  return response;
};

export const fetchRefreshToken = async () => {
  let ref_tok = JSON.parse(localStorage.getItem('login_data') || '{}');

  const res = await fetch(`${config.REACT_SECURITY_BASE_URL}/${REACT_APP_REFRESH_TOKEN_APP}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${ref_tok?.refresh_token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refreshToken: ref_tok?.refresh_token,
    }),
  });

  const data = await res.json();

  if (data && Object.values(data).length > 0) {
    ref_tok = { ...ref_tok, access_token: data.access_token, refresh_token: data.refresh_token };

    localStorage.setItem('login_data', JSON.stringify(ref_tok));
  }

  return res;
};
