import { Avatar } from 'antd';
import { Text } from 'rx-shared-lib';
import styled, { useTheme } from 'styled-components';
export const BASIC_USER_INFO = 'BASIC_USER_INFO';
export interface ProfileHeaderInterface {
  avatarColor: string;
  userInitials: string;
  name: string;
  title: string;
}
const StyledHeader = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProfileHeader = ({ avatarColor, userInitials, name, title }: ProfileHeaderInterface) => {
  const theme: any = useTheme();
  return (
    <StyledHeader data-testid={BASIC_USER_INFO}>
      <Avatar
        size={96}
        style={{
          backgroundColor: avatarColor,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Text shade="regular" color={theme.palette.ancillary.white} variant="H2">
          {userInitials}
        </Text>
      </Avatar>
      <br />
      <Text variant="SUBHEADING" shade="semibold" color={theme.palette.graphite[100]}>
        {name}
      </Text>
    </StyledHeader>
  );
};
export default ProfileHeader;
