import { Typography } from 'antd';
import Select from 'antd/lib/select';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useRef } from 'react';
import { DropDown, Text, TextVariants } from 'rx-shared-lib';
import styled, { css, useTheme } from 'styled-components';
import { fetchPaginatedUserList, updateUserStatus } from '../../../actions';
import { clearRoleNames } from '../../../API/util';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import InfiniteScrollDataGrid from '../../../component/DataGrid/InfiniteScrollDataGridUsers';
import DataSourcesPopOver from '../../../component/DatasourcesPopover';
import {
  onPageSizeChanged,
  onPaginationChanged,
  onUserSelection,
  setUsersSort,
} from '../../../slice/UsersSlice';
import styles from '../../dashboard/Dashboard.module.css';
import loadingGif from '../../dashboard/loading.gif';
import patientStyles from '../../dashboard/PatientData.module.css';

export const TITLE_DATA_GRID = 'TITLE_DATA_GRID';
export const FILTERED_DATA_GRID = 'FILTERED_DATA_GRID';
export const LOADING = 'LOADING';
export const FILTERED_DATA_GRID_ID = 'FILTERED_DATA_GRID_ID';

const StyledPatientName = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const StyledEmailName = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: none;
`;
export const UserList = (props: any) => {
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  const { filteredPatient, allSearchPatient, searchText, currentSelectedFilter, selectedFilterId } =
    useAppSelector((state: RootState) => {
      return state.dashboard;
    });

  const {
    users: { data: userList, total: totalUsersCount },
    offset,
    limit,
    pageSize,
    editUser,
  } = useAppSelector((state: RootState) => {
    return state.users;
  });

  const click = () => {};

  const handleRowSelectionChange = (selectedRows: any, dispatch: any) => {
    if (selectedRows.length > 0) {
      dispatch(onUserSelection(selectedRows[0]));
    }
  };

  const getHeaderComponent = (label: React.ReactNode) => () =>
    (
      <Text variant={TextVariants.P2} shade="bold">
        {label}
      </Text>
    );

  const getPatientCellRendererComponent = (props: any) => {
    if (props.data) {
      return (
        <span
          className={patientStyles.dataCell}
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
          onClick={click}
        >
          <StyledPatientName variant={TextVariants.P2} shade="bold">
            {props?.data?.firstName} {props?.data?.lastName}
          </StyledPatientName>
        </span>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getEmailCellRendererComponent = (key: string) => (props: any) => {
    if (props?.data) {
      return (
        <div className={patientStyles.dataCell}>
          <StyledEmailName variant={TextVariants.P2} shade="regular">
            {props?.data[key]}
          </StyledEmailName>
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getCellRendererComponent = (key: string) => (props: any) => {
    if (props?.data) {
      return (
        <div className={patientStyles.dataCell}>
          <Text variant={TextVariants.P2} shade="regular">
            {props?.data[key]}
          </Text>
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getRoleCellRendererComponent = (props: any) => {
    if (props?.data) {
      return (
        <div className={patientStyles.dataCell + ' ' + patientStyles.dataCellUserRole}>
          <Text variant={TextVariants.P2} shade="regular">
            {clearRoleNames(props?.data?.userData?.role)}
          </Text>
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const StyledDropDown = styled(DropDown)`
    ${({ theme }) => css`
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        text-transform: capitalize;
      }
      .ant-select-item-option {
        height: 40px;
        text-transform: capitalize;
      }
      .ant-select-item-option-content {
        display: flex;
        align-items: center;
      }
      .ant-select-arrow {
        right: 7px;
      }
    `}
  `;

  const getStatusCellRendererComponent = (props: any) => {
    const { Option } = Select;
    if (props?.data) {
      return (
        <div
          className={patientStyles.dataCell}
          ref={ref => {
            if (!ref) return;
            ref.onclick = e => {
              e.stopPropagation(); // this works
            };
          }}
        >
          <StyledDropDown
            onClick={(e: any) => {
              e.stopPropagation();
            }}
            onChange={(data: any, e: any) => {
              dispatch(
                updateUserStatus({
                  startRow: offset || 0,
                  endRow: limit || 10,
                  userId: props?.data?.id,
                  status: data,
                }),
              );
            }}
            value={props?.data?.enable ? 1 : 0}
          >
            {[
              { id: 1, name: 'Active' },
              { id: 0, name: 'Inactive' },
            ].map((status: any) => {
              const { id, name } = status;
              return (
                <Option key={id} value={id}>
                  <Text
                    variant={TextVariants.P2}
                    shade="bold"
                    color={id === 0 ? theme.stellusPalette.error[100] : '#0E8103'}
                  >
                    {name}
                  </Text>
                </Option>
              );
            })}
          </StyledDropDown>
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getAdminCellRendererComponent = (props: any) => {
    if (props?.data) {
      return (
        <div className={patientStyles.dataCell}>
          <Text variant={TextVariants.P2} shade="regular">
            {['admin', 'pharmacist_manager'].includes(props?.data?.userData?.role?.toLowerCase())
              ? 'Yes'
              : 'No'}
          </Text>
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const getDataSourcesCellRendererComponent = (props: any) => {
    if (props?.data) {
      const length = props?.data?.dataSource?.length || 0;
      return (
        <div className={patientStyles.dataCell} style={{ width: 'auto' }}>
          {length > 0 ? (
            <DataSourcesPopOver
              dataSources={props?.data?.dataSource}
              label={
                <Text variant={TextVariants.P2} shade="regular">
                  {length}
                </Text>
              }
            />
          ) : (
            <Text variant={TextVariants.P2} shade="regular">
              {length}
            </Text>
          )}
        </div>
      );
    } else {
      return <img src={loadingGif} alt="Loading..." />;
    }
  };

  const agGridConfigs: any = [
    {
      key: 'USERNAME',
      minWidth: 300,
      maxWidth: 300,
      lockPinned: true,
      resizable: false,
      headerComponent: getHeaderComponent('Name'),
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eText" class="header-text"></span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
</div>`,
      },
      // it is important to have node.id here, so that when the id changes (which happens
      // when the row is loaded) then the cell is refreshed.
      valueGetter: 'node.data.id',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        'min-width': 0,
      },
      cellRenderer: getPatientCellRendererComponent,
    },
    {
      key: 'EMAIL',
      minWidth: 400,
      maxWidth: 400,
      lockPinned: true,
      resizable: false,
      headerComponent: getHeaderComponent('Email'),
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <span ref="eText" class="header-text"></span>
          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
          <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
          <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
          <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
          <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
      </div>
  </div>`,
      },
      // it is important to have node.id here, so that when the id changes (which happens
      // when the row is loaded) then the cell is refreshed.
      valueGetter: 'node.data.id',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        'min-width': 0,
      },
      cellRenderer: getEmailCellRendererComponent('email'),
    },
    {
      key: 'ROLES',
      minWidth: 242,
      maxWidth: 242,
      lockPinned: true,
      resizable: false,
      headerComponent: getHeaderComponent('Role'),
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <span ref="eText" class="header-text"></span>
          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
          <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
          <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
          <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
          <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
      </div>
  </div>`,
      },
      // it is important to have node.id here, so that when the id changes (which happens
      // when the row is loaded) then the cell is refreshed.
      valueGetter: 'node.data.id',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        'min-width': 0,
      },
      cellRenderer: getRoleCellRendererComponent,
    },
    {
      key: 'STATUS',
      minWidth: 300,
      maxWidth: 300,
      lockPinned: true,
      resizable: false,
      headerComponent: getHeaderComponent('Status'),
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <span ref="eText" class="header-text"></span>
          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
          <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
          <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
          <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
          <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
      </div>
  </div>`,
      },
      // it is important to have node.id here, so that when the id changes (which happens
      // when the row is loaded) then the cell is refreshed.
      valueGetter: 'node.data.id',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        'min-width': 0,
      },
      cellRenderer: getStatusCellRendererComponent,
    },
    {
      key: 'ADMIN',
      minWidth: 180,
      maxWidth: 180,
      lockPinned: true,
      resizable: false,
      headerComponent: getHeaderComponent('Admin'),
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <span ref="eText" class="header-text"></span>
          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
          <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
          <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
          <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
          <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
      </div>
  </div>`,
      },
      // it is important to have node.id here, so that when the id changes (which happens
      // when the row is loaded) then the cell is refreshed.
      valueGetter: 'node.data.id',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        'min-width': 0,
      },
      cellRenderer: getAdminCellRendererComponent,
    },
    {
      key: 'DATASOURCES',
      minWidth: 300,
      maxWidth: 600,
      lockPinned: true,
      resizable: false,
      headerComponent: getHeaderComponent('Data Sources'),
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <span ref="eText" class="header-text"></span>
          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
          <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
          <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
          <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
          <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
      </div>
  </div>`,
      },
      // it is important to have node.id here, so that when the id changes (which happens
      // when the row is loaded) then the cell is refreshed.
      valueGetter: 'node.data.id',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        'min-width': 0,
      },
      cellRenderer: getDataSourcesCellRendererComponent,
    },
  ];
  const gridRef: any = useRef();
  return (
    <>
      <div data-testid={TITLE_DATA_GRID} className={styles.gridRoot + ' ' + styles.gridRootUsers}>
        <InfiniteScrollDataGrid
          gridRef={gridRef}
          onPaginationChanged={onPaginationChanged}
          onPageSizeChanged={onPageSizeChanged}
          fetchPaginatedRecords={fetchPaginatedUserList}
          handleRowSelectionChange={handleRowSelectionChange}
          setSortChanged={setUsersSort}
          columns={agGridConfigs}
          offset={offset}
          limit={limit}
          selectedPatient={editUser}
          pageSize={pageSize}
          selectedFilterId={selectedFilterId}
          patients={userList}
          filterdPatients={filteredPatient?.result}
          searchText={searchText}
          totalFilteredSearchRecords={filteredPatient?.metadata?.total}
          totalPaginatedRecords={totalUsersCount}
          totalAllSearchRecords={allSearchPatient?.metadata?.total}
          currentSelectedFilter={currentSelectedFilter}
          searchPatientClick={false}
          source="USERS"
        />
      </div>
    </>
  );
};
export default React.memo(UserList);
