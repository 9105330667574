import { get, put } from '../commonAPI';
import config from '../../config';

export const searchAssigneesAPI = (payload: any) => {
  const searchUrl = `${
    config.REACT_COHORT_BASE_URL
  }/api/patients/reassignment/users/search?patientIds=${encodeURIComponent(
    JSON.stringify(payload.patientIds),
  )}&teamId=${payload.teamId}`;
  return get(searchUrl);
};

export const reassignPatientsAPI = (payload: any) => {
  const reassignUrl = `${config.REACT_ACTIVITY_BASE_URL}/api/patient-task/user/reassign`;
  return put(reassignUrl, payload);
};
