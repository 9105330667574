import React from 'react';
import ReactDOM from 'react-dom';
import './theme.less';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { stellusAdhereTheme } from 'rx-shared-lib';
import 'rx-shared-lib/styles.less';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={stellusAdhereTheme}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
