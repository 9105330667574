import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Popover } from 'antd';
import { InfoIcon, Text } from 'rx-shared-lib';
import { formatPhone } from '../../API/util';
import { useTranslation } from 'react-i18next';

const StyledPopover = styled(Popover)``;
const StyledInfoIcon = styled(InfoIcon)`
  ${({ theme }) => css`
    margin-left: ${theme.spacingInPx(1.5)};
    cursor: pointer;
  `}
`;
const StyledPopoverContent = styled('div')`
  ${({ theme }) => css`
    overflow: overlay;
    max-height: 260px;
    border-radius: 8px;
  `}
`;
const StyledContentRow = styled('div')`
  display: flex;
  height: 26px;
  padding: 4px 16px;
  align-items: center;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
`;

interface DataSourcesPopOverProps {
  dataSources?: any[];
  label: React.ReactNode;
}

const DataSourcesPopOver = ({ label, dataSources }: DataSourcesPopOverProps) => {
  const theme: any = useTheme();
  return (
    <StyledPopover
      placement="top"
      trigger="hover"
      overlayClassName="datasource-popover"
      overlayInnerStyle={{
        borderRadius: 8,
      }}
      overlayStyle={{ borderRadius: 8 }}
      content={
        <StyledPopoverContent>
          {dataSources?.map((dataSource: any) => {
            const { dataSourceId, dataSourceName } = dataSource;
            return (
              <StyledContentRow key={dataSourceId}>
                <Text shade="regular" variant="CAPTION" color={theme.stellusPalette.grey[90]}>
                  {dataSourceName}:
                </Text>
              </StyledContentRow>
            );
          })}
        </StyledPopoverContent>
      }
    >
      {label}
    </StyledPopover>
  );
};

export default DataSourcesPopOver;
