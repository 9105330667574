import { Space } from 'antd';
import { ReactFragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rx-shared-lib';
import styled, { css, useTheme } from 'styled-components';
import { clearRoleNames } from '../../API/util';
import { Role } from '../../features/authentication/authAPI';

export const DETAILED_USER_INFO = 'DETAILED_USER_INFO';
export interface DetailedUserInfoProps {
  email: string;
  phone: string;
  role: Role;
  groups: string[];
}
const StyledDetailRow = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: ${theme.spacingInPx(3)} 0px !important;
  `}
`;
const StyledDetailContainer = styled('div')`
  ${({ theme }) => css`
    margin-top: ${theme.spacingInPx(7)};
  `}
`;
const StyledText = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacingInPx(1)} !important;
  `}
`;
const RoleName = styled(Text)`
  text-transform: capitalize;
`;
export const DetailedUserInfo = ({ email, phone, role, groups }: DetailedUserInfoProps) => {
  const { t } = useTranslation();

  return (
    <StyledDetailContainer data-testid={DETAILED_USER_INFO}>
      <Space direction="vertical">
        <UserDetailSection title={t('common:email')} content={email} />
        <UserDetailSection title={t('common:phone')} content={phone} />
        <UserDetailSection
          title={t('common:role')}
          content={
            role.name && (
              <RoleName variant="P2" shade="regular">
                {clearRoleNames(role.name)}
              </RoleName>
            )
          }
        />
        <UserDetailSection title={t('common:groups')} />
      </Space>
    </StyledDetailContainer>
  );
};
export const UserDetailSection = ({
  title,
  content,
}: {
  title: string;
  content?: string | ReactFragment;
}) => {
  const theme: any = useTheme();
  return (
    <StyledDetailRow>
      <StyledText variant="P3" shade="bold" color={theme.stellusPalette.grey[70]}>
        {title}
      </StyledText>
      <Text variant="P2" shade="regular">
        {content || 'No-Data'}
      </Text>
    </StyledDetailRow>
  );
};
