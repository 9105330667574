import { Form } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormInputItem,
  PopoverModal,
  stellusEngageTheme,
  Text,
  variantMap,
} from 'rx-shared-lib';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import styled, { css, ThemeProvider, useTheme } from 'styled-components';
import validator from 'validator';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { minScreenSize } from '../../ScreenSizes';
import LoginLegalText from '../dashboard/legal/LoginLegalText';
import { isUserAuthenticated, onLogin } from './authSlice';
import styles from './Login.module.css';

export const USERNAME = 'USERNAME';
export const PASSWORD = ' PASSWORD';
export const FORGOT_PASSOWRD_BUTTON = 'FORGOT_PASSOWRD_BUTTON';
export const LOGIN = 'LOGIN';
export const LOGIN_ID_INPUT = 'LOGIN_ID_INPUT';
export const LOGIN_PASSWORD_INPUT = 'LOGIN_PASSWORD_INPUT';
export const SUBMIT_LOGIN_BUTTON = 'SUBMIT_LOGIN_BUTTON';
export const BCKG_IMG = 'BCKG_IMG';
export const LOGIN_FORM = 'LOGIN_FORM';

const ForgotPassword = styled('div')`
  text-align: center;
  cursor: pointer;
`;

const LoginButtonContainer = styled(Form.Item)`
  ${({ theme }) => css`
    margin-top: ${theme.spacingInPx(12)};
  `}
`;

const LoginRoot = styled(PopoverModal)`
  .ant-modal-body {
    padding-bottom: 24px !important;
    height: unset !important;
    min-height: 506px;
  }
  top: 28%;
  @media screen and ${minScreenSize.laptopL} {
    margin-right: 15%;
  }
`;

const ErrorTextHolder = styled('div')`
  ${({ theme }) => css`
    background-color: ${theme.palette.error[100]};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    text-align: center;
  `}
`;

const LoginContent = styled('div')`
  display: flex;
  flex-direction: column;
  ${variantMap(TypographySubType.semibold, 'CAPTION')['CAPTION'].styles}
`;

export const Login = () => {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState<ValidateStatus>('');
  const [passwordError, setPasswordError] = useState<ValidateStatus>('');
  const [loginButtonText, setLoginButtonText] = useState('Sign in');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { loginError } = useAppSelector((state: RootState) => {
    return state.auth;
  });
  const dispatch = useAppDispatch();

  const isALoggedinUser = useAppSelector(isUserAuthenticated);

  useEffect(() => {
    if (isALoggedinUser) {
      navigate('/');
      setLoginButtonText('Sign in');
      setLoading(false);
    }
  }, [isALoggedinUser, navigate]);

  const onSubmit = async (values: any) => {
    try {
      setLoginButtonText('Authenticating');
      setLoading(true);
      await dispatch(onLogin(values.username, values.password));
      navigate('/');
    } catch (error) {
      //do nothing
      setLoginButtonText('Sign in');
      setLoading(false);
    }
  };

  const onForgotPassword = () => {
    navigate('/forgotPassword');
  };

  const theme = useTheme();

  return (
    <ThemeProvider theme={stellusEngageTheme}>
      <div data-testid={BCKG_IMG} className={styles.loginRoot}>
        <ErrorTextHolder>
          <Text variant="P2" color={theme.palette.ancillary.white} shade="semibold">
            {loginError}
          </Text>
        </ErrorTextHolder>

        <LoginRoot
          dataTestId={LOGIN}
          visible
          popoverHeader={t('common:account-login-title')}
          width={480}
          height={506}
          mask={false}
          maskClosable={false}
          closable={false}
          showLogo
          content={
            <LoginContent>
              <Form
                data-testid={LOGIN_FORM}
                layout="horizontal"
                initialValues={{ remember: true }}
                onFinish={onSubmit}
                autoComplete="off"
                form={form}
              >
                <div style={{ paddingTop: 24 }}>
                  <FormInputItem
                    InputTestID={LOGIN_ID_INPUT}
                    validateStatus={emailError}
                    hasFeedback
                    name="username"
                    placeholder="Email"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value === undefined || value === null || value === '') {
                            setEmailError('error');
                            return Promise.reject(t('common:email-field-empty-string'));
                          } else if (!validator.isEmail(value)) {
                            setEmailError('error');
                            return Promise.reject(t('common:provide-email-string'));
                          } else {
                            setEmailError('success');
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  />

                  <FormInputItem
                    name="password"
                    type="password"
                    placeholder="Password"
                    allowClear
                    InputTestID={LOGIN_PASSWORD_INPUT}
                    rules={[
                      () => ({
                        validator(_, value: any) {
                          if (value === undefined || value === null || value === '') {
                            setPasswordError('error');
                            return Promise.reject(t('common:pwd-field-empty-string'));
                          } else {
                            setPasswordError('success');
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                    validateStatus={passwordError}
                  />
                </div>
                <LoginButtonContainer>
                  <Button
                    data-testid={SUBMIT_LOGIN_BUTTON}
                    block
                    type="primary"
                    htmlType="submit"
                    isLoading={loading}
                    size="large"
                  >
                    <Text variant="P1" shade="semibold" color="inherit">
                      {loginButtonText}
                    </Text>
                  </Button>
                </LoginButtonContainer>
              </Form>

              <ForgotPassword data-testid={FORGOT_PASSOWRD_BUTTON} onClick={onForgotPassword}>
                <Text variant="P2" shade="semibold" color={theme.stellusPalette.indigo[70]}>
                  {t('common:forgot-password-title')}
                </Text>
              </ForgotPassword>
              <LoginLegalText />
            </LoginContent>
          }
        />
      </div>
    </ThemeProvider>
  );
};

export default Login;
