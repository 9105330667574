import { Button, Tabs } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Drawer, Text, theme } from 'rx-shared-lib';
import styled, { css } from 'styled-components';
import { useAppDispatch } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { Spinner } from '../../../component/PatientSearch/Loader';
import { onUserSelection } from '../../../slice/UsersSlice';
import MemberDetails from './memberDetails';

const MemberDrawer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showAnswerLoader } = useSelector((state: RootState) => {
    return state.dashboard;
  });

  const { editUser } = useSelector((state: RootState) => {
    return state.users;
  });

  const onClose = () => {
    dispatch(onUserSelection(undefined));
  };

  const StyledArrowButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  let withLoaderStyle: any = {
    opacity: 0.5,
    PointerEvent: 'none',
  };
  const loaderStyle = showAnswerLoader ? withLoaderStyle : {};
  return (
    <Drawer
      bodyStyle={{
        padding: '24px 48px 48px 48px',
        ...loaderStyle,
      }}
      placement={'right'}
      headerStyle={{
        padding: theme.spacingInPx(12),
        paddingBottom: 0,
      }}
      title={
        <StyledArrowButtonContainer style={loaderStyle}>
          <Text variant="H2" shade="semibold">
            Edit User
          </Text>
        </StyledArrowButtonContainer>
      }
      onClose={onClose}
      maskClosable={false}
      footer={<></>}
      visible={!!editUser}
      mask={false}
      width={616}
    >
      <MemberDetails />
      {showAnswerLoader && <Spinner style={{ zIndex: 1, top: '50%' }} />}
    </Drawer>
  );
};

export default MemberDrawer;
