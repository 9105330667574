import moment from 'moment';
import config from '../config';
import _, { flow, trim, upperFirst, lowerCase } from 'lodash';

// We must modify the moment object on a global level to allow for proper DateTime renders - Exported for testing only.
export const calendarConfig = () => {
  moment.calendarFormat = function (myMoment, now) {
    let diff = myMoment.diff(now, 'days', true);
    if (diff < -365) return 'lastYear';
    if (diff < -6) return 'sameElse';
    if (diff < -1) return 'lastWeek';
    if (diff < 0) return 'lastDay';
    if (diff < 1) return 'sameDay';
    if (diff < 2) return 'nextDay';
    return 'sameElse';
  };

  moment.updateLocale('en', {
    calendar: {
      lastDay: '[Yesterday], LT',
      sameDay: '[Today], LT',
      lastYear: 'MM/DD/YYYY, LT',
      lastWeek: 'MMM Do, h:mm A',
      sameElse: 'MMM Do, h:mm A',
    },
  });
};

export const getDaysBetweenStartAndEndDates = ({ startDate, endDate }) => {
  const start = moment(new Date(startDate));
  const end = moment(new Date(endDate));

  const differenceInDays = Math.floor(moment.duration(end.diff(start)).asDays());

  return differenceInDays;
};

export const getLoggedInData = () => {
  const dataString = localStorage.getItem('login_data');
  if (dataString) {
    return JSON.parse(dataString);
  }
  return null;
};

export const setSearchedPharmacyData = value => {
  const dataString = localStorage.getItem('pharmacy_data');
  let pharmacyData = [];
  if (dataString) {
    pharmacyData = JSON.parse(dataString);
  }
  pharmacyData.unshift(value);
  pharmacyData = _.uniqBy(pharmacyData, 'id');
  if (pharmacyData?.length > 5) {
    pharmacyData.pop();
  }
  localStorage.setItem('pharmacy_data', JSON.stringify(pharmacyData));
};

export const getSearchedPharmacyData = () => {
  const dataString = localStorage.getItem('pharmacy_data');
  if (dataString) {
    return JSON.parse(dataString);
  }
  return null;
};

export const isEventDrivenSystem = () => {
  const EVENT_DRIVEN_APP = 'eventDriven';

  if (config.REACT_APP_TYPE) {
    return config.REACT_APP_TYPE === EVENT_DRIVEN_APP;
  }
  const appEnvData = localStorage.getItem('appEnv');
  return appEnvData === EVENT_DRIVEN_APP;
};

export const getAccessToken = async () => {
  const data = await getLoggedInData();
  if (data) {
    return data.access_token ? data.access_token : '';
  }

  return null;
};

export const gotoLogin = () => {
  window.history.go('/login');
};

export const getFormattedDate = date => {
  return moment.utc(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
};

export const formatPhone = phone => {
  const normalizedPhone = phone?.replace(/\D/g, '');
  return normalizedPhone?.length === 10
    ? `(${normalizedPhone.substring(0, 3)}) ${normalizedPhone.substring(
        3,
        6,
      )}-${normalizedPhone.substring(6, 10)}`
    : null;
};

export const removePhoneFormat = str => {
  return str?.replace(/\D/g, '');
};

export const removeCommaFromString = str => {
  return str?.replace(',', ' ');
};
export const removeParenthesisFromString = str => {
  return str?.replaceAll(/[|\[\]]/gi, '');
};

export const removeCommaParenthesisTrailingSpace = str => {
  // compose multiple function and returns the output, add additional function to remove the char here
  return flow([removeCommaFromString, removeParenthesisFromString, trim])(str);
};

export const phoneInputFormating = phoneNumber => {
  let newText = '';
  let cleaned = removePhoneFormat('' + phoneNumber);
  if (cleaned.length !== 0) {
    for (let i = 0; i < cleaned.length; i++) {
      if (i === 0) {
        newText = '(';
      } else if (i === 3) {
        newText = newText + ') ';
      } else if (i === 6) {
        newText = newText + '-';
      }
      newText = newText + cleaned[i];
    }
  }
  return newText;
};

export const valueDoesNotExist = value => {
  return value == undefined || value == null;
};

export const cutpointValueIncorrect = value => {
  try {
    if (value === undefined || value === null || value === '' || isNaN(value)) {
      return true;
    }
    const number = parseInt(value);

    if (number < 70 || number > 100) {
      return true;
    }
  } catch (error) {
    return true;
  }
  return false;
};

export const getCurrentSelectedMeasureCount = (id, filteredResult) => {
  let newIdValue = id;
  if (id === undefined || id === null) {
    newIdValue = 'all';
  }
  const result = filteredResult?.measures?.find(x => x.id === newIdValue);
  return result?.count;
};

export const styleFromPdc = (pdc, styles) => {
  if (pdc >= 80) {
    return styles.taskStatusPass;
  } else {
    return styles.taskStatusFail;
  }
};
export const getReassignButtonText = selectedPatient => {
  let nameSplit = selectedPatient?.assignedUserName?.split(' ') || ['', ''];
  const firstName = nameSplit[0].substring(0, 1);
  const lastName = nameSplit[1] || '';
  return `${firstName} ${lastName}`;
};
export const capitalizeString = string => {
  const splittedString = string?.split(',');
  const stringMap = splittedString?.map(str => upperFirst(lowerCase(str?.trim())));
  const commaJoined = stringMap?.join(',');
  const spaceStrings = commaJoined?.split(' ')?.map(str => upperFirst(str?.trim()));
  return spaceStrings?.join(' ');
};

export const truncateToDecimals = (num, dec = 1) => {
  const calcDec = Math.pow(10, dec);
  return Math.trunc(num * calcDec) / calcDec;
};

export const isAdminUser = userDetails => {
  const { roles } = userDetails || {};
  return !!(roles && roles.name?.toLowerCase() === 'admin');
};

export const isPharmacistManager = userDetails => {
  const { roles } = userDetails || {};
  return !!(roles && roles.name?.toLowerCase() === 'pharmacist_manager');
};

export const isUserMgmntEnabled = userDetails => {
  return isAdminUser(userDetails) || isPharmacistManager(userDetails);
};

export const clearRoleNames = roleName => {
  return roleName && roleName.replaceAll('_', ' ');
};

export const capitalizeFirstLetter = string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1)?.toLowerCase() : '';
};
