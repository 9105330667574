import styled, { css } from 'styled-components';
import { Button, Text } from 'rx-shared-lib';
import { APP_VERSION } from '../../../src/constants';
import { useTranslation } from 'react-i18next';
export const EDIT_SAVE_BUTTON = 'EDIT_SAVE_BUTTON';

export interface ProfileFooterProps {
  isEditMode?: boolean;
  handleEditClick?: any;
  handleSaveClick?: any;
  setLegalVisible?: any;
}
const StyledFooterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledTermsCondition = styled('div')`
  ${({ theme }) => css`
    margin-top: ${theme.spacingInPx(6)};
    cursor: pointer;
    text-align: center;
  `}
`;
const Footer = ({
  isEditMode,
  handleEditClick,
  handleSaveClick,
  setLegalVisible,
}: ProfileFooterProps) => {
  const { t } = useTranslation();
  return (
    <StyledFooterContainer data-testid={EDIT_SAVE_BUTTON}>
      <Button
        block
        type="primary"
        size="large"
        onClick={isEditMode ? handleSaveClick : handleEditClick}
      >
        {isEditMode ? t('common:save') : t('common:edit-profile')}
      </Button>
      <StyledTermsCondition>
        <span onClick={() => setLegalVisible(true)}>
          <Text variant="P3" shade="bold">
            {t('common:terms-amp-conditions')}
          </Text>
        </span>
        <Text variant="P3" shade="bold">
          {APP_VERSION}
        </Text>
      </StyledTermsCondition>
    </StyledFooterContainer>
  );
};
export default Footer;
