import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { Button as CommonButton } from 'rx-shared-lib';
import { AssigneeHeader } from '../assignee/AssigneeHeader';
import { useDispatch, useSelector } from 'react-redux';
import { setAssigneeButtonText, setAssigneeSelector } from '../../../slice/PatientSlice';
import { RootState } from '../../../app/store';
import { onAssigneeCheckboxClick, reloadGrid } from '../dashboardSlice';
import { CURRENT } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { getReassignButtonText } from '../../../API/util';
export const PATIENT_CARD_FOOTER = 'PATIENT_CARD_FOOTER';

export const ASSIGNEE_FOOTER = 'ASSIGNEE_FOOTER';
export const ASSIGNEE_HEADER = 'ASSIGNEE_HEADER';
export const ASSIGNEE_BUTTON = 'ASSIGNEE_HEADER';
const Footer = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 120px;
    box-shadow: inset 0px 1px 0px ${theme.stellusPalette.grey[30]};
  `}
`;

const FooterContainer = styled('div')`
  ${({ theme }) => css`
    margin-top: ${theme.spacingInPx(6)};
    width: 100%;
    display: flex;
    justify-content: center;
  `}
`;
const PatientCardFooter = ({ onPatientCardClose }: any) => {
  const { assigneeSelector, assigneeButtonText, allMeasureData, selectedPatientCardTab } =
    useSelector((state: RootState) => state.patient);
  const { selectedPatient, currentUser } = useSelector((state: RootState) => state.dashboard);
  const role: string = (currentUser?.role || '').toLowerCase();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const disableReassignButton = useCallback(() => {
    let disabled = true;
    if (['admin'].includes(role)) {
      return disabled;
    } else {
      _.forEach(allMeasureData, function (measure) {
        if (measure?.isGapOpen) {
          disabled = false;
          return false;
        }
      });
    }
    return disabled;
  }, [allMeasureData, role]);

  const onClickReassign = () => {
    dispatch(setAssigneeSelector(true));
    dispatch(onAssigneeCheckboxClick(selectedPatient?.id));
  };

  const onSuccessReassign = () => {
    dispatch(setAssigneeSelector(false));
    onPatientCardClose();
    dispatch(reloadGrid(CURRENT));
  };

  return (
    <div data-testid={PATIENT_CARD_FOOTER}>
      {selectedPatientCardTab === '1' && !['admin'].includes(role) ? (
        <>
          <Footer data-testid={ASSIGNEE_FOOTER} id="footer">
            <FooterContainer>
              {!assigneeSelector ? (
                <CommonButton
                  data-testid={ASSIGNEE_BUTTON}
                  size="large"
                  type="primary"
                  style={{ width: 504 }}
                  onClick={onClickReassign}
                  isDisabled={disableReassignButton()}
                  onMouseOver={() => dispatch(setAssigneeButtonText(t('common:reassign')))}
                  onMouseOut={() =>
                    dispatch(setAssigneeButtonText(getReassignButtonText(selectedPatient)))
                  }
                >
                  {assigneeButtonText || t('common:reassign')}
                </CommonButton>
              ) : (
                <AssigneeHeader
                  data-testId={ASSIGNEE_HEADER}
                  onFinishReassign={onSuccessReassign}
                />
              )}
            </FooterContainer>
          </Footer>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default PatientCardFooter;
