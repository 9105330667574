import { CheckCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResetPassword, Text, theme } from 'rx-shared-lib';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { changePassword } from './authAPI';
import { onChangePasswordError } from './authSlice';
import styles from './Login.module.css';
import styled, { css } from 'styled-components';
import { minScreenSize } from '../../ScreenSizes';
import {
  InvalidPatternError,
  InvalidPatternMsg,
} from '../../component/UserProfile/PasswordSection';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORCE_PASSWORD_FORM_TEST_ID = 'FORCE_PASSWORD_FORM_TEST_ID';
export const STYLED_NEW_INPUT_TEST_ID = 'STYLED_NEW_INPUT_TEST_ID';
export const NEW_PASSWORD_FIELD_TEST_ID = 'NEW_PASSWORD_FIELD_TEST_ID';
export const STYLED_CONFIRM_NEW_INPUT_TEST_ID = 'STYLED_CONFIRM_NEW_INPUT_TEST_ID';
export const CONFIRM_NEW_PASSWORD_FIELD_TEST_ID = 'CONFIRM_NEW_PASSWORD_FIELD_TEST_ID';

const ErrorTextHolder = styled('div')`
  ${({ theme }) => css`
    background-color: ${theme.palette.error[100]};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    text-align: center;
  `}
`;

const ChangePasswordRoot = styled('div')`
  ${({ theme }) => css``}
`;

const StyldResetPassword = styled(ResetPassword)`
  ${({ theme }) => css`
    top: 25%;
    @media screen and ${minScreenSize.laptopL} {
      margin-right: 15%;
    }
  `}
`;

export const ForceChangePassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const changePasswordError = useAppSelector((state: RootState) => state.auth.changePasswordError);

  const dispatch = useAppDispatch();
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams: any, prop: any) => searchParams.get(prop),
  });

  const onSubmit = async (password: string) => {
    dispatch(onChangePasswordError(''));
    setLoading(true);
    if (!params.userdata) {
      dispatch(onChangePasswordError(t('login:changePasswordError')));
      setLoading(false);
      return;
    }
    const keyData = JSON.parse(window.atob(params.userdata));
    try {
      await changePassword(keyData.key, keyData.emailAddress, password);
      notification.info({
        message: `Success!`,
        description: t('login:changePasswordSuccess'),
        placement: 'topRight',
        icon: <CheckCircleOutlined style={{ color: 'rgb(135,219,137)' }} />,
      });
      setLoading(false);
      navigate('/');
    } catch (error) {
      dispatch(onChangePasswordError(t('login:changePasswordError')));
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <ChangePasswordRoot className={styles.loginRoot}>
      <ErrorTextHolder>
        <Text variant="P2" color={theme.palette.ancillary.white} shade="semibold">
          {changePasswordError}
        </Text>
      </ErrorTextHolder>

      <StyldResetPassword
        visible
        mask={false}
        onClose={handleCancel}
        onSubmit={onSubmit}
        resetButtonLabel={loading ? t('common:processing') : t('common:submit-button-label')}
        isLoading={loading}
        popoverHeader={t('common:password-reset-label')}
        newPasswordLabel={t('common:new-password')}
        newPasswordPlaceholder={t('common:new-password')}
        confirmPasswordLabel={t('common:confirm-new-password')}
        confirmPasswordPlaceholder={t('common:confirm-new-password')}
        errorMsg={{
          needPassword: t('common:please-enter-new-password'),
          notMatch: t('common:password-not-match'),
          invalidPatternMsg: <InvalidPatternMsg />,
          invalidPatternError: <InvalidPatternError />,
        }}
        dataTestid={{
          changePasswordTitle: CHANGE_PASSWORD,
          form: FORCE_PASSWORD_FORM_TEST_ID,
          newPasswordFormItem: STYLED_NEW_INPUT_TEST_ID,
          newPassword: NEW_PASSWORD_FIELD_TEST_ID,
          confirmNewPasswordFormItem: STYLED_CONFIRM_NEW_INPUT_TEST_ID,
          confirmNewPassword: CONFIRM_NEW_PASSWORD_FIELD_TEST_ID,
          button: RESET_PASSWORD,
        }}
      />
    </ChangePasswordRoot>
  );
};

export default ForceChangePassword;
