import { t } from 'i18next';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Text, TextInputComp as Input } from 'rx-shared-lib';
import styled from 'styled-components';
import { editPatientDetails, fetchPatientDetails } from '../../../actions';
import {
  formatPhone,
  isEventDrivenSystem,
  phoneInputFormating,
  removeCommaFromString,
  removePhoneFormat,
} from '../../../API/util';
import { RootState } from '../../../app/store';
import styles from './PatientCard.module.css';
import { AlertIcon } from '../../../component/icons/AlertIcon';
import { ContactPhone } from '../../../component/FiveNine/ContactPhone';
import config from '../../../config';

export const Back_btn = 'Back_btn';
export const PATIENT_DETAILS_CONTAINER = 'PATIENT_DETAILS_CONTAINER';
export const EDIT_BTN = 'EDIT_BTN';
export const CANCEL_BTN = 'CANCEL_BTN';
export const MOBILE_INPUT = 'MOBILE_INPUT';
export const SAVE_BTN = 'SAVE_BTN';
export const HOME_PHONE_INPUT = 'HOME_PHONE_INPUT';
export const PRESCRIBER_DATA = 'PRESCRIBER_DATA';
export const PHARMACY_DATA = 'PHARMACY_DATA';

interface PatientDetailsProps {
  details: any;
}
interface EditDetailType {
  homePhone?: string;
  homePhoneIsNull?: boolean;
  mobilePhone?: string;
  mobilePhoneIsNull?: boolean;
}

export const CapitalizeText = styled(Text)`
  text-transform: capitalize;
`;

const TextCenter = styled(Text)`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

const NoData = () => (
  <div style={{ display: 'inline-flex', alignItems: 'center', color: '#9F5710' }}>
    <AlertIcon style={{ color: '#9F5710' }} size="14" />
    <span style={{ margin: '0.15rem 0 0 0.25rem', fontSize: '0.75rem' }}>No data</span>
  </div>
);

const PatientDetails = ({ details }: PatientDetailsProps) => {
  const dispatch = useDispatch();
  const phoneDetail = details?.patient?.homePhone;
  const mobileDetail = details?.patient?.mobilePhone;
  const [edit, setEdit] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [mobileNumber, setMobileNumber] = useState<string>('');

  const { currentUser } = useSelector((state: RootState) => state.dashboard);
  const { selectedPatientDetails } = useSelector((state: RootState) => state.patient);

  const { patient } = selectedPatientDetails ?? {};

  const role: string = (currentUser?.role || '').toLowerCase();

  const patientInfo = details?.patient ?? {};

  const onEdit = () => {
    setEdit(true);
    setPhoneNumber(phoneDetail && formatPhone(phoneDetail));
    setMobileNumber(mobileDetail && formatPhone(mobileDetail));
  };

  const onSave = async () => {
    const mobileNum = removePhoneFormat(mobileNumber);
    const homeNumber = removePhoneFormat(phoneNumber);

    if (mobileNum?.length != 10 || homeNumber?.length != 10) {
      /* istanbul ignore else  */
      if (mobileNum?.length != 10) {
        setErrorMobilePhone(true);
      }
      /* istanbul ignore else  */
      if (homeNumber?.length != 10) {
        setErrorPhone(true);
      }
    } else {
      const edsPayload = {
        mobile: mobileNum,
        home: homeNumber,
      };

      const editDetails: EditDetailType = {
        homePhone: homeNumber,
        homePhoneIsNull: homeNumber == '' ? true : false,
        mobilePhone: mobileNum,
        mobilePhoneIsNull: mobileNum == '' ? true : false,
      };
      setEdit(false);
      await dispatch(editPatientDetails(editDetails, edsPayload));
      await dispatch(fetchPatientDetails());
    }
  };

  const disableReassignButton = (): boolean => {
    return ['admin'].includes(role);
  };

  const patientHomePhoneInput = (phone: any) => {
    const phnNum: string = phoneInputFormating(phone.target.value);
    setPhoneNumber(phnNum);
  };
  const patientMobileInput = (mobile: any) => {
    const mobNum: string = phoneInputFormating(mobile.target.value);
    setMobileNumber(mobNum);
  };
  const [errorMobile, setErrorMobilePhone] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  const prescriber = details?.prescriber;

  const getPhoneDetails = () => {
    if (!edit) {
      return (
        <td>
          <TextCenter variant="P3" shade="regular">
            {`${t('patientCard:home-label')}: `}
            <ContactPhone contactNumber={details?.patient?.homePhone} noData={<NoData />} />
          </TextCenter>
        </td>
      );
    } else {
      return (
        <td className={styles.editStyles}>
          <div>{t('patientCard:home-label')}:</div>
          <Input
            data-testid={HOME_PHONE_INPUT}
            maxLength={14}
            style={errorPhone ? { borderColor: 'red' } : { borderColor: '#545454' }}
            value={phoneNumber}
            placeholder="Home Phone"
            onChange={patientHomePhoneInput}
            onClick={() => setErrorPhone(false)}
          />
        </td>
      );
    }
  };

  const getMobileDetails = () => {
    if (!edit) {
      return (
        <td className={''}>
          <TextCenter variant="P3" shade="regular">
            {`${t('patientCard:mobile-label')}: `}
            <ContactPhone contactNumber={details?.patient?.mobilePhone} noData={<NoData />} />
          </TextCenter>
        </td>
      );
    } else {
      return (
        <td className={styles.editStyles}>
          <div>{t('patientCard:mobile-label')}:</div>
          <Input
            style={errorMobile ? { borderColor: 'red' } : { borderColor: '#545454' }}
            data-testid={MOBILE_INPUT}
            maxLength={14}
            value={mobileNumber}
            placeholder="Mobile Phone"
            onChange={patientMobileInput}
            onClick={() => setErrorMobilePhone(false)}
          />
        </td>
      );
    }
  };

  const PcpAddressInfo = () => {
    const addressComponent = [];
    let cityLabel = '';

    if (!prescriber?.address)
      return (
        <span key="address" style={{ display: 'block', marginLeft: '0.5rem' }}>
          <NoData />
        </span>
      );

    addressComponent.push(
      <span key="address" style={{ display: 'block', wordBreak: 'break-all' }}>
        {prescriber?.address}
      </span>,
    );

    if (patientInfo?.address2)
      addressComponent.push(
        <span key="address2" style={{ display: 'block', wordBreak: 'break-all' }}>
          {prescriber?.address2}
        </span>,
      );

    if (!patientInfo?.city) return <>{addressComponent}</>;

    cityLabel += prescriber.city ? `${prescriber.city}` : '';

    cityLabel += prescriber.state ? `, ${prescriber.state}` : '';

    cityLabel += prescriber.zipcode ? ` ${prescriber.zipcode}` : '';

    addressComponent.push(
      <span key="cityLabel" style={{ display: 'block' }}>
        {cityLabel}
      </span>,
    );

    return <>{addressComponent}</>;
  };

  const getPcpDetails = () => {
    if (!isEventDrivenSystem()) {
      return (
        <div className={styles.tableDiv} data-testid={PRESCRIBER_DATA}>
          <div style={{ marginLeft: 12 }}>
            <Text variant="P2" shade="bold">
              {t('patientCard:pcp')}
            </Text>
          </div>

          <div className={styles.tableContentDiv}>
            <table className={styles.table}>
              <tbody>
                <tr>
                  <td>
                    <Text variant="P2" shade="regular">
                      {removeCommaFromString(prescriber?.prescriberName) || '  No-Data'}
                    </Text>
                  </td>
                  <td>
                    <Text variant="P2" shade="regular">
                      <span>{t('patientCard:office-label')} :</span>
                      <span style={{ marginLeft: '0.5rem' }}>
                        <ContactPhone contactNumber={prescriber?.phone} noData="No data" />
                      </span>
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text variant="P2" shade="regular">
                      <label>{t('patientCard:clinic')}:</label>
                      <span style={{ display: 'block', marginLeft: '0.5rem' }}>
                        {patient?.clinicName || <NoData />}
                      </span>
                    </Text>
                  </td>
                  <td>
                    <Text variant="P2" shade="regular">
                      {`${t('patientCard:fax-label')}: ${
                        formatPhone(prescriber?.fax) || '  No-Data'
                      }`}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2}>
                    <Text variant="P2" shade="regular">
                      <label>Address:</label>
                      <PcpAddressInfo />
                    </Text>
                  </td>
                  <td>
                    <Text variant="P2" shade="regular">
                      {`${t('patientCard:npi-label')}: ${prescriber?.npi || '  No-Data'}`}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text variant="P2" shade="regular">
                      {prescriber?.email || ''}
                    </Text>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const getPayer = () =>
    `${patientInfo?.payer ?? ''} ${patientInfo?.dataSourceOrganizationName ?? ''}`.trim();

  const AddressInfo = () => {
    const addressComponent = [];
    let cityLabel = '';

    if (!patientInfo?.address)
      return (
        <span key="address" style={{ display: 'block', marginLeft: '0.5rem' }}>
          <NoData />
        </span>
      );

    addressComponent.push(
      <span key="address" style={{ display: 'block', wordBreak: 'break-all' }}>
        {patientInfo?.address}
      </span>,
    );

    if (patientInfo?.address2)
      addressComponent.push(
        <span key="address2" style={{ display: 'block', wordBreak: 'break-all' }}>
          {patientInfo?.address2}
        </span>,
      );

    if (!patientInfo?.city) return <>{addressComponent}</>;

    cityLabel += patientInfo.city ? `${patientInfo.city}` : '';

    cityLabel += patientInfo.state ? `, ${patientInfo.state}` : '';

    cityLabel += patientInfo.zipcode ? ` ${patientInfo.zipcode}` : '';

    addressComponent.push(
      <span key="cityLabel" style={{ display: 'block' }}>
        {cityLabel}
      </span>,
    );

    return <>{addressComponent}</>;
  };

  const editPatientDetailsFlag = config.REACT_APP_EDIT_PATIENT_DETAILS === 'true';

  return (
    <div
      data-testid={PATIENT_DETAILS_CONTAINER}
      className={styles.patientDetailBox2}
      id="patient-details"
    >
      <div className={styles.detailContentDiv}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%' }}>
          <div className={styles.tableDiv}>
            <div style={{ marginLeft: 12 }}>
              <Text variant="P2" shade="bold">
                {t('patientCard:patient')}
              </Text>
            </div>
            <div className={styles.tableContentDiv}>
              <table className={styles.table}>
                <tbody>
                  <tr>
                    <td rowSpan={3} style={{ verticalAlign: 'top' }}>
                      <Text variant="P3" shade="regular">
                        <label> Address: </label>
                        <AddressInfo />
                      </Text>
                    </td>
                    {getPhoneDetails()}
                  </tr>
                  <tr>{getMobileDetails()}</tr>
                  <tr>
                    <td style={details?.patient?.email ? { color: '#2F54EB' } : {}}>
                      <TextCenter variant="P3" shade="regular">
                        {`${t('patientCard:email-label')}: `}
                        {details?.patient?.email || <NoData />}
                      </TextCenter>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextCenter variant="P3" shade="regular">
                        {`${t('patientCard:payer-label')}: `}
                        {getPayer() || <NoData />}
                      </TextCenter>
                    </td>
                    <td>
                      <TextCenter variant="P3" shade="regular">
                        {`${t('patientCard:member-id-label')}: `}
                        {capitalize(details?.patient?.memberId) || <NoData />}
                      </TextCenter>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {getPcpDetails()}
        </div>
        {editPatientDetailsFlag && !disableReassignButton() && (
          <div
            style={{
              width: 'calc(100% + 96px)',
              display: 'flex',
              justifyContent: 'space-around',
              height: 120,
              borderTop: '1px solid #d6d6d6',
              padding: '24px 48px 48px 48px',
              marginBottom: '-48px',
            }}
          >
            <div style={{ height: 48, width: '80%' }}>
              {!edit ? (
                <Button
                  data-testid={EDIT_BTN}
                  type="primary"
                  size="middle"
                  onClick={onEdit}
                  block
                  isDisabled={disableReassignButton()}
                >
                  Edit
                </Button>
              ) : (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  {' '}
                  <Button
                    size="middle"
                    data-testid={CANCEL_BTN}
                    className={styles.editBtns}
                    onClick={() => {
                      setEdit(false);
                      setErrorPhone(false);
                      setErrorMobilePhone(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    size="middle"
                    className={styles.saveBtn}
                    data-testid={SAVE_BTN}
                    onClick={onSave}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default PatientDetails;
