import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown, Text, theme, variantMap } from 'rx-shared-lib';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import styled, { css, CSSProperties } from 'styled-components';
import { ReactComponent as SearchIcon } from '../../../assets/search.svg';
import { NoResults } from '../../../component/PatientSearch/NoSearchResult';
import { ReactComponent as NoSearchResults } from '../../../assets/NoSearchResults.svg';
import { User } from '../Types';
import styles from './AssigneeHeader.module.css';

export const SEARCH_SELECT_USER = 'SEARCH_SELECT_USER';

const StyledDropDown = styled(DropDown)`
  ${({ theme, open }) => {
    return css`
      .ant-select-selector {
        height: 48px !important;
      }
      .ant-select-clear {
        top: 20px;
      }
      .ant-select-selection-item {
        ${variantMap(TypographySubType.regular, 'P1')['P1'].styles}
        line-height: 44px !important;
      }
      .ant-select-selection-search-input {
        height: 48px !important;
      }
      .ant-select-dropdown-placement-topLeft {
        padding: 0;
      }
      .rc-virtual-list-holder-inner div:last-child {
        border-radius: 4px;
        border-bottom: none;
      }
      .ant-select-item-option {
        height: 48px;
        align-items: center;
        border-bottom: 1px solid ${theme.palette.others.borderColor};
      }
      &:hover {
        .ant-select-selector {
          border-color: ${open
            ? theme.palette.blueberry['80']
            : theme.palette.graphite['70']} !important;
        }
      }
      &:focus-within {
        border-right: 1px solid ${theme.palette.blueberry['80']};
        border-radius: 4px;
        .ant-select-selector {
          border-width: 2px !important;
          border-right-width: 2px !important;
        }
      }
    `;
  }}
`;

export const PlaceHolder = ({ focus }: { focus: boolean }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      {!focus && <SearchIcon role={'img'} height={24} width={24} />}
      &nbsp;{' '}
      <Text variant="P2" shade="regular" color={theme.palette.graphite['60']}>
        {t('common:search-users')}
      </Text>
    </div>
  );
};

export const SearchSelectUser = ({
  handleUserChange,
  handleUserSearch,
  users,
  style,
  searchValue,
}: {
  users: User[];
  handleUserChange: any;
  handleUserSearch: any;
  style: CSSProperties;
  searchValue: string;
}) => {
  const { t } = useTranslation();
  const [focus, setFocus] = React.useState(false);

  const onChangeHandler = (value: any) => {
    handleUserChange(value);
    setFocus(false);
  };

  const onClearHandler = () => {
    handleUserSearch('');
  };

  return (
    <StyledDropDown
      id="searchSelectUser"
      data-testid={SEARCH_SELECT_USER}
      style={style}
      placeholder={<PlaceHolder focus={focus} />}
      searchValue={searchValue}
      onClear={onClearHandler}
      notFoundContent={
        <NoResults>
          <>
            <NoSearchResults />
            <Text variant="P2" shade="regular">
              {t('common:no-results-found')}
            </Text>
          </>
        </NoResults>
      }
      getPopupContainer={triggerNode => triggerNode}
      dropdownMatchSelectWidth
      className={styles.searchSelectUser}
      size="large"
      showSearch={true}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      allowClear={true}
      onChange={onChangeHandler}
      onSearch={handleUserSearch}
      filterOption={false}
      options={users?.map((user: User) => {
        return {
          label: user.userFullName,
          value: user.id,
          disabled: !user.isUserAllowForReassignment,
        };
      })}
    ></StyledDropDown>
  );
};
