import styled from 'styled-components';
import LoaderIcon from '../../assets/adhere-loader-96.png';

const StyledLoaderScreen = styled('div')`
  height: 220px;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const Loader = () => {
  return (
    <StyledLoaderScreen>
      <Spinner />
    </StyledLoaderScreen>
  );
};
export const NewCard = styled.div`
  animation: spin 2s linear infinite;
  position: absolute;
  top: 31%;
  left: 40%;
  transform: translate(-50%, -50%);
`;

interface SpinnerProps {
  style?: any;
}

export const Spinner: React.FC<SpinnerProps> = ({ style }) => (
  <NewCard style={style}>
    <img src={LoaderIcon} />
  </NewCard>
);
