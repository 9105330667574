import { Popover } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'rx-shared-lib';
import styled from 'styled-components';
import {
  cancelRefreshFiveNine,
  createRefreshFiveNine,
  sendFiveNineNotification,
} from '../../actions';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import config from '../../config';
import { setShowFiveNinePopover } from '../../features/dashboard/dashboardSlice';
import { PhoneIcon } from '../icons/PhoneIcon';

const ButtonFiveNine = styled(Button)`
  background: #2b6678 !important;
`;

const PopoverFiveNine = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0px !important;
  }
`;

let interval: NodeJS.Timeout | undefined;

export const FiveNinePopover = () => {
  const dispatch = useAppDispatch();

  const { showFiveNinePopover } = useSelector((state: RootState) => {
    return state.dashboard;
  });

  const toggleFiveNinePopover = () => dispatch(setShowFiveNinePopover(!showFiveNinePopover));

  const url = new URL(config.REACT_APP_FIVE9_BASE_URL);

  url.searchParams.append('f9verticalthreshold', '300px');
  url.searchParams.append('f9crmapi', 'true');

  useEffect(() => {
    const interactionApi = window.Five9.CrmSdk.interactionApi();
    const registerApi = window.Five9.CrmSdk.crmApi();

    registerApi.registerApi({
      getAdtConfig: function () {
        const config = {
          myCallsTodayEnabled: false,
          myChatsTodayEnabled: false,
          myEmailsTodayEnabled: false,
          showContactInfo: false,
        };

        return Promise.resolve(config);
      },
      saveLog: function () {},
      screenPop: function () {},
      enableClickToDial: function () {},
      disableClickToDial: function () {},
    });

    interactionApi.subscribe({
      callStarted: (payload: any) => {
        if (!showFiveNinePopover) {
          sendFiveNineNotification(payload);
          dispatch(setShowFiveNinePopover(true));
        }

        interval = createRefreshFiveNine();
      },
      callEnded: () => {
        cancelRefreshFiveNine(interval);
      },
    });

    return () => cancelRefreshFiveNine(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <PopoverFiveNine
      trigger="click"
      placement="bottom"
      visible={showFiveNinePopover}
      content={
        <iframe
          id="iframe-api-v2-panel"
          title="FiveNine Integration"
          width={299}
          height={600}
          src={url.toString()}
        />
      }
    >
      <ButtonFiveNine type="primary" onClick={toggleFiveNinePopover} icon={<PhoneIcon />}>
        {showFiveNinePopover ? 'Close' : 'Open'}
      </ButtonFiveNine>
    </PopoverFiveNine>
  );
};
