import { get, put } from '../commonAPI';
import config from '../../config';

export const PutStepResponse = async (payload: any, patientTaskId: string) => {
  let res = await put(
    `${config.REACT_ACTIVITY_BASE_URL}/api/patient-task/${patientTaskId}/event`,
    payload,
  );
  return res;
};

export const FetchPatientMeasures = (patientId: string) => {
  const url = `${config.REACT_ACTIVITY_BASE_URL}/api/patient-task/${patientId}`;
  return get(url);
};
