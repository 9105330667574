import { get, patch, post, put } from '../commonAPI';
import config from '../../config';
const url = `${config.REACT_COHORT_BASE_URL}/api/patients/search`;

export const FetchPatientRowWithoutFilter = (payload: any) => {
  return post(url, payload);
};

export const FetchPaginatedPatients = (payload: any) => {
  return post(url, payload);
};

export const FetchPatientRow = (payload: any) => {
  return post(url, payload);
};

export const FetchPatientDetailsById = (patientId?: string) => {
  const url = `${config.REACT_PATIENT_BASE_URL}/api/patient/${patientId}`;
  return get(url);
};
export const editPatientDetailsEds = (payload: any, patientId?: string) => {
  const url = `${config.REACT_PATIENT_BASE_URL}/api/patient/${patientId}`;
  return patch(url, payload);
};
export const searchLastWorkedPatients = () => {
  const url = `${config.REACT_COHORT_BASE_URL}/api/patients/last-worked-patients`;
  return get(url);
};

export const fetchTaskData = (patientId: string, taskId: string) => {
  const url = `${config.REACT_ACTIVITY_BASE_URL}/api/patient-task/${patientId}/${taskId}`;
  return get(url);
};

export const fetchPatientLogs = (noteId: string, payload: any) => {
  const url = `${config.REACT_ACTIVITY_BASE_URL}/api/patient-logs/${noteId}`;
  return put(url, payload);
};

export const searchPatientLogs = (payload: any) => {
  const url = `${config.REACT_ACTIVITY_BASE_URL}/api/patient-logs/search`;
  return post(url, payload);
};
