import { List, Tag } from 'antd';
import { t } from 'i18next';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { SearchInput, Text, TextVariants, variantMap } from 'rx-shared-lib';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import styled, { css } from 'styled-components';
import { ReactComponent as NoSearchResults } from '../../../assets/noSearchResults-small.svg';
import { NoResults } from '../../PatientSearch/NoSearchResult';

const StyledContainer = styled.div`
  ${({ theme }) => css`
    box-shadow: 0px ${theme.spacingInPx(1.5)} ${theme.spacingInPx(3)} rgba(0, 0, 0, 0.11);
    border-radius: ${theme.spacingInPx(1)};
    background: ${theme.palette.ancillary.white};
  `}
`;

interface PharmacyFilterInterface {
  value?: any;
  onChangeFunc: any;
  dataSources: any[];
  selectedDataSources: any[];
  onRemove: any;
  disabled?: boolean;
}

export const PHARMACY_INPUT = 'PHARMACY_INPUT';
const RemoveIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3101_6723)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99967 1.83398C4.59392 1.83398 1.83301 4.5949 1.83301 8.00065C1.83301 11.4064 4.59392 14.1673 7.99967 14.1673C11.4054 14.1673 14.1663 11.4064 14.1663 8.00065C14.1663 4.5949 11.4054 1.83398 7.99967 1.83398ZM0.833008 8.00065C0.833008 4.04261 4.04163 0.833984 7.99967 0.833984C11.9577 0.833984 15.1663 4.04261 15.1663 8.00065C15.1663 11.9587 11.9577 15.1673 7.99967 15.1673C4.04163 15.1673 0.833008 11.9587 0.833008 8.00065ZM10.3532 5.6471C10.5485 5.84236 10.5485 6.15894 10.3532 6.3542L8.70678 8.00065L10.3532 9.6471C10.5485 9.84236 10.5485 10.1589 10.3532 10.3542C10.158 10.5495 9.84138 10.5495 9.64612 10.3542L7.99967 8.70776L6.35323 10.3542C6.15797 10.5495 5.84138 10.5495 5.64612 10.3542C5.45086 10.1589 5.45086 9.84236 5.64612 9.6471L7.29257 8.00065L5.64612 6.3542C5.45086 6.15894 5.45086 5.84236 5.64612 5.6471C5.84138 5.45184 6.15797 5.45184 6.35323 5.6471L7.99967 7.29354L9.64612 5.6471C9.84138 5.45184 10.158 5.45184 10.3532 5.6471Z"
          fill="#2B2B2B"
        />
      </g>
      <defs>
        <clipPath id="clip0_3101_6723">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TickIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1083 4.97407C17.3524 5.21815 17.3524 5.61388 17.1083 5.85796L7.94162 15.0246C7.69754 15.2687 7.30181 15.2687 7.05773 15.0246L2.89107 10.858C2.64699 10.6139 2.64699 10.2182 2.89107 9.97407C3.13514 9.73 3.53087 9.73 3.77495 9.97407L7.49967 13.6988L16.2244 4.97407C16.4685 4.73 16.8642 4.73 17.1083 4.97407Z"
      />
    </svg>
  );
};

const StyledText = styled(Text)`
  ${({ theme }) => css`
    margin-right: ${theme.spacingInPx(1)} !important;
  `}
`;

const StyledList = styled(List)`
  ${({ theme }) => css`
    overflow: scroll;
    margin-right: -3px;
    max-height: 200px;
  `}
`;
const StyledListItem = styled(List.Item)`
  ${({ theme }) => css`
    border-bottom: 1px solid #e7e7e7 !important;
    cursor: pointer;
    padding: 8px 16px 8px 16px !important;
    width: 100%;
    &.selected {
      background: ${theme.stellusPalette.tiger[5]} !important;
    }
    &.ant-list-item > svg > path {
      fill: #2b2b2b !important;
    }
    &.selected > svg > path {
      fill: #0e8103 !important;
    }
    &:hover {
      background: ${theme.palette.graphite[20]} !important;
    }
    &.selected:hover {
      background: #f5ebe2 !important;
    }
  `}
`;

const TagsContainer = styled.div`
  ${({ theme }) => css`
    display: inline-block;
    min-height: 0px;
    max-height: 190px;
    overflow-y: auto;
    margin-top: 8px;
    .ant-tag {
      ${variantMap(TypographySubType.regular, 'P2')['P2'].styles}
      color: ${theme.stellusPalette.grey[90]} !important;
      background: #ebebeb;
      border-radius: 4px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      margin-right: 8px;
      margin-bottom: 8px;
    }
    .ant-tag-close-icon {
      display: flex;
    }
  `}
`;

const StyledSearchInput = styled(SearchInput)`
  ${({ theme }) => css`
    &.ant-col {
      font-size: 17px;
    }
  `}
`;

const DataSourceFilter = ({
  value,
  onChangeFunc,
  selectedDataSources,
  dataSources,
  onRemove,
  disabled,
}: PharmacyFilterInterface) => {
  const [searchDebounceText, setSearchDebounceText] = useState('');
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  const [isContentBlured, setIsContentBlured] = useState(false);
  const [isSearchInputBlured, setIsSearchInputBlured] = useState(false);

  useEffect(() => {
    if (isContentBlured && isSearchInputBlured) {
      setShowSearchContainer(false);
    }
  }, [isContentBlured, isSearchInputBlured]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchDebounceText(e.target.value);
  };

  const filteredList = dataSources.filter((ds: any) => {
    if (searchDebounceText === '') {
      return true;
    } else {
      return ds.name.toLowerCase().indexOf(searchDebounceText.toLowerCase()) > -1;
    }
  });

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsContentBlured(true);
        }
      };
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <>
      <StyledSearchInput
        {...(disabled ? { disabled: true } : '')}
        onBlur={() => {
          setIsSearchInputBlured(true);
        }}
        value={searchDebounceText}
        data-testid={PHARMACY_INPUT}
        onInputChange={onInputChange}
        onClick={() => {
          setIsContentBlured(false);
          setIsSearchInputBlured(false);
          if (!showSearchContainer) {
            setShowSearchContainer(true);
          }
        }}
        allowClear
        placeholder="Search DataSource(s)"
      />
      {showSearchContainer && (
        <StyledContainer ref={wrapperRef}>
          {filteredList?.length > 0 && (
            <StyledList>
              {filteredList
                .sort((ds1: any, ds2: any) => {
                  return ds1.name.toLowerCase().localeCompare(ds2.name.toLowerCase());
                })
                .map((dataSource: any) => {
                  const { id, name } = dataSource;
                  return (
                    <StyledListItem
                      key={id}
                      onClick={() => {
                        if (isContentBlured) {
                          setIsContentBlured(false);
                          setIsSearchInputBlured(true);
                        }
                        onChangeFunc(id);
                      }}
                      className={selectedDataSources.includes(id) ? 'selected' : ''}
                    >
                      <StyledText variant={TextVariants.P2} shade="regular">
                        {name}
                      </StyledText>
                      <TickIcon />
                    </StyledListItem>
                  );
                })}
            </StyledList>
          )}
          {filteredList?.length <= 0 && (
            <NoResults>
              <>
                <NoSearchResults />
                <Text variant="FOOTER" shade="regular">
                  {t('common:no-results-found')}
                </Text>
              </>
            </NoResults>
          )}
        </StyledContainer>
      )}
      {!showSearchContainer && selectedDataSources.length > 0 && (
        <TagsContainer>
          {dataSources
            .filter(d => selectedDataSources.includes(`${d.id}`))
            .sort((ds1: any, ds2: any) => {
              return ds1.name.toLowerCase().localeCompare(ds2.name.toLowerCase());
            })
            .map((dataSource: any) => {
              const { id, name } = dataSource;
              return (
                <Tag
                  key={id}
                  onClose={() => {
                    onRemove(id);
                  }}
                  closeIcon={<RemoveIcon />}
                  closable={true}
                >
                  {name}
                </Tag>
              );
            })}
        </TagsContainer>
      )}
    </>
  );
};
export default DataSourceFilter;
