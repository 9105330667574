import { Form } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Text } from 'rx-shared-lib';
import { useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { onUserSelection, setCloseUserModal, setErrorOnCreate } from '../../../../slice/UsersSlice';
import { MemberFields } from '../../addNewMember';
import styled, { css } from 'styled-components';
import { fetchDataSources, updateUser } from '../../../../actions';
import { FetchGenericEmail } from '../../../../API/Monolith/usersAPI';

const MemberDetails = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    userDetails: { applications },
  } = useAppSelector((state: RootState) => {
    return state.auth;
  });
  const [roleName, setRoleName] = useState<string>('');
  const onSubmit = (values: any) => {
    if (emailError === 'error') {
      return;
    }
    console.log('onSubmitupdate', values);
    const { firstName, lastName, role, dataSources, email } = values;
    const foundRole = roles
      .filter((r: any) => {
        return r.id === role;
      })
      .map((r: any) => {
        return {
          id: r.guid,
          name: r.name,
        };
      });

    const dataSourcesNormalized = dataSources.map((dsId: string) => parseInt(dsId));
    const foundApp = applications.find((a: any) => {
      return a.name === 'Rx Adhere';
    });
    dispatch(
      updateUser({
        userId: editUser.id,
        firstName,
        lastName,
        email,
        role: foundRole[0],
        datasource: dataSourcesNormalized,
        applications: [foundApp?.guid],
      }),
    );
  };

  const [selectedDataSources, setSelectedDataSources] = useState<any>([]);

  const onChange = (newValue: any) => {
    const updatedValues = [...selectedDataSources];
    const index = updatedValues.indexOf(`${newValue}`);
    let newValues;
    if (index > -1) {
      updatedValues.splice(index, 1);
      newValues = updatedValues;
    } else {
      newValues = [...selectedDataSources, newValue];
    }
    setSelectedDataSources(newValues);
    form.setFieldsValue({ dataSources: newValues });
  };

  const onRemove = (id: any) => {
    const updatedValues = [...selectedDataSources];
    const index = updatedValues.indexOf(`${id}`);
    updatedValues.splice(index, 1);
    setSelectedDataSources(updatedValues);
    form.setFieldsValue({ dataSources: updatedValues });
    form.validateFields(['dataSources']);
  };

  const { editUser } = useSelector((state: RootState) => {
    return state.users;
  });

  const { roles, dataSources, errorOnCreate, closeUserModal } = useAppSelector(
    (state: RootState) => {
      return state.users;
    },
  );

  useEffect(() => {
    if (errorOnCreate) {
      if (errorOnCreate.code === 'email') {
        form.validateFields(['email']);
      }
    }
  }, [errorOnCreate, form]);

  const onClose = () => {
    dispatch(onUserSelection(undefined));
  };

  const [emailError, setEmailError] = useState<ValidateStatus>('');

  const ActionBtnContainer = styled.div`
    ${() => css`
      display: flex;
      justify-content: space-between;
      padding-top: 96px;
      align-items: flex-end;
    `}
  `;

  const ActionBtn = styled(Button)`
    ${() => css`
      width: 248px;
      margin-bottom: 20px;
    `}
  `;

  useEffect(() => {
    if (editUser) {
      setEmailError('');
      setSelectedDataSources([]);
      dispatch(setErrorOnCreate(undefined));
      form.resetFields();
      const { firstName, lastName, email, dataSource, userData } = editUser;
      const foundRole = roles.find((r: any) => {
        return r.guid === userData?.id;
      });
      dispatch(fetchDataSources(foundRole?.name?.toLowerCase()));
      setRoleName(foundRole.name.toLowerCase());
      form.setFieldsValue({
        firstName,
        lastName,
        email,
        role: foundRole?.id,
      });
      let values = [];
      if (dataSource) {
        values = dataSource.map((ds: any) => ds.dataSourceId);
      }
      setSelectedDataSources(values);
      form.setFieldsValue({ dataSources: values });
    }
  }, [form, editUser]);

  useEffect(() => {
    if (closeUserModal) {
      onClose();
      dispatch(setCloseUserModal(undefined));
    }
  }, [closeUserModal]);

  useEffect(() => {
    if (dataSources.length === 0) {
      form.setFieldsValue({ dataSources: [] });
      setSelectedDataSources([]);
    }
  }, [dataSources]);

  const onRoleChange = async (roleId: any) => {
    const foundRole = roles.find((r: any) => {
      return r.id === roleId;
    });
    if (['unassigned'].includes(roleName)) {
      const { email, dataSource } = editUser;
      form.setFieldsValue({
        email,
      });
      let values = [];
      if (dataSource) {
        values = dataSource.map((ds: any) => ds.dataSourceId);
      }
      setSelectedDataSources(values);
      form.setFieldsValue({ dataSources: values });
    } else if (['admin'].includes(roleName)) {
      const { dataSource } = editUser;
      let values = [];
      if (dataSource) {
        values = dataSource.map((ds: any) => ds.dataSourceId);
      }
      setSelectedDataSources(values);
      form.setFieldsValue({ dataSources: values });
    }
    setRoleName(foundRole.name.toLowerCase());
    if (foundRole.name.toLowerCase() === 'unassigned') {
      const response = await FetchGenericEmail();
      console.log('unassigned', response);
      form.setFieldsValue({ email: response });
    }
    if (foundRole.name.toLowerCase() === 'admin') {
      form.setFieldsValue({ dataSources: [] });
      setSelectedDataSources([]);
    }
    await dispatch(fetchDataSources(foundRole?.name?.toLowerCase()));
    form.validateFields();
  };

  return (
    <>
      <Form
        layout="horizontal"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div>
          <MemberFields
            onRoleChange={onRoleChange}
            dataSources={dataSources}
            emailError={emailError}
            onChange={onChange}
            setEmailError={setEmailError}
            selectedDataSources={selectedDataSources}
            errorOnCreate={errorOnCreate}
            roles={roles}
            onRemove={onRemove}
            editView={!!editUser}
            roleName={roleName}
          />
        </div>
        <ActionBtnContainer>
          <ActionBtn type="default" size="large" onClick={onClose}>
            <Text variant="P1" shade="semibold" color="inherit">
              {t('common:cancel')}
            </Text>
          </ActionBtn>
          <ActionBtn type="primary" size="large" htmlType="submit">
            <Text variant="P1" shade="semibold" color="inherit">
              {t('common:save')}
            </Text>
          </ActionBtn>
        </ActionBtnContainer>
      </Form>
    </>
  );
};
export default MemberDetails;
