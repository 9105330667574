import { post } from './commonAPI';
import config from '../config';
import { isEventDrivenSystem } from './util';
const url = `${config.REACT_COHORT_BASE_URL}/api/patients/search`;
export const searchPatients = async payload => {
  if (isEventDrivenSystem()) {
    const url = `${config.REACT_COHORT_BASE_URL}/api/patients/search`;
    return post(url, payload);
  } else {
    const url = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/search`;
    const data = {
      searchText: payload.search,
      skip: payload.offset,
      take: payload.limit,
    };
    const response = await post(url, data);
    const res = {
      result: response.searchedPatients.map(patient => {
        return {
          ...patient,
          dob: patient.birthDate,
          phoneNumber: patient.mobilePhone,
        };
      }),
      metadata: {
        total: response.metadata.count,
      },
    };
    return res;
  }
};
