import { Button as AntButton, Modal, Popover, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { useTheme, ThemeProvider } from 'styled-components';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { deselectAllAssignees, fetchUsers, reassignPatient } from '../dashboardSlice';
import { User } from '../Types';
import styles from './AssigneeHeader.module.css';
import { useTranslation } from 'react-i18next';
import { SearchSelectUser } from './SearchSelectUser';
import { Text, Button, stellusEngageTheme } from 'rx-shared-lib';
import notificationBanner from '../../../utility/notification';
import CloseIcon from '../../../assets/X-circle.svg';
import { t } from 'i18next';

export const ASSIGNEE_POPOVER = 'ASSIGNEE_POPOVER';

const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-arrow {
    display: none;
  }
`;

const StyledText = styled(Text)`
  display: flex;
  justify-content: center;
  margin: 24px 0px !important;
`;

const StyledButton = styled(AntButton)`
  border: none;
  color: #336d9f;
  font-size: 14px;
  :hover {
    background: #f4f4f4;
    color: #336d9f;
  }
  :active {
    background: #f4f4f4;
    color: #336d9f;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface ReassignmentModalContentsProps {
  reassignStatus: string;
  assignees: any[];
  selectedUser: any;
  onOk: any;
  t: any;
}

const ReassignmentModalContents = ({
  reassignStatus,
  assignees,
  selectedUser,
  onOk,
  t,
}: ReassignmentModalContentsProps) => {
  const theme = useTheme();
  switch (reassignStatus) {
    // MOVE THIS
    case 'pending':
      return (
        <>
          <StyledText
            color={theme.stellusPalette.indigo['90']}
            variant="H2"
            shade="semibold"
            data-testid="reassignment-pending"
          >
            {t('common:reassign')}?
          </StyledText>
          <Typography.Text>
            {t('common:are-you-sure-you-want-to-reassign')} {assignees.length}{' '}
            {t('common:patients-to')}
            <b>&nbsp;{`${selectedUser?.userFullName}`}?</b>
          </Typography.Text>
          <Button
            style={{
              width: '100%',
              marginTop: '48px',
            }}
            type="primary"
            size="large"
            onClick={onOk}
            data-testid="confirm-reassignment"
          >
            {t('common:confirm')}
          </Button>
        </>
      );

    case 'loading':
      return (
        <div className={styles?.header} data-testid="reassignment-loading">
          Loading...
        </div>
      );

    default:
      return <></>;
  }
};

const configurePopover = ({
  assignees,
  setIsPopoverVisible,
  searchUsers,
  setUserSearchText,
}: {
  assignees: any[];
  setIsPopoverVisible: Function;
  searchUsers: Function;
  setUserSearchText: Function;
}) => {
  if (assignees.length > 0) {
    setIsPopoverVisible(true);
    searchUsers();
  } else {
    setIsPopoverVisible(false);
    setUserSearchText('');
  }
};

const handleModalCancel = ({
  setIsReassignModalVisible,
  setReassignStatus,
  deselectAssignees,
  showPopover,
  isClicked,
}: {
  setIsReassignModalVisible: Function;
  setReassignStatus: Function;
  deselectAssignees: Function;
  showPopover: boolean;
  isClicked: boolean | undefined;
}) => {
  setIsReassignModalVisible(false);
  setReassignStatus('pending');
  if (showPopover && !isClicked) {
    deselectAssignees();
  }
};

const performUserSearch: any = ({
  assignees,
  userSearchText,
  fetchUsers,
  dispatch,
}: {
  assignees: any[];
  userSearchText: string;
  fetchUsers: Function;
  dispatch: Function;
}) => {
  if (assignees.length === 0) return;
  (async () => {
    try {
      (await dispatch(fetchUsers({ userSearchText }))) as User[];
    } catch (error) {
      console.log(error);
    }
  })();
};
const reassignmentNotAllowe =
  'Action not permitted.  This patient`s is assigned to another team member.';

const performUserAssignment = ({
  selectedUser,
  dispatch,
  setReassignStatus,
  reassignPatient,
  setIsReassignModalVisible,
  onFinishReassign,
  assignees,
  oldAssigneeId,
}: {
  selectedUser: any;
  dispatch: Function;
  setReassignStatus: Function;
  reassignPatient: Function;
  setIsReassignModalVisible: Function;
  onFinishReassign: Function;
  assignees: any[];
  oldAssigneeId?: any;
}) => {
  (async () => {
    try {
      const assigneeId = selectedUser?.id ?? '';
      let payload: any = {
        patientIds: [...assignees],
        assigneeId,
        user: selectedUser,
        oldAssigneeId,
      };

      setIsReassignModalVisible(false);
      dispatch(reassignPatient(payload))
        .then((response: any) => {
          setReassignStatus('success');
          notificationBanner({
            type: 'success',
            message: (
              <Typography.Text>
                <b>Success!</b>{' '}
              </Typography.Text>
            ),
            description: (
              <Typography.Text>
                {assignees.length} {t('common:patients-have-been-reassigned-to')}
                <b>&nbsp;{selectedUser?.userFullName}</b>
              </Typography.Text>
            ),
          });

          dispatch(deselectAllAssignees());

          if (onFinishReassign) {
            onFinishReassign();
          }
          setReassignStatus('pending');
        })
        .catch((e: any) => {
          setReassignStatus('failure');
          const description =
            reassignmentNotAllowe === e?.response?.data?.message
              ? reassignmentNotAllowe
              : t('common:please-refresh-and-try-again-or-contact-your-system-administrator');
          notificationBanner({
            type: 'error',
            message: (
              <Typography.Text>
                <b>{t('common:reassignment-failed')}</b>
              </Typography.Text>
            ),
            description: <Typography.Text>{description}</Typography.Text>,
          });
        });
    } catch (error) {
      setReassignStatus('failure');
      notificationBanner({
        type: 'error',
        message: (
          <Typography.Text>
            <b>{t('common:reassignment-failed')}</b>
          </Typography.Text>
        ),
        description: (
          <Typography.Text>
            {t('common:please-refresh-and-try-again-or-contact-your-system-administrator')}
          </Typography.Text>
        ),
      });
      console.log(error);
    }
  })();
};

export const AssigneeHeader = (props: any) => {
  const { selectedAssignees, isClicked, users, oldAssigneeId } = useAppSelector(
    (state: RootState) => {
      return state.dashboard;
    },
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  // const [users, setUsers] = useState<User[]>([]);
  const [userSearchText, setUserSearchText] = useState('');
  const [isReassignModalVisible, setIsReassignModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<undefined | User>(undefined);
  const [reassignStatus, setReassignStatus] = useState('pending');

  const assignees = useMemo(() => Object.keys(selectedAssignees), [selectedAssignees]);

  const searchUsers = useCallback(() => {
    performUserSearch({ assignees, userSearchText, fetchUsers, dispatch });
  }, [assignees, userSearchText]);

  const assignUsers = useCallback(() => {
    performUserAssignment({
      selectedUser,
      dispatch,
      setReassignStatus,
      setIsReassignModalVisible,
      reassignPatient,
      assignees,
      onFinishReassign: props.onFinishReassign,
      oldAssigneeId,
    });
  }, [assignees, userSearchText, selectedUser]);

  const deselectAssignees = () => {
    dispatch(deselectAllAssignees());
  };

  const onOk = () => {
    assignUsers();
  };

  const handleUserSearch = (newValue: string) => {
    setUserSearchText(newValue);
  };

  const handleUserChange = (newValue: any) => {
    setReassignStatus('pending');

    const user = users?.find(user => user.id === newValue);
    setSelectedUser(user);
    setIsReassignModalVisible(newValue ? true : false);
  };

  const onModalCancel = () => {
    handleModalCancel({
      setIsReassignModalVisible,
      setReassignStatus,
      deselectAssignees,
      showPopover: props.showPopover,
      isClicked,
    });
  };

  useEffect(() => {
    configurePopover({ assignees, setIsPopoverVisible, searchUsers, setUserSearchText });
  }, [assignees, searchUsers, setIsPopoverVisible]);

  return (
    <>
      {props.showPopover && !isClicked ? (
        <StyledPopover
          placement="left"
          visible={isPopoverVisible}
          getPopupContainer={triggerNode =>
            document.getElementById('assigneeHeaderId') as HTMLElement
          }
          destroyTooltipOnHide={true}
          overlayInnerStyle={{ padding: '0px' }}
          content={
            <>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                data-testid={ASSIGNEE_POPOVER}
              >
                <Text variant="P1" shade="semibold">
                  {t('common:reassign')} ({assignees.length})
                </Text>
                <StyledButton type="ghost" size="small" onClick={deselectAssignees}>
                  {t('common:cancel')}
                </StyledButton>
              </div>
              <div style={{ marginTop: '12px' }}>
                <SearchSelectUser
                  style={{ width: '240px' }}
                  handleUserChange={handleUserChange}
                  handleUserSearch={handleUserSearch}
                  users={users}
                  searchValue={userSearchText}
                />
              </div>
            </>
          }
        ></StyledPopover>
      ) : (
        <SearchSelectUser
          style={{ width: '75%' }}
          handleUserChange={handleUserChange}
          handleUserSearch={handleUserSearch}
          users={users}
          searchValue={userSearchText}
        />
      )}
      ;
      <ThemeProvider theme={stellusEngageTheme}>
        <StyledModal
          visible={isReassignModalVisible}
          style={{ top: '40%' }}
          closeIcon={
            <img src={CloseIcon} style={{ height: '24px', width: '24px' }} alt="Close-Iocn" />
          }
          onCancel={onModalCancel}
          onOk={assignUsers}
          destroyOnClose={true}
          width={480}
          okText={t('common:confirm')}
          footer={null}
        >
          <ReassignmentModalContents
            reassignStatus={reassignStatus}
            assignees={assignees}
            selectedUser={selectedUser}
            onOk={onOk}
            t={t}
          />
        </StyledModal>
      </ThemeProvider>
    </>
  );
};

export const exportedForTesting = {
  ReassignmentModalContents,
  configurePopover,
  handleModalCancel,
  performUserSearch,
  performUserAssignment,
};
