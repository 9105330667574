import { Alert } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLeft,
  ForgotPasswordComponent,
  PopoverModal,
  stellusEngageTheme,
  Text,
  theme,
} from 'rx-shared-lib';
import styled, { css, ThemeProvider } from 'styled-components';
import { RootState } from '../../app/store';
import { minScreenSize } from '../../ScreenSizes';
import { forgotPassword } from './authAPI';
import styles from './Login.module.css';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const BUTTON = 'BUTTON';
export const FORM = 'FORM';

const StyldForgotPassword = styled(ForgotPasswordComponent)`
  ${({ theme }) => css`
    top: 30%;
    @media screen and ${minScreenSize.laptopL} {
      margin-right: 15%;
    }
  `}
`;

const ErrorTextHolder = styled('div')`
  ${({ theme }) => css`
    background-color: ${theme.palette.error[100]};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    text-align: center;
  `}
`;

const AlertStyled = styled(Alert)`
  font-size: 1rem;
  margin-top: 1rem;
  .ant-alert-content .ant-alert-message {
    font-weight: 600;
    color: #217f32;
  }
  .ant-alert-content .ant-alert-description {
    color: black;
  }
`;

const SuccessPopOver = styled(PopoverModal)`
  ${({ theme }) => css`
    top: 30%;
    @media screen and ${minScreenSize.laptopL} {
      margin-right: 15%;
    }
  `}
`;

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [forgotPasswdModalVisible, setForgotPasswdModalVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  const { t } = useTranslation();

  const { loginError } = useSelector((state: RootState) => {
    return state.auth;
  });

  const onSubmit = async (values: any) => {
    try {
      setProcessing(true);
      await forgotPassword(values.toLowerCase());
      setResetPasswordSuccess(true);
    } catch (error: any) {
      setErrorMessage('Something went wrong. Please try again.');
    } finally {
      setProcessing(false);
    }
  };

  const handleCancel = () => {
    setForgotPasswdModalVisible(false);
    navigate('/');
  };

  const SuccessComponent = () => (
    <SuccessPopOver
      visible={true}
      popoverHeader={t('common:forgot-password-title')}
      width={480}
      height={362}
      onClose={handleCancel}
      mask={false}
      bodyStyle={{ padding: '48px 48px 24px 48px' }}
      content={
        <>
          <AlertStyled
            message="Password reset requested"
            description="If your email address is in our system, you’ll soon receive instructions on how to reset your password."
            type="success"
            showIcon
          />
          <label style={{ fontSize: '1rem', margin: '1rem 0' }}>
            Didn’t get the email?{' '}
            <span
              onClick={() => setResetPasswordSuccess(false)}
              style={{ fontWeight: 700, color: '#565FE8', cursor: 'pointer' }}
            >
              Resend your request
            </span>
          </label>
          <a
            href="/login"
            style={{
              fontWeight: 600,
              color: '#565FE8',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '1rem',
            }}
          >
            <ArrowLeft style={{ marginRight: '0.5rem' }} />
            Back to signin
          </a>
        </>
      }
    />
  );

  return (
    <ThemeProvider theme={stellusEngageTheme}>
      <div className={styles.loginRoot}>
        <ErrorTextHolder>
          <Text variant="P2" color={theme.palette.ancillary.white} shade="semibold">
            {loginError}
          </Text>
        </ErrorTextHolder>

        <div data-testid={FORGOT_PASSWORD} id={FORGOT_PASSWORD}>
          {resetPasswordSuccess ? (
            <SuccessComponent />
          ) : (
            <StyldForgotPassword
              popoverHeader={t('common:forgot-password-title')}
              visible={forgotPasswdModalVisible}
              onSubmit={onSubmit}
              onClose={handleCancel}
              validateStatus={errorMessage ? 'error' : ''}
              helperText={errorMessage}
              resetButtonLable={processing ? 'Processing' : t('common:submit-button-label')}
              isLoading={processing}
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ForgotPassword;
