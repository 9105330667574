import { List } from 'antd';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import styled, { css, useTheme } from 'styled-components';
import { RootState } from '../../../app/store';
import { TypographySubType } from 'rx-shared-lib/dist/components/Text/TextUtil';
import { variantMap } from 'rx-shared-lib';
export const PHARMACY_SEARCH_LIST = 'PHARMACY_SEARCH_LIST';
export const PHARMACY_NAME = 'PHARMACY_NAME';
const StyledListItem = styled(List.Item)`
  ${({ theme }) => css`
    border-bottom: none !important;
    cursor: pointer;

    width: 100%;
    &:hover {
      background: ${theme.palette.graphite[20]} !important;
    }
  `}
`;

const StyledList = styled(List)`
  ${({ theme }) => css`
    overflow: scroll;
    margin-right: -3px;
    max-height: 200px;
  `}
`;

const StyledName = styled('div')`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    padding-left: ${theme.spacingInPx(4)};
    align-items: center;
    height: 40px;
    width: 100%;
    ${variantMap(TypographySubType.regular, 'P2')['P2'].styles};
  `}
`;
const StyledHighlighter = styled(Highlighter)`
  ${({ theme }) => css`
    mark {
      font-weight: 700;
      background-color: rgba(0, 0, 0, 0);
      margin: 0px;
      padding: 0px;
    }
  `}
`;

export const PharmacySearchList = ({ data, searchText, onClick }: any) => {
  const theme: any = useTheme();
  const onPharmacyClick = async (pharmacy: any) => {
    onClick(pharmacy);
  };
  const { searchedPharmacyName } = useSelector((state: RootState) => {
    return state.dashboard;
  });

  return (
    <StyledList
      dataSource={data}
      data-testid={PHARMACY_SEARCH_LIST}
      renderItem={(pharmacy: any, index: number) => (
        <StyledListItem
          data-testid={PHARMACY_NAME}
          style={{
            background: searchedPharmacyName === pharmacy.name ? theme.palette.goldenrod[10] : '',
          }}
          key={index}
          onClick={() => {
            onPharmacyClick(pharmacy);
          }}
        >
          <StyledName>
            <StyledHighlighter
              searchWords={searchText ? [...searchText?.split(/[, ]+/)] : []}
              autoEscape={true}
              textToHighlight={pharmacy?.name}
            />
          </StyledName>
        </StyledListItem>
      )}
    />
  );
};

export default PharmacySearchList;
