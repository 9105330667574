import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import commonTranslationEN from './assets/locales/en/common.json';
import loginTranslationEN from './assets/locales/en/login.json';
import dashboardEN from './assets/locales/en/dashboard.json';
import patientCardEN from './assets/locales/en/patientCard.json';
import patientFiltersEN from './assets/locales/en/patientFilters.json';
import patientGridHeade from './assets/locales/en/header.json';

const resources = {
  en: {
    // Namspaces
    common: commonTranslationEN,
    login: loginTranslationEN,
    dashboard: dashboardEN,
    patientCard: patientCardEN,
    patientFilters: patientFiltersEN,
    header: patientGridHeade,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: localStorage.getItem('lng') || 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    defaultNS: 'common',
  });

export default i18n;
