import React from 'react';
import { Divider, Select } from 'antd';
import styled, { css, useTheme } from 'styled-components';
import { Text, DropDown } from 'rx-shared-lib';
import _ from 'lodash';

const StyledText = styled(Text)`
  ${({ theme }) => css`
    margin-right: ${theme.spacingInPx(1)} !important;
  `}
`;
const StyledOptionsContainer = styled('div')`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    align-items: center;
  `}
`;
const StyledDivider = styled(Divider)`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0px;
    width: 100%;
  `}
`;

const StyledDropDown = styled(DropDown)`
  ${() => css`
    .ant-select-item-option-content .measureCountInPayer {
      display: none;
    }
  `}
`;

interface FilterDropDownInterface {
  value: any;
  defaultValue?: any;
  filteredResult?: any;
  onChangeFunc: any;
  type?: string;
  options?: any;
}

const { Option } = Select;
const FilterDropDown = ({
  value,
  defaultValue,
  filteredResult,
  onChangeFunc,
  type,
  options,
}: FilterDropDownInterface) => {
  const theme: any = useTheme();
  return (
    <StyledDropDown
      labelInValue
      defaultValue={defaultValue}
      value={value}
      onChange={onChangeFunc}
      options={options}
      getPopupContainer={(triggerNode: any) => triggerNode}
    >
      {_.map(filteredResult, (filter: any) => {
        return (
          <Option value={type === 'team' ? filter.uId : filter.id} disabled={filter?.count === 0}>
            <StyledOptionsContainer>
              <StyledText variant="P2" shade="regular" color={theme.stellusPalette.grey['90']}>
                {filter.name}
              </StyledText>{' '}
              {filter?.count >= 0 && (
                <Text variant="P3" shade="regular" color={theme.stellusPalette.grey['70']}>
                  ({filter?.count})
                </Text>
              )}
            </StyledOptionsContainer>
            <StyledDivider />
          </Option>
        );
      })}
    </StyledDropDown>
  );
};

export default FilterDropDown;
