import { post } from '../commonAPI';
import config from '../../config';

const searchUrl = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/assignees/search`;
const reassignUrl = `${config.REACT_APP_MONOLITH_BASE_URL}/api/patients/assignees`;

export const searchAssignees = payload => {
  return post(searchUrl, payload);
};

export const reassignPatients = payload => {
  return post(reassignUrl, payload);
};
