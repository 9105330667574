import { useTranslation } from 'react-i18next';
import { FetchRoles } from '../API/Monolith/securityAPI';
import {
  CreateUser,
  FetchDataSources,
  GetUsers,
  UpdateUser,
  UpdateUserStatus,
} from '../API/Monolith/usersAPI';

import { RootState } from '../app/store';
import { setAnswerLoader } from '../features/dashboard/dashboardSlice';

import {
  setRoles,
  setDataSources,
  setErrorOnCreate,
  setCloseUserModal,
  setUserList,
  onPaginationChanged,
  onPageSizeChanged,
  setSearchUserList,
  setSearchLoader,
} from '../slice/UsersSlice';

export const fetchRoles = () => async (dispatch: any, getState: () => RootState) => {
  try {
    let roles = await FetchRoles();
    dispatch(setRoles(roles));
  } catch (error) {
    dispatch(setRoles([]));
    console.error(error);
  }
};

const transformDataSourceApiResponse = (dataSources: any[]) => {
  return dataSources.map(d => {
    return { id: d.DataSourceId, name: d.DataSourceName };
  });
};

export const fetchDataSources =
  (roleName: string) => async (dispatch: any, getState: () => RootState) => {
    try {
      let dataSources = await FetchDataSources(roleName);
      dispatch(setDataSources(transformDataSourceApiResponse(dataSources)));
    } catch (error) {
      dispatch(setDataSources([]));
      console.error(error);
    }
  };

export const createUser = (data: any) => async (dispatch: any, getState: () => RootState) => {
  try {
    await CreateUser(data);
    dispatch(setErrorOnCreate({ code: 'userCreateSuccess', message: '' }));
    dispatch(setCloseUserModal(true));
  } catch (error: any) {
    dispatch(setCloseUserModal(undefined));
    if (error.response) {
      const message = (error as any).response.data.message;
      if (message === 'create => email already exist for another user') {
        return dispatch(setErrorOnCreate({ code: 'email', message }));
      }
    } else if (error.request) {
      // The client never received a response, and the request was never left
    } else {
      // Anything else
      console.error(error.message);
    }
    dispatch(
      setErrorOnCreate({
        code: 'userUpdateFailed',
        header: 'Error while updating user!',
        message: 'Please contact Administrator.',
      }),
    );
  }
};

export const fetchUserList = (payload: any) => async (dispatch: any, getState: () => RootState) => {
  try {
    const response = await GetUsers({ pageSize: 10, pageNumber: 0 });
    const { data, totalCount } = response || {};
    dispatch(onPageSizeChanged(10));
    dispatch(setUserList({ data, total: totalCount }));
  } catch (error: any) {
    console.error(error);
  }
};

export const searchPaginatedUserList =
  (searchQuery: string, pageNumer: number) => async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setSearchLoader(true));
      const response = await GetUsers({
        pageSize: 20,
        pageNumber: pageNumer,
        searchQuery,
      });
      const { data, totalCount } = response || {};
      dispatch(setSearchUserList({searchPageNumber : pageNumer, data, total: totalCount }));
      dispatch(setSearchLoader(false));
    } catch (error: any) {
      dispatch(setSearchLoader(false));
      console.error(error);
    }
  };

export const fetchPaginatedUserList =
  (startRow: number, endRow: number, sort?: any, highlightRow?: any) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      const offset = startRow;
      let limit = Math.abs(endRow - startRow);
      const pageNumber = Math.floor(offset / limit) + 1 > 0 ? Math.floor(offset / limit) + 1 : 1;
      dispatch(onPaginationChanged({ offset, limit }));
      const response = await GetUsers({
        pageSize: limit > 0 ? limit : 10,
        pageNumber: pageNumber,
      });
      const { data, totalCount } = response || {};
      dispatch(setUserList({ data, total: totalCount }));
    } catch (error: any) {
      console.error(error);
    }
  };

export const updateUserStatus =
  (payload: any) => async (dispatch: any, getState: () => RootState) => {
    try {
      const { userId, status, startRow, endRow } = payload;
      await UpdateUserStatus(userId, { enable: status === 0 ? false : true });
      dispatch(fetchPaginatedUserList(startRow, startRow + endRow));
    } catch (error: any) {
      if (error.response) {
        const message = (error as any).response.data.message;
        if (message === 'Error while disabling user. User have active patient(s)') {
          dispatch(
            setErrorOnCreate({
              code: 'useractivationfailed',
              message: 'Error while disabling user. User have active patient(s)',
            }),
          );
        }
      } else if (error.request) {
        dispatch(setErrorOnCreate(undefined));
        // The client never received a response, and the request was never left
      } else {
        // Anything else
        dispatch(setErrorOnCreate(undefined));
        console.error(error.message);
      }
    }
  };

export const updateUser = (data: any) => async (dispatch: any, getState: () => RootState) => {
  try {
    dispatch(setAnswerLoader(true));
    const { userId, ...rest } = data;
    await UpdateUser(userId, rest);
    dispatch(setErrorOnCreate({ code: 'userUpdateSuccess', message: '' }));
    const {
      users: { offset, limit },
    } = getState();
    const startRow = offset || 0;
    const endRow = limit || 10;
    dispatch(fetchPaginatedUserList(startRow, startRow + endRow));
    dispatch(setAnswerLoader(false));
  } catch (error: any) {
    dispatch(setAnswerLoader(false));
    if (error?.response) {
      const message = (error as any).response.data.message;
      if (message === 'update => email already exist for another user') {
        return dispatch(setErrorOnCreate({ code: 'email', message }));
      } else if (message === 'Error while updating user. User have active patient(s)') {
        return dispatch(setErrorOnCreate({ code: 'patientExistInDatasource', message }));
      }
    } else if (error?.request) {
      // The client never received a response, and the request was never left
    } else {
      // Anything else
      console.error(error?.message);
    }
    dispatch(
      setErrorOnCreate({
        code: 'userUpdateFailed',
        header: 'Error while updating user!',
        message: 'Please contact Administrator.',
      }),
    );
  }
};
