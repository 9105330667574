import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { UserData, login, UserDetails, fetchUserDetails, changePassword } from './authAPI';

export interface AuthState {
  isAuthenticated: boolean;
  loginError: string;
  changePasswordError?: string;
  userData: UserData;
  userDetails: UserDetails;
  selfCohortGlobalId: string;
  GlobalId: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  loginError: '',
  selfCohortGlobalId: '',
  GlobalId: '',
  changePasswordError: '',
  userData: {
    userId: '123',
    firstName: 'Default',
    lastName: 'User',
    name: 'Default User',
  },
  userDetails: {
    applications: [],
    email: 'default.user@gmail.com',
    enabled: true,
    guid: '!@#',
    id: 1,
    mobile: '555-5555',
    name: 'Default User',
    password: '',
    roles: {
      name: 'none',
      guid: '123!@#',
      enabled: true,
    },
    avatarColor: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    onLoginError: (state, action: PayloadAction<string>) => {
      state.loginError = action.payload;
    },
    onChangePasswordError: (state, action: PayloadAction<string>) => {
      state.changePasswordError = action.payload;
    },
    onLoginSuccess: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },
    onFetchUserDetailsSuccess: (state, action: PayloadAction<UserDetails>) => {
      state.userDetails = action.payload;
      state.isAuthenticated = true;
      state.loginError = '';
    },
    onFetchUserDetailsFailure: (state, action: PayloadAction<string>) => {
      state.loginError = action.payload;
    },

    setSelfGlobalId: (state, action: PayloadAction<string>) => {
      state.selfCohortGlobalId = action.payload;
    },

    setGlobalId: (state, action: PayloadAction<string>) => {
      state.GlobalId = action.payload;
    },
  },
});

export const {
  onLoginSuccess,
  setSelfGlobalId,
  setGlobalId,
  onLoginError,
  onChangePasswordError,
  onFetchUserDetailsSuccess,
  onFetchUserDetailsFailure,
} = authSlice.actions;

export const isUserAuthenticated = (state: RootState) => state.auth.isAuthenticated;

export const onLogin = (userName: string, password: string) => {
  return async (dispatch: any) => {
    try {
      const loginData = await login(userName, password);
      dispatch(onLoginSuccess(loginData));
      await dispatch(onFetchUserDetails(loginData.globalId));
    } catch (error) {
      console.log(error);
      dispatch(onLoginError('Invalid Email or Password.'));
    }
  };
};

export const onChangePassword = (key: string, emailId: string, password: string) => {
  return async (dispatch: any) => {
    try {
      await changePassword(key, emailId, password);
    } catch (error) {
      dispatch(
        onChangePasswordError(
          'Unable to reset password. Please use link available in your email to change password.',
        ),
      );
    }
  };
};

export const onFetchUserDetails = (userId: string) => {
  return async (dispatch: any) => {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const userDetail = await fetchUserDetails(userId);
        dispatch(onFetchUserDetailsSuccess(userDetail));
        resolve(userDetail);
      } catch (error) {
        dispatch(onFetchUserDetailsFailure('error fetching user details'));
      }
    });
  };
};

export default authSlice.reducer;
