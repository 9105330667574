import { Avatar } from 'antd';
import { Text } from 'rx-shared-lib';
import styled, { useTheme } from 'styled-components';
import UserOptionsDropdown from './UserOptionsDropdown';
export const USER_OPTIONS = 'USER_OPTIONS';

const StyledAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.ant-avatar-lg {
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }
  }
`;
export default function UserOptions({
  avatarColor,
  userInitials,
}: {
  avatarColor: string;
  userInitials: string;
}) {
  const theme: any = useTheme();
  return (
    <div data-testid={USER_OPTIONS}>
      <UserOptionsDropdown avatarColor={avatarColor} userInitials={userInitials}>
        <StyledAvatar
          style={{
            backgroundColor: avatarColor,
            paddingRight: 0,
          }}
          size={48}
        >
          <Text shade="regular" variant="P3" color={theme.palette.ancillary.white}>
            {userInitials}
          </Text>
        </StyledAvatar>
      </UserOptionsDropdown>
    </div>
  );
}
